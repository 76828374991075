import React from 'react';
import './App.css';
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import RenderOnAnonymous from "./authentication/RenderOnAnonymous";
import RenderOnAuthenticated from "./authentication/RenderOnAuthenticated";
import Routes from "./routes/routes";
import SignupRoute from "./routes/routes";
import { store } from './app/store';
import { Helmet } from 'react-helmet';
import { ThemeProvider } from '@mui/styles';
import { createTheme } from '@mui/material';



function App() {
  const theme = createTheme({})



  return (
    <>
      {/* <div className="mobile">
      Mobile App in progress, please login in Desktop or Laptop. Thank you.
      </div> */}
      <div className="docsteth-app app">
        <Helmet>
          <title>Docsteth</title>
        </Helmet>

        <Provider store={store}>
          <ThemeProvider theme={theme}>
            <BrowserRouter>
              <RenderOnAnonymous>
                <SignupRoute />
              </RenderOnAnonymous>
              <RenderOnAuthenticated>
                <Routes />
              </RenderOnAuthenticated>
            </BrowserRouter>
          </ThemeProvider>
        </Provider>
      </div>
    </>
  );
}

export default App;
