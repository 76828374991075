import React, { useState, useEffect } from 'react';
import './profile.css';
import "../../utility/style.css";
import { Avatar, Grid, Box, Tab } from "@mui/material";
import { ClickAwayListener } from '@mui/material';
import Drawer from '@mui/material/Drawer';
// import Update from '../MyProfile/organisation/userupdate'
import MyProfile from '../MyProfile/myprofile';
// import Publication from "../publications/publicaton";
import Edit from "../../Atom/svg/primaryEdit.svg";
import EditIcon from '@mui/icons-material/Edit';
import img from '../../Atom/img/human-brain-medical-digital-illustration.png'
import axios from 'axios';
import URL from '../../apiurl'
import { updateProfile, handleActiveValue, fetchProfile, getMetaData } from '../../../redux/Actions/actions';
import { useDispatch, useSelector } from 'react-redux';
import { Snackbar, Button } from '@mui/material'
import { Helmet } from 'react-helmet';
import InputField from '../../Fields/TextFields';
import UserService from '../../../services/UserService'
import ImageCropper from './imageCropper';
import DialogBox from '../../DialogBox/dialog';
import Development from '../../Molecule/EbmKit/development';
import { useHistory, useParams, useLocation } from 'react-router';
import Feed from '../../Molecule/Feed/Feed';
import useMediaQuery from '@mui/material/useMediaQuery';
import profilecoverpic from "../../../icons/backgroundpeople.png";
import profimage from '../../../icons/Userprofilelogo.svg'
import { WhatsappShareButton, EmailShareButton, LinkedinShareButton, TwitterShareButton, FacebookShareButton } from 'react-share';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import TwitterIcon from '@mui/icons-material/Twitter';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import EmailIcon from '@mui/icons-material/Email';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import FacebookIcon from '@mui/icons-material/Facebook';
import { Typography, Divider } from '@mui/material';
import Dialog from "@mui/material/Dialog";
import '../../peers/peer.css';
// import { styled } from '@mui/material/styles';
import { styled } from '@mui/styles';
import shareicon from '../../../icons/share_icon.svg'
import UserProfileOverview from './UserProfileOverview';
import IconButton from '@mui/material/IconButton';
import { ValidatorForm } from 'react-material-ui-form-validator';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import Settings from '../../../icons/settings.svg';
import ProfileTimeline from './ProfileTimeline';
import edit1 from '../../../icons/editt.svg';
import Verified from '../../../icons/verifieduser.svg';
import ProfilePublication from './ProfilePublication';
import Bookmark from './Bookmark';
import ErrorIcon from '@mui/icons-material/Error';
// import Verified from '../../../icons/verifieduser.svg'
import Verifyprofile from './Verifyprofile';
import { headers } from '../../../redux/Actions/connectActions';
import { getPractitionerFromFhir, userId } from '../../../redux/Actions/regActions';
import { DOC_API, D_API, D_API_FHIR, DOCS_WITHOUT_TOKEN } from '../../../services/APIService';
import CustomInput from '../../common/inputs/customInput';
import { deleteEmptyKeys } from '../../common/functions/functions';
import dayjs from 'dayjs';
import e from 'cors';
import Update from '../MyProfile/organisation/userupdate';
// import Update from '../../Layout/MyProfile/userupdate'


export const AntTabs = styled(TabList)({
    '& .MuiTabs-indicator': {
        backgroundColor: '#1500B2',
    },
    '& .MuiTouchRipple': {
        color: "blue !important"
    }
});

const formatAddress = (address) => {
    // console.log(address)
    return [
        {
            text: address.line1 + "," + address.line2 + "," + address.city + "," + address.state + "," + address.country + "," + address.postalCode,
            line: [address.line1, address.line2],
            city: address.city,
            state: address.state,
            postalCode: address.postalCode,
            country: address.country,
        }
    ]
}

const AboutBox = (props) => {
    const [about, setAbout] = useState(props.about)

    return (
        <DialogBox open={props.open} handleDialog={props.close}
            Title="About"
            Content={
                <>
                    <textarea rows="5" value={about} style={{ width: "100%", fontFamily: 'Helvetica,Neue', padding: '5px', fontSize: '16px' }}
                        onChange={(e) => setAbout(e.target.value)}
                    />
                    <div style={{ textAlign: 'right' }}>
                        <Button onClick={(e) => props.submit(about)}>Update</Button>
                        <Button onClick={props.close}>Cancel</Button>
                    </div>
                </>
            }
        />
    )
}

const CustomButton = (props) => {
    return (
        <Button className="add-option" sx={{ mt: '0px!important', mr: 2, fontWeight: '550!important', padding: '5px 12px!important' }} variant="outlined">{props.children}&nbsp;{props.name}</Button>
    )
}

const CustomRow = (props) => {
    return (
        <Typography component="div" sx={{ px: 2, pt: 2 }} >
            <Typography component="p" sx={{ pb: 1 }} className="e-align d-flex">
                {props.children}
            </Typography>
            {props.true ? '' : <Divider sx={{ backgroundColor: 'rgba(0, 0, 0, 0.12)' }} />}
        </Typography>
    )
}

// const DrawerApp = (props) => {
//     // console.log(props)
//     const { open, toggleDrawer } = props



//     return (
//         <ClickAwayListener onClickAway={toggleDrawer}>
//             <Drawer
//                 anchor={'right'}
//                 open={open}
//                 onClose={toggleDrawer}
//                 // variant="persistent"
//                 sx={{
//                     '& .MuiPaper-root': {
//                         top: '65px!important',
//                         right: '0px!important'
//                     },
//                     '& .MuiBackdrop-root': {
//                         opacity: '0.7!important',
//                         backgroundColor: 'white!important',
//                         top: '60px!important'
//                     },
//                     position: 'absolute',
//                     top: '60px'
//                 }}>
//                 <Box
//                     sx={{ width: 450 }}
//                     role="presentation"
//                 >
//                     <Verifyprofile toggleDrawer={toggleDrawer} />
//                 </Box>
//             </Drawer >
//         </ClickAwayListener >
//     )
// }

export default function Profile(props) {
    // console.log(props)


    let active = sessionStorage.getItem("profile-active") || '0'
    const [open, setOpen] = React.useState(false);
    const [address, setAddress] = useState([])
    const e_types = ["", ""]
    // console.log(address)
    // const matches = useMediaQuery('(max-width:769px)');
    const matches = useMediaQuery('(max-width:961px)');
    const [profile, setProfile] = useState(parseInt(active));
    const [view, setView] = useState(false)
    const [verify, setVerify] = React.useState({ open: false, key: '', value: '', index: '' })
    // const [show, setShow] = useState(false)
    const [about, setAbout] = useState(false)
    const [otp, setOtp] = React.useState('')
    const [url, setUrl] = useState('')
    const [edit, setEdit] = useState(false);
    const [cover, setCover] = useState(img)
    const [user, setUser] = useState({})
    const [contact, setContact] = useState([])
    const [alertSnack, setAlertSnack] = useState({ open: false, msg: '' })
    const [alert, setAlert] = useState({ open: false, msg: '', upload: false })
    const dispatch = useDispatch();
    const [stepUpdate, setStepUpdate] = useState()
    const [hprId, setHprId] = useState('')
    const [usersDatas, setUsersDatas] = useState()
    const [userAccountDet, setUserAccountDet] = useState()
    const [userAcPractitioner, setUserAcPractitioner] = useState({})
    const state = useSelector(state => state.userProfile.userProfile);

    const [licenceDet, setLicenceDet] = useState([])
    const [qualificationDetail, setQualificationDetail] = useState([])
    const qualify = (state.hasOwnProperty("practioner")) ? state.practioner.qualification : ""
    // console.log(qualify)
    const education = ((qualify !== null) && (qualify.length > 0)) ? qualify[qualify.length - 1] : null
    // console.log(education)
    const filedata = useSelector(state => state.userProfile.exp)
    // console.log(filedata)
    const experience = (filedata !== undefined) ? filedata[filedata.length - 1] : ""
    // console.log(experience)

    // console.log((state.hasOwnProperty("practioner")) ? state.practioner.address[0].city : "")
    // console.log(filedata)
    const active_value = useSelector(state => state.userProfile.active)
    const [imageCrop, setImageCrop] = useState({ open: false, state: '' })
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [value, setValue] = React.useState('1');
    const [identity1, setIdentity1] = React.useState("");

    let { name } = useParams()
    let { id } = useParams();
    const locObj = useLocation();

    const userData = useSelector(state => state.userProfile.userProfile)
    const practitionerDetails = useSelector(state => state.registration.fhirUserData)
    console.log(practitionerDetails, "practitionerDetails")
    const history = useHistory()
    // console.log(`/myprofile/${UserService.getUsername().split("@")[0]}-${userId}`)
    // console.log(`/myprofile/${state.fullName ? state.fullName.replace(/ /g, '').toLowerCase() : ''}-${userId}`)

    useEffect(() => {
        dispatch(fetchProfile(sessionStorage.getItem("userId")))


        if (userId !== id) {

            // history.push(`/myprofile/${name}-${id}`)
            history.push(`/myprofile/${state.fullName ? state.fullName.replace(/ /g, '').toLowerCase() : UserService.getUsername().split("@")[0]}-${id}`)
            // history.push(`/myprofile/${UserService.getUsername().split("@")[0]}-${userId}`)
            // history.push("/")
        }
    }, [])

    // useEffect(() => {
    //     if (locObj?.state?.opnEditProfile) {
    //         setEdit(locObj?.state?.opnEditProfile);
    //     }
    // }, [locObj])


    const title = state.fullName;
    const url1 = `https://www.docsteth.com/profile/${name}-${id}`;
    const image = user.photo;
    const description = state.about

    const HandleCopy = () => {
        navigator.clipboard.writeText(`https://www.docsteth.com/profile/${name}-${id}`)
    }

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };


    useEffect(() => {
        dispatch(handleActiveValue(0))
    }, [active_value])


    useEffect(() => {
        dispatch(getPractitionerFromFhir());
    }, [practitionerDetails?.id])


    // useEffect(() => {
    //     let data = state;
    //     console.log(data, "vuppula")
    //     if (Object?.keys(data)?.length > 0) {
    //         //     // console.log(data)
    //         let name = data.practioner.name[0]
    //         let contact = data.practioner.telecom
    //         let address = data.practioner.address ? data.practioner.address[0] : []
    //         const cerQualifications = [];
    //         const otherQualifications = [];
    //         data?.practioner?.qualification?.forEach(qualification => {
    //             if (qualification?.identifier?.[0]?.type?.coding?.[0]?.code === "CER") {
    //                 cerQualifications.push({
    //                     degree: {
    //                         system: qualification?.code?.coding?.[0]?.system,
    //                         display: qualification?.code?.text
    //                     },
    //                     course: qualification?.code?.coding?.[0],
    //                     orgName: "rtyu",
    //                     issuer: {
    //                         name: qualification?.issuer?.display,
    //                         id: qualification?.issuer?.reference?.split('/')?.[1],
    //                         code: qualification?.issuer?.code
    //                     },
    //                     dateIss: e?.period?.end,
    //                     identifier: qualification?.identifier?.[0]?.value
    //                 });
    //             } else {
    //                 otherQualifications.push({
    //                     council: qualification?.issuer?.display,
    //                     regNo: qualification?.identifier?.[0]?.value,
    //                     date: qualification?.period?.start,
    //                     dueDate: qualification?.period?.end
    //                 });
    //             }
    //         });

    //         console.log(otherQualifications, "@1", cerQualifications)
    //         setQualificationDetail(cerQualifications);
    //         setLicenceDet(otherQualifications);

    //         //     const identifiers = data?.practioner?.identifier?.length > 0 ? data?.practioner?.identifier : [];
    //         //     const identity12 = identifiers
    //         //         .filter(e => e.system === "https://healthid.ndhm.gov.in/")
    //         //         .map(e => e.value)[0];
    //         //     setIdentity1(identity12)

    //         //     // const identity12 = data?.practioner?.identifier?.length > 0
    //         //     //     ? data.practioner.identifier
    //         //     //         .filter(e => e.system === "https://healthid.ndhm.gov.in/")
    //         //     //         .map(e => e.value)[0]
    //         //     //     : undefined;

    //         //     // setIdentity1(identity12);

    //         setUser({
    //             family: name.family,
    //             active: data?.practioner?.active,
    //             firstName: name.given[0],
    //             middleName: name.given[1],
    //             prefix: name.prefix ? name.prefix[0] : '',
    //             suffix: name?.suffix ? [...name?.suffix] : "",
    //             text: name.text,
    //             gender: data.practioner.gender,
    //             birthDate: data.practioner.birthDate,
    //             caption: data.captionAndTaglines,
    //             photo: data.practioner.photo[0].url,
    //             about: data.about,
    //             id: data.id,
    //             keycloakId: data.keycloakId,
    //             coverPic: data.coverPic,
    //             address: data.practioner.address,
    //             about: data?.about || "",
    //             identifier: data?.practioner?.identifier,
    //             qualification: data.practioner.qualification || [],
    //             languages: data?.practioner?.communication?.length > 0 ? data?.practioner?.communication?.map(e => e?.language?.coding?.[0]) : []
    //             // languages: data?.practioner?.communication?.length > 0 ? data?.practioner?.communication : []
    //         })
    //         const addressData = {
    //             facilityName: address.facilityName ? address.facilityName : '',
    //             text: address.text,
    //             line1: address !== undefined && address.hasOwnProperty('line') ? address.line[0] : '',
    //             line2: address !== undefined && address.hasOwnProperty('line') ? address.line[1] : '',
    //             city: address ? address.city : " ",
    //             state: address.state,
    //             postalCode: address.postalCode,
    //             country: address.country,
    //             district: address.district,
    //         }
    //         setContact(contact)
    //         setAddress(addressData)
    //     }
    //     if (alert.upload) {
    //         setAlert({ open: true, msg: 'Uploaded Successfully', upload: false })
    //         setImageCrop({ open: false, image: '' })
    //     }
    // }, [state, edit])

    useEffect(() => {
        let data = practitionerDetails;

        if (Object?.keys(data)?.length > 0) {
            let name = data?.name?.[0]?.given?.[0];
            // let address = data.practioner.address ? data.practioner.address[0] : []
            const cerQualifications = [];
            const otherQualifications = [];
            data?.qualification?.forEach((qualification) => {
                if (qualification?.identifier?.[0]?.type?.coding?.[0]?.code === "CER") {
                    cerQualifications.push({
                        degree: {
                            system: qualification?.code?.coding?.[0]?.system,
                            display: qualification?.code?.text
                        },
                        course: qualification?.code?.coding?.[0],
                        orgName: "rty",
                        issuer: {
                            name: qualification?.issuer?.display,
                            id: qualification?.issuer?.reference?.split('/')?.[1]
                        },
                        dateIss: qualification?.period?.end,
                        identifier: qualification?.identifier?.[0]?.value

                    })
                }
                else {
                    otherQualifications.push({
                        council: qualification?.issuer?.display,
                        regNo: qualification?.identifier?.[0]?.value,
                        date: qualification?.period?.start,
                        dueDate: qualification?.period?.end
                    });
                }
            })
            setQualificationDetail(cerQualifications);
            setLicenceDet(otherQualifications);

            setContact(data?.telecom ? data.telecom : [])

            setUser({
                family: data?.name?.[0]?.family,
                firstName: data?.name?.[0]?.given?.[0],
                middleName: data?.name?.[0]?.given?.[1],
                prefix: data?.name?.[0]?.prefix ? data?.name?.[0]?.prefix?.[0] : '',
                suffix: data?.name?.[0]?.suffix ? [...data?.name?.[0]?.suffix] : [],
                birthDate: data?.birthDate,
                qualification: data?.qualification,
                text: data?.name?.[0]?.text,
                languages: data?.communication?.length > 0 ? data?.communication?.map(e => e?.coding?.[0]) : [],
                gender: data.gender,
                photo: data?.photo?.[0]?.url ? data?.photo?.[0]?.url : "",
                caption: data.identifier && data.identifier?.find((e) => e["system"] == "https://profiletagline/" && e["value"]) ? data.identifier?.find((e) => e["system"] == "https://profiletagline/" && e["value"])?.value : "",
                // about: data.about,
                // id: data.id,
                // photo: data.practioner.photo[0].url,
                // coverPic: data.coverPic,
                // languages: data?.practioner?.communication?.length > 0 ? data?.practioner?.communication?.map(e => e?.language?.coding?.[0]) : []
            })
            const addressData = {
                text: [`${data?.address?.[0]?.line?.[0]},${data?.address?.[0]?.line?.[1]},${data?.address?.[0].city},${data?.address?.[0]?.state},${data?.address?.[0]?.country},${data?.address?.[0]?.postalCode}`]?.filter((val) => Boolean(val)),
                line1: data?.address?.[0]?.line?.[0] ? data?.address?.[0]?.line?.[0] : "",
                line2: data?.address?.[0]?.line?.[1] ? data?.address?.[0]?.line?.[1] : '',
                city: data?.address?.[0].city ? data?.address?.[0].city : "",
                state: data?.address?.[0]?.state ? data?.address?.[0]?.state : "",
                postalCode: data?.address?.[0]?.postalCode ? data?.address?.[0]?.postalCode : "",
                country: data?.address?.[0]?.country ? data?.address?.[0]?.country : "",
                district: data?.address?.[0].city,
            }
            setAddress(addressData)

        }
        // if (Object?.keys(data)?.length > 0) {
        //     let name = data.practioner.name[0]
        //     let contact = data.practioner.telecom
        //     let address = data.practioner.address ? data.practioner.address[0] : []
        //     const cerQualifications = [];
        //     const otherQualifications = [];
        //     console.log(data?.practioner?.qualification, "50")
        //     data?.practioner?.qualification?.forEach(qualification => {
        //         if (qualification?.identifier?.[0]?.type?.coding?.[0]?.code === "CER") {
        //             cerQualifications.push({
        //                 degree: {
        //                     system: qualification?.code?.coding?.[0]?.system,
        //                     display: qualification?.code?.text
        //                 },
        //                 course: qualification?.code?.coding?.[0],
        //                 orgName: "rtyu",
        //                 issuer: {
        //                     name: qualification?.issuer?.display,
        //                     id: qualification?.issuer?.reference?.split('/')?.[1]
        //                 },
        //                 dateIss: qualification?.period?.end,
        //                 identifier: qualification?.identifier?.[0]?.value
        //             });
        //         } else {
        //             otherQualifications.push({
        //                 council: qualification?.issuer?.display,
        //                 regNo: qualification?.identifier?.[0]?.value,
        //                 date: qualification?.period?.start,
        //                 dueDate: qualification?.period?.end
        //             });
        //         }
        //     });

        //     setQualificationDetail(cerQualifications);
        //     setLicenceDet(otherQualifications);
        //     setUser({
        //         family: name.family,
        //         firstName: name.given[0],
        //         middleName: name.given[1],
        //         prefix: name.prefix ? name.prefix[0] : '',
        //         suffix: name.suffix ? [...name.suffix] : "",
        //         text: name.text,
        //         gender: data.practioner.gender,
        //         birthDate: data.practioner.birthDate !== null ? data.practioner.birthDate : '',
        //         caption: data.captionAndTaglines,
        //         about: data.about,
        //         id: data.id,
        //         photo: data.practioner.photo[0].url,
        //         coverPic: data.coverPic,
        //         qualification: data.practioner?.qualification,
        //         languages: data?.practioner?.communication?.length > 0 ? data?.practioner?.communication?.map(e => e?.language?.coding?.[0]) : []
        //     })
        //     // console.log(address)
        //     const addressData = {
        //         facilityName: address.facilityName ? address.facilityName : '',
        //         text: address.text,
        //         line1: address.hasOwnProperty('line') ? address?.line[0] : '',
        //         line2: address.hasOwnProperty('line') ? address?.line[1] : '',
        //         city: address.city,
        //         state: address.state,
        //         postalCode: address.postalCode,
        //         country: address.country,
        //         district: address.district,
        //     }

        //     setContact(contact)
        //     setAddress(addressData)
        // }
        // else {
        //     let userid = sessionStorage.getItem("userId")
        // }

    }, [practitionerDetails])
    // console.log(state)
    // console.log(address)



    console.log(user, "user")

    useEffect(() => {
        if (state.practioner) {
            if (state.practioner.birthDate === null) {
                // console.log("hello")
                setEdit(true)
            }
        }
    }, [state])

    useEffect(() => {
        // D_API_FHIR.get(`Practitioner?identifier=${process.env.REACT_APP_DOCSTETH}|${UserService.getUserId()}`)
        //     .then(res => {
        //         // setUserAcPractitioner(res?.data?.entry?.[0] || {})
        //         // setPractitionerBool(res?.data?.entry?.[0] || {})
        //         // res?.data?.entry?.[0]?.resource?.identifier?.some(e => e?.value.split('.')[1] === 'abdm' ? setPractitioner(e?.value.split('.')[1]) : console.log('no hpr'));

        //     })
        //     .catch(err => err);
    }, []);

    useEffect(() => {
        axios.get(process.env.REACT_APP_CLINIDESK + `getStepsByUserId/${UserService.getUserId()}`)
            .then(res => {
                setStepUpdate(res?.data || {})
            })
            .catch(err => console.log(err))
    }, [])

    // 


    const handleSubmit = (form, contact, address, edu, licence, UsersDatas, ident,) => {
        console.log(licence, "lice", edu)

        let educationDetails = [];
        let licenceDetails = [];
        edu?.map((e) => {
            if (e?.course?.code && e?.degree?.system && e?.issuer?.name && e?.dateIss) {
                educationDetails.push(e)
            }
        })

        licence?.map((li) => {
            if (li?.regNo && li?.council && li?.date && li?.dueDate) {
                licenceDetails.push(li)
            }
        })


        console.log(licenceDetails, educationDetails, "submit");

        // dispatch(updateProfile(form, contact, formatAddress(address), user.qualification))
        // dispatch(updateProfile(form, contact, formatAddress(address), edu, licence, stepUpdate, UsersDatas, ident))
        dispatch(updateProfile(form, contact, formatAddress(address), educationDetails, licenceDetails, stepUpdate, UsersDatas, ident))
        setEdit(false)
    }

    const submitAbout = (userAbout) => {
        setUser({ ...user, about: userAbout })
        dispatch(updateProfile({ ...user, about: userAbout }, contact, formatAddress(address), qualificationDetail, licenceDet, stepUpdate, usersDatas))
        // dispatch(updateProfile({ ...user, about: userAbout }, contact, formatAddress(address), user.qualification))
        setAbout(false)
    }

    const handleDelete = (id) => {

        let contacts = [...contact]
        contacts = contacts.filter((contact, i) => id !== i ? { ...i } : '')
        setContact(contacts)
        dispatch(updateProfile(user, contacts, formatAddress(address), qualificationDetail, licenceDet, stepUpdate, usersDatas))
        // dispatch(updateProfile(user, contacts, formatAddress(address), user.qualification))

    }

    const handleVisibility = (data) => {
        let length = contact.length
        if (contact[length - 1].system !== '' && contact[length - 1].use !== '' && contact[length - 1].value !== '') {
            setContact(data)
            dispatch(updateProfile(user, data, formatAddress(address), qualificationDetail, licenceDet))
            // dispatch(updateProfile(user, data, formatAddress(address), user.qualification))
        }
    }

    useEffect(() => {
        ValidatorForm.addValidationRule('length', (value) => {
            if (value.length !== 6) {
                return false;
            }
            return true;
        });
    }, [])

    useEffect(() => {
        let link = window?.location?.href?.split('#')?.[1];
        const element = document.getElementById(`${link}`);
        if (element) {
            element.scrollIntoView()
        }
    }, [])

    const submitOTP = () => {
        let con = [...contact]
        con[verify.index]['verify'] = true
        const data = {
            otp: otp,
            metadata: {},
            [verify.key]: verify.value,
        };
        axios.post(`${verify.key === 'email' ? URL.verifyEmail : URL.verifyOTP}`, data).then(res => {
            if (res["status"] === 200) {
                setAlertSnack({ open: true, msg: `${res.data.message}` });
                setVerify({ open: false, key: '', value: '', index: '' })
                handleVisibility(con)
            } else {
                setAlertSnack({ open: true, msg: `${res.data.message}` });
                setVerify({ open: false, key: '', value: '', index: '' })
            }
        }, error => {
            setAlertSnack({ open: true, msg: "Either otp is expired or invalid." });
            setVerify({ open: false, key: '', value: '', index: '' })
        })

    }


    const sendOTP = (value, i) => {
        let length = contact.length
        if (contact[length - 1].system !== '' && contact[length - 1].use !== '' && contact[length - 1].value !== '') {
            const data = {
                metadata: {},
                phoneNumber: value
            }
            axios.post(`${URL.signupSendOTP}`, data).then(res => {
                if (res["status"] === 200) {
                    if (res.data.status === 201) {
                        setAlertSnack({ open: true, msg: `${res.data.message}` })
                        setVerify({ open: true, key: 'phoneNumber', value: value, index: i })

                    } else if (res.data.status === 409) {
                        setAlertSnack({ open: true, msg: `${res.data.message}` })
                        setVerify({ open: false, key: '', value: '', index: '' })
                    }
                } else {
                    setAlertSnack({ open: true, msg: "Internal server error" });
                    setVerify({ open: false, key: '', value: '', index: '' })
                }
            }, error => {
                setAlertSnack({ open: true, msg: "Error " });
            })
        }
        else {
            alert('Please fill the empty fields')
        }
    }

    const sendEmailOTP = (value, i) => {
        let length = contact.length
        if (contact[length - 1].system !== '' && contact[length - 1].use !== '' && contact[length - 1].value !== '') {
            const data = {
                metadata: {},
                email: value
            }
            axios.post(`${URL.sendEmailOTP}`, data).then(res => {
                if (res["status"] === 200) {
                    if (res.data.status === 200) {
                        setAlertSnack({ open: true, msg: `${res.data.message}` })
                        setVerify({ open: true, key: 'email', value: value, index: i })

                    } else if (res.data.status === 409) {
                        setAlertSnack({ open: true, msg: `${res.data.message}` })
                        setVerify({ open: false, key: '', value: '', index: '' })
                    }
                } else {
                    setAlertSnack({ open: true, msg: "Internal server error" });
                    setVerify({ open: false, key: '', value: '', index: '' })
                }
            }, error => {
                setAlertSnack({ open: true, msg: "Error " });
            })
        }
        else {
            alert('Please fill the empty fields')
        }
    }

    const handleActive = (val) => {
        sessionStorage.setItem("profile-active", val)
        let v = sessionStorage.getItem("profile-active")
        // console.log(v)
        setProfile(v)
    }



    const uploadFile = (e, state) => {
        if (e.target.files.length > 0) {
            if (state) {
                // console.log(state)
                const reader = new FileReader()
                let type = e.target.files[0].type.split('/')
                // console.log(type[1])
                if (type[1] === "jpg" || type[1] === 'jpeg' || type[1] === 'png') {
                    reader.readAsDataURL(e.target.files[0]);

                    // reader.onloadend = () => {
                    //     // console.log(reader.result)
                    //     let file = reader.result.split(',')
                    //     // console.log(file)
                    //     if(state === 'profile'){
                    //     setUrl(reader.result)
                    //     fileUpload(file[1],type[1],'profilePic')
                    //     }
                    //     else{
                    //         setCover(reader.result)
                    //         fileUpload(file[1],type[1],'userCoverPhoto')
                    //     }

                    // }

                    reader.onloadend = () => {
                        setImageCrop({ open: true, state: state })
                        setUrl(reader.result)
                    }
                    e.target.value = ''

                }
                else {
                    setAlert({ open: true, msg: 'Only .jpeg, .png, .jpeg extensions allowed', upload: false })
                }
            }
        }
    }

    const uploadImage = (image, state) => {
        let file = image.split(',')
        let type = file[0].split('/')
        let ty = type[1].split(';');
        // console.log(state, "++++", image)
        if (state === 'profile') {
            setUrl(image)
            setAlert({ open: true, msg: 'Uploading Profile Pic...', upload: true })
            fileUpload(file[1], ty[0], 'profilePic')
        }
        else {
            setCover(image)
            setAlert({ open: true, msg: 'Uploading Cover Pic...', upload: true })
            fileUpload(file[1], ty[0], 'userCoverPhoto')
        }
    }


    const fileUpload = (file, type, subType) => {
        let data = {
            "file": [file],
            "fileType": type,
            "multiple": false,
            "subType": subType,
            "type": "userProfilePics"
        }
        // console.log(data)
        axios.post(`${URL.uploadFile}`, data, headers()).then(res => {
            // console.log(res)
            if (res.data.status === 200) {
                // setUser({...user,coverPic: res.data.data.data})
                let data = { ...user };
                console.log(data, "++++")
                // console.log(res.data.data[0],data)
                if (subType === 'profilePic') {
                    data = { ...user, photo: res.data.data[0] }
                    // console.log(data)
                    setUrl(res.data.data[0])
                    console.log(user.qualification, licenceDet, "7070")
                    // dispatch(updateProfile(data, contact, data.address, user.qualification, licenceDet))
                    dispatch(updateProfile(data, contact, data.address, qualificationDetail, licenceDet))
                    setImageCrop({ open: false, state: '' })

                }
                else {
                    data = { ...user, coverPic: res.data.data[0] }
                    setCover(res.data.data[0])
                    // dispatch(updateProfile(data, contact, data.address, user.qualification, licenceDet))
                    dispatch(updateProfile(data, contact, data.address, qualificationDetail, licenceDet))
                    setImageCrop({ open: false, state: '' })
                }
            }
        }).catch(error => { })
    }
    function formatNumber(number) {
        // var formattedNumber = number.match(/.{1,4}/g).join('-');
        var formattedNumber = number.substring(0, 2) + '-' + number.substring(2, 6) + '-' + number.substring(6, 10) + '-' + number.substring(10, 14);

        return formattedNumber;
    }
    useEffect(() => {
        // D_API.get(`Practitioner/identifier/dev.docsteth.com/${UserService.getUserId()}`)
        //     .then(res => {
        //         console.log(res)
        //         setUsersDatas(res?.data?.entry?.[0]?.resource || {})
        //     })
        //     .catch(err => console.log(err))
        // DOC_API.get(`User/${UserService.getUserId()}`)
        //     .then(res => {
        //         console.log(res, "5556789vbnm")
        //         setUsersDatas(res?.data?.data || {})
        //     }).catch(err => console.log(err))
    }, [])
    useEffect(() => {
        DOCS_WITHOUT_TOKEN.get(`Practitioner/identifier/new/dev.docsteth.com/${UserService.getUserId()}`)
            .then(res => {
                // console.log(res)
                setUsersDatas(res?.data?.entry?.[0] || {})
            })
            .catch(err => console.log(err))
    }, [])


    console.log(usersDatas, "7878", state)


    const submitHpr = () => {


        // dispatch(updateProfile(form, contact, formatAddress(address), edu, licence, stepUpdate, UsersDatas, ident))
        dispatch(updateProfile({ ...user }, contact, formatAddress(address), qualificationDetail, licenceDet, stepUpdate, usersDatas, formatNumber(hprId)))
        setView(false)

        console.log("hpr")
        // let formattedNumber = formatNumber(hprId);
        // let data1 = {
        //     "about": usersDatas?.about,
        //     "captionAndTaglines": usersDatas?.captionAndTaglines,
        //     "fullName": usersDatas?.fullName,
        //     "id": usersDatas?.id,
        //     "keyclockId": usersDatas?.keyclockId,
        //     "coverPic": usersDatas?.coverPic,
        //     "metadata": usersDatas?.metadata,
        //     "active": false,
        //     "practioner": {
        //         "resourceType": "Practitioner",
        //         "id": usersDatas?.id,
        //         "text": {
        //             "status": "generated",
        //             "div": `<div xmlns=http://www.w3.org/1999/xhtml>\n      <p>${usersDatas?.fullName}</p>\n    </div>`
        //         },
        //         "name": [...usersDatas?.practioner?.name],
        //         "telecom": usersDatas?.practioner?.telecom,
        //         "gender": user?.gender,
        //         identifier: [
        //             ...(usersDatas?.practioner?.identifier?.length >= 2
        //                 ? [
        //                     ...usersDatas?.practioner?.identifier,
        //                     {
        //                         "system": "https://healthid.ndhm.gov.in/",
        //                         "value": formattedNumber
        //                     }
        //                 ]
        //                 : [
        //                     {
        //                         "system": "https://dev.docsteth.com",
        //                         "value": `${UserService.getUserId()}`
        //                     },
        //                     {
        //                         "system": "https://dev.docsteth.com",
        //                         "value": usersDatas?.userName
        //                     },
        //                     {
        //                         "system": "https://healthid.ndhm.gov.in/",
        //                         "value": formattedNumber
        //                     }
        //                 ]
        //             )
        //         ],
        //         "birthDate": usersDatas?.practioner?.birthDate,
        //         "photo": usersDatas?.practioner?.photo,

        //         "address": usersDatas?.practioner?.address,
        //         ...(usersDatas?.practioner?.qualification?.length > 0 &&
        //             { qualification: usersDatas?.practioner?.qualification }
        //         ),
        //     }
        // }

        // let dataAcc = {
        //     "resourceType": "Practitioner",

        //     "name": [
        //         {
        //             "use": "official",
        //             "text": usersDatas?.practioner?.name?.[0]?.text,
        //             "family": usersDatas?.practioner?.name?.[0]?.family,
        //             "given": [
        //                 usersDatas?.practioner?.name?.[0]?.given?.[0]
        //             ],
        //             "prefix": usersDatas?.practioner?.name?.[0]?.prefix,
        //             "suffix": usersDatas?.practioner?.name?.[0]?.suffix?.[0]
        //         }
        //     ],

        //     ...(usersDatas?.practioner?.telecom?.length > 0 && {
        //         telecom: usersDatas?.practioner?.telecom?.map(each => (
        //             each.system === "phone" ? {
        //                 "system": "phone",
        //                 "use": each?.use,
        //                 "value": each?.value
        //             } : {
        //                 "system": "email",
        //                 "use": each?.use,
        //                 "value": each?.value
        //             }
        //         ))
        //     }),


        //     ...(user?.gender && { "gender": user?.gender }),
        //     active: false,
        //     identifier: [
        //         ...(usersDatas?.practioner?.identifier?.length >= 2
        //             ? [
        //                 ...usersDatas?.practioner?.identifier,
        //                 {
        //                     "system": "https://healthid.ndhm.gov.in/",
        //                     "value": formattedNumber
        //                 }
        //             ]
        //             : [
        //                 {
        //                     "system": "https://dev.docsteth.com",
        //                     "value": `${UserService.getUserId()}`
        //                 },
        //                 {
        //                     "system": "https://dev.docsteth.com",
        //                     "value": usersDatas?.userName
        //                 },
        //                 {
        //                     "system": "https://healthid.ndhm.gov.in/",
        //                     "value": formattedNumber
        //                 }
        //             ]
        //         )
        //     ],
        //     // "birthDate": "2024-05-01",
        //     "birthDate": dayjs(usersDatas?.practioner?.birthDate).format('YYYY-MM-DD'),
        //     ...(usersDatas?.practioner?.address?.[0]?.line?.[0] && usersDatas?.practioner?.address?.[0]?.line?.[1] && { "address": usersDatas?.practioner?.address?.[0] }),
        //     // "photo": usersDatas?.practioner?.photo,
        //     // ...(usersDatas?.practioner?.qualification?.length > 0 &&
        //     //     { qualification: usersDatas?.practioner?.qualification }
        //     // ),


        // }
        // // console.log("jkdjskjsksjk", deleteEmptyKeys(dataAcc))
        // console.log(data1)
        // let dataAccc = deleteEmptyKeys(dataAcc)
        // axios.put(`${URL.updateUser}/${user.id}`, data1, headers())
        //     .then(response => {
        //         console.log(response)
        //         // updateStepsData("1", stepUpdate)
        //         dispatch(fetchProfile(user.id))
        //         setView(false)
        //         // window.location.reload();
        //     })
        //     .catch(error => { })
        // if (userAccountDet?.resource?.id) {
        //     D_API_FHIR.put(`Practitioner/${userAccountDet?.resource?.id}`, { ...dataAcc, id: userAccountDet?.resource?.id }, headers())
        //         .then(response => {
        //             console.log(response)
        //             // updateStepsData("1", stepUpdate)
        //             // dispatch(fetchProfile(user.id))
        //         })
        //         .catch(error => { })
        // }
        // else {
        //     D_API_FHIR.post(`Practitioner`, dataAcc, headers())
        //         .then(response => {
        //             console.log(response)
        //             // updateStepsData("1", stepUpdate)
        //             // dispatch(fetchProfile(user.id))
        //         })
        //         .catch(error => { })
        // }

    }
    const initHPR = () => {
        console.log("first")
        // API_WEBHOOKS.post('hpr/auth/init', { hprId }).then(res => setTxnId(res.data.txnId)).catch(() => {
        //     alert("Internal Server Error")
        //     setHprId('')
        //     setOtp('')
        //     setTxnId('')
        // })
    }
    return (
        <div className="profile">
            {/* {console.log(state.practioner)} */}
            <Helmet>
                <title>{`${state.fullName}-Profile | Docsteth `}</title>
                <meta name="description" content={state.about} />
                <meta name="canonical" content={url1} />
                <meta name="keywords" content={`${state.fullName}, "Docsteth", ${state.fullName} `} />
                <meta property="image" content={user.photo} />
            </Helmet>
            {console.log(user, 844, user?.languages)}
            {edit ?
                <Update open={edit} data={user} address={address} languageValue={user?.languages}
                    con={contact} handleDelete={handleDelete}
                    handleVisibility={handleVisibility} charan={"charan"}
                    handleSubmit={handleSubmit} handleDialog={() => setEdit(false)} width="md" sendOTP={sendOTP} sendEmailOTP={sendEmailOTP}
                />
                : ''}
            {/* {console.log(address)} */}

            <DialogBox open={view} handleDialog={() => setView(false)}
                Title={`Get Verified With Ayushman Bharath Digital Health Mission`} onClose={() => setView(false)}

                Content={<>
                    <Divider />
                    <ValidatorForm onSubmit={() => submitHpr()}>
                        {/* <Typography>Get your HPR ID</Typography> */}
                        <h3 className='mt1'>Existing HPR - User</h3>
                        <div className='flx-center'>
                            <Grid container spacing={2}>
                                <Grid item xs={10}>
                                    <CustomInput type='Number' placeholder="Enter 14 Digits HPR ID" Icon={<>@hpr.abdm</>} value={hprId} name="userName"
                                        handleChange={(name, value) => setHprId(value?.slice(0, 14))}
                                    />
                                </Grid>
                                <Grid item xs={2}>
                                    <Button type='submit' sx={{ color: 'red' }} className={!hprId ? "" : 'clr-btn'} variant={"contained"} disabled={hprId.length !== 14}
                                    // onClick={initHPR}
                                    >{"Submit"}</Button>
                                </Grid>
                            </Grid>
                            {/* <p style={{ flex: props.popup ? 1 : 0.3 }}> */}

                            {/* </p> */}
                            {/* <div className="ml2" style={{ flex: 0.4 }}> */}

                            {/* </div> */}

                        </div>
                    </ValidatorForm>
                </>
                    // Content={<Add state={state} handleChange={handleChange} handleOrg={handleOrg} cancel={() => handleCancel()} handleSubmit={edit ? handleUpdate : handleAdd} submit={edit ? "Update" : "Add"} check={check} handleCheck={(e) => setCheck(e.target.checked)} handlecity={handlecity}
                    // />
                }
            />



            {about ? <AboutBox submit={submitAbout} open={about} about={user.about} close={() => setAbout(false)} /> : ''}
            <DialogBox open={imageCrop.open} width={imageCrop.state === 'cover' ? "lg" : 'md'} handleDialog={() => setImageCrop({ open: false, state: '' })} Content={<ImageCropper url={url} state={imageCrop.state} save={uploadImage} handleDialog={() => setImageCrop({ open: false, state: '' })} />} />
            {/* <div className='profilecontainer'> */}

            <DialogBox open={verify.open} handleDialog={() => setVerify({ open: false, key: '', value: '', index: '' })} Title="Verify OTP" width="xs"

                Content={<>

                    <ValidatorForm onSubmit={() => submitOTP()}>

                        <InputField label="OTP" placeholder="Enter OTP"

                            value={otp} name="OTP" handleChange={(name, value) => setOtp(value)}

                            validators={["required", 'length']}

                            errorMessages={['Please Enter OTP', "Enter Valid OTP"]}

                        />

                        <div style={{ textAlign: 'right' }}>

                            <Button type="submit">Verify</Button>

                            <Button onClick={() => setVerify({ open: false, key: '', value: '', index: '' })}>Cancel</Button>

                        </div>

                    </ValidatorForm>

                </>

                }

            />

            <div style={{ width: '100%', overflow: 'hidden', height: `${user.coverPic ? '' : ''}`, }} className={`${user.coverPic ? '' : 'cover__pic'}`}>
                <div className='coverpic' style={{ position: 'relative' }}>
                    <img src={(user.coverPic) ? user.coverPic : profilecoverpic} className="profile__cover" alt="" width="100%" />
                    <div className="profile__top">
                        <div className="profileEdit">
                            <input type="file" name="uploadfile" id="img" accept='.jpg, .png, .jpeg' style={{ display: 'none' }} onChange={(e) => uploadFile(e, 'cover')} />
                            <label htmlFor="img" className="follow-link font-weight add-cover cursor">
                                <img src={Edit} alt="edit" className='cover_edit_icon' />
                            </label>
                        </div>
                    </div>
                </div>
            </div>
            <Grid container id="profile_header" className="border_img1" style={{ height: "220px", backgroundColor: "#ffffff", margin: "-4px 0px 0px 0px", }}>
                <Grid item md={4}>
                    <div className="profile__header_a" id='laptopscreen'>
                        <div className="profile__left">
                            {/* <Avatar className="profile-pic" style={{ height: "144px", width: "144px", position: 'absolute', top: '-100px', marginLeft: '30px' }} />
                                <EditIcon className="edit-icon cursor" onClick={()=>console.log("profile")} style={{ fontSize: "16px", color: 'black', position: 'absolute', top: '-51px',left: '93px' }} /> */}
                            <div className="show-image" >
                                {/* <Avatar src={user.photo} id='profile__pic' /> */}
                                <img src={(user.photo) ? user.photo : profimage} id='profile__pic' style={{ boxShadow: 'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px', border: '4px solid #fff' }}></img>
                                <p className='cover-link'>
                                    <input type="file" name="uploadfile2" id="img1" accept='.jpg, .png, .jpeg' style={{ display: 'none' }} onChange={(e) => uploadFile(e, 'profile')} />
                                    <label htmlFor="img1" className="follow-link font-weight add-cover cursor">
                                        <span className="edit-icon" id="hover_black_none">
                                            <EditIcon className="cursor" style={{ fontSize: "16px", position: 'absolute', top: '15px', left: '62px' }} />
                                        </span>
                                    </label>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className='details__were'>
                        <div className="sidepadding bg__white" style={{ display: "flex" }}>
                            {/* name */}
                            <div className="sidebar__stat1">
                                <div>
                                    <p className="sidebar__title capitalize" id="username_dec">{user.text}</p>
                                    {/* <p className="sidebar__title capitalize" id="user_title" style={{ color: "#2E3B43", fontSize: "21px", display: "flex", justifyContent: "flex-end" }}> {(state.practioner.name[0].suffix[0]) ? state.practioner.name[0].suffix[0] : "N/A"}</p> */}
                                </div>
                                <div>
                                    <p className="sidebar__title" style={{ fontSize: "18px", paddingTop: '0px', paddingBottom: '5px', color: "rgb(54, 69, 79)" }}>{user.caption}</p>
                                </div>
                                {state.practioner && (state?.practioner?.address?.[0]?.city || state?.practioner?.address?.[0]?.state || state?.practioner.address?.[0]?.country) ?
                                    <div style={{ paddingTop: "8px" }}>
                                        <span className="sidebar__title" style={{ fontSize: "18px", fontWeight: "400", color: "rgb(54, 69, 79)" }} >{(state.hasOwnProperty("practioner")) ? state.practioner.address[0].city : ""}</span>
                                        {state.practioner && state.practioner.address[0].city && state.practioner.address[0].state ? ',' : ''} <span className="sidebar__title" style={{ fontSize: "18px", fontWeight: "400", color: "rgb(54, 69, 79)" }}>{(state.hasOwnProperty("practioner")) ? state.practioner.address[0].state : ""}</span>
                                        {state.practioner && state.practioner.address[0].state && state.practioner.address[0].country ? ',' : ''} <span className="sidebar__title" style={{ fontSize: "18px", fontWeight: "400", color: "rgb(54, 69, 79)" }}>{(state.hasOwnProperty("practioner")) ? state.practioner.address[0].country : ""}</span>
                                    </div>
                                    : <p style={{ fontSize: "18px", color: "rgb(54, 69, 79)", paddingTop: "8px" }}></p>}
                            </div>
                        </div>
                    </div>
                    <div style={{ display: "flex", justifyContent: "flex-start", alignItems: "center", padding: "10px 0px 0px 20px" }}>
                        <div>
                            {/* <IconButton aria-label="visible" onClick={() => setEdit(true)}> */}
                            <div className='edit_btn cursor' onClick={() => setEdit(true)}>
                                <img width="18px" height="18px" src={edit1}></img>
                                <span>Edit Profile</span>
                                {/* <FiEdit3 style={{ color: "rgb(46, 59, 67)" }}></FiEdit3> */}
                            </div>
                            {/* </IconButton> */}
                        </div>
                        <div>
                            <a className='setting-btn' href={`${process.env.REACT_APP_API_ACCOUNTS}`} target="_blank">
                                <img width="18px" height="18px" src={Settings}></img>
                                <span>Settings</span>
                            </a>
                        </div>
                        <div>
                            <Button className='sh_icon' variant='outlined' onClick={handleClickOpen}><img src={shareicon} alt="share" width='20px' />&nbsp;
                            </Button>
                            <Dialog
                                open={open}
                                onClose={handleClose}
                                aria-labelledby="alert-dialog-title"
                                aria-describedby="alert-dialog-description"
                            >
                                <Box>
                                    <Typography sx={{ p: '15px', backgroundColor: '#007dcd', color: 'white' }}>
                                        Invite Your Peers
                                    </Typography>
                                    <CustomRow>
                                        <WhatsappShareButton
                                            windowWidth='800px'
                                            windowHeight='800px'
                                            url={url1}
                                            title={title}
                                            onShareWindowClose={() => handleClose}
                                        >
                                            <CustomButton name="share Profile" >
                                                <WhatsAppIcon className='inside_icon' sx={{ color: 'black' }} />
                                            </CustomButton>
                                        </WhatsappShareButton>

                                        <TwitterShareButton
                                            windowWidth='800px'
                                            windowHeight='800px'
                                            url={url1}
                                            title={title}
                                        >
                                            <CustomButton name="share in Twitter">
                                                <TwitterIcon className='inside_icon' sx={{ color: 'black' }} />
                                            </CustomButton>
                                        </TwitterShareButton>
                                    </CustomRow>

                                    <CustomRow>
                                        <LinkedinShareButton
                                            url={`https://www.docsteth.com/myprofile/${name}-${id}`}
                                            title={`${state.fullName} - ${user.caption} | Docsteth `}
                                            summary={`${state.about}`}
                                        >
                                            <CustomButton name="share in Linkedin" >
                                                <LinkedInIcon className='inside_icon' sx={{ color: 'black' }} />
                                            </CustomButton>
                                        </LinkedinShareButton>
                                        <FacebookShareButton
                                            windowWidth='800px'
                                            windowHeight='800px'
                                            url={url1}
                                            quote={`${state.fullName} - ${user.caption} | Docsteth `}
                                            hashtag='#docsteth'
                                        >
                                            <CustomButton name="share in Facebook">
                                                <FacebookIcon className='inside_icon' sx={{ color: 'black' }}></FacebookIcon>
                                            </CustomButton>
                                        </FacebookShareButton>
                                    </CustomRow>

                                    <CustomRow true={true}>
                                        <EmailShareButton
                                            url={`https://www.docsteth.com/myprofile/${name}-${id}`}
                                            separator={"    =>  "}
                                            subject={`${state.fullName} - ${user.caption} | Docsteth `}
                                            body={`${state.about}`}
                                        >
                                            <CustomButton name="Email Invite">
                                                <EmailIcon className='inside_icon' sx={{ color: 'black' }} />
                                            </CustomButton>
                                        </EmailShareButton>
                                        {/* <CustomButton name="SMS">
                                            <SmsIcon className='inside_icon' sx={{ color: 'black' }} />
                                        </CustomButton> */}
                                        <div onClick={HandleCopy}>
                                            <CustomButton name="Copy Invite" >
                                                <ContentCopyIcon className='inside_icon' sx={{ color: 'black' }} />
                                            </CustomButton>
                                        </div>
                                    </CustomRow>
                                    <Typography onClick={handleClose} className="textCenter font-bold cursor" sx={{ mt: 1, p: '10px', backgroundColor: '#e4eef5', color: '#0f3f5d' }}>
                                        Done
                                    </Typography>
                                </Box>
                            </Dialog>
                        </div>
                    </div>
                </Grid>
                <Grid item md={3}>

                </Grid>
                {/* <Grid item md={4}>
                    <div className='badges_responsive'>
                        <div className="sidebar__icon1" id='flex_align'>
                            <div className="sidebar__title sidebar__pb fw__normal">Certifications</div>
                            <img src={certificate} alt="certificate" />
                            <div className="sidebar__statNumber">{user.certificatesCount}</div>
                        </div>
                        <div className="sidebar__icon1" id='flex_align'>
                            <div className="sidebar__title sidebar__pb fw__normal">Badges</div>
                            <img src={ribbon} alt="certificate" />
                            <div className="sidebar__statNumber">{user.badgesCount}</div>
                        </div>
                        <div className="sidebar__icon1" id='flex_align'>
                            <div className="sidebar__title sidebar__pb fw__normal">Achievements</div>
                            <img src={medal} alt="certificate" />
                            <div className="sidebar__statNumber">{user.achievementsCount}</div>
                        </div>
                        <div className="sidebar__statNumber1">
                        </div>
                    </div>
                    <div style={{ display: "flex", marginTop: "20px" }}>
                        {(experience) ?
                            <div className="sidebar__substat ta__center">
                                <img src={(experience.logo) ? experience.logo : Organisation} width="40px" height="40px" alt="medxpert" />
                                <div className="sidebar__title sidebar__ml " style={{ textTransform: 'capitalize' }}>
                                    <h5 style={{ fontSize: "16px", color: "#000000", fontWeight: "300" }}>{(experience.designation) ? experience.designation : ""}</h5>
                                    <h5 style={{ fontSize: "16px", color: "#000000", fontWeight: "300" }}>{(experience.organisationName) ? experience.organisationName : experience.unofficialOrganisationName}</h5>
                                </div>
                            </div>
                            : "N/A"}
                    </div>
                    <div style={{ display: "flex", marginTop: "20px" }}>
                        {education ?
                            <div className="sidebar__substat ta__center">
                                <img src={Organisation} width="40px" height="40px" alt="medxpert" />
                                <div className="sidebar__title sidebar__ml " style={{ textTransform: 'capitalize' }}>
                                    <h5 style={{ fontSize: "16px", color: "#000000", fontWeight: "300" }}>{(education.code.coding[0].display) ? education.code.coding[0].display : null}-{(education.code.text) ? education.code.text : ""}</h5>
                                    <h5 style={{ fontSize: "16px", color: "#000000", fontWeight: "300" }}>{(education.issuer) ? education.issuer : ""}</h5>
                                </div>
                            </div>
                            : ""}
                    </div>
                </Grid> */}
                {/* <Grid item md={1}>

                </Grid> */}
                <Grid item md={5} style={{ marginTop: "20px" }}>
                    {/* <div style={{ display: "flex", justifyContent: "flex-end", alignItems: "center", paddingRight: "10px" }}>
                        <Button className='error_text'><ErrorIcon className='error_icon' />Get Verified</Button>
                    </div> */}



                    {
                        usersDatas?.resource?.active === true ?

                            <div style={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
                                <Button className='verify_text'><img src={Verified} style={{ paddingRight: "10px" }} width="20px" height="20px"></img>Verified</Button>
                            </div>
                            :
                            usersDatas?.resource?.active === false && (usersDatas?.resource?.identifier === null || state?.practioner?.identifier?.length >= 3) ?
                                <div style={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
                                    <Button className='error_text'>
                                        <ErrorIcon className='error_icon' />
                                        {/* <img src={Verified} style={{ paddingRight: "10px" }} width="20px" height="20px"></img> */}
                                        Pending</Button>
                                </div>

                                :

                                <div style={{ display: "flex", justifyContent: "flex-end", alignItems: "center", paddingRight: "10px" }}>
                                    <Button onClick={() => setView(true)} className='error_text'><ErrorIcon className='error_icon' />
                                        {/* <a href={`${process.env.REACT_APP_API_ACCOUNTS}`} target="_blank"> */}
                                        Get Verified
                                        {/* </a> */}
                                    </Button>
                                </div>



                    }

                    {/* {

                        (

                            state?.practioner?.active === false && (state?.practioner?.identifier === null || state?.practioner?.identifier?.length <= 2)
                        ) ?

                            <div style={{ display: "flex", justifyContent: "flex-end", alignItems: "center", paddingRight: "10px" }}>
                                <Button onClick={() => setView(true)} className='error_text'><ErrorIcon className='error_icon' />
                                    Get Verified

                                </Button>
                            </div>
                            :
                            (
                                state?.practioner?.active === false
                            ) ?
                                <div style={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
                                    <Button className='error_text'>
                                        <ErrorIcon className='error_icon' />
                                        Pending
                                    </Button>
                                </div>
                                :
                                <div style={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
                                    <Button className='verify_text'><img src={Verified} style={{ paddingRight: "10px" }} width="20px" height="20px"></img>Verified</Button>
                                </div>
                    } */}
                    {/* {view ? <div style={{ position: 'relative' }}><DrawerApp open={view} toggleDrawer={() => setView(false)} /></div> : ''} */}
                    {/* <div style={{ display: "flex", alignItems: "center" }}> */}
                    {/* <div className="sidebar__statNumber pr-2">
                        <Button className='highlight-btn'>
                            Add Highlights
                        </Button>
                    </div> */}
                    {/* <div>
                        <div className='mt-20'>
                            <span style={{ display: "flex", alignItems: "center" }}><img src={Globe} width="25px" height="25px" style={{ paddingRight: "10px" }}></img>Website</span>
                        </div>
                        <div className='mt-10 pl-3'>
                            <a target="_blank" href={`www.docsteth.com/profile/${name}-${id}`}><p style={{ fontSize: "14px", color: "blue" }}>{`www.docsteth.com/profile/${name}-${id}`}</p></a>
                        </div>
                    </div> */}
                    {/* </div> */}
                    {/* <div className='qr_code'>
                        <img src={QRCODE} width="80px" height="80px" ></img>
                    </div>
                    <div className='qr_code'>
                        <span>Scan QR Code</span>
                    </div> */}
                </Grid>
            </Grid>
            <Grid container>
                <Grid item xs={12}>
                    <TabContext value={value}>
                        <Box sx={{ width: '100%', display: "flex", justifyContent: "space-between" }} className='tablet_display'>
                            <div className="profile__info border_img" style={{ width: "100%" }}>
                                <ul>
                                    <li className={`${active == 0 ? 'profile-active3' : ''}`} onClick={() => handleActive('0')}>Overview</li>
                                    <li className={`${active == 1 ? 'profile-active3' : ''}`} onClick={() => handleActive('1')}>Timeline</li>
                                    <li className={`${active == 2 ? 'profile-active3' : ''}`} onClick={() => handleActive('2')}>Publications</li>
                                    <li className={`${active == 3 ? 'profile-active3' : ''}`} onClick={() => handleActive('3')}>Bookmark</li>
                                </ul>
                            </div>
                        </Box>
                        <Grid container>
                            {/* <Grid item md={4} xs={12} className="padding_left">
                                <div className='desktop_publications'>
                                    {profile == 3 ? <Overview /> : ""}
                                    {profile == 0 ? <MyProfile /> : ""}
                                    {profile == 1 ? <Development /> : ""}
                                </div> :
                                    <div className='Tablet_publications'>
                                        <MyProfile showvalue={profile == 0 ? true : false} handleActive={(value) => handleActive(value)} active={active} />
                                        {profile == 1 ? <Feed /> : ""}
                                        {profile == 2 ? <Development /> : ""}
                                    </div>}
                            </Grid> */}
                            {!matches ?
                                <Grid item md={12} xs={12} className="tablet_mobile">
                                    {profile == 0 ? <UserProfileOverview /> : ""}
                                    {profile == 1 ? <ProfileTimeline /> : ""}
                                    {profile == 2 ? <ProfilePublication /> : ""}
                                    {profile == 3 ? <Bookmark /> : ""}
                                    {/* <TabPanel value='1' sx={{ p: '0px' }}><UserProfileOverview ></Overview></TabPanel>
                                    <TabPanel value="2" sx={{ p: '0px' }}><ProfileTimeline /></TabPanel>
                                    <TabPanel value="3" sx={{ mt: '10px', p: 0 }}><ProfilePublication /></TabPanel>
                                    <TabPanel value="4" sx={{ mt: '10px', p: 0 }}><Bookmark /></TabPanel> */}
                                </Grid>
                                :
                                <Grid item xs={12} className='Tablet_publications'>
                                    <MyProfile showvalue={profile == 0 ? true : false} handleActive={(value) => handleActive(value)} active={active} />
                                    {profile == 1 ? <Feed view={true} /> : ""}
                                    {profile == 2 ? <ProfilePublication /> : ""}
                                    {profile == 3 ? <Bookmark /> : ""}
                                </Grid>
                            }
                        </Grid>
                    </TabContext>
                </Grid>
            </Grid>

            {/* </div> */}
            {/* <div className="profile__info" id='profile_tablet'>
                <ul>
                    <li className={`${active == 0 ? 'profile-active1' : ''}`} onClick={() => handleActive('0')}>Info</li>
                    <li className={`${active == 1 ? 'profile-active1' : ''}`} onClick={() => handleActive('1')}>Timeline</li>
                    <li className={`${active == 2 ? 'profile-active1' : ''}`} onClick={() => handleActive('2')}>Publications</li>
                </ul>
            </div> */}


            {/* {profile == 2 ? <Development /> : ""} */}
            <Snackbar
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                autoHideDuration={alert.upload ? null : 2000}
                open={alert.open}
                onClose={() => setAlert({ open: false, msg: '', upload: false })}
                message={alert.msg}
            />
        </div >
    )
}
