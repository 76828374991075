import React, { useState, useEffect } from 'react'
import { Avatar, Grid } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { ValidatorForm } from 'react-material-ui-form-validator';
import axios from 'axios';
import { handleHealthServiceLoc, handleHealthServiceOrgInfo, handleSubHealthServiceLoc, handleSubHealthServiceOrgInfo } from "../../../../../../../../redux/Actions/regActions";
import DeleteIcon from '@mui/icons-material/Delete';
import { useHistory } from 'react-router-dom';
import SearchIcon from '@mui/icons-material/Search';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import { D_API, TERMINOLOGY_VALUESET } from '../../../../../../../../services/APIService';
import { Actions, FormLayout } from '../../../../../../../common/components/layout';
import { URI } from '../../../../../../../utility/uri';
import { deleteKeys } from '../../../../../../../common/functions/functions';
import { FormDivider } from '../../../../../../../utility/utils';



const modeList = ["kind", "instance"];
const CreateNewServiceLoc = ({ clsn, switchTabs, serviceId }) => {


    let orgData = useSelector(state => state.registration.userOrgData);
    const orgLoc = useSelector(state => state.registration.location);
    console.log(orgLoc, "orgLoc");
    console.log(orgData, "orgData")

    const healthDeptLoc = useSelector(state => state?.registration?.healthDeptLocationDetails);
    const healthDeptOrg = useSelector(state => state.registration.healthDeptDetails);

    const healthServiceLoc = useSelector(state => state.registration.subhealthServiceLocInfo);
    const healthServiceOrg = useSelector(state => state.registration.subhealthServiceOrgInfo);
    const history = useHistory();
    console.log(healthServiceLoc, "1")

    console.log(healthServiceOrg, "44")

    const [serviceAreaInfo, setServiceAreaInfo] = useState({
        locName: "",
        alias: "",
        locServiceType: "",
        modeType: "kind",
        locPhysicalType: "",
        position: "",
        address: "",
    })
    const [physicalLocList, setPhysicalLocList] = useState([]);
    const [hideKindRes, setHideKindRes] = useState(false);
    const [coverPhoto, setCoverPhoto] = useState({});
    const [url, setUrl] = useState('')
    const [uploadLoader, setUploadLoader] = useState({ open: false, msg: '' });
    const [uploadedLogo, setUploadedLogo] = useState(null);
    const [toUpdateKey, setToUpdateKey] = useState("")

    const [alert, setAlert] = useState({ open: false, msg: '' });

    const [healthDeptList, setHealthDeptList] = useState([]);



    const [contact, setContact] = useState([]);
    const [selectedOrgList, setSelectedOrgList] = useState([]);
    const [healthDeptOrgList, setHealthDeptOrgList] = useState([]);
    const [locationList, setLocationList] = useState([]);
    const [createHealthLoc, setCreateHealthLoc] = useState(false);
    const [locServiceTypeList, setLocServiceTypeList] = useState([]);
    const dispatch = useDispatch();

    useEffect(() => {
        let url = "http://terminology.hl7.org/ValueSet/v3-ServiceDeliveryLocationRoleType"
        TERMINOLOGY_VALUESET.get(`$expand?url=${url}`).then((res) => {
            setLocServiceTypeList(res.data?.expansion?.contains)
        }).catch((err) => console.log(err))

    }, [])


    useEffect(() => {
        D_API.get(`Organization/partof/${orgData?.id}`).then((res) => {
            setHealthDeptOrgList(res?.data?.entry || []);
        }).catch((err) => console.log(err));

    }, [orgData])

    // console.log(healthDeptOrgList, "healthDeptOrgList")



    useEffect(() => {
        let healthIds = selectedOrgList?.map((e) => e?.resource?.id)?.filter((value) => value != undefined && value != null && value != "");
        getLocationInfo(healthIds);
    }, [selectedOrgList])


    const getLocationInfo = async (Ids) => {

        try {
            let List = await Promise.all(
                Ids?.map(async (id) => {
                    try {
                        let res = await D_API.get(`${URI.getLocation}${id}`);
                        return res.data?.entry?.[0];
                    } catch (err) {
                        console.log(err)
                    }
                })
            );
            setLocationList(List)

        } catch (err) {
            setLocationList([])
        }

    }

    // console.log(locationList, "locationList")

    useEffect(() => {
        getLocPhysicalType();
    }, [])

    useEffect(() => {
        if (serviceId) {
            D_API.get(`${URI.getHealthCareService}/${serviceId}`).then((res) => {
                // console.log(res.data, "$$$$4")
                dispatch(handleSubHealthServiceOrgInfo(res.data))
                if (res?.data?.location?.[0]?.reference?.split("/")?.[1]) {
                    D_API.get(`${URI.addLocation}/${res?.data?.location?.[0]?.reference?.split("/")?.[1]}`).then((res) => {
                        // let data = res.data?.entry?.[0]?.resource;
                        // console.log(res.data, "000")
                        dispatch(handleSubHealthServiceLoc(res.data))
                    }).catch((err) => console.log(err))
                }
            }).catch((err) => {
                console.log(err);
            })


        }

    }, [])


    useEffect(() => {
        if (healthServiceOrg?.location) {
            let listOfIds = healthServiceOrg?.location?.map((e) => e?.reference?.split("/")?.[1]);
            getLocationsByLocId(listOfIds)
        }
    }, [healthServiceOrg])


    const getLocationsByLocId = async (Ids) => {
        try {
            let List = await Promise.all(
                Ids?.map(async (id) => {
                    try {
                        let res = await D_API.get(`${URI.addLocation}/${id}`);
                        return res.data;
                    } catch (err) {
                        console.log(err)
                    }
                })
            );
            // setLocationList(List)
            setSelectedOrgList(List)
        } catch (err) {
            // setLocationList([])
            setSelectedOrgList([])
        }
    }


    useEffect(() => {
        if (healthServiceLoc?.id) {
            setServiceAreaInfo((prev) => {
                return {
                    ...prev,
                    locName: healthServiceLoc?.name ? healthServiceLoc?.name : "",
                    alias: healthServiceLoc?.alias ? healthServiceLoc?.alias?.[0] : "",
                    locServiceType: "",
                    modeType: healthServiceLoc?.mode ? healthServiceLoc.mode : "kind",
                    locPhysicalType: healthServiceLoc?.physicalType ? healthServiceLoc?.physicalType?.coding?.[0] : "",
                    position: `${healthServiceLoc?.position?.latitude ? healthServiceLoc?.position?.latitude : ""}${healthServiceLoc?.position?.longitude ? `,${healthServiceLoc?.position?.longitude}` : ""}`,
                    address: healthServiceLoc?.address ? healthServiceLoc?.address?.line?.[0] : "",
                }
            })
        }

    }, [healthServiceLoc])



    // useEffect(() => {
    //     D_API.get(`Organization/partof/${orgData?.id}`).then((res) => {
    //         setHealthDeptList(res?.data?.entry);
    //     }).catch((err) => console.log(err));

    // }, [orgData])

    useEffect(() => {
        if (healthDeptList?.length > 0) {
            let listOfOrgId = healthDeptList?.map(({ resource, ...val }) => resource?.id)?.filter((value) => value != "null" && value != "undefined" && value != "");
            // console.log(listOfOrgId, "listOfOrgId")
        }

    }, [healthDeptList])






    useEffect(() => {
        if (serviceAreaInfo?.modeType == "instance") {
            setHideKindRes(true);
        } else {
            setHideKindRes(false);
        }

    }, [serviceAreaInfo?.modeType])

    const getLocPhysicalType = () => {
        let val = "http://hl7.org/fhir/ValueSet/location-physical-type"
        TERMINOLOGY_VALUESET.get(`$expand?url=${val}`).then((res) => {
            setPhysicalLocList(res.data?.expansion?.contains)
            // console.log(res.data?.expansion?.contains, "physicalList")
        }).catch((err) => console.log(err))
    }



    const getExistingLocation = (name, value) => {
        setSelectedOrgList(value)
    }

    // console.log(selectedOrgList, "selectedOrgList")


    const showCreateLocation = () => {
        setCreateHealthLoc(true);

    }

    const hideCreateLocation = () => {
        setCreateHealthLoc(false);
    }

    const handleServiceLogo = (e, fileType, state, name) => {
        // console.log(e.target.files[0], "iiii");
        if (e.target.files.length > 0) {
            let a = [];
            // let b = [];
            let c = [];
            for (let i = 0; i < e.target.files.length; i++) {
                let types = e.target.files[0].type?.split("/")?.[1]
                const eTypes = types === 'jpg' || types === "png" || types == "jpeg"
                const imgTypes = eTypes || types == "gif"

                if (fileType == "photo") {
                    const reader = new FileReader()
                    // console.log(reader, "reader")
                    reader.readAsDataURL(e.target.files[0]);
                    reader.onloadend = () => {
                        console.log(reader.result, "result")
                        a.push(reader.result)
                        let base64 = reader.result.split(",")[1].split("=")[0]
                        var strLength = base64.length;
                        var fileLength = strLength - (strLength / 8) * 2;
                        const [, type] = reader.result.split(";")[0].split('/');
                        c.push(type)
                        // setCoverPhoto({ file: base64, fileType: type })
                        // setCoverPhoto((prev) => {
                        //     return [...prev, { file: base64, fileType: type }]
                        // })
                        setCoverPhoto({ file: base64, fileType: type })
                        fileUpload(base64, type, state == "coverpic" ? 'userCoverPhoto' : 'profilePic', name)
                    }
                }
                else {
                    setUploadLoader({ open: true, msg: 'Only .jpg, .png, .jpeg, .gif extensions allowed' })
                }
            }

        }
    }

    // console.log(coverPhoto, "coverphoto")

    const fileUpload = (file, type, subType, name) => {
        // setUploadLoader({ open: true, msg: "uploading ..." })
        let data = {
            "file": [file],
            "fileType": type,
            "multiple": false,
            "subType": subType,
            "type": "userProfilePics"
        }
        console.log(URL.upload, "upload");
        axios.post(`${URL.uploadFile}`, data, {
            headers: {
                'Authorization': `Bearer ${sessionStorage.getItem('kctoken')}`
            }
        }).then(res => {
            // console.log(res.data.data[0], "success")
            // setUploadLoader({ open: false, msg: "uploaded successfully" })
            setUploadedLogo(res.data.data?.[0]);
            // setUploadedFile((prev) => {
            //     return [...prev, res.data.data[0]]
            // })
            // setToUpdateKey(name)
            console.log()

            // updateOrg1(res.data.data[0], name)
        }).catch(err => {
            setUploadLoader({ open: false, msg: "uploading failed, try again." })

        })

    }


    const handleSearchArea = (name, value) => {
        setServiceAreaInfo((prev) => { return { ...prev, [name]: value } })
    }

    // console.log(serviceAreaInfo, "serviceAreaInfo")


    const handleSubmit = () => {


        // if (healthServiceOrg?.id && !createHealthLoc) {
        //     let data = {
        //         ...healthServiceOrg,
        //         location: locationList.map(({ resource, ...e }) => (resource?.id && resource?.id != undefined && resource?.id != null && resource?.id != "") && {
        //             reference: `Location/${resource?.id}`
        //         })
        //     }
        //     let val = deleteKeys(data);
        //     D_API.put(`${URI.updateHealthServiceDept}/${healthServiceOrg?.id}`, val).then((res) => {
        //         console.log(res.data, "7")
        //         D_API.get(`${URI.getHealthCareService}/${res.data}`).then((res) => {
        //             dispatch(handleSubHealthServiceOrgInfo(res.data));
        //             switchTabs();
        //             // clsn();
        //             console.log(res.data)
        //         }).catch((err) => console.log(err));

        //     }).catch((err) => console.log(err));
        // }



        let data = {
            "resourceType": "Location",
            status: "inactive",
            ...(healthServiceLoc?.id && { id: healthServiceLoc?.id }),
            name: serviceAreaInfo?.locName ? serviceAreaInfo?.locName : "",
            alias: serviceAreaInfo?.alias ? [serviceAreaInfo?.alias] : "",
            mode: serviceAreaInfo?.modeType == "kind" ? "kind" : "instance",
            type: serviceAreaInfo?.locServiceType ? [{ coding: [{ ...serviceAreaInfo?.locServiceType }] }] : "",
            ...(orgData?.id && { managingOrganization: { "reference": `${"Organization" + "/" + orgData?.id}` } }),
            ...(healthDeptLoc?.id && { partOf: { "reference": `${"Location" + "/" + healthDeptLoc?.id}` } }),
            address: serviceAreaInfo?.modeType == "kind" && serviceAreaInfo.address ? {
                line: [`${serviceAreaInfo.address}`]
            } : "",
            ...((serviceAreaInfo?.modeType == "kind" && serviceAreaInfo.position && serviceAreaInfo?.position?.split(",")?.[1] && serviceAreaInfo?.position?.split(",")?.[0]) && { position: { "longitude": parseFloat(serviceAreaInfo?.position?.split(",")?.[1]), "latitude": parseFloat(serviceAreaInfo.position?.split(",")?.[0]) } }),
            ...((serviceAreaInfo?.modeType == "kind" && serviceAreaInfo.locPhysicalType) && {
                physicalType: {
                    coding: [
                        { ...serviceAreaInfo.locPhysicalType }
                    ]
                }
            }),
            // ...(healthServiceLoc?.hoursOfOperation && { hoursOfOperation: healthServiceLoc?.hoursOfOperation }),
            // ...(healthServiceLoc?.telecom && { telecom: healthServiceLoc?.telecom }),
            ...(healthServiceOrg?.hoursOfOperation && { hoursOfOperation: healthServiceOrg?.hoursOfOperation }),
            ...(healthServiceOrg?.telecom && { telecom: healthServiceOrg?.telecom }),

        }

        let val = deleteKeys(data);
        // console.log(val, "data")

        if (healthServiceLoc?.id) {
            D_API.put(`${URI.addLocation}/${healthServiceLoc?.id}`, val).then(res => {
                // console.log(res.data, "02")
                D_API.get(`${URI.addLocation}/${res.data}`).then(res => {
                    dispatch(handleSubHealthServiceLoc(res.data));
                    switchTabs();
                }).catch((err) => alert(err));

            }).catch((err) => {
                console.log(err)
            })
        } else {
            D_API.post(URI.addLocation, val).then((res) => {
                console.log(res.data)
                let healthOrgData = { ...healthServiceOrg, location: [...healthServiceOrg.location, { reference: `Location/${res.data}` }] }
                D_API.put(`${URI.updateHealthServiceDept}/${healthServiceOrg?.id}`, healthOrgData).then((res) => {
                    // console.log(res.data, "7")
                    D_API.get(`${URI.getHealthCareService}/${res.data}`).then((res) => {
                        dispatch(handleSubHealthServiceOrgInfo(res.data));
                        // switchTabs();
                        console.log(res.data)
                    }).catch((err) => console.log(err));

                }).catch((err) => console.log(err));

                D_API.get(`${URI.addLocation}/${res.data}`).then(res => {
                    dispatch(handleSubHealthServiceLoc(res.data));
                    setCreateHealthLoc(false);
                    switchTabs();
                }).catch((err) => alert(err));
            }).catch((err) => {
                console.log(err);
                alert(err);
            })
        }

        // console.log(serviceAreaInfo, "serviceAreaInfo")

    }


    // const deleteOrgFn = () => {

    //     if (healthServiceLoc?.id && (healthServiceLoc?.managingOrganization?.reference?.split("/")?.[1] != undefined || null || "")) {
    //         D_API.delete(`${URI.addLocation}/${healthServiceLoc?.id}/${healthServiceLoc?.managingOrganization?.reference?.split("/")?.[1]}`).then((res) => {
    //             if (healthServiceOrg?.id && healthServiceOrg?.providedBy?.reference?.split("/")?.[1]) {
    //                 D_API.delete(`${URI.updateHealthServiceDept}/${healthServiceOrg?.id}/${healthServiceOrg?.providedBy?.reference?.split("/")?.[1]}`).then((res) => {
    //                     clsn();
    //                     history.push(`/admin/organization/${orgData?.identifier?.[1]?.value}`)
    //                     console.log(res.data)
    //                 }).catch((err) => console.log(err))
    //             } else {
    //                 clsn();
    //                 history.push(`/admin/organization/${orgData?.identifier?.[1]?.value}`)
    //             }
    //         }).catch((err) => console.log(err))
    //     } else if (healthServiceOrg?.id && healthServiceOrg?.providedBy?.reference?.split("/")?.[1]) {
    //         D_API.delete(`${URI.updateHealthServiceDept}/${healthServiceOrg?.id}/${healthServiceOrg?.providedBy?.reference?.split("/")?.[1]}`).then((res) => {
    //             clsn();
    //             history.push(`/admin/organization/${orgData?.identifier?.[1]?.value}`)
    //             console.log(res.data)
    //         }).catch((err) => console.log(err))
    //     } else {
    //         clsn();
    //         history.push(`/admin/organization/${orgData?.identifier?.[1]?.value}`);
    //     }

    // }




    return (
        <div>

            <ValidatorForm onSubmit={handleSubmit} className='tohideScrollbar' >
                <Grid
                    container
                    spacing={2}
                    className="ph1 tohideScrollbar"
                    sx={{ height: "410px", overflow: "auto" }}
                >
                    {/* <div className="flx-center ph1"><span className="flx-center" ><RemoveCircleIcon style={{ paddingTop: "2px", color: "red", }} className="cursor" onClick={hideCreateLocation} /></span>&nbsp; Close Specific Location</div> */}
                    <FormLayout
                        label="Location Name"
                        name="locName"
                        size={12}
                        value={serviceAreaInfo.locName}
                        handleChange={handleSearchArea}
                        placeholder="Name of Location"
                    />
                    <FormLayout
                        size={12}
                        value={serviceAreaInfo.alias}
                        label="Alias"
                        name="alias"
                        handleChange={handleSearchArea}
                        placeholder="Name of Location as Alias"
                    />
                    <FormLayout
                        size={12}
                        label="Location Service Type"
                        input="select"
                        name="locServiceType"
                        value={serviceAreaInfo.locServiceType}
                        options={locServiceTypeList || []}
                        getOptionLabel={(option) => option?.display || ""}
                        handleChange={handleSearchArea}
                        placeholder="Select Service Type of Location"
                    />

                    <FormLayout
                        // size={12}
                        label="Mode of Locations"
                        input="select"
                        name="modeType"
                        value={serviceAreaInfo.modeType}
                        options={modeList}
                        getOptionLabel={(option) => option || ""}
                        handleChange={handleSearchArea}
                        placeholder="Select Type of Location"
                    />
                    {
                        hideKindRes == false &&
                        <>
                            <FormLayout
                                // size={12}
                                label="Physical Type"
                                input="select"
                                name="locPhysicalType"
                                value={serviceAreaInfo.locPhysicalType}
                                options={physicalLocList || []}
                                getOptionLabel={(option) => option?.display || ""}
                                handleChange={handleSearchArea}
                                placeholder="Select Type of Location"
                            />
                            <FormLayout
                                size={12}
                                label="GeoLocation Position"
                                name="position"
                                value={serviceAreaInfo.position}
                                // options={[]}
                                // getOptionLabel={(option) => option?.display || ""}
                                handleChange={handleSearchArea}
                                placeholder="Select Type of Location"
                            />
                            <FormLayout
                                size={12}
                                label="Address"
                                name="address"
                                value={serviceAreaInfo.address}
                                options={[]}
                                getOptionLabel={(option) => option?.display || ""}
                                handleChange={handleSearchArea}
                                placeholder="Enter the Address"
                            />
                        </>
                    }


                    <Grid item xs={12} />






                </Grid>
                <FormDivider />

                {/* <div className="flx-center justify-between">

                    <div style={{ textAlign: 'right', marginTop: "10px" }}>
                        <DeleteIcon sx={{ color: "red" }} onClick={deleteOrgFn} />
                    </div>
                    <div>
                        <Actions close={clsn} name={healthServiceLoc?.id ? "Update" : "Next"} />
                    </div>
                </div> */}
                <Actions close={clsn} name={healthServiceOrg?.id && !createHealthLoc ? "Update" : "Next"} />
            </ValidatorForm >

        </div >
    )
}

export default CreateNewServiceLoc;