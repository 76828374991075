import React, { useContext, useState } from 'react';
import { useParams } from 'react-router-dom';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Box, Card, Dialog, DialogContent, DialogTitle, Tab, Button, IconButton, Snackbar } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { getFacilities, getUserOrg, handleUserOrg } from '../../../../../redux/Actions/regActions';
import { DOC_API, D_API, D_API_ADD_ORG } from '../../../../../services/APIService';
import { URI } from '../../../../utility/uri';
import BasicDetails from './basicDetails';
import Location from './location';
import UploadKYC from './uploadDocs';
import UserService from '../../../../../services/UserService';
import FacilityInfo from './FacilityInfo';
import Workhours from './Workhours';
import { fetchProfile } from '../../../../../redux/Actions/actions'
import CloseIcon from '@mui/icons-material/Close';
import { ContextInfo } from './adminOrganisationNew';

const TabsList = [
    { name: <span style={{ fontSize: '18px' }}><span> Basic Details</span></span>, Component: BasicDetails },
    { name: <span style={{ fontSize: '18px' }}><span> Location</span></span>, Component: Location },
    { name: <span style={{ fontSize: '18px' }}><span> Healthcare Facility KYC</span></span>, Component: UploadKYC },
]

const TabsListEdit = [
    { name: <span style={{ fontSize: '16px' }}><span> Basic Details</span></span>, Component: BasicDetails },
    { name: <span style={{ fontSize: '16px' }}><span> Location</span></span>, Component: Location },
    { name: <span style={{ fontSize: '16px' }}><span> Facility Info</span></span>, Component: FacilityInfo },
    { name: <span style={{ fontSize: '16px' }}><span> Work hours</span></span>, Component: Workhours },
]




const EditProfile = (props) => {
    const orgData = useSelector(state => state.registration.userOrgData);
    const orgLocation = useSelector(state => state.registration.location);
    const contextDetails = useContext(ContextInfo);
    console.log(orgData, "uuu", orgLocation)
    const subOrgList = useSelector((state) => state.registration.listOfSubOrganizations);
    console.log(subOrgList, "ineditProfile");
    // console.log(props, "0000000000")

    const [tab, setTab] = useState('1')
    const [pageId, setPageId] = useState(null);
    const [alertMsg, setAlertMsg] = useState({
        open: false,
        msg: ""
    })

    const { id } = useParams()
    // console.log(id, "one")

    const dispatch = useDispatch()

    const update = async (pageId) => {
        let orgId;
        let orgData;
        let locationId;
        let datatobeupdate;
        await D_API.get(`Organization/identifier/pageId/${pageId}`).then(res => {
            orgId = res?.data?.entry?.[0]?.resource?.id
            orgData = res?.data?.entry?.[0]?.resource
        })

        // await D_API.get(URI.getLocation + orgId).then(res => {
        //     locationId = res?.data?.entry?.[0]?.resource?.id
        // })

        // await D_API.get(`${URI.addLocation}/${locationId}`).then(res => {
        //     datatobeupdate = { ...res.data, physicalType: { coding: [{ code: "si", display: 'Site' }] }, mode: 'kind', description: orgLocation?.description, type: [{ coding: [{ code: "_DedicatedServiceDeliveryLocationRoleType", display: 'DedicatedServiceDeliveryLocationRoleType' }] }] }
        // })

        console.log(datatobeupdate, "datatobeupdate")
        // await D_API.put(`${URI.addLocation}/${datatobeupdate.id}`, datatobeupdate).then(res => {
        //     // console.log(res, "location updated here")
        // })
    }

    const handleTabs = (val) => {
        // console.log(val)
        setTab(val)
        if (!props?.edit && val == 4) {
            props?.close()
        }
    }



    const submitData = (data) => {
        // console.log(data, "data")
        let website = data?.endpoint?.[0]?.display
        if (tab === "1") {
            if (data && data?.id) {
                D_API.put(URI.updateOrg + data?.id, data).then((res) => {

                    props.edit ? handleTabs(`${parseInt(tab) + 1}`) : handleTabs(4);
                    dispatch(getUserOrg(data))
                    dispatch(getFacilities())
                    if (data?.identifier?.[1]?.value) {
                        D_API.get(`Organization/identifier/pageId/${data?.identifier?.[1]?.value}`).then(res => {
                            // orgId = res?.data?.entry[0]?.resource.id
                            // console.log(res.data, "get")
                            dispatch(handleUserOrg(res.data?.entry?.[0]?.resource))
                            dispatch(getUserOrg(res?.data?.entry?.[0]?.resource))
                            if (props?.branch == "mainBranch") {
                                props.setOrgData(res?.data?.entry?.[0]?.resource)
                            }

                        }).catch(err => {

                            console.log(err)
                        })
                    }
                }).catch((err) => {
                    console.log(err)
                })

            } else {
                let orgPage = {
                    organisation: {
                        ...data,
                        resourceType: "Organization",
                        active: false,
                        identifier: []
                    },
                    pageName: data.name,
                    "pageAdmin": `${UserService.getUserId()}`,
                    "pageStatus": "ACTIVE",
                    "pageStatusUpdatedDate": new Date().toISOString(),
                    // "pageWebsite": "",
                    "kycStatus": "PENDING",
                    "date": new Date().toISOString(),
                    "metadata": {},
                    "status": true,
                    "userId": Number(UserService.getUserId())
                }
                // console.log(orgPage, "orgPage");
                DOC_API.post(URI.addPage, orgPage).then(async res => {
                    let response = { ...res.data.data.organsation }
                    // console.log(res.data, "zzz")

                    setPageId(res.data.data.id)
                    let pageId = res.data.data.id;
                    let page = {
                        ...orgPage.organisation, identifier: [{
                            system: `${process.env.REACT_APP_DOCSTETH}/`,
                            value: `${UserService.getUserId()}`
                        }, {
                            system: "https://pageId/",
                            value: `${res.data.data.id}`
                        },

                        ...(props?.branch === "mainBranch" ? [] : [{ system: "https://positionofsuborganization/", value: (subOrgList.length)?.toString() }])

                        ]


                    }

                    if (website) {
                        page.telecom = [...orgPage.organisation.telecom, { system: 'other', value: website }]
                    }

                    // await D_API.post(URI.organization, page).then((res) => {
                    await D_API.post(URI.organization, page).then((res) => {
                        // console.log(res)
                        // dispatch(getUserOrg({ ...page, id: res.data }))
                        // console.log(page, "pgs")
                        dispatch(handleUserOrg({ ...page, id: res.data }))
                        dispatch(getFacilities())
                        dispatch(fetchProfile());
                        update(pageId)
                        setTab(`${parseInt(tab) + 1}`)

                        if (data?.partOf) {
                            let Id = data?.partOf?.reference?.split("/")?.[1];
                            if (Id != null && Id != undefined && Id != "" && !(data?.id)) {
                                contextDetails.getsubOrgDetails(Id);
                            }

                        }
                        // handleTabs(`${parseInt(tab) + 1}`)
                    }).catch(err => {
                        DOC_API.delete(URI.deletePage + res.data.data.id).then(() => alert("Page could not be created.Please try again."))
                    })
                    // setPageId(res.data.data.id)
                    // update(res.data.data.id)
                }).catch((err) => {


                })
            }

        }
        else if (tab === "2") {
            if (data && data?.id) {
                let Obj = { ...data, physicalType: { coding: [{ code: "si", display: 'Site' }] }, mode: 'kind', description: orgLocation?.description, type: [{ coding: [{ code: "_DedicatedServiceDeliveryLocationRoleType", display: 'DedicatedServiceDeliveryLocationRoleType' }] }] }
                // console.log(Obj, "2323")
                D_API.put(`${URI.addLocation}/${Obj?.id}`, Obj).then(res => {
                    D_API.get(`${URI.addLocation}/${Obj?.id}`).then((res) => {
                        dispatch({
                            type: "location",
                            payload: { ...res.data }

                        })
                    })

                    if (orgData?.id) {
                        let val = { ...orgData, address: [Obj?.address] }
                        D_API.put(URI.updateOrg + orgData?.id, val).then((res) => {
                            dispatch(getUserOrg(val))
                            dispatch(getFacilities())
                            if (val?.identifier?.[1]?.value) {
                                D_API.get(`Organization/identifier/pageId/${val?.identifier?.[1]?.value}`).then(res => {
                                    dispatch(handleUserOrg(res.data?.entry?.[0]?.resource))
                                    dispatch(getUserOrg(res?.data?.entry?.[0]?.resource))
                                    // if (props?.branch == "mainBranch") {
                                    //     props.setOrgData(res?.data?.entry?.[0]?.resource)
                                    // }
                                }).catch(err => {

                                    console.log(err)
                                })
                            }
                        }).catch((err) => console.log(err))
                    }

                    setTab(`${parseInt(tab) + 1}`)
                    // console.log(res, "updatedLocation")
                }).catch((err) => {
                    console.log(err)
                })
            } else {
                D_API.post(URI.addLocation, data).then((res) => {
                    // console.log(res.data)
                    // dispatch(handleUserOrg({ locationId: res.data }))

                    // below code is for put api for adding address in organization 
                    if (orgData?.id) {
                        let val = { ...orgData, address: [data?.address] }
                        D_API.put(URI.updateOrg + orgData?.id, val).then((res) => {
                            dispatch(getUserOrg(val))
                            dispatch(getFacilities())
                            if (val?.identifier?.[1]?.value) {
                                D_API.get(`Organization/identifier/pageId/${val?.identifier?.[1]?.value}`).then(res => {
                                    dispatch(handleUserOrg(res.data?.entry?.[0]?.resource))
                                    dispatch(getUserOrg(res?.data?.entry?.[0]?.resource))
                                    // if (props?.branch == "mainBranch") {
                                    //     props.setOrgData(res?.data?.entry?.[0]?.resource)
                                    // }
                                }).catch(err => {

                                    console.log(err)
                                })
                            }
                        }).catch((err) => console.log(err))
                    }

                    update(pageId)
                    setTab(`${parseInt(tab) + 1}`)
                }).catch((err) => {
                    console.log(err);
                    alert(err);
                })
            }
        }
        else {
            handleTabs(`${parseInt(tab) + 1}`)
            //         // dispatch(getUserOrg(data))
            dispatch(handleUserOrg(data))
        }
        //     dispatch(handleUserOrg(data));
        // handleTabs(`${parseInt(tab) + 1}`)
    }
    // const submitData = (data) => {
    // console.log(data, "submitObjcreatnOrg")
    // // console.log(data,'hello')
    // let website = data?.endpoint?.[0]?.display
    // // setTab(`${parseInt(tab) + 1}`)
    // // handleTabs(`${parseInt(tab) + 1}`)
    // // test mode
    // if (tab === '2') {
    //     if (data && data.id) {
    //         // console.log("update fhir")
    //         D_API.put(URI.updateOrg + data.id, data).then(() => {
    //             console.log(tab === '1')
    //             props.edit ? handleTabs(`${parseInt(tab) + 1}`) : handleTabs(4)

    //             // handleTabs(`${parseInt(tab) + 1}`)
    //             // if (tab === '1') {
    //             dispatch(getUserOrg(data))
    //             // }
    //             dispatch(getFacilities())

    //             //for update on the fly
    //             D_API.get(`Organization/identifier/pageId/${id}`).then(res => {
    //                 // orgId = res?.data?.entry[0]?.resource.id
    //                 dispatch(getUserOrg(res?.data?.entry[0]?.resource))
    //                 props.setOrgData(res?.data?.entry[0]?.resource)
    //             }).catch(err => {
    //                 console.log(err)
    //             })
    //         })
    //     }
    //     else if (data && !data.id && data?.identifier?.length === 2) {
    //         D_API.post(URI.organization, data).then((res) => {
    //             handleTabs(`${parseInt(tab) + 1}`)
    //             dispatch(handleUserOrg({ ...data, id: res.data }))
    //             dispatch(getFacilities())
    //         })
    //     }
    //     else if (data && !data.identifier) {
    //         let orgPage = {
    //             organisation: {
    //                 ...data,
    //                 resourceType: "Organization",
    //                 active: false,
    //                 identifier: []
    //             },
    //             pageName: data.name,
    //             "pageAdmin": `${UserService.getUserId()}`,
    //             "pageStatus": "ACTIVE",
    //             "pageStatusUpdatedDate": new Date().toISOString(),
    //             // "pageWebsite": "",
    //             "kycStatus": "PENDING",
    //             "date": new Date().toISOString(),
    //             "metadata": {},
    //             "status": true,
    //             "userId": Number(UserService.getUserId())
    //         }
    //         DOC_API.post(URI.addPage, orgPage).then(async res => {
    //             // let response = {...res.data.data.organsation}
    //             console.log(res)
    //             // setPageId(res.data.data.id)
    //             let pageId = res.data.data.id;
    //             let page = {
    //                 ...orgPage.organisation, identifier: [{
    //                     system: `https://${process.env.REACT_APP_DOCSTETH}/`,
    //                     value: `${UserService.getUserId()}`
    //                 }, {
    //                     system: "https://pageId/",
    //                     value: `${res.data.data.id}`
    //                 }]
    //             }

    //             if (website) {
    //                 page.telecom = [...orgPage.organisation.telecom, { system: 'other', value: website }]
    //             }
    //             console.log(page, "page")

    //             await D_API.post(URI.organization, page).then((res) => {
    //                 // console.log(res)
    //                 setTab(`${parseInt(tab) + 1}`)
    //                 // dispatch(getUserOrg({ ...page, id: res.data }))
    //                 dispatch(handleUserOrg({ ...page, id: res.data }))
    //                 dispatch(getFacilities())
    //                 dispatch(fetchProfile())
    //                 update(pageId)

    //             }).catch(err => {
    //                 DOC_API.delete(URI.deletePage + res.data.data.id).then(() => alert("Page could not be created.Please try again."))
    //             })
    //             // setPageId(res.data.data.id)
    //             // update(res.data.data.id)
    //         })
    //     }
    //     else {
    //         handleTabs(`${parseInt(tab) + 1}`)
    //     }
    // }
    // else {
    //     handleTabs(`${parseInt(tab) + 1}`)
    //     // dispatch(getUserOrg(data))
    //     dispatch(handleUserOrg(data))
    // }
    // }


    const handleOpenSnackbar = () => {

    }


    const handleCloseSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setAlertMsg({ open: false, msg: "" })
    }



    const action = (
        <React.Fragment>
            {/* <Button color="secondary" size="small" onClick={handleCloseSnackbar}>
            UNDO
        </Button> */}
            <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={handleCloseSnackbar}
            >
                <CloseIcon fontSize="small" />
            </IconButton>
        </React.Fragment>
    );



    return (
        <>

            <Dialog id="services" open={true} onClose={props.close} maxWidth={"sm"} sx={{ height: "600px", position: 'absolute', top: "10%" }} fullWidth>
                <TabContext value={tab}>
                    <DialogTitle sx={{ padding: '0px' }}>
                        <Card sx={{ borderRadius: 0, boxShadow: '0px 3px 6px #00000029' }}>
                            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                <TabList onChange={(e, newval) => { setTab(newval) }} classes={{ indicator: { backgroundColor: '#0F22B5!important' } }} aria-label="lab API tabs example" variant="scrollable">
                                    {props.edit ?
                                        TabsListEdit?.map((tabs, i) => <Tab className='capitalize m-auto' key={i} classes={{ selected: { color: '#0F22B5!important' } }} label={tabs.name} value={`${i + 1}`} />) :
                                        TabsList?.map((tabs, i) => <Tab className='capitalize m-auto' key={i} classes={{ selected: { color: '#0F22B5!important' } }} label={tabs.name} value={`${i + 1}`} />)
                                    }
                                </TabList>
                            </Box>
                        </Card>
                    </DialogTitle>
                    <DialogContent sx={{ padding: '0px' }}>
                        {props.edit ?
                            TabsListEdit?.map((tabs, i) => tab == i + 1 && <TabPanel key={i} sx={{ padding: i === 3 ? '10px 0' : '20px 20px 10px 20px' }} value={`${i + 1}`}><tabs.Component close={props.close} submitData={submitData} currTab={tab} branch={props.branch} parentOrgObj={props.parentOrgObj} /></TabPanel>) :
                            TabsList?.map((tabs, i) => tab == i + 1 && <TabPanel key={i} sx={{ padding: i === 3 ? '10px 0' : '20px 20px' }} value={`${i + 1}`}><tabs.Component close={props.close} submitData={submitData} branch={props.branch} parentOrgObj={props.parentOrgObj} /></TabPanel>)
                        }
                    </DialogContent>
                </TabContext>
            </Dialog>
            <Snackbar
                open={alertMsg.open}
                onClose={handleCloseSnackbar}
                message={alertMsg.msg}
                action={action}
            />
        </>
    )
}

export default EditProfile