import React, { useEffect, useState } from 'react';
import { Autocomplete, Box, Button, Card, Dialog, DialogActions, DialogTitle, FormControlLabel, Grid, MenuItem, Radio, RadioGroup, Select, TextField, Typography } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import axios from 'axios';
import { DOC_API, D_API } from '../../../../../services/APIService';
import { AddPurpose, DropMenu } from '../../../../common/components/layout';
import UserService from '../../../../../services/UserService';
import { URI } from '../../../../utility/uri';
import { FormDivider } from '../../../../utility/utils';
import { getLocation } from '../../../../../redux/Actions/regActions';
// import icons
import AddCircleIcon from '@mui/icons-material/AddCircle';
import SearchIcon from '@mui/icons-material/Search';
import { CheckCircle } from '@mui/icons-material';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';


const coverageprogramslist = [
  { displayName: 'NHRR Id', sendName: 'nhrrId', value: "" },
  { displayName: 'NIN', sendName: 'nin', value: "" },
  { displayName: 'ABPMJAY Id', sendName: 'abpmjayId', value: "" },
  { displayName: 'ROHINI Id', sendName: 'rohiniId', value: "" },
  { displayName: 'ECHS Id', sendName: 'echsId', value: "" },
  { displayName: 'CGHS Id', sendName: 'cghsId', value: "" },
  { displayName: 'CEA Registartion', sendName: 'ceaRegistration', value: "" },
  { displayName: 'State Insurance Scheme Id', sendName: 'stateInsuranceSchemeId', value: "" },
]

const FacilityInfo = (props) => {

  const [facilities, setFacilities] = useState({
    facilityRole: 'Hospital',
    active: 'active'
  })



  const handleFacility = (e) => {
    // console.log(name, value, "11")
    const { name, value } = e.target
    setFacilities((prev) => {
      return { ...prev, [name]: value }
    })
  }

  // console.log(facilities, "facilities")


  const [specialityType, setSpecialityType] = useState('Single speciality')
  const [clinicalRole, setClinicalRole] = useState({})
  const [clinicalOptions, setCliniclaOptions] = useState([])

  const [openSearch, setOpenSearch] = React.useState(false);
  const [openDelete, setOpenDelete] = useState({ open: false, value: null });
  const [openProgrammDelete, setOpenProgrammDelete] = useState({ open: false, value: null });
  const [valuesetData, setValusetData] = React.useState([]);
  // const [healthCareService, setHealthCareServices] = React.useState([]);

  const dispatch = useDispatch()

  const [servicesList, setServicesList] = useState([]);
  const [careUnitValue, setCareUnitValue] = useState([]);
  const [programList, setProgramsList] = useState([]);


  // console.log(servicesList, "hello")
  // console.log(programList, "program");

  const location = useSelector(state => state.registration.location)
  const orgData = useSelector(state => state.registration.userOrg)
  const userOrg = useSelector(state => state.registration.userOrg)



  useEffect(() => {
    if (userOrg?.id) {
      dispatch(getLocation(userOrg.id))
    }
  }, [])

  // console.log(location, "location")

  const handlePrograms = (value) => {

    const data = [...programList, value]
    setProgramsList(data)
  }

  const handleChangePrograms = (name, value) => {
    setProgramsList(programList?.map((val) => val.sendName === name ? { ...val, value: value } : { ...val }))
  };

  useEffect(() => {
    D_API.get(`ValueSet/name/ServiceDeliveryLocationRoleType`).then(res => {
      setValusetData(res?.data?.entry?.[0]?.resource?.compose?.include?.[0]?.concept || [])
    }).catch(err => { console.log(err?.response?.data?.message || 'Error.Please try after sometime') })
  }, [])


  useEffect(() => {
    geServicesList()
    // getHealthCareServices()
    setFacilities({
      facilityRole: location.name,
      active: location.status
    })
    setClinicalRole({ code: location?.type?.[0]?.coding?.[0]?.code, display: location?.type?.[0]?.coding?.[0]?.display })
  }, [location.id])

  // const getHealthCareServices = async () => {
  //   let data = await getHealthServices(location.id)
  //   setHealthCareServices(data)
  // }

  const selectCareUnit = (val) => {
    let position = {
      longitude: parseFloat(orgData?.address?.[0]?.line?.[3]?.split(',')[0]) || '',
      latitude: parseFloat(orgData?.address?.[0]?.line?.[3]?.split(',')[1]) || '',
    }


    if (!servicesList.some(s => s.name === val.display)) {

      let data = {
        "resourceType": "Location",
        "status": "active",
        "name": val.display,
        // managingOrganization: {
        //     reference: `Organization/${orgData.id}`
        // },
        partOf: {
          reference: `Location/${location.id}`
        },
        "type": [{
          "coding": [val]
        }],
        "position": orgData?.address?.[0]?.line?.[3] ? position : ''
      }

      D_API.post('Location', data).then(res => {
        geServicesList()
        setOpenSearch(prev => !prev)
      }).catch(err => { alert(err?.response?.data?.message || 'Error.Please try after sometime') })
    } else {
      alert('already service selected')
    }
  }

  const geServicesList = () => {
    if (location?.id) {
      D_API.get(`Location/partof/${location?.id}?count=50&page=1`).then((res) => {
        setServicesList(res?.data?.entry?.map(val => val?.resource) || [])
      }).catch(err => { alert(err?.response?.data?.message || 'Error.Please try after sometime') })
    }

  }

  const handleremoveServices = (id, i, name) => {
    console.log(id, i, name)
    if (name == 'amenities') {
      setOpenDelete({ open: true, value: id })
    } else if (name == 'programs') {
      setOpenProgrammDelete({ open: true, value: i })
    }
  }

  const deleteService = () => {
    let data = [...servicesList]?.filter((val, index) => openDelete?.value !== val?.id)
    setServicesList(data)
    D_API.delete('Location/' + openDelete?.value).then((res) => console.log(res)).catch((err) => console.log(err))
    setOpenDelete({ open: false, value: null })
  }

  const deleteProgrammes = () => {
    let data = [...programList]?.filter((val, index) => openProgrammDelete?.value !== index)
    // setProgramsList([...programList].filter((val, index) => openProgrammDelete.value !== index))
    setOpenProgrammDelete({ open: false, value: null })
    DOC_API.post(`post/pageSupportedProgrammes?IbmPageId=${orgData?.id}&docUserId=${UserService.getUserId()}`, data).then((res) => {
      setProgramsList(res?.data?.prorammSupported);
    }).catch(err => { alert(err?.response?.data?.message || 'Error.Please try after sometime') })
  }

  const submitSupportedPrograms = () => {
    DOC_API.post(`post/pageSupportedProgrammes?IbmPageId=${orgData?.id}&docUserId=${UserService.getUserId()}`, programList).then((res) => {
      setProgramsList(res?.data?.prorammSupported);
      // setOpenSupportedPrograms(false);
    }).catch(err => { alert(err?.response?.data?.message || 'Error.Please try after sometime') })

    updateServiceRole()

    props.submitData()
  };

  const updateServiceRole = () => {
    let data = {
      ...location,
      name: facilities.facilityRole,
      status: facilities.active,
      type: [
        {
          coding: [{
            system: 'https://dev.apis.clinivault.org/fhir-server/api/v4/ValueSet?name=ServiceDeliveryLocationRoleType',
            code: clinicalRole.code,
            display: clinicalRole.display
          }]
        }
      ]
    }


    D_API.put(`${URI.addLocation}/${location?.id}`, data)
      .then(res => {
        dispatch({
          type: 'location',
          payload: { ...data }
        })

      }).catch(err => {
        console.log(err)
      })
  }

  // useEffect(()=>{
  //   D_API.get(`${URI.organization}/${location.id}`)
  //   .then(res=>{
  //     console.log(res,"allll")
  //   }).catch(err=>{
  //     console.log(err,"errrrrrrr")
  //   })
  // },[location.id])

  useEffect(() => {
    axios.get("https://dev.apis.clinivault.org/fhir-server/api/v4/ValueSet?name=ServiceDeliveryLocationRoleType")
      .then(res => {
        // console.log(res?.data?.entry?.[0]?.resource?.compose?.include?.[0]?.concept, "hey")
        setCliniclaOptions(res?.data?.entry?.[0]?.resource?.compose?.include?.[0]?.concept,)
      })
  }, [])


  return (
    <>
      <div style={{ height: '410px', overflow: 'auto' }} >
        <Grid container >
          <Grid item xs={12} sm={12} md={8} >
            <Grid container item >
              <Grid item xs={12} sm={5} md={5.1}><Typography>Facility Service Role</Typography></Grid>
              <Grid item xs={12} sm={6} md={6}>
                <Select
                  value={facilities.facilityRole}
                  name="facilityRole"
                  // onChange={(e) => setFacilityRole(e.target.value)}
                  defaultValue=''
                  onChange={handleFacility}
                  sx={{ height: "30px", boxShadow: 1, borderRadius: "8px" }}
                  fullWidth
                  IconComponent={KeyboardArrowDownIcon}
                >
                  <MenuItem value='Hospital'>Hospital</MenuItem>
                  <MenuItem value='Clinic'>Clinic</MenuItem>
                </Select>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} md={4} sx={{ marginTop: { xs: '10px', sm: '10px', md: '0' } }}>
            <Grid container item >
              <Grid item xs={12} sm={5} md={4}><Typography>Status</Typography></Grid>
              <Grid item xs={12} sm={6} md={8}>
                <Select
                  value={facilities.active}
                  name="active"
                  // onChange={(e) => setActive(e.target.value)}
                  defaultValue=''
                  onChange={handleFacility}
                  sx={{ height: "30px", boxShadow: 1, borderRadius: "8px" }}
                  fullWidth
                  IconComponent={KeyboardArrowDownIcon}
                >
                  <MenuItem value='active'>Active</MenuItem>
                  <MenuItem value='inactive'>Inactive </MenuItem>
                </Select>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid container mt={2}>
          <Grid item xs={12} sm={5} md={3.4}><Typography>Clinical Role</Typography></Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Select
              defaultValue=''
              value={clinicalRole?.display}
              onChange={(e) => setClinicalRole(e.target.value)}
              sx={{ height: "30px", boxShadow: 1, borderRadius: "8px" }}
              IconComponent={KeyboardArrowDownIcon}
              fullWidth
              displayEmpty
              renderValue={(selected) => {
                if (!clinicalRole?.display && !selected?.display) {
                  return <Typography>Select Role</Typography>;
                }

                return selected?.display || clinicalRole?.display;
              }}
            >
              {clinicalOptions?.map((item, i) => {
                return <MenuItem value={item} key={i}>{item.display}</MenuItem>
              })}
            </Select>
          </Grid>
        </Grid>

        <Grid sx={{ xs: { px: 1 }, md: { px: 5 } }} >
          <Typography style={{ marginTop: "1.2rem", fontWeight: "500" }}>Facility Dedicated Location </Typography>
          <Box sx={{ px: 1, pb: 1, pt: 1 }} >
            <Grid container sx={{ pt: 1 }} className="" rowSpacing={1} columnSpacing={2}>
              {servicesList && servicesList?.map((val, i) =>
                <Grid item md={servicesList?.length === i + 1 && (i + 1) % 2 !== 0 ? 12 : 6} xs={12} key={i}>
                  <Typography component="div" key={i} onClick={() => handleremoveServices(val.id, i, 'amenities')}>
                    <div style={{ display: 'flex', paddingBottom: "10px" }} title={val.name}><RemoveCircleIcon sx={{ color: 'red' }} /> &nbsp; <span style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', }}>{val.name}</span></div>
                  </Typography>
                </Grid>
              )}
              <Grid item xs={12}>
                <Grid container item spacing={2} >
                  {openSearch &&
                    <Grid item xs={12} sm={6} md={6}>
                      <Autocomplete
                        multiple
                        // open={true}
                        id="combo-box-demo"
                        filterSelectedOptions
                        clearOnBlur={true}
                        options={valuesetData}
                        value={careUnitValue}
                        onChange={(val) => setCareUnitValue([])}
                        getOptionLabel={(option) => option.display}
                        renderOption={(props, option) => (
                          <Box component="li" {...props}>
                            <Typography onClick={() => selectCareUnit(option)}>{option.display}</Typography>
                          </Box>
                        )}
                        renderInput={(params) => (
                          <TextField
                            sx={{ '.MuiOutlinedInput-root': { padding: '0px 5px!important' }, width: '100%' }}
                            {...params}
                            placeholder="Facility Clinical Role"
                            InputProps={{
                              ...params.InputProps,
                              endAdornment: <SearchIcon />
                            }}
                          />)
                        }
                      />
                    </Grid>
                  }
                  <Grid item xs={12} sm={6} md={6}>
                    <div onClick={() => setOpenSearch(prev => !prev)} style={{ display: 'flex', alignItems: 'center', paddingTop: "5px", textTransform: 'capitalize', cursor: 'pointer' }} ><AddCircleIcon sx={{ color: '#00C73D' }} /><span>&nbsp; New care Unit</span></div>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Grid>

        <Dialog
          open={openDelete.open}
          onClose={() => setOpenDelete({ open: false, value: null })}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            Do you want to delete this service ?
          </DialogTitle>
          <DialogActions>
            <Button color='error' onClick={() => setOpenDelete({ open: false, value: null })}>cancel</Button>
            <Button variant='contained' onClick={() => deleteService()} autoFocus>
              confirm
            </Button>
          </DialogActions>
        </Dialog>

        <Typography style={{ marginTop: "1rem", fontWeight: "500" }}>Healthcare Programs</Typography>
        <Grid container sx={{ xs: { px: 1 }, md: { px: 5 }, marginTop: "1rem", paddingLeft: "8px" }} >
          {programList.map((val, i) =>
            <Grid container style={{ display: 'flex', alignItems: 'center' }} className='flx-center py2' key={i}>
              <Grid item xs={5}>
                <Typography key={i}>
                  <div style={{ display: 'flex' }}><RemoveCircleIcon className='cursPoint' style={{ color: '#FF0000', fontSize: '22px', }} onClick={() => handleremoveServices(val.sendName, i, 'programs')} />&nbsp;<span>{val.displayName}</span></div>
                </Typography>
              </Grid>
              <Grid item xs={7}>
                <TextField value={val?.value} onChange={(value) => handleChangePrograms(val.sendName, value?.target?.value)} name={val.sendName} sx={{ '.MuiInputBase-input': { py: '5px', borderRadius: '12px', }, my: 1, width: '100%' }} placeholder="Enrollment Id" />
              </Grid>
            </Grid>
          )}
          <DropMenu Type={<AddPurpose value={'Supported Programs'} />}
            handleType={handlePrograms} options={coverageprogramslist} />
        </Grid>


        <Dialog
          open={openProgrammDelete.open}
          onClose={() => setOpenProgrammDelete({ open: false, value: null })}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            Do you want to delete this Program ?
          </DialogTitle>
          <DialogActions>
            <Button color='error' onClick={() => setOpenProgrammDelete({ open: false, value: null })}>cancel</Button>
            <Button variant='contained' onClick={() => deleteProgrammes()} autoFocus>
              confirm
            </Button>
          </DialogActions>
        </Dialog>
      </div>
      <FormDivider />
      <div style={{ display: 'flex', justifyContent: 'end', }}>
        <Button type="submit" onClick={() => submitSupportedPrograms()} sx={{ marginLeft: "20px", padding: "4px 20px !important", backgroundColor: '#1A00A7' }} name="save" className="save-btn capitalize" variant="contained">Next</Button>

        {/* <Button variant="contained" size="medium" onClick={() => submitSupportedPrograms()} sx={{ backgroundColor: '#1A00A7' }} className='capitalize'>Next</Button> */}
      </div>
    </>

  )
}

export default FacilityInfo