import React, { useState, useContext } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Snackbar } from '@mui/material';
import { D_API } from '../../../../../../services/APIService';
import { URI } from '../../../../../utility/uri';
import CloseIcon from '@mui/icons-material/Close';
import { ContextInfo } from '../adminOrganisationNew';
import { useHistory } from 'react-router-dom';




const DeleteFacilityDialog = ({ open, close, delObj, closeEditDialog, parentOrg }) => {


    const [alert, setAlert] = useState({
        open: false,
        msg: ""
    })


    const history = useHistory();
    const contextObj = useContext(ContextInfo);

    console.log(contextObj, "contextObj")



    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setAlert({
            open: false,
            msg: ""
        })
    };

    const DeleteOrgFn = (Obj, parentObj) => {

        let LocObj = null;
        if (Obj?.resource?.id) {
            D_API.get(`${URI.getLocation}${Obj?.resource?.id}`).then((res) => {
                LocObj = res.data?.entry?.[0];
            }).catch((err) => console.log(err))
        }
        // console.log(Obj, "come")

        let pageId
        let copyObj = { ...Obj?.resource };
        let indx = copyObj?.identifier?.findIndex((val) => val.system == 'https://pageId/');
        pageId = indx != -1 && parseInt(copyObj?.identifier?.[indx]?.value) != NaN ? copyObj?.identifier?.[indx]?.value : null;

        let parentIndx = parentObj?.identifier?.findIndex((val) => val.system == 'https://pageId');
        let parentPageId = parentIndx != -1 && parseInt(parentObj?.identifier?.[indx]?.value) != NaN ? parentObj?.identifier?.[indx]?.value : null;


        if (LocObj != null && LocObj?.resource?.id) {
            D_API.delete(`Location/${LocObj?.resource?.id}`).then((res) => {
                if (Obj?.resource?.id && pageId) {
                    D_API.delete(`${URI?.organization}/${Obj?.resource?.id}/${pageId}`).then((res) => {
                        if (!contextObj?.orgObj?.partOf && contextObj?.orgObj?.id) {
                            contextObj.getsubOrgDetails(contextObj?.orgObj?.id);
                        }
                        close();
                        setAlert({ open: true, msg: "Successfully Deleted Organization" });
                        if (parentPageId) {
                            history.push(`/admin/organization/${parentPageId}`);
                        } else {
                            history.push(`/`);
                        }

                    })
                }
            }).catch((err) => {
                close();
                setAlert({ open: true, msg: "Something went wrong Plz try after sometime" });
                closeEditDialog();
            })

        } else if (Obj?.resource?.id && pageId) {
            D_API.delete(`${URI?.organization}/${Obj?.resource?.id}/${pageId}`).then((res) => {
                if (!contextObj?.orgObj?.partOf && contextObj?.orgObj?.id) {
                    contextObj.getsubOrgDetails(contextObj?.orgObj?.id);
                }
                close();
                setAlert({ open: true, msg: "Successfully Deleted Organization" });
                if (parentPageId) {
                    history.push(`/admin/organization/${parentPageId}`);
                } else {
                    history.push(`/`);
                }
            }).catch((err) => {
                close();
                setAlert({ open: true, msg: "Something went wrong Plz try after sometime" });
                closeEditDialog();
            });

        }
        else {
            close();
            closeEditDialog();
            setAlert({
                open: true, msg: "Organization reference not found"
            })
        }


    }

    // this piece of code should keep above the return only don't change it
    const action = (
        <React.Fragment>
            <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={handleClose}
            >
                <CloseIcon fontSize="small" />
            </IconButton>
        </React.Fragment>
    );

    return (
        <div>


            <React.Fragment>

                <Dialog
                    open={open}
                    onClose={close}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    maxWidth="xs"
                    fullWidth
                >
                    <DialogTitle id="alert-dialog-title">
                        <div className='fntMd fntBold9' style={{ color: '#007cdc' }}>Caution</div>
                    </DialogTitle>
                    <DialogContent>
                        Are sure do you want to delete the facility because it will not revert back further !
                    </DialogContent>
                    <DialogActions>
                        <Button variant="contained" className='capitalize' sx={{ padding: "4px 20px !important", borderRadius: "20px" }} onClick={() => DeleteOrgFn(delObj, parentOrg)}>Yes</Button>
                        < Button variant="contained" className='capitalize' sx={{ padding: "4px 20px !important", borderRadius: "20px" }} onClick={close}>No</Button>
                    </DialogActions>
                </Dialog>
            </React.Fragment>

            <Snackbar
                open={alert?.open}
                // autoHideDuration={6000}
                message={alert.msg}
                onClose={handleClose}
                action={action}
            />
        </div>
    )
}

export default DeleteFacilityDialog