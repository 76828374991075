














// import React, { useState, useEffect } from "react";
// import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
// import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
// import HealthDeptIcon from "../../../../../icons/Healthcare_Department.svg";
// import { D_API } from "../../../../../services/APIService";
// import { URI } from "../../../../utility/uri";
// import { useSelector } from "react-redux";
// import scroll from "../../../../Atom/svg/scroll.svg";
// import { FormDivider } from "../../../../utility/utils";

// const RearrangingDepartments = ({ open, close, departList, updateGetList }) => {
//     const [info, setInfo] = useState([]);
//     const orgData = useSelector(state => state.registration.userOrgData);

//     useEffect(() => {
//         const sortList = departList?.slice().sort((a, b) => {
//             const aValue = a?.resource?.identifier?.find((e) => e.system === "https://positionofdepartments/")?.value;
//             const bValue = b?.resource?.identifier?.find((e) => e.system === "https://positionofdepartments/")?.value;
//             return (aValue || 0) - (bValue || 0);
//         });
//         setInfo(sortList || []);
//     }, [departList]);


//     console.log(info, "4545")
//     const getListOfHealthDeparts = async (orgID) => {
//         try {
//             const res = await D_API.get(`resource/authorization/docsteth/Organization?partof=${orgID}&type=dept`);
//             const sortedList = res.data?.entry ? res.data.entry.sort((a, b) => {
//                 const aValue = a?.resource?.identifier?.find((e) => e.system === "https://positionofdepartments/")?.value;
//                 const bValue = b?.resource?.identifier?.find((e) => e.system === "https://positionofdepartments/")?.value;
//                 return (aValue || 0) - (bValue || 0);
//             }) : [];
//             updateGetList(sortedList);
//         } catch (err) {
//             console.error('Error fetching departments:', err);
//         }
//     };

//     const updateDepartments = async (data) => {
//         const { resource } = data;
//         if (resource?.id) {
//             try {
//                 await D_API.put(`${URI.updateOrg}${resource?.id}`, { ...resource });
//                 if (orgData?.id) {
//                     await getListOfHealthDeparts(orgData.id);
//                 }
//             } catch (err) {
//                 console.error('PUT Error:', err);
//             }
//         }
//     };

//     const handleDragEnd = async (result) => {
//         if (!result.destination) return;

//         const { source, destination } = result;
//         const updatedList = [...info];
//         const [movedItem] = updatedList.splice(source.index, 1);
//         updatedList.splice(destination.index, 0, movedItem);

//         const updatedListWithIdentifiers = updatedList.map((item, index) => {
//             const newIdentifier = { system: "https://positionofdepartments/", value: index.toString() };
//             return {
//                 ...item,
//                 resource: {
//                     ...item.resource,
//                     identifier: [
//                         ...(item.resource.identifier.filter(e => e.system !== "https://positionofdepartments/")),
//                         newIdentifier
//                     ]
//                 }
//             };
//         });

//         setInfo(updatedListWithIdentifiers);

//         // Update departments with new positions
//         await Promise.all(updatedListWithIdentifiers.map(updateDepartments));
//     };

//     return (
//         <Dialog
//             open={open}
//             onClose={close}
//             aria-labelledby="alert-dialog-title"
//             aria-describedby="alert-dialog-description"
//             maxWidth="sm"
//             fullWidth
//             style={{ height: "500px", overflow: "hidden" }}
//         >
//             <DialogTitle id="alert-dialog-title">
//                 <div className='fntMd fntBold9' style={{ color: '#007cdc' }}>Re-Arrange Departments</div>
//             </DialogTitle>
//             <DialogContent style={{ overflow: "auto", '&::-webkit-scrollbar': { display: 'none' } }}>
//                 <DragDropContext onDragEnd={handleDragEnd}>
//                     <Droppable droppableId="Depts">
//                         {(provided, snapshot) => (
//                             <div
//                                 {...provided.droppableProps}
//                                 ref={provided.innerRef}
//                                 style={{ overflowY: "auto", height: "100%" }}
//                             >
//                                 {info.length > 0 ? (
//                                     info.map((val, indx) => (
//                                         <Draggable
//                                             key={val?.resource?.id || indx.toString()}
//                                             draggableId={val?.resource?.id || indx.toString()}
//                                             index={indx}
//                                         >
//                                             {(provided) => (
//                                                 <div
//                                                     ref={provided.innerRef}
//                                                     {...provided.draggableProps}
//                                                     {...provided.dragHandleProps}
//                                                     style={{
//                                                         ...provided.draggableProps.style,
//                                                         display: "flex",
//                                                         alignItems: "center",
//                                                         gap: "10px",
//                                                         padding: "10px",
//                                                         marginBottom: "10px",
//                                                         backgroundColor: snapshot.isDragging ? "#e3f2fd" : "white",
//                                                         borderRadius: "7px",
//                                                         border: "1px solid #ddd"
//                                                     }}
//                                                 >
//                                                     <div className="my-img domain-icon" style={{ height: '55px', width: '55px', borderRadius: '7px' }}>
//                                                         <img
//                                                             src={val?.resource?.identifier?.find(identifier => identifier.system === process.env.REACT_APP_API_URL && identifier.value.startsWith("https"))?.value || HealthDeptIcon}
//                                                             alt="department"
//                                                             width='30px'
//                                                             height="30px"
//                                                         />
//                                                     </div>
//                                                     <div style={{ height: "40px", width: "100%" }}>
//                                                         <div className='fntMd capitalize' style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', width: `calc(100% - 50px)` }}>
//                                                             {val?.resource?.name}
//                                                         </div>
//                                                         <div className='fntXSm capitalize' style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', width: `calc(100% - 50px)` }}>
//                                                             {val?.resource?.alias?.[0]}
//                                                         </div>
//                                                     </div>
//                                                     <div>
//                                                         <img src={scroll} alt="scroll" />
//                                                     </div>
//                                                 </div>
//                                             )}
//                                         </Draggable>
//                                     ))
//                                 ) : (
//                                     <p className="flx-center justify-center fntSemiBold fntSm14">No Data Available</p>
//                                 )}
//                                 {provided.placeholder}
//                             </div>
//                         )}
//                     </Droppable>
//                 </DragDropContext>
//             </DialogContent>
//             <FormDivider />
//             <DialogActions>
//                 <Button variant="text" className='capitalize' sx={{ padding: "4px 20px !important", borderRadius: "20px" }} onClick={close}>Close</Button>
//             </DialogActions>
//         </Dialog>
//     );
// };

// export default RearrangingDepartments;



















// testend
import React, { useState, useEffect } from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import HealthDeptIcon from "../../../../../icons/Healthcare_Department.svg";
import { D_API } from "../../../../../services/APIService";
import { URI } from "../../../../utility/uri";
import { useSelector } from "react-redux";
import scroll from "../../../../Atom/svg/scroll.svg";
import { FormDivider } from "../../../../utility/utils";

const RearrangingDepartments = ({ open, close, departList, updateGetList }) => {
    const [info, setInfo] = useState([]);
    const orgData = useSelector(state => state.registration.userOrgData);

    useEffect(() => {
        let sortList = departList?.sort((a, b) => a?.resource?.identifier?.filter((e) => e.system == "https://positionofdepartments/")?.[0]?.value - b?.resource?.identifier?.filter((e) => e.system == "https://positionofdepartments/")?.[0]?.value);
        if (sortList?.length > 0) {
            setInfo(sortList);
        } else {
            setInfo([]);
        }

    }, [departList]);


    const getListOfHealthDeparts = (orgID) => {
        D_API.get(`resource/authorization/docsteth/Organization?partof=${orgID}&type=dept`).then((res) => {
            console.log(res.data, "7878")
            let sortList = res.data?.entry ? res.data?.entry?.sort((a, b) => a?.resource?.identifier?.filter((e) => e.system == "https://positionofdepartments/")?.[0]?.value - b?.resource?.identifier?.filter((e) => e.system == "https://positionofdepartments/")?.[0]?.value) : []
            updateGetList(sortList)

        }).catch((err) => console.log(err));
    }

    const updateDepartments = (data) => {
        const { resource, ...rest } = data;
        if (resource?.id) {
            D_API.put(`${URI.updateOrg}${resource?.id}`, { ...resource }).then((res) => {
                console.log(res.data);
                if (orgData?.id) {
                    getListOfHealthDeparts(orgData?.id)
                }

            }).catch((err) => console.log('PUT Error:', err));
        }

    }


    console.log(info, "info")


    const handleDragEnd = (result) => {

        let list = [...info];
        if (!result?.destination) {
            return;
        }

        const { source, destination } = result;
        console.log(result, "result")
        const [sourceObj] = list?.splice(source?.index, 1);
        list?.splice(destination?.index, 0, sourceObj);
        setInfo(list);

        const updatedList = list?.map((item, index) => {
            if (item?.resource?.identifier?.some((val) => val.system === "https://positionofdepartments/")) {

                let data = {
                    ...item,
                    resource: {
                        ...item.resource,
                        identifier: item.resource.identifier?.map((e) =>
                            e.system === "https://positionofdepartments/"
                                ? { ...e, value: (index).toString() }
                                : e
                        ),
                    },
                }
                updateDepartments(data);

                return {
                    ...item,
                    resource: {
                        ...item.resource,
                        identifier: item.resource.identifier?.map((e) =>
                            e.system === "https://positionofdepartments/"
                                ? { ...e, value: (index).toString() }
                                : e
                        ),
                    },
                }

            } else {
                let data = {
                    ...item, resource: { ...item.resource, identifier: item?.resource?.identifier ? [...item.resource?.identifier, { system: "https://positionofdepartments/", value: (index)?.toString() }]?.filter(((val) => val != undefined)) : [{ system: "https://positionofdepartments/", value: (index)?.toString() }] }
                }
                updateDepartments(data);
                return {
                    ...item, resource: { ...item.resource, identifier: item?.resource?.identifier ? [...item.resource?.identifier, { system: "https://positionofdepartments/", value: (index)?.toString() }]?.filter(((val) => val != undefined)) : [{ system: "https://positionofdepartments/", value: (index)?.toString() }] }
                }
            }


        })

        // if (updatedList?.length > 0) {
        //     updateDepartments(data);
        // }
        // const updatedList = list.map((item, index) => {
        //     if (item?.resource?.identifier?.some((val) => val.system === "https://positionofdepartments/")) {
        //         if (index === source?.index) {
        //             let data = {
        //                 ...item,
        //                 resource: {
        //                     ...item.resource,
        //                     identifier: item.resource.identifier?.map((e) =>
        //                         e.system === "https://positionofdepartments/"
        //                             ? { ...e, value: (source?.index).toString() }
        //                             : e
        //                     ),
        //                 },
        //             }
        //             // updateDepartments(data);

        //             return {
        //                 ...item,
        //                 resource: {
        //                     ...item?.resource,
        //                     identifier: item?.resource?.identifier?.map((e) =>
        //                         e?.system === "https://positionofdepartments/"
        //                             ? { ...e, value: (source?.index)?.toString() }
        //                             : e
        //                     ),
        //                 },
        //             };
        //         }
        //         if (index === destination?.index) {
        //             let data = {
        //                 ...item,
        //                 resource: {
        //                     ...item?.resource,
        //                     identifier: item?.resource?.identifier?.map((e) =>
        //                         e?.system === "https://positionofdepartments/"
        //                             ? { ...e, value: (destination?.index)?.toString() }
        //                             : e
        //                     ),
        //                 },
        //             }

        //             // updateDepartments(data);

        //             return {
        //                 ...item,
        //                 resource: {
        //                     ...item?.resource,
        //                     identifier: item?.resource?.identifier?.map((e) =>
        //                         e?.system === "https://positionofdepartments/"
        //                             ? { ...e, value: (destination?.index)?.toString() }
        //                             : e
        //                     ),
        //                 },
        //             };
        //         }
        //     }
        //     else {

        //         let data = {
        //             ...item, resource: { ...item.resource, identifier: [...item.resource?.identifier, { system: "https://positionofdepartments/", value: (index)?.toString() }]?.filter(((val) => val != undefined)) }
        //         }
        //         updateDepartments(data);
        //         return {
        //             ...item, resource: { ...item.resource, identifier: [...item.resource?.identifier, { system: "https://positionofdepartments/", value: (index)?.toString() }]?.filter(((val) => val != undefined)) }
        //         }
        //     }
        // })

        console.log(updatedList, "updatedList1")

        setInfo(updatedList);

    };


    return (
        <Dialog
            open={open}
            onClose={close}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            maxWidth="sm"
            fullWidth
            style={{
                height: "500px",
                overflow: "hidden",

            }}
        >
            <DialogTitle id="alert-dialog-title">
                <div className='fntMd fntBold9' style={{ color: '#007cdc' }}>Re-Arrange Departments</div>
            </DialogTitle>
            <DialogContent
                style={{
                    overflow: "auto",

                    '&::-webkit-scrollbar': { display: 'none' }
                }}
            >
                <DragDropContext onDragEnd={handleDragEnd}>
                    <Droppable droppableId="Depts">
                        {(provided, snapshot) => (
                            <div
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                                style={{
                                    overflowY: "auto",
                                    height: "100%",
                                }}
                            >
                                {info.length > 0 ? (
                                    info.map((val, indx) => (
                                        <Draggable
                                            key={indx.toString()}  // Use one-based index as string for key
                                            draggableId={indx.toString()}  // Use one-based index as string for draggableId
                                            index={indx}  // Use zero-based index for sorting
                                        >
                                            {(provided) => (
                                                <div
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}
                                                    style={{
                                                        ...provided.draggableProps.style,
                                                        display: "flex",
                                                        alignItems: "center",
                                                        gap: "10px",
                                                        padding: "10px",
                                                        marginBottom: "10px",
                                                        backgroundColor: snapshot.isDragging ? "#e3f2fd" : "white",
                                                        borderRadius: "7px",
                                                        border: "1px solid #ddd"

                                                    }}
                                                >
                                                    <div className="my-img domain-icon" style={{ height: '55px', width: '55px', borderRadius: '7px', }}>
                                                        <img
                                                            src={val?.resource?.identifier?.filter((identifier) => identifier["system"] === `${process.env.REACT_APP_API_URL}v1/logo` && identifier["value"]?.startsWith("https"))?.[0]?.value || HealthDeptIcon}
                                                            alt="organisation"
                                                            width='30px'
                                                            height="30px"
                                                        />
                                                    </div>
                                                    <div style={{ height: "40px", width: "100%" }}>
                                                        <div className='fntMd capitalize' style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', width: `calc(100% - 50px)` }}>
                                                            {`${val?.resource?.name}`}
                                                        </div>
                                                        <div className='fntXSm capitalize' style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', width: `calc(100% - 50px)` }}>
                                                            {val?.resource?.alias?.[0]}
                                                        </div>
                                                    </div>

                                                    <div>
                                                        <img src={scroll} alt="scroll" />
                                                    </div>
                                                </div>
                                            )}
                                        </Draggable>
                                    ))
                                ) : (
                                    <p className="flx-center justify-center fntSemiBold fntSm14">No Data Available</p>
                                )}
                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>
            </DialogContent>
            <FormDivider />
            <DialogActions>
                <Button variant="text" className='capitalize' sx={{ padding: "4px 20px !important", borderRadius: "20px" }} onClick={close}>Close</Button>
            </DialogActions>
        </Dialog>
    );
};

export default RearrangingDepartments;




