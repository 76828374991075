import React, { useEffect, useState } from "react";
import { Grid, IconButton, Snackbar } from "@mui/material";
import { ValidatorForm } from "react-material-ui-form-validator";
// import { FormDivider } from "../../registration/common/common";
import { FormDivider } from "../../../../utility/utils";
import { useSelector } from "react-redux";
import { Actions, OrgLayout, Purpose } from "../../../../common/components/layout";
import { deleteKeys } from "../../../../common/functions/functions";
import { URI } from "../../../../utility/uri";
import { DOC_API, D_API } from "../../../../../services/APIService";
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import DeleteMainOrgDialog from "./deleteDialogboxMainOrg";

const orgType = [
  {
    code: 'prov',
    display: 'Healthcare Provider'
  },
  {
    code: 'dept',
    display: 'Hospital Department'
  },
  {
    code: 'team',
    display: 'Organizational team'
  },
  {
    code: 'govt',
    display: 'Government'
  },
  {
    code: 'ins',
    display: 'Insurance Company'
  },
  {
    code: 'pay',
    display: 'Payer'
  },
  {
    code: 'edu',
    display: 'Educational Institute'
  },
  {
    code: 'reli',
    display: 'Religious Institution'
  },
  {
    code: 'crs',
    display: 'Clinical Research Sponsor'
  },
  {
    code: 'cg',
    display: 'Community Group'
  },
  {
    code: 'bus',
    display: 'Non-Healthcare Business or Corporation'
  },
  {
    code: 'other',
    display: 'Other'
  },

]

const BasicDetails = (props) => {
  // console.log(props, "propsbasic")
  // const orgData = useSelector(state => state.registration.userOrg)
  const orgData = useSelector((state) => state.registration.userOrgData);
  const location = useSelector(state => state.registration.location);

  console.log(orgData, "orgdata here", location)

  const [contact, setContact] = useState([]);
  const [details, setDetails] = useState({
    name: "",
    alias: "",
    display: "",
    reference: "",
    partOf: "",
  });

  const [type, setType] = useState(null);
  const [partOfListInfo, setPartOfListInfo] = useState([]);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [alert, setAlert] = useState({
    open: false,
    msg: ""
  })


  console.log(type, "type here")
  console.log(contact, "contact");

  // http://hl7.org/fhir/ValueSet/organization-type

  useEffect(() => {
    ValidatorForm.addValidationRule("pageExists", (value) => {
      console.log(value);
      if (!orgData?.identifier?.length > 0 && value && value?.length >= 3) {
        return DOC_API.get(URI.findPageName + value).then((res) => {
          console.log(res.data);
          return !res.data;
        });
      } else {
        return true;
      }
    });
  }, [orgData]);

  useEffect(() => {
    if (orgData?.identifier?.length > 0) {
      const { name, alias, endpoint, partOf, type } = orgData;
      console.log(partOf, "partOf")
      setDetails({
        name: name,
        alias: alias?.[0] || "",
        display: endpoint?.[0]?.display || "",
        reference: endpoint?.[0]?.reference || "",
        // partOf: partOf?.display !== "NA" ? partOf?.display : "",
        // partOf: partOf?.display !== "NA" ? partOf?.display : "",
      });
      setContact(orgData?.telecom || []);
      setType(type?.[0]?.coding?.[0])
    }
  }, [orgData]);

  // useEffect(() => {
  //   if (orgData?.partOf?.reference?.split("/")?.[1]) {

  //     D_API.get(`${URI.updateOrg}${orgData.partOf.reference.split("/")?.[1]}`).then((res) => {
  //       console.log(res.data, "ttt")
  //       setDetails((prev) => {
  //         return { ...prev, partOf: res.data }
  //       })

  //     }).catch((err) => console.log(err))
  //   }



  // }, [orgData?.partOf])


  const getOrganizationDetailsOfPartOfOrg = (val) => {
    D_API.get(`${URI.organization}/${val}`).then((res) => {
      console.log(res.data)
      setDetails((prev) => {
        return {
          ...prev, partOf: res.data
        }
      })
    }).catch((err) => console.log(err))
  }

  useEffect(() => {
    if (props?.parentOrgObj?.partOf) {
      let Id = props?.parentOrgObj?.partOf?.reference?.split("/")?.[1];
      if (Id != null && Id != undefined && Id != "") {
        getOrganizationDetailsOfPartOfOrg(Id)
      }

    }
    else if (props?.parentOrgObj?.id && !props?.parentOrgObj?.partOf) {
      setDetails((prev) => {
        return {
          ...prev, partOf: props?.parentOrgObj
        }
      })
    }

  }, [props.parentOrgObj])

  useEffect(() => {
    setType(orgType?.[0])
  }, [])


  const addContact = (system) => (use) => {
    setContact([...contact, { system: system, value: "", use: use }]);
  };

  const deleteContact = (index) => () => {
    setContact([...contact?.filter((con, i) => index !== i)]);
  };

  const handleSearchPartOf = (event, value) => {
    if (value?.length > 2) {
      D_API.get(URI.getOrganizationPartOf + value).then((res) => {
        console.log(res.data);
        setPartOfListInfo(res.data?.entry)
      }).catch((err) => console.log(err))
    }
  }

  console.log(details, "details")

  const handleDetails = (name, value) => {

    setDetails({ ...details, [name]: value });

  };

  const handleContact = (i) => (name, value) => {
    setContact([
      ...contact?.map((con, ind) =>
        i === ind ? { ...con, [name]: value } : { ...con }
      ),
    ]);
  };


  const handleDeleteOpnDialog = () => {
    setOpenDeleteDialog(true)
  }

  const handleDeleteClsDialog = () => {
    setOpenDeleteDialog(false);
  }



  console.log(partOfListInfo, "entry")
  const handleSubmit = () => {
    const { display, reference } = details;

    let endpoint = { display, reference };

    let data = deleteKeys({
      ...orgData,
      name: details.name,
      type: type.code ? [{
        "coding": [
          { ...type, "system": 'http://hl7.org/fhir/ValueSet/organization-type' }
        ],
      }] : "",
      alias: details?.alias ? [details?.alias] : [],
      ...(props.branch != "mainBranch" && props.parentOrgObj && {
        partOf: details.partOf
          ? {
            "reference": "Organization" + "/" + `${details.partOf?.resource?.id ? details?.partOf?.resource?.id : details?.partOf?.id ? details?.partOf?.id : null}`
          }
          : ""
      }),

      ...(location?.address && { address: [location?.address] }),

      endpoint: [deleteKeys(endpoint)]?.filter(
        (val) => Object.keys(val)?.length > 0
      ),
      telecom: contact?.filter((con) => con.value?.replace(/\+91/, "") !== ""),
    });

    props.submitData(orgData?.id ? deleteKeys({ ...data, id: orgData?.id }) : { ...deleteKeys(data) })
  };


  const deleteMainOrgFn = () => {


    handleDeleteOpnDialog();



    // if (valId) {
    //   let managOrgId = parseInt(location?.managingOrganization?.reference?.split("/")?.[1]);
    //   let pageId = orgData?.identifier.filter((val) => val["system"] == "https://pageId/" && parseInt(val["value"]) != NaN)

    //   if (location?.id && managOrgId != NaN) {
    //     D_API.delete(`${URI.addLocation}/${location?.id}/${managOrgId}`).then((res) => {
    //       if (orgData?.id && pageId?.[0]?.value) {
    //         D_API.delete(`${URI.updateOrg}${orgData?.id}/${pageId?.[0]?.value}`).then((res) => {
    //         }).catch((err) => console.log(err))
    //       } else {

    //       }

    //     }).catch((err) => console.log(err))

    //   }


    // } else {

    //   setAlert({
    //     open: true,
    //     msg: "Their is no Organization Id"
    //   })
    //   props.close();
    // }

  }



  // this below code should not reorder in position it should above return only
  const handleCloseSnackbar = () => {
    setAlert({
      open: false,
      msg: ""
    })
  }

  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleCloseSnackbar}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  return (
    <ValidatorForm onSubmit={handleSubmit}>
      <Grid
        container
        spacing={2}
        className="ph1"
        sx={{ height: "410px", overflow: "auto" }}
      >
        <OrgLayout
          label="Facility Name"
          value={details.name}
          disabled={orgData?.id}
          name="name"
          handleChange={handleDetails}
          placeholder="Name of Hospital / Clinic"
          required
          validators={["required", "pageExists", "minStringLength:3"]}
          errorMessages={[
            "Enter Facility Name",
            "Page Name already Exists",
            "Page Name should be atleast 3 characters",
          ]}
        />
        <OrgLayout
          label="Tagline"
          value={details.alias}
          name="alias"
          handleChange={handleDetails}
          placeholder="Any Other name with which this hospital is known"
        />
        {
          props.branch != "mainBranch" &&
          <OrgLayout
            label="Part Of"
            input="text"
            name="partOf"
            value={details?.partOf?.name}
            disabled
            // options={partOfListInfo || []}
            // onInputChange={handleSearchPartOf}
            // getOptionLabel={(option) => option?.resource?.name || option?.name || ""}
            handleChange={handleDetails}
            placeholder="If this Hospital / Clinic has a parent organisation"
          />
        }


        {/* old code for partOf */}
        {/* <OrgLayout
          label="Part Of"
          input="select"
          name="partOf"
          value={details.partOf}
          options={partOfListInfo || []}
          onInputChange={handleSearchPartOf}
          getOptionLabel={(option) => option?.resource?.name || option?.name || ""}
          handleChange={handleDetails}
          placeholder="If this Hospital / Clinic has a parent organisation"
        /> */}

        {/* {console.log(partOfListInfo, "partOfListInfo")} */}
        {/* <OrgLayout
          label="Jurisdiction"
          input="select"
          options={["India"]}
          value={"India"}
          disabled
          placeholder="Country"
        /> */}



        <OrgLayout
          label="Organization Type"
          // input="select"
          required
          // options={orgType}
          value={type?.display}
          // getOptionLabel={(option) => option.display}
          // handleChange={(name, value) => setType({ ...value, "system": 'http://hl7.org/fhir/ValueSet/organization-type' })}
          placeholder="Select Organization type"
          disabled
        />


        {/* old code */}
        {/* 
        <OrgLayout
          label="Organization Type"
          input="select"
          required
          options={orgType}
          value={type}
          getOptionLabel={(option) => option.display}
          handleChange={(name, value) => setType({ ...value, "system": 'http://hl7.org/fhir/ValueSet/organization-type' })}
          placeholder="Select Organization type"
        /> */}


        {/* Telecom */}

        <Purpose
          input="phone"
          name="Facility Phone"
          contact={contact}
          handleContact={handleContact}
          deleteContact={deleteContact}
          handleType={addContact("phone")}
          options={["mobile", "work"]}
        />
        <Purpose
          input="email"
          name="Facility Email"
          placeholder="Email Address"
          contact={contact}
          handleContact={handleContact}
          deleteContact={deleteContact}
          handleType={addContact("email")}
          options={["work"]}
        />

        <Grid item xs={12} />
        {/* <OrgLayout
          label="Website"
          value={details.display}
          name="display"
          handleChange={handleDetails}
          placeholder="https://www.xyz.com"
        />
        <OrgLayout
          label="Patient Portal-End Point"
          value={details.reference}
          name="reference"
          validators={[
            "matchRegexp:^(http|https)://[a-zA-Z0-9-.]+.[a-zA-Z]{2,3}(:[a-zA-Z0-9]*)?/?([a-zA-Z0-9-._?,'/\\+&amp;%$#=~])*$",
          ]}
          errorMessages={["Please enter valid url"]}
          handleChange={handleDetails}
          placeholder="https://www.xyz.com"
        /> */}
      </Grid>
      <FormDivider />

      <div className="flx-center justify-between">
        <div style={{ textAlign: 'right', marginTop: "10px" }} className="cursor">
          {
            orgData?.id && <DeleteIcon sx={{ color: "red" }} onClick={() => deleteMainOrgFn()} />
          }
        </div>
        <div>
          <Actions close={props.close} name={orgData?.id ? "Update" : "Next"} />
        </div>

      </div>

      <DeleteMainOrgDialog open={openDeleteDialog} close={handleDeleteClsDialog} delObj={orgData} closeEditDialog={props.close} />

      <Snackbar
        open={alert.open}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        message={alert.msg}
        action={action}
      />
    </ValidatorForm>
  );
};

export default BasicDetails;
