import { D_API } from "../../../services/APIService"
import { URI } from "../../utility/uri"
import UserService from "../../../services/UserService"


export const getOrganisations = async (count) => {
    return await D_API.get(URI.getOrganization + UserService.getUserId() + (count ? `?count=${count}` : "")).then(res => res.data || {})
}

export const getHealthServices = async (id) => {
    return await D_API.get('HealthcareService/location/' + id).then(res => res?.data?.entry || []).catch(err => { alert(err?.response?.data?.message || 'Couldn\'t fetch services.Please try later.') })
}
export const getHealthServicesData = async (id) => {
    return await D_API.get('HealthcareService/organization/' + id).then(res => res?.data?.entry || []).catch(err => { alert(err?.response?.data?.message || 'Couldn\'t fetch services.Please try later.') })
}

export const updateHealthcare = async (data) => {
    console.log(data, 'cccc')
    return await D_API.put('HealthcareService/' + data.id, data).then(() => true).catch(err => { alert(err?.response?.data?.message || 'Couldn\'t update service.Please try after sometime') })
}
export const updateLocation = async (data) => {
    console.log(data, 'cccc')
    return await D_API.put('Location/' + data.id, data).then(() => true).catch(err => { alert(err?.response?.data?.message || 'Couldn\'t update service.Please try after sometime') })
}
export const updateLocationAmenities = async (data) => {
    console.log(data, 'cccc')
    return await D_API.put('Location/' + data.id, data).then(() => true).catch(err => { alert(err?.response?.data?.message || 'Couldn\'t update service.Please try after sometime') })
}
