import React from "react";
import { Button, Card, Grid, Paper, Typography } from "@mui/material";
import QRcode from "qrcode.react";
import {
  Call as CallIcon,
  Email as EmailIcon,
  Public as PublicIcon,
} from "@mui/icons-material";
import { useSelector } from "react-redux";
import moment from "moment";

const buttonJson = [
  { name: "Call Facility", icon: CallIcon, action: "tel" },
  { name: "Send Email", icon: EmailIcon, action: "mailto" },
  { name: "Visit Website", icon: PublicIcon, action: "visitWebsite" },
];

const ButtonComponent = ({
  name,
  icon: IconComponent,
  action,
  onClick,
  userOrg,
}) => {
  const buttonProps = {
    variant: "contained",
    style: {
      minWidth: 130,
      fontSize: 13,
      borderRadius: 20,
      background: "#fff",
      color: "#2b2b2b",
      fontWeight: "bold",
      textTransform: "capitalize",
      whiteSpace: "nowrap",

    },
  };

  if (action === "visitWebsite") {
    return (
      <Button {...buttonProps} onClick={onClick}>
        {IconComponent && (
          <IconComponent style={{ fontSize: "16px", marginRight: 5 }} />
        )}
        {name}
      </Button>
    );
  } else {
    return (
      <a
        href={`${action}:${action === "mailto" ? "" : "//"}${userOrg?.telecom?.[action === "tel" ? 0 : 1]?.value
          }`}
      >
        <Button {...buttonProps}>
          {IconComponent && (
            <IconComponent style={{ fontSize: "16px", marginRight: 5 }} />
          )}
          {name}
        </Button>
      </a>
    );
  }
};

const Appiontment = ({
  addWebsite,
  qr,
  timings,
  WorkingDays,
  setTimings,
  setActiveDay,
  activeDay,
}) => {
  const userOrg = useSelector((state) => state.registration.userOrg);
  console.log(timings, "timings")
  return (
    <Card sx={{ mt: "15px", borderRadius: 3 }}>
      <Paper elevation={5} style={{ padding: " 15px" }}>
        {/* <Typography variant="body2" sx={{ margin: "0" }}>
          Organization Type
        </Typography>
        <Typography
          sx={{
            marginBottom: "12px !important",
            fontWeight: "500",
            fontSize: "18px",
          }}
        >
          {userOrg?.type?.[0]?.coding?.[0]?.display}
        </Typography> */}
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            gap: 10,
          }}
        >
          <h1
            className="fntMd text-align-center mt-10"
            style={{ textTransform: "uppercase", color: "#1976d2" }}
          >
            QR CODE
          </h1>
          {qr && <QRcode value={qr} style={{ margin: "auto" }} size={100} />}
          <p className="text-align-center p1 line_height30">
            Scan QR code to scan and book appointment
          </p>
        </div>
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            gap: 12,
            justifyContent: "center",
          }}
        >
          {buttonJson?.map((button) => (
            <ButtonComponent
              key={button.name}
              name={button.name}
              icon={button.icon}
              action={button.action}
              onClick={button.action === "visitWebsite" ? addWebsite : null}
              userOrg={userOrg}

            />
          ))}
        </div>

        <h1 className="fntMd mt-10" style={{ color: "#1976d2" }}>
          Work Hours
        </h1>
        <div className="work-hours">
          {
            timings?.day && timings?.today ? (
              <p>
                {" "}
                {timings?.day == "mon"
                  ? "Monday" + "(Today)"
                  : timings?.day == "tue"
                    ? "Tuesday" + "(Today)"
                    : timings?.day == "wed"
                      ? "Wednesday" + "(Today)"
                      : timings?.day == "thu"
                        ? "Thursday" + "(Today)"
                        : timings?.day == "fri"
                          ? "Friday" + "(Today)"
                          : timings?.day == "sat"
                            ? "Saturday" + "(Today)"
                            : timings?.day == "sun"
                              ? "Sunday" + "(Today)"
                              : ""}{" "}
              </p>
            ) :
              timings?.day && !timings?.today ? (
                <p>
                  {" "}
                  {timings?.day == "mon"
                    ? "Monday"
                    : timings?.day == "tue"
                      ? "Tuesday"
                      : timings?.day == "wed"
                        ? "Wednesday"
                        : timings?.day == "thu"
                          ? "Thursday"
                          : timings?.day == "fri"
                            ? "Friday"
                            : timings?.day == "sat"
                              ? "Saturday"
                              : timings?.day == "sun"
                                ? "Sunday"
                                : ""}{" "}
                </p>
              )
                : ""
          }

          <p className="open-hospital">Open</p>
          {/* 
          {timings?.day
            && (
              <p>
                Today-{" "}
                {timings?.day == "mon"
                  ? "Monday"
                  : timings?.day == "tue"
                    ? "Tuesday"
                    : timings?.day == "wed"
                      ? "Wednesday"
                      : timings?.day == "thu"
                        ? "Thursday"
                        : timings?.day == "fri"
                          ? "Friday"
                          : timings?.day == "sat"
                            ? "Saturday"
                            : timings?.day == "sun"
                              ? "Sunday"
                              : ""}{" "}
              </p>
            )} */}

        </div>
        <div
          style={{
            display: "flex",
            gap: "5px",
            overflowY: "scroll",
            margin: "12px 0",
          }}
        >
          {WorkingDays?.map((item, i) => {
            return (
              <Button
                key={i}
                variant="contained"
                onClick={() => {
                  setTimings(item);
                  setActiveDay(i);
                }}
                sx={{
                  minWidth: "25px",
                  background:
                    activeDay == i && activeDay !== null
                      ? "black"
                      : item.today && activeDay == null
                        ? "black"
                        : !item.allDay && !item.hasOwnProperty("workingHours")
                          ? "#A9A9A9"
                          : "#197FC4",
                  padding: "0px 7px",
                }}
              >
                <Typography
                  variant="caption"
                  sx={{
                    color: "white",
                    textTransform: "capitalize",
                  }}
                >
                  {item.day}
                </Typography>
              </Button>
            );
          })}
        </div>

        {timings && timings.allDay ? (
          <Typography variant="body2" className="timing">
            All Day
          </Typography>
        ) : timings.allDay == false ? (
          <Typography variant="body2" className="timing">
            No Slot avalable
            {console.log(timings, "666666666")}
          </Typography>
        ) : (
          timings?.workingHours &&
          timings?.workingHours?.map((item, ind) => {
            console.log(item, "");
            return (
              <Typography
                key={ind}
                mb={2}
                sx={{ marginBottom: "5px !important" }}
                variant="body2"
                className="timing"
              >
                {moment(item?.openingTime, "hh:mm").format("HH:mm A")}
                &nbsp;&nbsp;-&nbsp;&nbsp;
                {moment(item?.closingTime, "hh:mm").format("HH:mm A")}
              </Typography>
            );
          })
        )}
      </Paper>
    </Card>
  );
};

export default Appiontment;
