import React, { useState, useEffect } from "react";
import { Card, Grid, CircularProgress, Avatar } from "@mui/material";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { useSelector } from "react-redux";
import { D_API } from "../../../../../../services/APIService";
import { URI } from "../../../../../utility/uri";
import DefaultHealthServiceIcon from "../../../../../../icons/Healthcare_Service.svg";

const HealthServices = () => {


    const [subServicesList, setSubServicesList] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const parentOrgInfo = useSelector((state) => state.registration.userOrgData);
    const healthServiceOrg = useSelector(state => state.registration.healthServiceOrgInfo);
    console.log(healthServiceOrg, "5")


    useEffect(() => {

        let listOfSpecialityCodes = healthServiceOrg?.specialty?.map((e) => e?.coding?.[0]?.code)?.filter((val) => val != null && val != undefined && val != "")
        if (listOfSpecialityCodes?.length > 0) {
            getSubHealthService(listOfSpecialityCodes)
        }

    }, [])


    const getSubHealthService = (codes) => {
        setIsLoading(true)
        D_API.post(`${URI.updateHealthServiceDept}/location/${parentOrgInfo?.id}`, [...codes]).then((res) => {
            // console.log(res.data, "//")
            let newList = [...res.data];
            newList = newList.filter((val) => val?.id != healthServiceOrg?.id);
            setSubServicesList(newList);
            setIsLoading(false)
        }).catch((err) => {
            console.log(err)
            setIsLoading(false)

        }
        )
    }



    return (
        <div>

            <div className="mv1">
                <Card sx={{ padding: "10px", minHeight: "150px" }}>
                    <Grid container alignItems="center" justifyContent="space-between">
                        <Grid item>
                            <div className="font-head fntSemiBold">
                                Similar Services
                            </div>
                        </Grid>
                        <Grid item>
                            <div style={{ paddingTop: "2px" }}>
                                <AddCircleIcon fontSize="small" sx={{ color: "#008114", }} className="cursor" />
                            </div>
                        </Grid>
                    </Grid>

                    {
                        isLoading && <div className="flx-center jc__center " style={{ height: "150px", }}> <CircularProgress />
                        </div>
                    }


                    <div>
                        {
                            <Grid container spacing={3} sm={12} md={12} lg={12} style={{ paddingRight: "5px" }}>
                                {
                                    subServicesList?.length > 0 && subServicesList?.map((val) => {
                                        return (
                                            <Grid item xs={12} sm={6} md={4} lg={4}>
                                                <Card sx={{
                                                    padding: "5px", borderRadius: "",
                                                    width: "100%",
                                                    //  width: {
                                                    //     sm: "100%", md: "100%", lg: "100%"
                                                    // },
                                                    height: { sm: "45px", md: "45px", lg: "45px" }
                                                }} className='mv1'

                                                >
                                                    <Grid container alignItems="" spacing={2}>
                                                        <Grid item>
                                                            <Avatar variant="square" sx={{
                                                                width: '40px !important', height: '40px !important',
                                                                // borderRadius: '5px !important', width: '50px !important', height: '50px !important', backgroundColor: "", boxShadow:
                                                                //   "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px", border: "0.5px solid #fff",
                                                            }} src={val?.photo?.url || DefaultHealthServiceIcon} />

                                                        </Grid>
                                                        <Grid item>
                                                            <div style={{ height: "45px" }}>
                                                                <div className='fntMd'>{val?.name?.length > 30 ? <span>{val?.name?.slice(0, 29)}...</span> : <span>{val?.name}</span>}</div>
                                                                <div className='fntXSm'>{val?.comment?.length > 40 ? `${val?.comment?.slice(0, 39)}...` : val?.comment}</div>
                                                            </div>
                                                        </Grid>
                                                    </Grid>
                                                </Card>
                                            </Grid>
                                        )
                                    }
                                    )
                                }

                            </Grid>
                        }
                    </div>

                    {
                        (!isLoading && subServicesList?.length <= 0) &&
                        <div className="flx-center justify-center fntMd  fntSemiBold " style={{ height: "150px", }} >
                            <div >
                                No Data is Available
                            </div>

                        </div>
                    }

                </Card>
            </div>

        </div >
    )
}
export default HealthServices;