import React, { useState, useEffect } from "react";
import { ValidatorForm } from "react-material-ui-form-validator";
import { Avatar, Button, Card, CardActions, CircularProgress, DialogActions, DialogContent, Dialog, Grid, DialogTitle, Snackbar, IconButton } from "@mui/material";
import { Actions, FileInput, OrgLayout, ProfileLayout, Purpose } from "../../../../../common/components/layout";
import { FormDivider } from "../../../../../utility/utils";
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import { DOC_API, D_API } from "../../../../../../services/APIService";
import { URI } from "../../../../../utility/uri";
import URL from "../../../../../apiurl";
import { deleteKeys } from "../../../../../common/functions/functions";
import { handleHealthDeptOrg, handleUserOrg, handleHealthDeptLoc } from "../../../../../../redux/Actions/regActions";
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import HealthDeptIcon from "../../../../../../icons/Healthcare_Department.svg";
import UserService from "../../../../../../services/UserService";
import { headers } from "../../../../../../redux/Actions/connectActions";
import DialogBox from "../../../../../DialogBox/dialog";
import { CloseBtn } from "../../../../LinkAccount/linkaccount";
import CloseIcon from '@mui/icons-material/Close';
import DeleteDeptDialog from "./deleteDeptDialog";






const HealthDeptBasicDetails = ({ switchTabs, clsn, healthid, Reset }) => {


    const orgData = useSelector(state => state.registration.userOrgData);
    const healthDeptOrg = useSelector(state => state.registration.healthDeptDetails);
    const healthDeptLoc = useSelector(state => state.registration.healthDeptLocationDetails);
    console.log(orgData, "orgInfo")
    console.log(healthDeptOrg, "healthDeptOrg");
    console.log(healthDeptLoc, "healthDeptLoc")
    const [deptDetails, setDeptDetails] = useState({
        deptName: "",
        deptAlias: "",
        partOf: "",
        orgType: {
            system: "http://terminology.hl7.org/CodeSystem/organization-type",
            code: "dept",
            display: "Hospital Department"
        }
    })
    const [coverPhoto, setCoverPhoto] = useState({});
    const [url, setUrl] = useState('')
    const [uploadLoader, setUploadLoader] = useState({ open: false, msg: '' });
    const [uploadedFile, setUploadedFile] = useState(null);
    const [toUpdateKey, setToUpdateKey] = useState("")
    const [alert, setAlert] = useState({ open: false, msg: '' })
    const [isLoading, setIsLoading] = useState(false);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [contact, setContact] = useState([]);
    const [lengthOfDeparts, setLengthOfDeparts] = useState("0");
    console.log(uploadedFile, "uploadedFile")
    const dispatch = useDispatch();
    const { id } = useParams();
    const history = useHistory();

    useEffect(() => {
        if (orgData?.id) {
            setDeptDetails((prev) => {
                return {
                    ...prev, partOf: orgData?.id
                }
            })
        }
    }, [orgData?.id])

    useEffect(() => {
        if (orgData?.id) {
            D_API.get(`resource/authorization/docsteth/Organization?partof=${orgData?.id}&type=dept`).then((res) => {
                console.log(res.data, "7878")
                if (res.data?.entry) {
                    setLengthOfDeparts(res?.data?.entry?.length?.toString());
                } else {
                    setLengthOfDeparts("0");
                }

            }).catch((err) => console.log(err));
        }
    }, [orgData?.id])



    console.log(lengthOfDeparts, "lengthOfDeparts")

    useEffect(() => {
        if (healthid) {
            D_API.get(`Organization/${healthid}`).then((res) => {
                dispatch(handleHealthDeptOrg(res.data));
                console.log(res.data)
            }).catch((err) => console.log(err));
        }
    }, [healthid])


    useEffect(() => {
        setDeptDetails((prev) => {
            return {
                ...prev, deptName: healthDeptOrg.name,
                deptAlias: healthDeptOrg?.alias ? healthDeptOrg?.alias?.[0] : "",
            }
        })

        if (healthDeptOrg?.partOf?.reference?.split("/")?.[1]) {
            D_API.get(`Organization/${healthDeptOrg?.partOf?.reference?.split("/")?.[1]}`).then((res) => {
                dispatch(handleUserOrg(res.data));
                console.log(res.data)
            }).catch((err) => console.log(err));
        }
        setContact(healthDeptOrg?.telecom || []);


        let deptLogo = healthDeptOrg?.identifier?.find((val) => {
            if (val?.system?.includes(`${process.env.REACT_APP_API_URL}v1/logo`) && val?.value != undefined && val?.value != null && val?.value != "") {
                return val;
            } else {
                return null;
            }

        });
        console.log(deptLogo, "deptLogo")

        setUploadedFile(deptLogo?.value ? deptLogo.value : null);


        // setUploadedFile(healthDeptOrg.identifier?.[0]?.value ? healthDeptOrg.identifier?.[0]?.value : null);

        if (healthDeptOrg?.id) {
            D_API.get(`${URI.getLocation}${healthDeptOrg?.id}`).then((res) => {
                console.log(res.data)
                let data = res.data?.entry?.[0]?.resource;
                dispatch(handleHealthDeptLoc(data))

            }).catch((err) => console.log(err))
        }


    }, [healthDeptOrg])

    console.log(contact, "contact")


    const addContact = (system) => (use) => {
        setContact([...contact, { system: system, value: "", use: use }]);
    };

    const deleteContact = (index) => () => {
        setContact([...contact.filter((con, i) => index !== i)]);
    };


    const handleDeptdetails = (name, value) => {
        setDeptDetails((prev) => {
            return {
                ...prev, [name]: value
            }
        })

    }

    console.log(deptDetails, "deptDetails");
    console.log(contact, "contact")


    const handleContact = (i) => (name, value) => {
        setContact([
            ...contact.map((con, ind) =>
                i === ind ? { ...con, [name]: value } : { ...con }
            ),
        ]);
    };

    const handleDeptLogo = (e, fileType, state, name) => {
        console.log(e.target.files[0], "iiii");
        if (e.target.files.length > 0) {
            let a = [];
            // let b = [];
            let c = [];
            for (let i = 0; i < e.target.files.length; i++) {
                let types = e.target.files[0].type.split("/")[1]
                const eTypes = types === 'jpg' || types === "png" || types == "jpeg"
                const imgTypes = eTypes || types == "gif"

                if (fileType == "photo") {
                    const reader = new FileReader()
                    // console.log(reader, "reader")
                    reader.readAsDataURL(e.target.files[0]);

                    reader.onloadend = () => {
                        console.log(reader.result, "result")
                        a.push(reader.result)
                        let base64 = reader.result.split(",")[1].split("=")[0]
                        var strLength = base64.length;
                        var fileLength = strLength - (strLength / 8) * 2;
                        const [, type] = reader.result.split(";")[0].split('/');
                        c.push(type)
                        // setCoverPhoto({ file: base64, fileType: type })
                        // setCoverPhoto((prev) => {
                        //     return [...prev, { file: base64, fileType: type }]
                        // })
                        setCoverPhoto({ file: base64, fileType: type })
                        fileUpload(base64, type, state == "coverpic" ? 'userCoverPhoto' : 'profilePic', name)
                    }
                }
                else {
                    setUploadLoader({ open: true, msg: 'Only .jpg, .png, .jpeg, .gif extensions allowed' })
                }
            }

        }
    }

    console.log(coverPhoto, "coverphoto")

    const fileUpload = (file, type, subType, name) => {
        // setUploadLoader({ open: true, msg: "uploading ..." })
        setIsLoading(true);
        let data = {
            "file": [file],
            "fileType": type,
            "multiple": false,
            "subType": subType,
            "type": "userProfilePics"
        }
        console.log(URL.upload, "upload");
        axios.post(`${URL.uploadFile}`, data, {
            headers: {
                'Authorization': `Bearer ${sessionStorage.getItem('kctoken')}`
            }
        }).then(res => {
            console.log(res.data.data[0], "success")
            setUploadedFile(res.data.data[0]);
            setIsLoading(false);
        }).catch(err => {
            setIsLoading(false)
            alert("uploading failed, try again.")
            // setUploadLoader({ open: false, msg: "uploading failed, try again." })

        })

    }


    const handleOpnDeleteDialog = () => {
        setOpenDeleteDialog(true)
    }
    const handleClsDeleteDialog = () => {
        setOpenDeleteDialog(false)
    }

    const deleteOrgFn = () => {

        handleOpnDeleteDialog();



        // let mangOrId = healthDeptLoc?.managingOrganization && parseInt(healthDeptLoc?.managingOrganization?.reference?.split("/")?.[1]) != NaN ? healthDeptLoc?.managingOrganization?.reference?.split("/")?.[1] : null

        // let pageId
        // let copyObj = { ...healthDeptOrg };
        // let indx = copyObj?.identifier?.findIndex((val) => val.system == 'https://pageId/');
        // pageId = indx != -1 && parseInt(copyObj?.identifier?.[indx]?.value) != NaN ? copyObj?.identifier?.[indx]?.value : null;

        // if (healthDeptOrg?.id) {

        //     if (healthDeptLoc?.id && mangOrId) {
        //         D_API.delete(`${URI.addLocation}/${healthDeptLoc?.id}/${mangOrId}`).then((res) => {
        //             if (healthDeptOrg?.id && pageId) {
        //                 D_API.delete(`${URI?.organization}/${healthDeptOrg?.id}/${pageId}`).then((res) => {
        //                     clsn()
        //                     history.push(`/admin/organization/${orgData?.identifier?.[1]?.value}`)
        //                     // closeEditDialog();
        //                     setAlert({ open: true, msg: "Successfully Deleted Organization" });
        //                 })
        //             }

        //         }).catch((err) => console.log(err))

        //     } else if (healthDeptOrg?.id && pageId) {
        //         if (healthDeptOrg?.id && pageId) {
        //             D_API.delete(`${URI?.organization}/${healthDeptOrg?.id}/${pageId}`).then((res) => {
        //                 clsn();
        //                 history.push(`/admin/organization/${orgData?.identifier?.[1]?.value}`)
        //                 // closeEditDialog();
        //                 setAlert({ open: true, msg: "Successfully Deleted Organization" });
        //             })
        //         }
        //     } else {
        //         clsn();
        //         history.push(`/admin/organization/${orgData?.identifier?.[1]?.value}`)
        //         setAlert({
        //             open: true, msg: "Error occured while deleting "
        //         })
        //     }
        // }







        // old code for delete

        // let togetPageId = healthDeptOrg?.identifier?.filter((e) => e.system == "https://pageId/" && e.value !== null && e.value !== undefined && e.value !== "");
        // if (healthDeptOrg?.id) {
        //     if (healthDeptLoc?.id && (healthDeptLoc?.managingOrganization?.reference?.split("/")?.[1] != undefined && healthDeptLoc?.managingOrganization?.reference?.split("/")?.[1] != null && healthDeptLoc?.managingOrganization?.reference?.split("/")?.[1] != "")) {
        //         D_API.delete(`${URI.addLocation}/${healthDeptLoc?.id}/${healthDeptLoc?.managingOrganization?.reference?.split("/")?.[1]}`).then((res) => {
        //             if (togetPageId?.[0]?.value) {
        //                 axios.delete(`${process.env.REACT_APP_API_URL}v1/deletePagesById/${togetPageId?.[0]?.value}`, headers()).then((res) => {
        //                     if (healthDeptOrg?.id && healthDeptOrg?.partOf?.reference?.split("/")?.[1]) {
        //                         D_API.delete(`${URI.updateOrg}${healthDeptOrg?.id}/${healthDeptOrg?.partOf?.reference?.split("/")?.[1]}`).then((res) => {
        //                             clsn();
        //                             history.push(`/admin/organization/${orgData?.identifier?.[1]?.value}`)
        //                             console.log(res.data)
        //                         }).catch((err) => console.log(err))
        //                     }
        //                 }).catch((err) => console.log(err))
        //             }
        //         }).catch((err) => console.log(err));
        //     } else if (togetPageId?.[0]?.value) {
        //         axios.delete(`${process.env.REACT_APP_API_URL}v1/deletePagesById/${togetPageId?.[0]?.value}`, headers()).then((res) => {
        //             if (healthDeptOrg?.id && healthDeptOrg?.partOf?.reference?.split("/")?.[1]) {
        //                 D_API.delete(`${URI.updateOrg}${healthDeptOrg?.id}/${healthDeptOrg?.partOf?.reference?.split("/")?.[1]}`).then((res) => {
        //                     clsn();
        //                     history.push(`/admin/organization/${orgData?.identifier?.[1]?.value}`)
        //                     console.log(res.data)
        //                 }).catch((err) => console.log(err))
        //             }

        //         }).catch((err) => console.log(err))
        //     } else if (healthDeptOrg?.id && healthDeptOrg?.partOf?.reference?.split("/")?.[1]) {
        //         D_API.delete(`${URI.updateOrg}${healthDeptOrg?.id}/${healthDeptOrg?.partOf?.reference?.split("/")?.[1]}`).then((res) => {
        //             clsn();
        //             history.push(`/admin/organization/${orgData?.identifier?.[1]?.value}`)
        //             console.log(res.data)
        //         }).catch((err) => console.log(err))
        //     } else {
        //         clsn();
        //         history.push(`/admin/organization/${orgData?.identifier?.[1]?.value}`)
        //     }

        // } else {
        //     clsn();
        //     history.push(`/admin/organization/${orgData?.identifier?.[1]?.value}`)
        // }



        // if (healthDeptLoc?.id && (healthDeptLoc?.managingOrganization?.reference?.split("/")?.[1] != undefined || null || "")) {
        //     D_API.delete(`${URI.addLocation}/${healthDeptLoc?.id}/${healthDeptLoc?.managingOrganization?.reference?.split("/")?.[1]}`).then((res) => {
        //         axios.delete(`https://dev.api.docsteth.com/api/v1/deletePagesById/${togetPageId?.[0]?.value}`, headers()).then((res) => {
        //             if (healthDeptOrg?.id && healthDeptOrg?.partOf?.reference?.split("/")?.[1]) {
        //                 D_API.delete(`${URI.updateOrg}${healthDeptOrg?.id}/${healthDeptOrg?.partOf?.reference?.split("/")?.[1]}`).then((res) => {
        //                     clsn();
        //                     history.push(`/admin/organization/${orgData?.identifier?.[1]?.value}`)
        //                     console.log(res.data)
        //                 }).catch((err) => console.log(err))
        //             }
        //         }).catch((err) => console.log(err));
        //         if (healthDeptOrg?.id && healthDeptOrg?.partOf?.reference?.split("/")?.[1]) {
        //             D_API.delete(`${URI.updateOrg}${healthDeptOrg?.id}/${healthDeptOrg?.partOf?.reference?.split("/")?.[1]}`).then((res) => {
        //                 clsn();
        //                 history.push(`/admin/organization/${orgData?.identifier?.[1]?.value}`)
        //                 console.log(res.data)
        //             }).catch((err) => console.log(err))
        //         } else {
        //             clsn();
        //             history.push(`/admin/organization/${orgData?.identifier?.[1]?.value}`)
        //         }

        //     }).catch((err) => console.log(err))
        // } else if (healthDeptOrg?.id && healthDeptOrg?.partOf?.reference?.split("/")?.[1]) {
        //     D_API.delete(`${URI.updateOrg}${healthDeptOrg?.id}/${healthDeptOrg?.partOf?.reference?.split("/")?.[1]}`).then((res) => {
        //         clsn();
        //         history.push(`/admin/organization/${orgData?.identifier?.[1]?.value}`)
        //         console.log(res.data)
        //     }).catch((err) => console.log(err))
        // } else {
        //     clsn();
        //     history.push(`/admin/organization/${orgData?.identifier?.[1]?.value}`)
        // }
    }



    const handleSubmit = () => {

        function updateIdentifiers(healthDeptOrg, uploadedFile, url) {
            if (!healthDeptOrg || !Array.isArray(healthDeptOrg.identifier)) {

                return [];
            }

            let list = [...healthDeptOrg.identifier]
            const saw = list.some(e => e.system === url);

            if (saw && uploadedFile) {
                const indx = list.findIndex(e => e.system === url);
                if (indx !== -1) {

                    list[indx] = { system: url, value: uploadedFile };
                }
            } else if (!saw && uploadedFile) {

                list.push({ system: url, value: uploadedFile });
            }

            return [...list];
        };



        // let data = {
        //     ...(healthid && { id: healthid }),
        //     resourceType: "Organization",


        //     identifier: updateIdentifiers(healthDeptOrg, uploadedFile, `${process.env.REACT_APP_API_URL}v1/logo`),

        //     name: deptDetails.deptName ? deptDetails.deptName : "",
        //     alias: deptDetails.deptAlias ? [deptDetails.deptAlias] : "",
        //     active: true,
        //     type: [{
        //         "coding": [{
        //             ...deptDetails?.orgType
        //         }]
        //     }],
        //     partOf: deptDetails.partOf
        //         ? {
        //             "reference": "Organization" + "/" + `${deptDetails.partOf}`
        //         }
        //         : "",
        //     telecom: contact.filter((con) => con.value?.replace(/\+91/, "") !== ""),
        // }

        // let website = data?.endpoint?.[0]?.display;

        // let val = deleteKeys({ ...data });
        // console.log(val, "val")

        // if (healthid) {

        //     D_API.put(`${URI.updateOrg}${healthid}`, val).then((res) => {
        //         D_API.get(`Organization/${healthid}`).then((res) => {
        //             dispatch(handleHealthDeptOrg(res.data));
        //             switchTabs();
        //             console.log(res.data)
        //         }).catch((err) => console.log(err));

        //     })
        // } 


        // function updateIdentifiers(healthDeptOrg, uploadedFile, url) {
        //     if (!healthDeptOrg || !Array.isArray(healthDeptOrg.identifier)) {
        //         return [];
        //     }

        //     const existingIdentifier = healthDeptOrg.identifier.find(e => e.system === url);

        //     if (existingIdentifier) {
        //         return healthDeptOrg.identifier.map(e =>
        //             e.system === url ? { system: url, value: uploadedFile } : e
        //         );
        //     } else if (uploadedFile) {
        //         return [...healthDeptOrg.identifier, { system: url, value: uploadedFile }];
        //     }

        //     return [...healthDeptOrg.identifier];
        // }

        let data = {
            ...(healthid && { id: healthid }),
            resourceType: "Organization",
            identifier: updateIdentifiers(healthDeptOrg, uploadedFile, `${process.env.REACT_APP_API_URL}v1/logo`),
            name: deptDetails.deptName || "",
            alias: deptDetails.deptAlias ? [deptDetails.deptAlias] : "",
            active: true,
            type: [{
                coding: [{ ...deptDetails?.orgType }]
            }],
            partOf: deptDetails.partOf ? { reference: `Organization/${deptDetails.partOf}` } : "",
            telecom: contact.filter((con) => con.value?.replace(/\+91/, "") !== ""),
        };

        let website = data?.endpoint?.[0]?.display;
        let val = deleteKeys({ ...data });

        console.log(val, "val");

        if (healthid) {

            D_API.put(`${URI.updateOrg}${healthid}`, val).then((res) => {
                D_API.get(`Organization/${healthid}`).then((res) => {
                    dispatch(handleHealthDeptOrg(res.data));
                    switchTabs();
                }).catch((err) => console.log('Get Error:', err));
            }).catch((err) => console.log('PUT Error:', err));
        }


        else {

            let orgPage = {
                organisation: {
                    ...val,

                    // resourceType: "Organization",
                    // active: false,
                    // identifier: []
                },
                pageName: val.name,
                "pageAdmin": `${UserService.getUserId()}`,
                // "pageStatus": "ACTIVE",
                "pageStatusUpdatedDate": new Date().toISOString(),
                // "pageWebsite": "",
                "kycStatus": "PENDING",
                "date": new Date().toISOString(),
                "metadata": {},
                "status": true,
                "userId": Number(UserService.getUserId())
            }
            DOC_API.post(URI.addSubPage, orgPage).then(async res => {
                let response = { ...res.data.data.organsation }
                console.log(res.data, "zzz")

                // setPageId(res.data.data.id)
                let pageId = res.data.data.id;
                let page = {
                    ...orgPage.organisation, identifier: [

                        // ...(orgPage?.identifier?.[0]?.hasOwnProperty("value") ? [{ ...orgPage.identifier[0] }] : []),
                        {
                            system: `${process.env.REACT_APP_DOCSTETH}/`,
                            value: `${UserService.getUserId()}`
                        }, {
                            system: "https://pageId/",
                            value: `${res.data.data.id}`
                        },
                        ...(uploadedFile ? [{ system: `${process.env.REACT_APP_API_URL}v1/logo`, value: uploadedFile }] : []),
                        {
                            system: "https://positionofdepartments/", value: (parseInt(lengthOfDeparts))?.toString()
                        }

                    ]
                    // ...orgPage.organisation, identifier: [{
                    //     system: `https://${process.env.REACT_APP_DOCSTETH}/`,
                    //     value: `${UserService.getUserId()}`
                    // }, {
                    //     system: "https://pageId/",
                    //     value: `${res.data.data.id}`
                    // }]
                }
                console.log(page, "pages1")
                if (website) {
                    page.telecom = [...orgPage.organisation.telecom, { system: 'other', value: website }]
                }
                D_API.post(URI.uploadhealthDepartment, page).then((res) => {
                    console.log(res.data, "after post")
                    let Id = res.data;
                    D_API.get(`Organization/${res.data}`).then((res) => {
                        dispatch(handleHealthDeptOrg(res.data));

                        // if (orgData?.partOf && orgData?.partOf?.reference != "null" && orgData?.partOf?.reference != "undefined" && orgData?.partOf?.reference != "") {
                        //     let val = res.data
                        //     D_API.put(`${URI.updateOrg}${Id}`, val).then((res) => {
                        //         D_API.get(`Organization/${res.data}`).then((res) => {
                        //             dispatch(handleHealthDeptOrg(val));
                        //             switchTabs();
                        //             console.log(res.data)
                        //         }).catch((err) => console.log(err));

                        //     })
                        // }
                        switchTabs();
                        console.log(res.data)
                    }).catch((err) => console.log(err));

                }).catch(err => {
                    alert(err)
                })
            }).catch((err) => {
                console.log(err, "400")
                if (err?.response?.status == 409) {
                    setAlert((prev) => {
                        return {
                            ...prev, open: true, msg: "Page name already exists"
                        }
                    })
                }

                // console.log(err, "400")
            })

        }
    }


    const handleCloseAlert = () => {
        setAlert((prev) => {
            return {
                ...prev, open: false, msg: ""
            }
        })
    }

    const action = (
        <React.Fragment>
            {/* <Button color="secondary" size="small" onClick={handleClose}>
                UNDO
            </Button> */}
            <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={handleCloseAlert}
            >
                <CloseIcon fontSize="small" />
            </IconButton>
        </React.Fragment>
    );

    return (
        <>
            <ValidatorForm onSubmit={handleSubmit} >
                <Grid
                    container
                    spacing={2}
                    className="ph1 tohideScrollbar"
                    sx={{ height: "", overflow: "auto" }}
                >
                    <Grid container spacing={""} alignItems="center" sx={{ padding: "16px 16px 0px 16px", gap: 2 }} >
                        {/* <Grid item md={3.5} sm={6} xs={12}> */}
                        <Grid item >
                            <div className="showImg" style={{ width: "100%" }}>
                                <div style={{ position: "absolute", top: "20px", left: "15px", zIndex: 100 }}>
                                    {
                                        isLoading && <CircularProgress size={20} sx={{ color: "white" }} />
                                    }
                                </div>
                                <Card sx={{ width: "70px", height: "70px", borderRadius: '10px 10px 10px 10px', boxShadow: "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px", border: "1px solid #fff", }} className="flx-center justify-center" >
                                    <Avatar variant="square" sx={{
                                        borderRadius: '', border: "", width: '50px !important', height: '50px !important', backgroundColor: isLoading ? "grey" : ""
                                        // boxShadow: "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px", border: "1px solid #fff",
                                    }} src={uploadedFile?.startsWith("https") ? uploadedFile : HealthDeptIcon} />
                                </Card>

                                <div >
                                    <>
                                        <label >
                                            <p className="editImg uploadImg">
                                                <EditIcon className="uploadIcon"
                                                />
                                                <span>  <input
                                                    id="file-upload"
                                                    type="file"
                                                    accept=".jpg, .png, .jpeg"
                                                    onChange={(e) => handleDeptLogo(e, "photo", "coverpic", "coverPhoto")}
                                                    style={{ display: "none" }}
                                                /></span>
                                            </p>
                                        </label>
                                    </>
                                </div>
                                {/* <div style={{ fontSize: "11px", textAlign: "center", width: "50px" }}>Add Logo</div> */}
                            </div>
                        </Grid>
                        {/* <Grid item md={8.5} sm={6} xs={12}> */}
                        <Grid item>
                            <Grid container alignItems="center">
                                <Grid item>
                                    <div style={{ fontSize: "14px", fontWeight: 500 }}>PartOf</div>
                                </Grid>
                                <Grid item>
                                    <div>
                                        : {orgData?.name ? orgData?.name : ""}
                                    </div>
                                </Grid>

                            </Grid>
                            <Grid container alignItems="center">
                                <Grid item style={{ fontSize: "14px", fontWeight: 500 }}>
                                    <div>Organisation</div>
                                </Grid>
                                <Grid item>
                                    <div>
                                        : {deptDetails?.orgType?.display}
                                    </div>
                                </Grid>

                            </Grid>
                        </Grid>
                    </Grid>
                    <OrgLayout
                        label="Department Name"
                        value={deptDetails.deptName}
                        // disabled={"orgData?.id"}
                        name="deptName"
                        handleChange={handleDeptdetails}
                        placeholder="Name of Department"
                        required
                        validators={["required", "pageExists", "minStringLength:3"]}
                        errorMessages={[
                            "Enter Facility Name",
                            "Page Name already Exists",
                            "Page Name should be atleast 3 characters",
                        ]}
                    />
                    <OrgLayout
                        label="Tagline"
                        value={deptDetails.deptAlias}
                        name="deptAlias"
                        handleChange={handleDeptdetails}
                        placeholder="Any Other name with which this Department is known"
                    />



                    {/* Telecom */}
                    <div style={{ paddingLeft: "16px", paddingTop: "16px", fontWeight: 500 }}>
                        Contact Details
                    </div>


                    {/* <ProfileLayout title="Contact Details" /> */}
                    {/* <div>
                    <h5>Contact Details</h5>
                </div> */}

                    <Purpose
                        input="phone"
                        name="Facility Phone"
                        contact={contact}
                        handleContact={handleContact}
                        deleteContact={deleteContact}
                        handleType={addContact("phone")}
                        options={["mobile", "work"]}
                    />
                    <Purpose
                        input="email"
                        name="Facility Email"
                        placeholder="Email Address"
                        contact={contact}
                        handleContact={handleContact}
                        deleteContact={deleteContact}
                        handleType={addContact("email")}
                        options={["work"]}
                    />

                    <Grid item xs={12} />

                </Grid >
                <FormDivider />
                <div className="flx-center justify-between">
                    <div style={{ textAlign: 'right', marginTop: "10px" }} className="cursor">
                        <DeleteIcon sx={{ color: "red" }} onClick={deleteOrgFn} />
                    </div>
                    <div>
                        {/* <Actions close={clsn} name={healthid ? "Update" : "Next"} /> */}
                        <Actions close={() => {
                            clsn();
                            Reset();
                        }} name={healthid ? "Update" : "Next"} />
                    </div>
                </div>
                <DeleteDeptDialog open={openDeleteDialog} close={handleClsDeleteDialog} orgObj={healthDeptOrg} orgLoc={healthDeptLoc} closeEditDialog={clsn} parentOrgObj={orgData} />

            </ValidatorForm >

            {/* <Dialog open={alert.open} onClose={handleCloseAlert} maxWidth="sm" fullWidth>
                <DialogTitle>
                    Error Message
                </DialogTitle>
                <hr></hr>
                <DialogContent>
                    {alert.msg}
                </DialogContent>
                <FormDivider />
                <DialogActions>
                    <Button variant="contained" sx={{ padding: "4px 20px !important" }} onClick={handleCloseAlert} >Ok</Button>
                </DialogActions>

            </Dialog> */}



            {/* <DialogBox open={alert.open} handleDialog={handleCloseAlert} Title="Warning Message" Content={<p>{alert.msg}</p>} Actions={
                <>
                    <CloseBtn name="Ok" onClick={handleCloseAlert} />
                </>
            } /> */}


            <Snackbar
                open={alert.open}
                onClose={handleCloseAlert}
                message={alert.msg}
                action={action}
            />

        </>
    )

}
export default HealthDeptBasicDetails;