import React, { useState, useEffect } from "react";
import { Avatar, Grid, Switch, CircularProgress, Card } from '@mui/material';
import { ValidatorForm } from 'react-material-ui-form-validator';
import { FormDivider } from "../../../../utility/utils";
import { Actions, FormLayout } from "../../../../common/components/layout";
import { D_API, TERMINOLOGY_VALUESET } from "../../../../../services/APIService";
import SearchIcon from '@mui/icons-material/Search';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import { deleteKeys } from "../../../../common/functions/functions";
import { URI } from "../../../../utility/uri";
import { useDispatch, useSelector } from "react-redux";
import { handleHealthServiceOrgInfo } from "../../../../../redux/Actions/regActions";

const AdditionalServiceInfo = ({ clsn, switchTabs }) => {

    const [addnServiceInfo, setAddnServiceInfo] = useState({
        specialityInfo: [],
        categoryInfo: null,
        type: null,
        programsInfo: [],
        characteristics: []
    })
    let eligibilityDetails = {
        code: "",
        comment: ""
    }
    const [specialityList, setSpecialityList] = useState([]);
    const [categoryList, setCategoryList] = useState([]);
    const [typeList, setTypeList] = useState([]);
    const [programsList, setProgramsList] = useState([]);
    const [eligibilityList, setEligibilityList] = useState([eligibilityDetails]);
    const [showCharacterComp, setShowCharacterComp] = useState(false);


    const healthServiceOrg = useSelector(state => state.registration.healthServiceOrgInfo);
    const healthServiceLoc = useSelector(state => state.registration.healthServiceLocInfo);

    const dispatch = useDispatch();

    // to extract specialityList through valueset
    useEffect(() => {
        let specialityValueSetUrl = "http://hl7.org/fhir/ValueSet/c80-practice-codes";
        TERMINOLOGY_VALUESET.get(`$expand?url=${specialityValueSetUrl}`).then((res) => {
            // console.log(res.data?.expansion?.contains, "lolo");
            setSpecialityList(res.data?.expansion?.contains);
        }).catch((err) => console.log(err))


    }, [])

    // to extract categoryList through valueset
    useEffect(() => {
        let categoryValueSetUrl = "http://hl7.org/fhir/ValueSet/service-category";
        TERMINOLOGY_VALUESET.get(`$expand?url=${categoryValueSetUrl}`).then((res) => {
            // console.log(res.data?.expansion?.contains, "lolo");
            setCategoryList(res.data?.expansion?.contains);
        }).catch((err) => console.log(err))

    }, [])

    // to extract typeList through valueset
    useEffect(() => {
        let typeValueSetUrl = "http://hl7.org/fhir/ValueSet/service-type";
        TERMINOLOGY_VALUESET.get(`$expand?url=${typeValueSetUrl}`).then((res) => {
            // console.log(res.data?.expansion?.contains, "lolo");
            setTypeList(res.data?.expansion?.contains);
        }).catch((err) => console.log(err))

    }, [])

    // to extract programList through valueset
    useEffect(() => {
        let val = "http://hl7.org/fhir/ValueSet/program"
        TERMINOLOGY_VALUESET.get(`$expand?url=${val}`).then((res) => {
            setProgramsList(res.data?.expansion?.contains)
            // console.log(res.data?.expansion?.contains, "programList")
        }).catch((err) => {

            console.log(err)
        }
        )

    }, [])


    useEffect(() => {

        setAddnServiceInfo((prev) => {
            return {
                ...prev,
                specialityInfo: healthServiceOrg?.specialty ? healthServiceOrg?.specialty?.map((e) => {
                    return { ...e?.coding?.[0] }
                }) : [],
                categoryInfo: healthServiceOrg?.category ? healthServiceOrg?.category?.[0]?.coding?.[0] : null,
                type: healthServiceOrg?.type ? healthServiceOrg?.type?.[0]?.coding?.[0] : "",
                programsInfo: healthServiceOrg?.program ? healthServiceOrg?.program?.map((e) => {
                    return { ...e?.coding?.[0] }
                }) : [],
                characteristics: healthServiceOrg?.characteristic ? healthServiceOrg?.characteristic?.map((e) => {
                    return { ...e?.coding?.[0] }
                }) : []
            }
        })

        setEligibilityList(healthServiceOrg?.eligibility ? healthServiceOrg?.eligibility?.map((e) => {
            if (e?.hasOwnProperty("code")) {
                return {
                    ...e, code: e?.code?.coding?.[0]
                }
            } else {
                return { ...e }
            }

        }) : [eligibilityDetails])

    }, [healthServiceOrg])


    useEffect(() => {

        handleOpnCharacteristics()

    }, [addnServiceInfo.characteristics])


    // console.log(showCharacterComp, "showCharacterComp")

    const addEligibility = () => {
        setEligibilityList([...eligibilityList, eligibilityDetails])
    }


    const deleteEligibility = (i) => {
        let Reference = [...eligibilityList];
        setEligibilityList(Reference.filter((e, indx) => i != indx))
    }

    const handleChange = (name, value) => {
        setAddnServiceInfo((prev) => {
            return { ...prev, [name]: value }
        })

    }

    const handleEligibility = (name, value, i) => {
        // console.log(name, value, "3")

        let newList = eligibilityList?.map((e, indx) => {
            if (indx == i) {
                return { ...e, [name]: value }
            } else {
                return {
                    ...e
                }
            }
        })

        setEligibilityList(newList)
    }

    // console.log(eligibilityList, "eligibilityList")

    const handleOpnCharacteristics = () => {
        setShowCharacterComp(true)
    }

    const handleClsCharacteristics = () => {
        setAddnServiceInfo((prev) => {
            return {
                ...prev, characteristics: []
            }
        })
        setShowCharacterComp(false)

    }



    function adjustRows() {
        const textarea = document.getElementById('myTextarea');
        const rows = textarea.value.split('\n').length;
        textarea.rows = rows;
    }


    // console.log(addnServiceInfo, "addnServiceInfo")


    const handleSubmit = () => {

        let data = {
            ...healthServiceOrg,
            specialty: addnServiceInfo?.specialityInfo ? addnServiceInfo?.specialityInfo?.map((e) => {
                return {
                    coding: [
                        {
                            ...e
                        }
                    ]
                }

            }) : "",
            category: addnServiceInfo?.categoryInfo ? [{ coding: [{ ...addnServiceInfo?.categoryInfo }] }] : "",
            type: addnServiceInfo.type ? [{ coding: [{ ...addnServiceInfo.type }] }] : "",
            ...(addnServiceInfo?.programsInfo && {
                program: addnServiceInfo?.programsInfo?.map((e) => {
                    return {
                        coding: [{ ...e }]
                    }
                })
            }),
            characteristic: addnServiceInfo.characteristics ? addnServiceInfo.characteristics?.map(((e) => {
                return { coding: [{ ...e }] }
            })) : "",
            eligibility: eligibilityList?.map((e) => deleteKeys({ ...e })).filter((val) => Object.keys(val)?.length != 0)?.map((e) => {
                if (e?.hasOwnProperty("code")) {
                    return { ...e, code: { coding: [{ ...e.code }] } }
                } else {
                    return { ...e }
                }
            }),


        }


        let val = deleteKeys(data);

        if (healthServiceOrg?.id) {
            D_API.put(`${URI.updateHealthServiceDept}/${healthServiceOrg?.id}`, val).then((res) => {
                // console.log(res.data, "7")
                D_API.get(`${URI.getHealthCareService}/${res.data}`).then((res) => {
                    dispatch(handleHealthServiceOrgInfo(res.data));
                    switchTabs();
                    // console.log(res.data)
                }).catch((err) => console.log(err));

            }).catch((err) => console.log(err));
        }



        // console.log(data, "1234")


    }


    return (
        <div>

            <ValidatorForm onSubmit={handleSubmit} className='tohideScrollbar' >
                <Grid
                    container
                    spacing={2}
                    className="ph1 tohideScrollbar"
                    sx={{ height: "410px", overflow: "auto" }}
                >

                    <FormLayout
                        label="Speciality"
                        name="specialityInfo"
                        input="select"
                        multiple
                        Icon={<SearchIcon />}
                        size={12}
                        options={specialityList}
                        value={addnServiceInfo.specialityInfo}
                        handleChange={handleChange}
                        placeholder="Search for required Speciality"
                        getOptionLabel={option => option.display || option?.coding?.[0]?.display || ''}
                        sx={{ '.MuiOutlinedInput-root': { paddingRight: '10px !important' } }}
                    />

                    <FormLayout
                        label="Category"
                        name="categoryInfo"
                        input="select"
                        Icon={<SearchIcon />}
                        size={12}
                        options={categoryList}
                        value={addnServiceInfo.categoryInfo}
                        handleChange={handleChange}
                        placeholder="Search for required Category"
                        getOptionLabel={option => option.display || ""}
                        sx={{ '.MuiOutlinedInput-root': { paddingRight: '10px !important' } }}
                    />
                    <FormLayout
                        label="Type"
                        name="type"
                        input="select"
                        // Icon={<SearchIcon />}
                        size={12}
                        options={typeList}
                        value={addnServiceInfo.type}
                        handleChange={handleChange}
                        placeholder="Search for required Type"
                        getOptionLabel={option => option.display || ''}
                        sx={{ '.MuiOutlinedInput-root': { paddingRight: '10px !important' } }}
                    />


                    <FormLayout
                        size={12}
                        input="select"
                        multiple
                        label="Healthcare Programs"
                        name="programsInfo"
                        value={addnServiceInfo?.programsInfo}
                        options={programsList || []}
                        getOptionLabel={(option) => option?.display || ""}
                        handleChange={handleChange}
                        placeholder="Enter healthcare programs Info"
                    />

                    <Grid item xs={12}>
                        <div className="flx-center"><span className="flx-center" >{
                            showCharacterComp ?
                                <RemoveCircleIcon style={{ color: 'red', fontSize: "16px" }} onClick={handleClsCharacteristics} />
                                :
                                <AddCircleIcon style={{ paddingTop: "2px", color: "#008114", }} className="cursor" onClick={handleOpnCharacteristics} />
                        }</span>&nbsp;Characteristics</div>
                    </Grid>
                    {
                        showCharacterComp && (
                            <>
                                <FormLayout
                                    size={12}
                                    input="select"
                                    multiple
                                    label="Characteristic"
                                    name="characteristics"
                                    value={addnServiceInfo.characteristics}
                                    options={categoryList}
                                    getOptionLabel={(option) => option?.display || option?.coding?.[0]?.display || ""}
                                    handleChange={handleChange}
                                    placeholder="Enter healthcare programs Info"
                                />
                            </>
                        )
                    }


                    <Grid item xs={12}>
                        <div style={{ paddingLeft: "", paddingTop: "16px", fontWeight: 500 }}>
                            Eligibility Criteria
                        </div>
                    </Grid>

                    <Grid item xs={12} sm={12} md={12}>

                        {
                            eligibilityList?.map((value, i) => {
                                return (
                                    <>
                                        <FormLayout
                                            size={12}
                                            label="Eligibility Code"
                                            name="code"
                                            input="select"
                                            value={value.code}
                                            options={categoryList || []}
                                            getOptionLabel={(option) => option?.display || option?.coding?.[0]?.display || ""}
                                            handleChange={(name, value) => handleEligibility(name, value, i)}
                                            placeholder="Enter required Eligibilty code"
                                        />

                                        <Grid item xs={12} sm={12} md={12} pt={1}>
                                            <label>Eligibility Comments</label>
                                            <textarea name="comment" value={value.comment} onChange={(e) => handleEligibility("comment", e.target.value, i)} placeholder="Write something here" autoFocus id="myTextarea" rows="3" onInput={() => adjustRows()} className="w-100 ss_textarea" style={{
                                                boxShadow: "2px 3px 10px lightgrey",
                                                borderRadius: "10px",
                                                backgroundColor: "white",
                                                width: "100%",
                                                // height: "auto",
                                                fontSize: "16px",
                                                lineHeight: "1.5", // Adjust line height as needed
                                                padding: "10px", // Adjust padding as needed
                                            }}></textarea>
                                        </Grid>

                                        <div className="" style={{ display: "flex", justifyContent: "flex-end" }}><p className="fntSm14 ">Delete Required Eligibility</p>&nbsp;<span><RemoveCircleIcon style={{ color: 'red', fontSize: "16px" }} onClick={() => deleteEligibility(i)} /></span></div>
                                        <Grid item xs={12}>

                                        </Grid>
                                        <div>
                                            <FormDivider />
                                        </div>

                                    </>
                                )
                            })
                        }

                    </Grid>

                    {/* <Grid item>

                    </Grid> */}

                    <div className="flx-center ph1"><span className="flx-center" ><AddCircleIcon style={{ paddingTop: "2px", color: "#008114", }} className="cursor" onClick={addEligibility} /></span>&nbsp; Eligibility Criteria</div>




                    {/* 
                      <FormLayout
                        label="Add Location List"
                        name="addLocationList"
                        input="select"
                        multiple
                        Icon={<SearchIcon />}
                        size={12}
                        options={healthDeptList}
                        value={serviceAreaInfo.addLocationList}
                        handleChange={handleSearchArea}
                        placeholder="Search for required Service Location"
                        getOptionLabel={option => option?.resource?.name || ''}
                        sx={{ '.MuiOutlinedInput-root': { paddingRight: '10px !important' } }}
                    />

                    {/* <FormLayout
                        label="Add Location List"
                        name="addLocationList"
                        input="select"
                        multiple
                        Icon={<SearchIcon />}
                        size={12}
                        options={healthDeptList}
                        value={serviceAreaInfo.addLocationList}
                        handleChange={handleSearchArea}
                        placeholder="Search for required Service Location"
                        getOptionLabel={option => option?.resource?.name || ''}
                        sx={{ '.MuiOutlinedInput-root': { paddingRight: '10px !important' } }}
                    />
                   

                    <FormLayout
                        label="Location Name"
                        name="locName"
                        size={12}
                        value={serviceAreaInfo.locName}
                        handleChange={handleSearchArea}
                        placeholder="Name of Location"
                    />
                    <FormLayout
                        size={12}
                        value={serviceAreaInfo.alias}
                        label="Alias"
                        name="alias"
                        handleChange={handleSearchArea}
                        placeholder="Name of Location as Alias"
                    />
                    <FormLayout
                        size={12}
                        label="Location Service Type"
                        input="select"
                        name="locServiceType"
                        value={serviceAreaInfo.locServiceType}
                        options={[]}
                        getOptionLabel={(option) => option?.display || ""}
                        handleChange={""}
                        placeholder="Select Type of Location"
                    />

                    <FormLayout
                     
                        label="Mode of Locations"
                        input="select"
                        name="modeType"
                        value={serviceAreaInfo.modeType}
                        options={modeList}
                        getOptionLabel={(option) => option || ""}
                        handleChange={handleSearchArea}
                        placeholder="Select Type of Location"
                    />
                    {
                        hideKindRes == false &&
                        <>
                            <FormLayout
                              
                                label="Physical Type"
                                input="select"
                                name="locPhysicalType"
                                value={serviceAreaInfo.locPhysicalType}
                                options={physicalLocList || []}
                                getOptionLabel={(option) => option?.display || ""}
                                handleChange={handleSearchArea}
                                placeholder="Select Type of Location"
                            />
                            <FormLayout
                                size={12}
                                label="GeoLocation Position"
                                name="position"
                                value={serviceAreaInfo.position}
                                handleChange={handleSearchArea}
                                placeholder="Select Type of Location"
                            />
                            <FormLayout
                                size={12}
                                label="Address"
                                name="address"
                                value={serviceAreaInfo.address}
                                options={[]}
                                getOptionLabel={(option) => option?.display || ""}
                                handleChange={handleSearchArea}
                                placeholder="Enter the Address"
                            />
                        </>
                    } */}


                    <Grid item xs={12} />

                </Grid>
                <FormDivider />

                {/* <div className="flx-center justify-between">

                    <div style={{ textAlign: 'right', marginTop: "10px" }}>
                        <DeleteIcon sx={{ color: "red" }} onClick={deleteOrgFn} />
                    </div>
                    <div>
                        <Actions close={clsn} name={healthServiceLoc?.id ? "Update" : "Next"} />
                    </div>
                </div> */}
                <Actions close={clsn} name={"Update"} />
            </ValidatorForm >

        </div >
    )
}

export default AdditionalServiceInfo