import { Card, CardContent, Checkbox, Dialog, DialogContent, FormControlLabel, Grid, Switch, Typography } from '@mui/material'
import React, { useEffect } from 'react'
import { useState } from 'react'
import { ValidatorForm } from 'react-material-ui-form-validator'
import { Actions, OrgLayout } from '../../../common/components/layout'
import MedicalServicesIcon from '@mui/icons-material/MedicalServices';
import { useSelector } from 'react-redux'
import axios from 'axios'
import { D_API, D_API_FHIR } from '../../../../services/APIService'
import UserService from '../../../../services/UserService'
import { FormDivider } from '../../../utility/utils'


const orgType = [
  {
    code: 'prov',
    display: 'Healthcare Provider'
  },
  {
    code: 'dept',
    display: 'Hospital Department'
  },
  {
    code: 'team',
    display: 'Organizational team'
  },
  {
    code: 'govt',
    display: 'Government'
  },
  {
    code: 'ins',
    display: 'Insurance Company'
  },
  {
    code: 'pay',
    display: 'Payer'
  },
  {
    code: 'edu',
    display: 'Educational Institute'
  },
  {
    code: 'reli',
    display: 'Religious Institution'
  },
  {
    code: 'crs',
    display: 'Clinical Research Sponsor'
  },
  {
    code: 'cg',
    display: 'Community Group'
  },
  {
    code: 'bus',
    display: 'Non-Healthcare Business or Corporation'
  },
  {
    code: 'other',
    display: 'Other'
  },

]


const AddServices = ({ close, selectedItem, allServices, edit, toBeEditId, reset }) => {


  console.log(edit, toBeEditId, "ppp")
  const userOrg = useSelector(state => state.registration.userOrg)
  console.log(userOrg, "Orgdetails")
  const location = useSelector((state) => state.registration.location)


  const [details, setDetails] = useState({
    name: '',
    coverageareaofservice: '',
    program: '',
    speciality: null,
    category: null,
    type: null,
  })

  console.log(toBeEditId, "ppp1")

  console.log(details.coverageareaofservice, "spec")


  const [coverageOptions, setCoverageOptions] = useState(null)

  const [coverage, setCoverage] = useState(null)
  const [required, setRequired] = useState(false)
  const [programChecked, setProgramChecked] = useState({ cghs: false, abpm: false })
  const [allSpeciality, setAllSpecialty] = useState([])
  const [allCategory, setAllCategory] = useState([])
  const [allType, setAllType] = useState(null)

  console.log(selectedItem, "selectedItem")
  console.log(coverage, "coverage")



  console.log(details, "abcdefghi")

  const { l2id } = useSelector((state) => state.registration);



  const handleProgram = (e) => {
    setProgramChecked((prev) => {
      return {
        ...prev,
        [e.target.name]: e.target.checked
      }
    })
  }



  const handleDetails = (name, value) => {
    setDetails({ ...details, [name]: value })
  }


  useEffect(() => {
    const allnames = allServices?.map((item) => {
      return { id: item?.resource.id, display: item?.resource.name }
    })
    console.log(allnames, 'allnames')
    setCoverageOptions(allnames)
  }, [allServices])

  useEffect(() => {

    D_API.get("ValueSet/name/ServiceCategory")
      .then(res => {
        console.log(res, "reso1")
        setAllCategory(res.data.entry?.[0]?.resource?.compose?.include?.[0]?.concept)

      })


    D_API.get("ValueSet/name/PracticeSettingCodeValueSet")
      .then(res => {
        console.log(res, "reso")
        setAllSpecialty(res.data.entry?.[0]?.resource?.compose?.include?.[0]?.concept)
      })

    axios
      .get(
        `${process.env.REACT_APP_FHIR_SERVER}CodeSystem?name=ServiceType`
      )
      .then((res) => {
        console.log(res?.data?.entry?.[0]?.resource?.concept, "bbb")
        setAllType(res?.data?.entry?.[0]?.resource?.concept)
      });


  }, [])

  console.log(allType, "")


  const handleSubmit = () => {

    let type = details.type
    delete type.definition


    let data = {
      resourceType: "HealthcareService",
      name: details.name,
      coverageArea: [{
        // reference:`Location/${l2id}`
        reference: `Location/${selectedItem?.id || coverage?.id}`
      }],
      specialty: [{ coding: [details.speciality] }],
      category: [{ coding: [details.category] }],
      type: [{ coding: [type] }],
      providedBy: {
        reference: `Organization/${userOrg.id}`
      },
      location: [{ reference: `Location/${location.id}` }],
    }

    // console.log(data,"ddd")


    D_API.post(`HealthcareService`, data, {
      headers: { Authorization: `bearer ${UserService.getToken()}` },
    }).then(res => {
      console.log(res);
      let HealthId = res.data;
      // console.log(res.data, "vinay")
      D_API.get(`Practitioner/identifier/dev.docsteth.com/${userOrg.identifier?.[0]?.value}`).then((res) => {
        if (res.data?.entry) {
          let practionerInfo = res.data?.entry[0];
          D_API.get(`/Practitioner/getPractitionerRole?practitionerId=${practionerInfo?.resource?.id}&organizationId=${userOrg?.id}`).then((res) => {
            // console.log(res.data, "afterentryapi")
            let practionerRoleObj = res.data;
            let { id } = res.data;
            let data = {
              ...practionerRoleObj, healthcareService: practionerRoleObj.healthcareService ? [...practionerRoleObj.healthcareService, { reference: `HealthcareService/${HealthId}` }]
                :
                [{ reference: `HealthcareService/${HealthId}` }]
            }
            D_API.put(`/PractitionerRole/${id}`, data).then((res) => console.log(res.data, "put api response")).catch((err) => console.log(err));
          }).catch((err) => console.log(err))

        }

      }).catch((err) => console.log(err));
      reset()
      close()
    }).catch(err => { })
  }

  const handleEditSubmit = () => {
    let type = details.type
    console.log(type, "hurray")
    delete type.definition

    let data = {
      resourceType: "HealthcareService",
      name: details.name,
      coverageArea: [{
        // reference:`Location/${l2id}`
        reference: `Location/${selectedItem?.id || coverage?.id}`
      }],
      specialty: [{ coding: [details.speciality] }],
      category: [{ coding: [details.category] }],
      type: [{ coding: [type] }],
      providedBy: {
        reference: `Organization/${userOrg.id}`
      },
      location: [{ reference: `Location/${location.id}` }],
      id: toBeEditId
    }

    console.log(data, "hurray")

    D_API.put(`HealthcareService/${toBeEditId}`, data, {
      headers: { Authorization: `bearer ${UserService.getToken()}` },
    })
      .then(res => {
        console.log(res, "editted")
        reset()
        close()
      }).catch(err => {
        console.log(err)
      })
  }

  useEffect(() => {
    if (toBeEditId && coverageOptions && allSpeciality && allCategory && allType && allServices) {
      D_API.get(`HealthcareService/${toBeEditId}`)
        .then(res => {
          let response = res.data
          console.log(res.data.coverageArea?.[0]?.reference?.split('/')?.[1], "1234567890")
          setDetails({
            name: response?.name ? response?.name : '',
            coverageareaofservice: '',
            program: '',
            speciality: response?.specialty[0]?.coding?.[0] ? response?.specialty[0]?.coding?.[0] : null,
            category: response?.category[0]?.coding?.[0] ? response?.category[0]?.coding?.[0] : null,
            type: response?.type[0]?.coding?.[0] ? response?.type[0]?.coding?.[0] : null,
          })
          // setCoverageOptions({ id: res.data.coverageArea?.[0]?.reference?.split('/')?.[1], display: item?.resource.name })

        }).catch(err => {
          // console.log(err)
        })
    }
  }, [toBeEditId, coverageOptions, allSpeciality, allCategory, allType, allServices])

  return (
    <Dialog open={true} onClose={close} maxWidth={"sm"} sx={{ height: "600px", position: 'absolute', top: "40px", }} fullWidth>
      <Typography variant='h6' sx={{ color: '#750083', display: 'flex', alignItems: 'center', margin: '12px 0' }}>&nbsp;&nbsp;<MedicalServicesIcon />&nbsp;&nbsp;{toBeEditId ? "Edit" : "New"} Speciality Services</Typography>
      <Card sx={{ minHeight: "480px", overflow: 'scroll' }}>

        <CardContent>
          <ValidatorForm onSubmit={toBeEditId ? handleEditSubmit : handleSubmit}>
            <Grid container spacing={2}>
              <OrgLayout
                label="Service Name"
                name="name"
                value={details.name}
                required
                handleChange={handleDetails}
                placeholder="Name of the Service (People search by this name)"
                validators={["required"]}
                errorMessages={["required field"]}
              />

              <OrgLayout
                label="Coverage Area Of Service"
                input="select"
                coverageareaofservice
                // options={[selectedItem?.name]}
                options={selectedItem?.name ? [selectedItem?.name] : coverageOptions}
                value={coverage}
                getOptionLabel={(option) => option?.display || option}
                handleChange={(name, value) => setCoverage(value)}
                placeholder="Select facility Service Amenity of this service"

              />

              <Grid item xs={12} sm={12} md={12}>
                <Grid container item>
                  <Grid item xs={12} sm={12} md={3}>
                    <Typography variant='p'>Program &nbsp;&nbsp;</Typography>
                  </Grid>
                  <Grid item xs={12} sm={12} md={9}>
                    <Typography my={1}>(Select Program eligible to pay for this service)</Typography>
                    <div style={{ display: 'flex', gap: '50px' }}>
                      <FormControlLabel
                        label="CGHS"
                        control={
                          <Checkbox
                            name='cghs'
                            checked={programChecked.cghs}
                            onChange={handleProgram}
                            inputProps={{ 'aria-label': 'controlled' }}
                          />
                        }
                      />
                      <FormControlLabel
                        label="ABPM-JAY"
                        control={
                          <Checkbox
                            name="abpm"
                            checked={programChecked.abpm}
                            onChange={handleProgram}
                            inputProps={{ 'aria-label': 'controlled' }}
                          />
                        }
                      />
                    </div>
                  </Grid>

                </Grid>

              </Grid>

              <Grid item xs={12} sm={12} md={12}>
                <Typography variant='p'>Require Appointment &nbsp;&nbsp;</Typography>
                <FormControlLabel control={<Switch onChange={(event) => setRequired(event.target.checked)}
                  checked={required} color="success" endDecorator={required ? 'Yes' : 'No'} />} label={required ? "Yes" : "No"} />
              </Grid>

              <OrgLayout
                label="Speciality"
                name="speciality"
                input="select"
                options={allSpeciality}
                value={details.speciality}
                required
                getOptionLabel={(option) => option.display}
                handleChange={handleDetails}
                placeholder="Select Medical Speciality"
                validators={["required"]}
                errorMessages={["required field"]}

              />
              <OrgLayout
                label="Category"
                name="category"
                input="select"
                options={allCategory}
                value={details.category}
                required
                getOptionLabel={(option) => option.display}
                handleChange={handleDetails}
                placeholder="Select Broadly related to"
                validators={["required"]}
                errorMessages={["required field"]}

              />

              {/* <OrgLayout
                label="Category"
                name="category"
                value={details.category}
                handleChange={handleDetails}
                placeholder="Select Broadly related to"
                validators={["required"]}
                errorMessages={["required field"]}
              /> */}

              <OrgLayout
                label="Type"
                name="type"
                input="select"
                options={allType}
                value={details.type}
                required
                getOptionLabel={(option) => option.display}
                handleChange={handleDetails}
                placeholder="Tell about kind of Service"
                validators={["required"]}
                errorMessages={["required field"]}

              />

              {/* <OrgLayout
                label="Type"
                name="type"
                value={details.type}
                handleChange={handleDetails}
                placeholder="Tell about kind of Service"
                validators={["required"]}
                errorMessages={["required field"]}
              /> */}
            </Grid>
            <FormDivider />
            <div style={{ position: 'absolute', bottom: '5px', left: '0', right: '10px', background: "white", }}>
              <Actions close={close} name={toBeEditId ? "Edit" : "Create"} />
            </div>
          </ValidatorForm>
        </CardContent>
      </Card>

    </Dialog>
  )
}

export default AddServices