import IconButton from '@mui/material/IconButton';
import add from "../../../../images/add.svg";
import editor from '../../../Atom/modifiedicons/editor.svg'
import Tooltip from '@mui/material/Tooltip';
import { FiEdit3 } from 'react-icons/fi';
import { GrAddCircle } from 'react-icons/gr';
import '../myprofile.css';
export default function Layout({ HeadIcon, Title, handleAdd, handleView }) {
    return (
        <div className="sidebar__stat sidebar__padding">
            <div className="sidebar__substat ta__center ">
                {/* <TimelineIcon style={{ fontSize: "30px", color: '#1C4E9D' }} /> */}
                {HeadIcon}
                <div className="sidebar__lh sidebar__ml" style={{ color: "#237AC4", fontSize: "18px", fontWeight: "550" }}>{Title}</div>
            </div>
            {/* {Title === 'Qualification' ? '' :  */}
            <div className="sidebar__statNumber ">
                <Tooltip className='title_exp' title={`Add ${Title}`}>
                    <IconButton aria-label="visible" onClick={handleAdd}>
                        <GrAddCircle color='#2E3B43' />
                        {/* <img src={add} alt="add" /> */}
                    </IconButton>
                </Tooltip>
                <Tooltip title={`Edit ${Title}`}>
                    <IconButton aria-label="visible" onClick={handleView}>
                        <FiEdit3 color='#2E3B43' />
                        {/* <img src={editor} alt="edit" height="18px" /> */}
                    </IconButton>
                </Tooltip>
            </div>
        </div>
    )
}