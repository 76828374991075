// import React, { useState, useEffect } from "react";
// import { Grid, Box, Typography, Button } from "@mui/material";
// import Polling from "react-polling";
// import axios from "axios";
// import URL from '../../../apiurl'
// import { doRefresh, getMetaData, getPostById } from "../../../../redux/Actions/actions";
// import poll_icon from '../../../../images/poll.svg'
// import { headers } from "../../../../redux/Actions/connectActions";
// import UserService from "../../../../services/UserService";
// import { useDispatch } from "react-redux";

// const pollStyles1 = {
//     questionSeparator: false,
//     questionSeparatorWidth: "question",
//     questionBold: false,
//     questionColor: "#4F70D6",
//     align: "left",
//     theme: "blue"
// };

// export function expiryFun(expiryDate) {
//     // The number of milliseconds in one day
//     const ONE_DAY = 1000 * 60 * 60 * 24;
//     // Calculate the difference in milliseconds
//     const differenceMs = new Date(expiryDate) - new Date()
//     // Convert back to days and return
//     const days = Math.round(differenceMs / ONE_DAY)

//     return days > 0 ? days > 1 ? `Expires in ${days} days` : `Expires in ${days} day` : days === -0 ? 'Expires Today' : 'Expired'

// }

// export default function PollPost(props) {

//     // console.log(props)
//     const [load, setLoad] = useState(false)
//     const [polldata, setPolldata] = useState(props.post)
//     const [pollAnswers1, setPollAnswers1] = useState(props?.post?.pollData?.pollResponseData)

//     const dispatch = useDispatch()

//     useEffect(() => {
//         setLoad(true)
//     }, [props])

//     const handleVote = async (voteAnswer, pollAnswers, pollNumber) => {
//         const newPollAnswers = pollAnswers.map((answer) => {
//             if (answer.option === voteAnswer) answer.votes++;
//             return answer;
//         });
//         setPollAnswers1(newPollAnswers)

//         let data = {
//             "metadata": await getMetaData(),
//             "postId": polldata.id,
//             "questionId": polldata.pollData.id,
//             "response": voteAnswer,
//             "userId": sessionStorage.getItem('userId')
//         }

//         axios.post(`${URL.addPoll}`, data, headers()).then(res => {
//             console.log(res, 'polll')
//             if (res.status === 200 || 201) {
//                 setLoad(true)
//                 UpdatePostCache("add_pollresponse", polldata.id)
//             }
//             getpolldata()

//         }).catch(err => console.log(err))
//     };

//     const UpdatePostCache = async (type, id, pollId) => {
//         let postData = "";
//         await axios.get(`${URL.postById}/${id}`, headers())
//             .then((response) => {
//                 postData = response.data
//             })
//             .catch((error) => {
//                 console.log(error)
//             })

//         let data = {
//             update: type,
//             new_post: postData,
//             post_id: id,

//         }
//         UpdateChacheFeed(data)
//     }

//     const UpdateChacheFeed = async (data) => {
//         await axios.post(`${URL.updateChacheFeed}`, data, headers())
//             .then(res => {
//                 console.log(res)
//                 // dispatch(doRefresh("refreshFeed"))

//             }).catch(err => {
//                 console.log(err)
//             })
//     }

//     const handleresponse = () => {
//         let id = polldata.pollData.pollId
//         setLoad(false)
//         axios.delete(`${URL.deleteresponse}/${id}`, headers()).then(res => {
//             UpdatePostCache("delete_pollresponse", polldata.id)
//             getpolldata()
//         })
//     }

//     const getpolldata = async () => {

//         let data = await getPostById(polldata.id)
//         if (data.id) {
//             setPolldata(data)
//             setPollAnswers1(data.pollData.pollResponseData)
//             setLoad(true)
//         }
//         else if (data.error) setLoad(true)
//     }


//     return (
//         <Box sx={{ px: "10px" }}>
//             {polldata ?
//                 <Grid container className={props.border ? '' : 'celebratepoll'}>
//                     <Grid item
//                         className={`poll-class ${props.border ? 'poll-width' : ''}`} style={{ pointerEvents: props.border || !UserService.getToken() ? 'none' : new Date(new Date(polldata.pollData.expiry).toDateString()) < new Date(new Date().toDateString()) ? 'none' : 'all' }}
//                     >
//                         <Typography component={'div'} className="d-flex" sx={{ justifyContent: 'space-between', p: polldata.border ? '10px' : '0 5px' }}>
//                             <p className="color_head d-flex e-align" style={{ fontSize: '18px' }}>
//                                 {polldata.border ? <><img src={poll_icon} alt={'poll'} width="25px" height="25px" className="celebrate" />&nbsp;</> : ''}
//                                 {polldata.pollTitle}</p> {expiryFun(polldata.pollData.expiry)}</Typography>
//                         {load &&
//                             <>
//                                 <Polling
//                                     question={polldata.pollData.question}
//                                     answers={pollAnswers1}
//                                     onVote={(voteAnswer) =>
//                                         handleVote(voteAnswer, pollAnswers1, 1)
//                                     }
//                                     customStyles={pollStyles1}
//                                     noStorage={true}
//                                     vote={polldata?.pollData?.response}
//                                 />
//                                 {(!load || polldata.pollData.pollAnswered === true) &&
//                                     <div style={{ display: "flex", justifyContent: "flex-end" }}>
//                                         {expiryFun(polldata.pollData.expiry) !== "Expired" ? <Button onClick={handleresponse} className="undo_btn">undo</Button> : ""}
//                                     </div>}
//                             </>}

//                     </Grid>
//                 </Grid> : ""
//             }

//         </Box>
//     );
// }


import React, { useState, useEffect } from "react";
import { Grid, Box, Typography, Button } from "@mui/material";
import Polling from "react-polling";
import axios from "axios";
import URL from '../../../apiurl';
import { getMetaData, getPostById } from "../../../../redux/Actions/actions";
import poll_icon from '../../../../images/poll.svg';
import { headers } from "../../../../redux/Actions/connectActions";
import UserService from "../../../../services/UserService";
import { useDispatch } from "react-redux";


const pollStyles1 = {
    questionSeparator: false,
    questionSeparatorWidth: "question",
    questionBold: false,
    questionColor: "#4F70D6",
    align: "left",
    theme: "blue"
};

export function expiryFun(expiryDate) {
    const ONE_DAY = 1000 * 60 * 60 * 24;
    const differenceMs = new Date(expiryDate) - new Date();
    const days = Math.round(differenceMs / ONE_DAY);

    return days > 0 ? (days > 1 ? `Expires in ${days} days` : `Expires in ${days} day`) : days === 0 ? 'Expires Today' : 'Expired';
}

export default function PollPost(props) {
    const [load, setLoad] = useState(false);
    const [polldata, setPolldata] = useState(props.post);
    const [pollAnswers1, setPollAnswers1] = useState(props?.post?.pollData?.pollResponseData);

    const dispatch = useDispatch();

    useEffect(() => {
        setLoad(true);
    }, [props]);

    const handleVote = async (voteAnswer, pollAnswers) => {
        const newPollAnswers = pollAnswers.map((answer) => {
            if (answer.option === voteAnswer) answer.votes++;
            return answer;
        });
        setPollAnswers1(newPollAnswers);

        let data = {
            "metadata": await getMetaData(),
            "postId": polldata.id,
            "questionId": polldata.pollData.id,
            "response": voteAnswer,
            "userId": sessionStorage.getItem('userId')
        };

        axios.post(`${URL.addPoll}`, data, headers()).then(res => {
            if (res.status === 200 || 201) {
                setLoad(true);
                UpdatePostCache("add_pollresponse", polldata.id);
            }
            getpolldata();
        }).catch(err => console.log(err));
    };

    const UpdatePostCache = async (type, id) => {
        let postData = "";
        await axios.get(`${URL.postById}/${id}`, headers())
            .then((response) => {
                postData = response.data;
            })
            .catch((error) => {
                console.log(error);
            });

        let data = {
            update: type,
            new_post: postData,
            post_id: id,
        };
        UpdateChacheFeed(data);
    };

    const UpdateChacheFeed = async (data) => {
        await axios.post(`${URL.updateChacheFeed}`, data, headers())
            .then(res => {
                console.log(res);
            }).catch(err => {
                console.log(err);
            });
    };

    const handleresponse = () => {
        let id = polldata.pollData.pollId;
        setLoad(false);
        axios.delete(`${URL.deleteresponse}/${id}`, headers()).then(res => {
            UpdatePostCache("delete_pollresponse", polldata.id);
            getpolldata();
        });
    };

    const getpolldata = async () => {
        let data = await getPostById(polldata.id);
        if (data.id) {
            setPolldata(data);
            setPollAnswers1(data.pollData.pollResponseData);
            setLoad(true);
        } else if (data.error) {
            setLoad(true);
        }
    };

    return (
        <Box sx={{ px: "10px" }}>
            {polldata &&

                <Grid container className={props.border ? '' : 'celebratepoll'}>
                    <Grid item
                        className={`poll-class ${props.border ? 'poll-width' : ''}`}
                        style={{ pointerEvents: props.border || !UserService.getToken() ? 'none' : new Date(new Date(polldata.pollData.expiry).toDateString()) < new Date(new Date().toDateString()) ? 'none' : 'all' }}
                    >
                        <Typography component={'div'} className="d-flex" sx={{ justifyContent: 'space-between', p: polldata.border ? '10px' : '0 5px' }}>
                            <p className="color_head d-flex e-align" style={{ fontSize: '18px' }}>
                                {polldata.border ? <><img src={poll_icon} alt={'poll'} width="25px" height="25px" className="celebrate" />&nbsp;</> : ''}
                                {polldata.pollTitle}</p> {expiryFun(polldata.pollData.expiry)}
                        </Typography>
                        {load && (
                            <>
                                <Polling
                                    url={`${URL.postById}/${polldata.id}`}
                                    interval={10000} // Poll every 10 seconds
                                    retryCount={3}
                                    headers={{ Authorization: `Bearer ${UserService.getToken()}` }}
                                    onSuccess={(response) => {
                                        const data = response.data;
                                        setPolldata(data);
                                        setPollAnswers1(data.pollData.pollResponseData);
                                    }}
                                    onFailure={(error) => {
                                        console.log(error);
                                        return true; // Continue polling
                                    }}
                                >
                                    {() => (
                                        <>
                                            <div>
                                                <Typography component="div" sx={{ mt: 2 }}>
                                                    {polldata.pollData.question}
                                                </Typography>
                                                <ul style={{}} >
                                                    {pollAnswers1.map((answer, index) => (
                                                        <li key={index} style={{ listStyleType: 'none', margin: '8px 0' }}>
                                                            <Button className="capitalize"
                                                                sx={{
                                                                    border: '1px solid #4F70D6',
                                                                    borderRadius: '12px',
                                                                    width: '100%',
                                                                    justifyContent: 'center'
                                                                }}
                                                                onClick={() => handleVote(answer.option, pollAnswers1)}
                                                                disabled={polldata.pollData.pollAnswered}
                                                            >
                                                                {answer.option} - {answer.votes} votes
                                                            </Button>
                                                        </li>
                                                    ))}
                                                </ul>
                                            </div>
                                            {(!load || polldata.pollData.pollAnswered) && (
                                                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                                                    {expiryFun(polldata.pollData.expiry) !== "Expired" && (
                                                        <Button onClick={handleresponse} className="undo_btn">undo</Button>
                                                    )}
                                                </div>
                                            )}
                                        </>
                                    )}
                                </Polling>
                            </>
                        )}
                    </Grid>
                </Grid>
            }
        </Box >
    );
}
