import React, { useState, useContext } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Snackbar } from '@mui/material';

import CloseIcon from '@mui/icons-material/Close';
import { URI } from '../../../../../utility/uri';
import { D_API } from '../../../../../../services/APIService';
import { useHistory } from 'react-router-dom';





const DeleteDeptDialog = ({ open, close, orgObj, orgLoc, closeEditDialog, parentOrgObj }) => {



    const [alert, setAlert] = useState({
        open: false,
        msg: ""
    })

    const history = useHistory();


    // const contextObj = useContext(ContextInfo);

    // console.log(contextObj, "contextObj")



    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setAlert({
            open: false,
            msg: ""
        })
    };


    const DeleteOrgFn = (Obj, parentObj) => {

        let mangOrId = orgLoc?.managingOrganization && parseInt(orgLoc?.managingOrganization?.reference?.split("/")?.[1]) != NaN ? orgLoc?.managingOrganization?.reference?.split("/")?.[1] : null

        let pageId
        let copyObj = { ...Obj };
        let indx = copyObj?.identifier?.findIndex((val) => val.system == 'https://pageId/');
        pageId = indx != -1 && parseInt(copyObj?.identifier?.[indx]?.value) != NaN ? copyObj?.identifier?.[indx]?.value : null;


        let parentIndx = parentObj?.identifier?.findIndex((val) => val.system == 'https://pageId');
        let parentPageId = parentIndx != -1 && parseInt(parentObj?.identifier?.[indx]?.value) != NaN ? parentObj?.identifier?.[indx]?.value : null;

        if (orgLoc != null && orgLoc?.id) {
            D_API.delete(`Location/${orgLoc?.id}/${mangOrId}`).then((res) => {
                if (copyObj?.id && pageId) {
                    D_API.delete(`${URI?.organization}/${copyObj?.id}/${pageId}`).then((res) => {
                        close();
                        // history.push(`/admin/organization/${copyObj?.identifier?.[1]?.value}`)
                        setAlert({ open: true, msg: "Successfully Deleted Organization" });
                        closeEditDialog();
                        if (parentPageId) {
                            history.push(`/admin/organization/${parentPageId}`)
                        } else {
                            history.push(`/`)
                        }
                    })
                }
            }).catch((err) => {
                close();
                setAlert({ open: true, msg: "Something went wrong Plz try after sometime" });
                // closeEditDialog();
            })

        } else if (copyObj?.id && pageId) {
            D_API.delete(`${URI?.organization}/${copyObj?.id}/${pageId}`).then((res) => {
                close();
                // history.push(`/admin/organization/${copyObj?.identifier?.[1]?.value}`)
                setAlert({ open: true, msg: "Successfully Deleted Organization" });
                closeEditDialog();
                if (parentPageId) {
                    history.push(`/admin/organization/${parentPageId}`)
                } else {
                    history.push(`/`)
                }

            }).catch((err) => {
                close();
                setAlert({ open: true, msg: "Something went wrong Plz try after sometime" });
                // closeEditDialog();
            });

        }
        else {
            close();

            // history.push(`/admin/organization/${copyObj?.identifier?.[1]?.value}`)
            setAlert({
                open: true, msg: "Organization reference not found"
            })
            // closeEditDialog();
        }


    }

    // this piece of code should keep above the return only don't change it
    const action = (
        <React.Fragment>
            <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={handleClose}
            >
                <CloseIcon fontSize="small" />
            </IconButton>
        </React.Fragment>
    );

    return (
        <div>


            <React.Fragment>

                <Dialog
                    open={open}
                    onClose={close}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    maxWidth="xs"
                    fullWidth
                >
                    <DialogTitle id="alert-dialog-title">
                        <div className='fntMd fntBold9' style={{ color: '#007cdc' }}>Caution</div>
                    </DialogTitle>
                    <DialogContent>
                        Are sure do you want to delete the HealthDepartment because it will not revert back further !
                    </DialogContent>
                    <DialogActions>
                        <Button variant="contained" className='capitalize' sx={{ padding: "4px 20px !important", borderRadius: "20px" }} onClick={() => DeleteOrgFn(orgObj, parentOrgObj)}>Yes</Button>
                        < Button variant="contained" className='capitalize' sx={{ padding: "4px 20px !important", borderRadius: "20px" }} onClick={close}>No</Button>
                    </DialogActions>
                </Dialog>
            </React.Fragment>

            <Snackbar
                open={alert?.open}
                // autoHideDuration={6000}
                message={alert.msg}
                onClose={handleClose}
                action={action}
            />
        </div>
    )
}

export default DeleteDeptDialog;