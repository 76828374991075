import React, { useEffect, useState } from 'react';
import './HeaderBox.css';
import "../../utility/style.css";
import { Button } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import UserService from '../../../services/UserService';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import axios from 'axios';
import URL from '../../apiurl';
import { makeStyles } from '@mui/styles';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuList from '@mui/material/MenuList';
import DialogBox from '../../DialogBox/dialog';
import { useParams } from 'react-router';
import { useRouteMatch } from 'react-router';
import { fetchProfile, getMetaData } from '../../../redux/Actions/actions';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { WhatsappShareButton, EmailShareButton, LinkedinShareButton, TwitterShareButton, FacebookShareButton } from 'react-share';
import { Typography, Box, Divider } from '@mui/material';
import { socket } from '../../../socket';
import useMediaQuery from '@mui/material/useMediaQuery';
import Arrow from '../../../icons/leftarrow.svg';
import Message from '../../../icons/message.svg';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import TwitterIcon from '@mui/icons-material/Twitter';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import EmailIcon from '@mui/icons-material/Email';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import FacebookIcon from '@mui/icons-material/Facebook';
import shareicon from '../../../icons/share_icon.svg';
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Dialog from "@mui/material/Dialog";
import { headers } from '../../../redux/Actions/connectActions';
import { sendPushNotification } from '../Post/commonFun';
// import { WhatsappShareButton, EmailShareButton, LinkedinShareButton, TwitterShareButton, FacebookShareButton } from 'react-share';

const useStyles = makeStyles({
    paper: {
        boxShadow: '0px 3px 6px #00000029',
        borderRadius: '4px',
        opacity: 1,
        color: '#0054C7',
    },
    pos: {
        "& .css-6hp17o-MuiList-root-MuiMenu-list": {
            paddingTop: "0px",
            paddingBottom: "0px",
            width: "125px"
        },
        paddingTop: "0px",
        paddingBottom: "0px"
    },
    parent: {
        position: "relative"
    }
});

const MoreInfo = (props) => {

    const [openMenu, setOpenMenu] = React.useState(false);
    const anchorRef = React.useRef(null);

    const handleToggle = () => {
        setOpenMenu((prevOpen) => !prevOpen);
    };

    const handleCloseMenu = (event) => {
        if (anchorRef?.current && anchorRef?.current?.contains(event.target)) {
            return;
        }

        setOpenMenu(false);
    };

    function handleListKeyDown(event) {
        if (event.key === 'Tab') {
            event.preventDefault();
            setOpenMenu(false);
        } else if (event.key === 'Escape') {
            setOpenMenu(false);
        }
    }
    // return focus to the button when we transitioned from !open -> open
    const prevOpen = React.useRef(openMenu);

    React.useEffect(() => {
        if (prevOpen.current === true && openMenu === false) {
            anchorRef.current.focus();
        }

        prevOpen.current = openMenu;
    }, [openMenu]);

    return (
        <><span
            ref={anchorRef}
            id="composition-button"
            aria-controls={openMenu ? 'composition-menu' : undefined}
            aria-expanded={openMenu ? 'true' : undefined}
            aria-haspopup="true"
            onClick={handleToggle}
        >
            <div className='display_more_btn'>
                <Button className='more_text'>More<KeyboardArrowDownIcon /></Button>
            </div>
            <div className='display_more_icon'>
                <MoreVertIcon />
            </div>

        </span>
            <Popper
                open={openMenu}
                anchorEl={anchorRef.current}
                role={undefined}
                placement="bottom-start"
                transition
                // disablePortal
                style={{ zIndex: 1 }}
            >
                {({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}
                        style={{
                            transformOrigin:
                                placement === 'bottom-start' ? 'left top' : 'left bottom',
                            width: '80px', margin: 'auto',
                            // zIndex: 1
                        }}
                    >
                        <Paper>
                            <ClickAwayListener onClickAway={handleCloseMenu}>
                                <MenuList
                                    // autoFocusItem={openMenu}
                                    id="composition-menu"
                                    aria-labelledby="composition-button"
                                    onKeyDown={handleListKeyDown}
                                // sx={{px: 2}}
                                >
                                    <MenuItem sx={{ justifyContent: "center" }} onClick={props?.block}>Block</MenuItem>
                                    {/* <MenuItem sx={{mx: 2}} onClick={handleCloseMenu}>Report</MenuItem> */}
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper></>
    )
}

const CustomButton = (props) => {
    return (
        <Button className="add-option" sx={{ mt: '0px!important', mr: 2, fontWeight: '550!important', padding: '5px 12px!important' }} variant="outlined">{props?.children}&nbsp;{props?.name}</Button>
    )
}

const CustomRow = (props) => {
    return (
        <Typography component="div" sx={{ px: 2, pt: 2 }} >
            <Typography component="p" sx={{ pb: 1 }} className="e-align d-flex">
                {props?.children}
            </Typography>
            {props?.true ? '' : <Divider sx={{ backgroundColor: 'rgba(0, 0, 0, 0.12)' }} />}
        </Typography>
    )
}


function Connectbutton({ profileData1 }) {
    // console.log(profileData1)
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [reload, setreload] = useState(false);
    const maxWidth = useMediaQuery('(max-width: 960px)')
    const minWidth = useMediaQuery('(min-width: 961px)')
    const [open1, setOpen1] = React.useState(false);
    const [profileData, setProfileData] = useState();
    const username = (profileData1 !== undefined) ? profileData1?.user?.userName : null;
    const userID = (profileData1 !== undefined) ? profileData1?.user?.id : null;
    const receiverimage = (profileData1 !== undefined) ? profileData1?.user?.practioner?.photo?.[0]?.url : null;
    const [modal, setModal] = useState({ open: false, name: '' })
    const { id } = useParams();
    const dispatch = useDispatch()
    const classes = useStyles();
    const open = Boolean(anchorEl);
    const { url } = useRouteMatch();

    const title = (profileData1 === undefined) ? " " : profileData1?.user?.fullName;
    const url1 = (profileData1 === undefined) ? " " : `https://www.docsteth.com/profile/${profileData1?.user?.fullName}-${profileData1?.user?.id}`;
    // const image = (profileData1 === undefined) ? " " : profileData1.user.hasOwnProperty("practioner") ? profileData1.user.practioner.photo[0].url : "";
    const description = (profileData1 === undefined) ? " " : profileData1?.user?.about
    const history = useHistory()
    const myreloadingFn = () => {
        setreload(!reload)
    }
    const connectionStatusFn = () => {
        let connect = "Connect";
        if (profileData?.accepted) {
            connect = "Accepted"
        } else if (profileData?.connect) {
            connect = "Message"
        } else if (profileData?.requested) {
            connect = "Requested"
        }
        return connect;
    }

    const handleNotification = (id, notificationFrom) => {
        socket?.emit("sendNotification", {
            name: username,
            senderID: UserService.getUserId(),
            receiverID: userID,
            notificationFrom: notificationFrom,
            profilePic: receiverimage
        });
    };
    const handleNotification1 = (id, notificationFrom) => {
        socket?.emit("sendNotification", {
            name: username,
            senderID: UserService.getUserId(),
            receiverID: userID,
            notificationFrom: notificationFrom,
            profilePic: receiverimage
        });
    };

    const requestDisconnectFm = (peerId, userID) => {
        // let data = {
        //     peerId: peerId,
        //     userId: userID
        // };

        axios.delete(`${URL.deletePeersByUser}/${peerId}/user/${userID}`, headers()).then(res => {
            myreloadingFn()
            handleNotification1(userID, "REQUEST_DELETED")
        }).catch(err => {
            //   setAlert({ open: true, msg: "Internal server error Refresh the page" });
        })
    }

    // REQUESTING FUNCTION 
    const request = async (id) => {
        // console.log(id)
        let data = {
            "metadata": await getMetaData(),
            "requestedUser": id,
            "requestingFrom": sessionStorage.getItem('userId'),
            "status": "PENDING",
            "userId": sessionStorage.getItem('userId')
        }
        axios.post(`${URL.addRequest}`, data, headers()).then(res => {
            sendNotification(res.data?.data?.requestingFrom, res.data?.data?.requestedUser,)
            myreloadingFn()
            handleNotification(id, "REQUESTED")
        }).catch(err => {
            //   setAlert({ open: true, msg: "Internal server error Refresh the page" });
        })
    }

    const sendNotification = async (loggedInUserID, requestedUserId) => {
        axios.get(`${URL.findUser}/${loggedInUserID}`, headers()).then(res => {
            if (res.status === 200) {
                console.log(res.data?.data?.user?.fullName, 'cccc')
                sendPushNotification("friend_request_sent", requestedUserId, res.data?.data?.user?.fullName)
            }
        })
    }

    // CANCEL REQUEST FUNCTION START
    const cancelRequestFn = (id, reqId) => {
        axios.delete(`${URL.deleteRequestById}/${reqId}`, headers()).then(res => {
            myreloadingFn()
        }).catch(err => {
            handleNotification1(id, "REQUEST_DELETED")
            //   setAlert({ open: true, msg: "Internal server error Refresh the page" });
        })
    }

    // ACCEPT AND DECLINE FUNCTION 

    const requestAcceptFn = async (data, status) => {
        // console.log(data)
        let req = {
            "id": data.request,
            "metadata": await getMetaData(),
            "requestedUser": UserService.getUserId(),
            "requestingFrom": data.user.id,
            "status": status,
            "userId": data.user.id
        }

        axios.put(`${URL.acceptRequest}/${data.request}`, req, headers()).then(res => {
            myreloadingFn()
            handleNotification1(data, "REQUEST_ACCEPTED")
        })
            .catch(error => { })
    }

    const connectBtnFun = (e, status) => {
        if (status === "Connect") {
            request(profileData?.user?.id)

        } else if (status === "Connected") {
            setAnchorEl(e.currentTarget);

        } else if (status === 'Requested') {
            setAnchorEl(e.currentTarget);
        }
    }

    const handleClose = (name) => {
        if (name === 'Disconnect') {
            // handleFollow(value.id)
            if (window.confirm("Do you want to disconnect ?")) {
                requestDisconnectFm(profileData?.peerId, profileData?.user?.id)
            }
        }
        if (name === 'Cancel Request') {
            if (window.confirm("Do you want to cancel request ?")) {
                cancelRequestFn(profileData?.userId, profileData?.requestId)
            }
        }
        setAnchorEl(null);
    }

    const Item = ({ MenuName }) => {
        return (
            <MenuItem onClick={() => handleClose(MenuName)} sx={{ fontSize: '13px' }}>{MenuName}</MenuItem>
        )
    }

    const blockUser = () => {
        axios.post(`${URL.blockUser}/${UserService.getUserId()}/user/${id}`, {}, headers()).then(res => {
            // console.log(res)
            myreloadingFn()
            setModal({ open: false, name: '' })
            dispatch(fetchProfile())
        })
    }

    const unblock = () => {
        axios.delete(`${URL.unblockUser}/${UserService.getUserId()}/user/${id}`, headers()).then(res => {
            // console.log(res)
            setModal({ open: false, name: '' })
            myreloadingFn()
            dispatch(fetchProfile())
        }
        )
    }

    useEffect(() => {
        let u = url
        u = url.split('-');

        // let userid = sessionStorage.getItem("userId")
        // dispatch(fetchProfile(3))
        if (UserService.getToken()) {
            if (Number(UserService.getUserId()) === u[1]) {
                history.push(`/myprofile/${UserService.getUsername()}-${UserService.getUserId()}`)
            }
            else {
                axios.get(`${URL.findUser}/${u[1]}`, headers()).then(res => {
                    if (res.status === 200) {
                        setProfileData(res.data?.data)
                    }
                })
            }
        } else {
            axios.get(`${URL.getFindUser}/${u?.[1]}`).then(res => {
                if (res.status === 200) {
                    setProfileData(res.data?.data)
                }
            })
        }
    }, [reload, id])

    const handleClickOpen = () => {
        setOpen1(true);
    };
    const handleClose1 = () => {
        setOpen1(false);
    };
    const HandleCopy = () => {
        navigator.clipboard.writeText(`https://www.docsteth.com/profile/${profileData?.user?.fullName}-${profileData?.user?.id}`)
    }


    return (
        <>
            <DialogBox width="xs"
                open={modal.open}
                handleDialog={() => setModal({ open: false, name: '' })}
                Content={<p>Do you want to {modal.name} this account ?</p>}
                Actions={
                    <div>
                        <Button variant="contained" className="add-option" sx={{ mr: 2 }} onClick={() => modal.name === 'block' ? blockUser() : unblock()}>Yes</Button>
                        <Button variant="contained" className="add-option" onClick={() => setModal({ open: false, name: '' })}>No</Button>
                    </div>
                }
            ></DialogBox>
            {profileData ?
                <div className="profile_buttons e-align">
                    {UserService.getToken() === undefined ? (
                        <Button variant="outlined" onClick={() => UserService.doLogin()} className='btn_connect'>Connect</Button>
                    ) : ((profileData?.accepted) ? (<>
                        <Button variant="outlined" className='decline_btn' onClick={() => requestAcceptFn(profileData, "DECLINED")} id="accept">Decline</Button>
                        <Button variant="outlined" className='accept_btn' onClick={() => requestAcceptFn(profileData, "ACCEPTED")} id="decline">Accept</Button>
                    </>
                    ) : (profileData?.blocked ?

                        <Button variant="outlined" style={{ borderRadius: '12px', height: "33px", color: '#000000', fontWeight: '700', width: '125px', marginRight: '11px' }} onClick={() => setModal({ open: true, name: 'unblock' })}>Unblock</Button>
                        :
                        <div className='button_display_mobile'>
                            {/* // <div className={classes.parent}> */}
                            <Button className='btn_connect' id="basic-button" variant="outlined" aria-controls="basic-menu" aria-expanded={open ? 'true' : undefined} aria-haspopup="true" onClick={(e) => connectBtnFun(e, connectionStatusFn())}>
                                {profileData?.connect ? <img src={Message} alt="message" width="25px" height="25px" style={{ paddingRight: "10px", paddingTop: "4px" }}></img> : <img src={Arrow} alt="connect" width="15px" height="15px" style={{ paddingRight: "10px" }}></img>}
                                {connectionStatusFn()}
                            </Button>
                            {/* {profileData.requested ?
                                (<Button className='btn_connect' id="basic-button" variant="outlined" aria-controls="basic-menu" aria-expanded={open ? 'true' : undefined} aria-haspopup="true" onClick={(e) => connectBtnFun(e, connectionStatusFn())}>
                                    {connectionStatusFn()}</Button><img src={Arrow} width="15px" height="15px" style={{ paddingRight: "10px" }}></img>)
                                : ""} */}
                            <div>
                                {minWidth ? <Button variant="outlined" onClick={handleClickOpen} style={{ border: 'none', color: "black" }}>
                                    <span className='share__btn'><img src={shareicon} alt="share" width='20px' />Share Profile</span>
                                </Button> : ""
                                }
                                {maxWidth ? <Button variant="outlined" onClick={handleClickOpen} style={{ border: 'none', color: "black" }}>
                                    <span className='share__btn'><img src={shareicon} alt="share" width='20px' />Share</span>
                                </Button> : ""
                                }
                                <Dialog
                                    open={open1}
                                    onClose={handleClose1}
                                    aria-labelledby="alert-dialog-title"
                                    aria-describedby="alert-dialog-description"
                                >
                                    <Box>
                                        <Typography sx={{ p: '15px', backgroundColor: '#007dcd', color: 'white', textAlign: "center" }}>
                                            Invite Your Peers
                                        </Typography>
                                        <CustomRow>
                                            <WhatsappShareButton
                                                windowWidth='800px'
                                                windowHeight='800px'
                                                url={url1}
                                                title={title}
                                            >
                                                <CustomButton name="share Profile" >
                                                    <WhatsAppIcon className='inside_icon' sx={{ color: 'black' }} />
                                                </CustomButton>
                                            </WhatsappShareButton>

                                            <TwitterShareButton
                                                windowWidth='800px'
                                                windowHeight='800px'
                                                url={url1}
                                                title={title}
                                            // via={`Dr Akhil Kolli`}
                                            // related={`@Docsteth1`}
                                            >
                                                <CustomButton name="share in Twitter">
                                                    <TwitterIcon className='inside_icon' sx={{ color: 'black' }} />
                                                </CustomButton>
                                            </TwitterShareButton>
                                        </CustomRow>

                                        <CustomRow>
                                            <LinkedinShareButton
                                                url={url1}
                                                title={title}
                                                summary={description}
                                            >
                                                <CustomButton name="share in Linkedin" >
                                                    <LinkedInIcon className='inside_icon' sx={{ color: 'black' }} />
                                                </CustomButton>
                                            </LinkedinShareButton>
                                            <FacebookShareButton
                                                windowWidth='800px'
                                                windowHeight='800px'
                                                url={url1}
                                                quote={`${profileData?.user?.fullName} - ${profileData?.user?.captionAndTaglines} | Docsteth `}
                                                hashtag='#docsteth'
                                            >
                                                <CustomButton name="share in Facebook">
                                                    <FacebookIcon className='inside_icon' sx={{ color: 'black' }}></FacebookIcon>
                                                </CustomButton>
                                            </FacebookShareButton>
                                        </CustomRow>

                                        <CustomRow true={true}>
                                            <EmailShareButton
                                                url={url1}
                                                separator={"    =>  "}
                                                subject={`${profileData?.user?.fullName} - ${profileData?.user?.captionAndTaglines} | Docsteth `}
                                                body={description}
                                            >
                                                <CustomButton name="Email Invite">
                                                    <EmailIcon className='inside_icon' sx={{ color: 'black' }} />
                                                </CustomButton>
                                            </EmailShareButton>
                                            <div onClick={HandleCopy}>
                                                <CustomButton name="Copy Invite" >
                                                    <ContentCopyIcon className='inside_icon' sx={{ color: 'black' }} />
                                                </CustomButton>
                                            </div>
                                        </CustomRow>

                                        <Typography onClick={handleClose1} className="textCenter font-bold cursor" sx={{ mt: 1, p: '10px', backgroundColor: '#e4eef5', color: '#0f3f5d' }}>
                                            Done
                                        </Typography>
                                    </Box>
                                </Dialog>
                            </div>
                            <Menu
                                id="basic-menu"
                                anchorEl={anchorEl}
                                open={open}
                                onClose={handleClose}
                                MenuListProps={{
                                    'aria-labelledby': 'basic-button',
                                }}
                                sx={{ boxShadow: 'none' }}
                                classes={{ paper: classes.paper }}
                                className={classes.pos}
                                style={{ paddingTop: "0px" }}
                            >
                                {((profileData?.requested) ? ['Cancel Request'] : ((profileData?.connect) ? ['Disconnect'] : []))?.map((menu, i) => (
                                    <Item key={i} MenuName={menu} />
                                ))}
                            </Menu>
                        </div>
                    ))}
                    {UserService.getToken() !== undefined && !profileData?.blocked ? <MoreInfo block={() => setModal({ open: true, name: 'block' })} /> : ''}
                </div>
                : null}
        </>
    )
}

export default Connectbutton