import { Grid, List, ListItem, ListItemIcon, ListItemText, ListItemButton, RadioGroup, FormControl, FormControlLabel, Radio, Drawer } from '@mui/material'
import React, { useEffect, useState } from 'react'
import clsx from 'clsx';
import Followup from '../../../images/track.svg'
import managment from '../../Atom/modifiedicons/managment.svg';
import summary from '../../Atom/modifiedicons/summary.svg';
import epidemology from '../../Atom/modifiedicons/epidemology.svg';
import diagnosis from '../../Atom/modifiedicons/diagnosis.svg';
import resources from '../../Atom/modifiedicons/resources.svg';
import EbmkitHeader from './EbmkitHeader';
import useMediaQuery from '@mui/material/useMediaQuery';
import reference from '../../Atom/modifiedicons/reference.svg'
import axios from 'axios'
import { useRouteMatch } from 'react-router';
import URL from '../../apiurl'
import { Helmet } from "react-helmet"
import { useParams } from 'react-router';
import UserService from '../../../services/UserService';
import MenuIcon from '@mui/icons-material/Menu';
import './EbmKit.css'
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import EditIcon from '@mui/icons-material/Edit';
import Divider from '@mui/material/Divider';
import ArchiveIcon from '@mui/icons-material/Archive';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { alpha } from '@mui/material/styles';
import { styled } from '@mui/styles';
import Button from '@mui/material/Button';
import { socket } from '../../../socket';
import { makeStyles } from '@mui/styles';

const StyledMenu = styled((props) => (
    <Menu
        elevation={0}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
        }}
        {...props}
    />
))(({ theme }) => ({
    '& .MuiPaper-root': {
        borderRadius: 6,
        marginTop: '0px',
        width: '95%',
        color:
            theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
        boxShadow:
            'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
        '& .MuiMenu-list': {
            padding: '4px 0',
        },
        '& .MuiMenuItem-root': {
            '& .MuiSvgIcon-root': {
                fontSize: 18,
                color: theme.palette.text.secondary,
                marginRight: theme.spacing(1.5),
            },
            '&:active': {
                backgroundColor: alpha(
                    theme.palette.primary.main,
                    theme.palette.action.selectedOpacity,
                ),
            },
        },
    },
}));


const drawerWidth = 250
const drawerWidth1 = "auto"
const useStyles = makeStyles((theme) => ({
    drawer: {
        width: '6%',
        flexShrink: 0,
        whiteSpace: 'nowrap',
        backgroundColor: "rgb(0, 0 ,0)",

    },
    paper: {
        position: 'static!important'
    },
    //warning drawer bg color and text color
    background: {
        backgroundColor: "black"
    },
    color: {
        color: 'white'
    },
    drawerOpen: {
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,

        }), backgroundColor: "#2410C3",
        "& .MuiDrawer-paperAnchorDockedLeft": {
            borderRight: "none",

        }
    },
    drawerClose: {
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        backgroundColor: "#2410C3",

        overflowX: 'hidden',
        width: theme.spacing(9) + 5,
        //  [theme.breakpoints.up('md')]: {
        //     width: theme.spacing(8)+1
        // },
        [theme.breakpoints.up('lg')]: {
            width: theme.spacing(9) + 5
        }
    },
    drawerClose1: {
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        backgroundColor: "#2410C3",

        overflowY: 'hidden',
        width: '100%',
        //  [theme.breakpoints.up('md')]: {
        //     width: theme.spacing(8)+1
        // },
        [theme.breakpoints.up('lg')]: {
            width: theme.spacing(9) + 5
        }
    },
    selected: {
        borderLeft: '7px solid #a198e5'
    }
})
)

function EbmPracticeRadios(props) {

    {/* for listitems  changing*/ }
    const { url } = useRouteMatch();
    const maxWidth = useMediaQuery('(max-width: 770px)')
    const minWidth = useMediaQuery('(min-width: 769px)')
    const [title, setTitle] = useState("Summary");
    const [headingtitle, setheadingTile] = useState([])
    const [Icon, setIcon] = useState({ img: summary });
    const list = [{ title: 'Summary', img: summary }, { title: 'Subjective', img: epidemology }, { title: 'Diagnosis', img: diagnosis }, { title: 'Management', img: managment }, { title: 'Followup', img: Followup }, { title: 'Resources', img: resources }, { title: 'References', img: reference }]
    const [selectedIndex, setSelectedIndex] = React.useState(0);
    let { diseases } = useParams();
    let { content } = useParams();

    const [anchorEl, setAnchorEl] = React.useState(null);
    const [timer, setTimer] = useState(null)

    const openmenu = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };


    const handleTitle = (title, index, Icon) => {
        setTitle(title)
        setIcon({ img: Icon })
        setSelectedIndex(index)
    }

    const [sub, setSub] = useState([])
    const [value, setValue] = useState('')


    const classes = useStyles();

    const [open, setOpen] = React.useState(false);

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    useEffect(() => {
        let u = url
        u = url.split('/');
        let headingname = u[u.length - 1]
        let dataheading = headingname.replace(/_/g, '')
        let headingsplit = dataheading.split('-')
        setheadingTile(headingsplit[0])

        // setPath([u[3],u[4]])
        let diseasePath = u[3].split('-')
        // console.log(diseasePath[diseasePath.length - 1])
        if (title === 'Summary') {
            axios.get(`${URL.elib}getDiseaseSummaryData/${u[4] ? u[4].split('-')[1] : diseasePath[diseasePath.length - 1]}-${title}`)
                .then(res => {
                    // console.log(res.data)
                    setSub(res.data.data.sort((a, b) => a.id > b.id ? 1 : -1))
                }).catch(err => { })
        }
        else {
            if (UserService.getToken() !== undefined) {

                axios.get(`${URL.elib}diseaseArticlesData/${u[4] ? u[4].split('-')[1] : diseasePath[diseasePath.length - 1]}-${title}`, {
                    headers: {
                        'Authorization': `Bearer ${UserService.getToken()}`
                    }
                })
                    .then(res => {
                        // console.log(res)
                        setSub(res.data.data.sort((a, b) => a.id > b.id ? 1 : -1))
                    }).catch(err => { })
            }
            else {
                UserService.doLogin()
            }
        }
    }, [title])

    // useEffect(()=>{

    // },[])

    // console.log(sub)

    useEffect(() => {
        setTimer(new Date())
        let path = window.location.pathname
        return () => {
            // console.log(timer)
            socket.emit("unmount", path, 'diseaseAnalytics')
        }
    }, [])

    useEffect(() => {
        setTimeout(() => {
            if (timer) socket.emit("articles", ({ start: timer, userId: UserService.getUserId(), path: window.location.pathname, type: 'diseaseAnalytics' }))
        }, [1000])
    }, [timer])


    return (

        <div className="DoseDetails h-100" style={{ width: '100%', overflow: 'hidden', paddingBottom: '0px' }}>
            <EbmkitHeader />
            <div className="DoseDetails_body" style={{ height: 'calc(100vh - 180px)', overflow: "scroll" }}>
                {minWidth ?
                    <Grid container style={{ height: '100%', paddingBottom: '0px' }}>
                        {/* <Grid item style={{marginTop:'-10px'}}> */}
                        <Drawer
                            sx={{
                                width: drawerWidth,
                                flexShrink: 0,
                                height: '100%'
                            }}
                            anchor="left"
                            open={open}
                            variant="permanent"
                            onMouseOver={handleDrawerOpen}
                            onMouseOut={handleDrawerClose}
                            className={clsx(classes.drawer, {
                                [classes.drawerOpen]: open,
                                [classes.drawerClose]: !open,
                            })}
                            classes={{
                                paper: clsx(classes.paper, {
                                    [classes.drawerOpen]: open,
                                    [classes.drawerClose]: !open,
                                }),
                            }}
                        >
                            <List sx={{ width: '100%', color: 'white' }}>
                                {list.map((item, index) => (
                                    <ListItem sx={{ width: '100%', paddingX: '0px!important' }} key={item.title} onClick={() => handleTitle(item.title, index, item.img)} >
                                        <ListItemButton selected={selectedIndex === index} classes={{ selected: classes.selected }} sx={{ paddingX: '24px!important' }}>
                                            <ListItemIcon>
                                                <img src={item.img} style={{ color: 'white', fontSize: '26px' }} />
                                            </ListItemIcon>
                                            <ListItemText primary={item.title} />
                                        </ListItemButton>
                                    </ListItem>
                                ))}
                            </List>
                        </Drawer>
                        {/* </Grid> */}
                        <Grid item className={`${open ? "rightside-width" : 'right-width'}`} sx={{ height: 'inherit', overflow: 'scroll' }}>
                            <div className="update_details">
                                <div>
                                    <List>
                                        <ListItem style={{ fontSize: '26px', fontWeight: '500' }}>
                                            <ListItemButton>
                                                <img src={Icon.img} style={{ color: 'white', fontSize: '26px' }} />
                                                <ListItemText primary={title} />
                                            </ListItemButton>
                                        </ListItem>
                                    </List>
                                </div>
                                {/* <div className="updated_content">
                            <div>
                                <h6>
                                    Last Updated
                                </h6>
                                <p>
                                    12-8-2021
                                </p>
                            </div>
                        </div> */}
                            </div>
                            <div className="radioGroups">
                                <FormControl>
                                    <RadioGroup row className="radio" value={value}
                                        onChange={(e) => {
                                            setValue(e.target.value)
                                            // console.log(e.target.value)
                                            const element = document.getElementById(`${e.target.value}`);
                                            // console.log(element)
                                            element.scrollIntoView()
                                        }}
                                    >
                                        {sub.map(title => (
                                            <FormControlLabel key={title.id} className="capitalize" value={title.child_subtitle.replace(/\s+/g, '-').toLowerCase()} control={<Radio size="small" />} label={title.child_subtitle} />
                                        ))}
                                    </RadioGroup>
                                </FormControl>
                            </div>
                            <div style={{ padding: '10px 20px' }}>
                                {sub.map(title => (
                                    <div key={title.id}>
                                        <p id={`${title.child_subtitle.replace(/\s+/g, '-').toLowerCase()}`} key={title.id} className="capitalize font-weight" style={{ fontSize: '20px' }}>{title.child_subtitle}</p>
                                        <br />
                                        <div style={{ fontFamily: 'inherit' }} className="article-para" dangerouslySetInnerHTML={{ __html: title.body }}></div>
                                        <br />
                                    </div>
                                ))}
                            </div>
                        </Grid>
                    </Grid> : ""
                }
                {maxWidth ?
                    <div>
                        <Button
                            id="demo-customized-button"
                            aria-controls={openmenu ? 'demo-customized-menu' : undefined}
                            aria-haspopup="true"
                            aria-expanded={openmenu ? 'true' : undefined}
                            variant="contained"
                            disableElevation
                            onClick={handleClick}
                            // endIcon={<KeyboardArrowDownIcon />}
                            style={{ width: "100%", display: "block", position: "sticky", zIndex: "1000", top: "0px" }}
                        >
                            <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                <div>
                                    <span className='heading_title'>{headingtitle ? headingtitle.substr(0, 60) : ""}</span>
                                </div>
                                <div>
                                    <MenuIcon />
                                </div>
                            </div>
                        </Button>
                        <StyledMenu
                            id="demo-customized-menu"
                            MenuListProps={{
                                'aria-labelledby': 'demo-customized-button',
                            }}
                            anchorEl={anchorEl}
                            open={openmenu}
                            onClose={handleClose}
                            style={{ marginTop: "0px" }}
                        >
                            <MenuItem className='padding_menu' style={{ display: "block", backgroundColor: "#5798B2" }} onClick={handleClose} disableRipple>
                                {list.map((item, index) => (
                                    <div onClick={() => handleTitle(item.title, index, item.img)} style={{ display: "flex", justifyContent: "flex-start", alignItems: "center", padding: "10px" }} key={index}>
                                        {/* <img src={item.img} style={{ color: 'white', fontSize: '26px' }}></img> */}
                                        <span style={{ fontSize: "18px", fontWeight: "normal", color: "white" }}>{item.title}</span>
                                    </div>
                                ))}
                            </MenuItem>

                        </StyledMenu>
                        <div className="radioGroups">
                            <FormControl>
                                <RadioGroup row className="radio" value={value}
                                    onChange={(e) => {
                                        setValue(e.target.value)
                                        // console.log(e.target.value)
                                        const element = document.getElementById(`${e.target.value}`);
                                        // console.log(element)
                                        element.scrollIntoView()
                                    }}
                                >
                                    {sub.map(title => (
                                        <FormControlLabel key={title.id} className="capitalize" value={title.child_subtitle.replace(/\s+/g, '-').toLowerCase()} control={<Radio size="small" />} label={title.child_subtitle} />
                                    ))}
                                </RadioGroup>
                            </FormControl>
                        </div>
                        <div style={{ padding: '10px 20px' }}>
                            {sub.map(title => (
                                <div key={title.id}>
                                    <p id={`${title.child_subtitle.replace(/\s+/g, '-').toLowerCase()}`} key={title.id} className="capitalize font-weight" style={{ fontSize: '20px' }}>{title.child_subtitle}</p>
                                    <br />
                                    <div style={{ fontFamily: 'inherit' }} className="article-para" dangerouslySetInnerHTML={{ __html: title.body }}></div>
                                    <br />
                                </div>
                            ))}
                        </div>
                    </div> : ""
                }
            </div>
        </div>
    )
}

export default EbmPracticeRadios



