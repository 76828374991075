import React, { useEffect, useState } from 'react'
import './article.css'
import Edit from "../../images/edit.svg";
import IconButton from '@mui/material/IconButton';
import Container from '@mui/material/Container';
import { Editor } from "react-draft-wysiwyg";
import { EditorState, ContentState, convertToRaw, convertFromHTML, Modifier } from 'draft-js';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import Button from '@mui/material/Button'
import Publish from './publish'
import Create from './createarticle'
import { useHistory } from 'react-router-dom';
import UserService from '../../services/UserService'
import URL from '../apiurl';
import axios from 'axios'
import { useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { doRefresh, getMetaData, getPost } from '../../redux/Actions/actions';
import Snackbar from '@mui/material/Snackbar'
import { getLoc } from '../Molecule/Feed/Feed';
import { headers } from '../../redux/Actions/connectActions';
import { Dialog, DialogContent, List, ListItem, ListItemText, Menu, MenuItem } from '@mui/material';
import SunEditor, { buttonList } from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css';

import {
    align,
    font,
    fontColor,
    fontSize,
    formatBlock,
    hiliteColor,
    horizontalRule,
    lineHeight,
    list,
    paragraphStyle,
    table,
    template,
    textStyle,
    image,
    link
} from "suneditor/src/plugins";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import CloseIcon from '@mui/icons-material/Close';


const defaultFonts = [
    "Arial",
    "Comic Sans MS",
    "Courier New",
    "Impact",
    "Georgia",
    "Tahoma",
    "Trebuchet MS",
    "Verdana"
];

const LOES = [
    { id: '1a', value: 'Systematic reviews (with homogeneity) of randomized controlled trials' },
    { id: '1b', value: 'Individual randomized controlled trials (with narrow confidence interval)' },
    { id: '1c', value: 'All or none randomized controlled trials' },
    { id: '2a', value: 'Systematic reviews (with homogeneity) of cohort studies' },
    { id: '2b', value: 'Individual cohort study or low quality randomized controlled trials (e.g. <80% follow-up)' },
    { id: '2c', value: `"Outcomes" Research; ecological studies` },
    { id: '3a', value: 'Systematic review (with homogeneity) of case-control studies' },
    { id: '3b', value: `Individual case-control study` },
    { id: '4', value: 'Case-series (and poor quality cohort and case-control studies)' },
    { id: '5', value: `Expert opinion without explicit critical appraisal, or based on physiology, bench research or "first principles"` },
]

const CustomOption = (props) => {

    return (
        <>
            <div onClick={props.handleExpand} class="rdw-remove-wrapper" aria-label="rdw-remove-control">
                <div class="rdw-option-wrapper" title="LOE">
                    <div>LOE</div>
                </div>
            </div>
        </>
    );
}

export default function Article() {
    const { id } = useParams()
    const html = ``
    const blocksFromHTML = convertFromHTML(html)
    const content = ContentState.createFromBlockArray(blocksFromHTML)
    const editorContent = EditorState.createWithContent(content)
    const [editorState, setEditorState] = useState(editorContent)
    const [open, setOpen] = useState(false)
    const [openArticle, setOpenArticle] = useState(false)
    const [title, setTitle] = useState('')
    const [url, setUrl] = useState('')
    const [puid, setPUid] = useState({ userId: UserService.getUserId(), pageId: 0 })
    const [postPrivacy, setPostPrivacy] = useState('');
    const [loe, setLoe] = useState(null)
    const [alert, setAlert] = useState({ open: false, msg: '' })
    const [expand, setExpand] = useState(false)


    const [anchorEl, setAnchorEl] = React.useState(null);
    const openMenu = Boolean(anchorEl);



    const state = useSelector(state => state.userProfile?.userProfile)
    const post = useSelector(state => state.userProfile?.getPost);



    console.log(id, "444444")
    console.log(state, "state")



    const history = useHistory()
    const dispatch = useDispatch()

    const [value, setValue] = React.useState("");
    const handleChange = (content) => {
        console.log(content); //Get Content Inside Editor
    }

    const sortedFontOptions = [
        "Logical",
        "Salesforce Sans",
        "Garamond",
        "Sans-Serif",
        "Serif",
        "Times New Roman",
        "Helvetica",
        ...defaultFonts
    ].sort();


    useEffect(() => {
        if (id) {
            dispatch(getPost(id))
        }
    }, [])

    // console.log(post)

    useEffect(() => {
        if (id && Object?.keys(post)?.length > 0) {
            const html = post?.articleData?.articleDescription
            const blocksFromHTML = htmlToDraft(html)
            const content = ContentState.createFromBlockArray(blocksFromHTML)
            // editorContent = EditorState.createWithContent(content)
            setEditorState(EditorState.createWithContent(content))
            setValue(post?.articleData?.articleDescription)
            setTitle(post?.articleData?.articleTitle)
            setUrl(post?.sourceLink)
            setPUid({ userId: post?.userId, pageId: post?.pageId })
            setPostPrivacy(post?.postPrivacy)
        }
    }, [post])

    console.log(postPrivacy, "postPrivacy")
    const getFileBase64 = (file, callback) => {
        var reader = new FileReader();
        reader.readAsDataURL(file);
        // Since FileReader is asynchronous,
        // we need to pass data back.
        reader.onload = () => callback(reader.result);
        // TODO: catch an error
        reader.onerror = error => { };
    };

    const uploadImageCallBack = file => new Promise(
        (resolve, reject) => getFileBase64(
            file,
            data => resolve({ data: { link: data } })
        )
    );

    const editState = (editorState) => {
        // setEditorState(editorState)
        // setLoe(null)
        setValue(editorState)
    }


    const handleOpenMenu = (event) => {
        setAnchorEl(event.currentTarget);
        event.preventDefault();
    };

    const handleCloseMenu = () => {
        setAnchorEl(null);
    };


    // console.log(value, "value")

    const uploadFile = (e) => {

        const reader = new FileReader()
        reader.readAsDataURL(e.target?.files?.[0]);

        reader.onloadend = () => {
            setUrl(reader.result)
            setAnchorEl(null);
        }

        if (e.target.files?.[0]) {
            setUrl(reader.result)
        } else {
            setAnchorEl(null);
        }
    }


    console.log(puid, "person")

    const uploadLink = (postdata) => {
        // console.log(postdata)
        if (url !== '') {
            let image = url?.split(',')?.[1]
            let type = url?.split(',')?.[0]?.split('/')?.[1]?.split(';')?.[0]
            let base64 = url?.split(",")?.[1]?.split("=")?.[0];
            var strLength = base64.length;
            var fileLength = strLength - (strLength / 8) * 2;
            // console.log(image, type, fileLength)
            let data = {
                "file_type": 'IMAGE',
                "files": [
                    {
                        "file_size": Number((fileLength / 1048576)?.toFixed(3)),
                        "file_extension": type,
                        "data": image?.toString()
                    }
                ]
            }
            // console.log(data)
            axios.post(`${URL.upload}`, data, headers()).then(res => {
                // console.log(res)
                if (res.status === 200) {
                    postArticle(res.data, postdata)
                }
                else {
                    setAlert({ open: true, msg: 'Error while uploading...' })
                    // handleClose()
                }
            }).catch(error => {
                setAlert({ open: true, msg: 'Error while uploading...' })
                // handleClose()
            })
        }
        else {
            postArticle([], postdata)
        }
    }

    const postArticle = async (image, data) => {
        let val = await getLoc()
        let metadata = await getMetaData(post.metadata)
        let articleData =
        {
            "coAuthors": data?.coAuthors,
            "contributors": data?.contributors,
            "date": new Date().toISOString(),
            // "articleDescription": draftToHtml(convertToRaw(editorState.getCurrentContent())),
            "articleDescription": value,
            "name": "",
            "organisation": data?.organisations,
            "articleTitle": title,
            "createDate": new Date()?.toISOString(),
            "description": data.desc,
            "lastUpdated": new Date()?.toISOString(),
            "location": val,
            "metadata": metadata,
            "pageId": Number(puid.pageId),
            "postPrivacy": postPrivacy || 'ANYONE',
            "postType": "ARTICLE",
            "sourceLink": image[0],
            "tags": data.tags,
            "userId": puid.userId,
            "userData": {
                "fullName": state?.practioner?.name?.[0]?.text,
                "profilePic": state?.practioner?.photo?.[0]?.url,
                "caption": state.captionAndTaglines
            }
        }

        let updatedata = {
            "description": data?.desc,
            "location": val,
            "metadata": metadata,
            "pageId": Number(puid?.pageId),
            "postPrivacy": postPrivacy || 'ANYONE',
            "postType": "ARTICLE",
            "sourceLink": (post?.sourceLink) ? post?.sourceLink : image?.[0],
            "tags": data?.tags,
            mentions: "",
            "userId": puid?.userId,
            "userData": {
                "fullName": state?.practioner?.name?.[0]?.text,
                "profilePic": state?.practioner?.photo?.[0]?.url,
                "caption": state?.captionAndTaglines
            },
            "pageData": null,
            "articleData": {
                "id": id,
                "date": new Date()?.toISOString(),
                "name": "",
                "userId": puid?.userId,
                "metadata": metadata,
                "coAuthors": data?.coAuthors,
                "articleTitle": title,
                "contributors": data?.contributors,
                "organisation": data?.organisations,
                // "articleDescription": draftToHtml(convertToRaw(editorState.getCurrentContent())),
                "articleDescription": value,
            }
        }

        if (id) {
            // console.log("Edit")
            axios.put(`${URL.updatePost}/${id}`, updatedata, headers())
                .then(res => {
                    UpdatePostCache("update_post", res.data, id)
                    setAlert({ open: true, msg: 'Post Uploaded', post: false })
                    history.goBack()
                })
                .catch(err => {
                    setAlert({ open: true, msg: 'Error while uploading...' })
                    // handleClose()
                })
        } else {
            axios.post(`${URL.addPost}`, articleData, headers())
                .then(res => {
                    // console.log(res.data)
                    if (res.status === 201) {
                        UpdatePostCache("new_post", res.data, res.data?.id)
                        setAlert({ open: true, msg: 'Post Uploaded', post: false })
                        if (res.data?.pageId === 0) {
                            if (window.location.href.includes('/myprofile')) {
                                history.push(`/myprofile/${articleData?.userId}`)
                            }
                            else {
                                history.push(`/`)
                            }
                        }
                        else {
                            history.push(`/organization/${articleData?.pageId}`)
                        }
                    }
                })
                .catch(err => {
                    setAlert({ open: true, msg: 'Error while uploading...' })
                    // handleClose()
                })
        }
    }

    const UpdatePostCache = async (type, newPostData, id) => {

        let ArticleData = "";

        await axios.get(`${URL.postById}/${id}`, headers())
            .then((response) => {
                ArticleData = response.data
            })
            .catch((error) => {
                console.log(error)
                setAlert({ open: true, msg: 'Internal Server Error.Try after sometime' })
            })

        let data = {
            update: type,
            new_post: ArticleData,
            // new_post: newPostData,
            post_id: id ? +id : '',
        }
        UpdateChacheFeed(data)
    }



    const UpdateChacheFeed = async (data) => {

        await axios.post(`${URL.updateChacheFeed}`, data, headers())
            .then(res => {
                console.log(doRefresh(), 'doreferesh')
                dispatch(doRefresh("refreshFeed"))
            }).catch(err => {
                console.log(err)
            })
    }

    console.log(url, "tt1")
    const publish = (e, data, title, value) => {
        // console.log(data,url)
        console.log(e, "test", data);
        e.preventDefault();
        if (url != "" && !(url?.startsWith('https://'))) {
            let image = url?.split(',')?.[1]
            let type = url?.split(',')?.[0]?.split('/')?.[1]?.split(';')?.[0]
            let base64 = url?.split(",")?.[1]?.split("=")?.[0];
            var strLength = base64?.length;
            var fileLength = strLength - (strLength / 8) * 2;

            // console.log(image, type, "123")
            let uploadImgObj = {
                "file_type": 'IMAGE',
                "files": [
                    {
                        "file_size": Number((fileLength / 1048576)?.toFixed(3)),
                        "file_extension": type,
                        "data": image?.toString()
                    }
                ]
            }
            console.log(uploadImgObj, "ooo")
            axios.post(`${URL.upload}`, uploadImgObj, headers()).then(res => {
                if (res.status === 200) {
                    publishArticlethroughConvertImageIntoUrl(res.data, data, title, value)
                }
                else {
                    setAlert({ open: true, msg: 'Error while uploading...' })
                    // handleClose()
                }
            }).catch(error => {
                setAlert({ open: true, msg: 'Error while uploading...' })
                // handleClose()
            })
        }




        // old code start
        // e.preventDefault();
        // if (!url || url?.startsWith('https://')) {
        //     postArticle([url], data)
        // }
        // else {
        //     uploadLink(data)
        // }
        // setAlert({ open: true, msg: 'Uploading...', post: true })
        // old code ends

    }

    const handleExpand = () => {
        setExpand(prev => !prev)
    }

    const addLOE = (val) => {
        setLoe(val)
        handleExpand()
    }

    useEffect(() => {
        if (loe) {
            const contentState = Modifier.replaceText(
                editorState.getCurrentContent(),
                editorState.getSelection(),
                `[LOE-${loe}] `,
                editorState.getCurrentInlineStyle(),
            );
            editState(EditorState.push(editorState, contentState, 'insert-characters'));

        }
        else {
            EditorState.moveSelectionToEnd(editorState);
            setEditorState(EditorState.forceSelection(editorState, editorState.getSelection()));
        }
    }, [loe])

    const handleImageUpload = ((targetImgElement, index, state, imageInfo, remainingFilesCount) => {
        console.log(targetImgElement, index, state, imageInfo, remainingFilesCount)
    })

    const handleImageUploadBefore = (files, info, uploadHandler) => {
        // uploadHandler is a function
        console.log(files, info)
    }

    const handleImageUploadError = (errorMessage, result) => {
        console.log(errorMessage, result)
    }


    const handleEdit = (id, child_subtitle) => {
        // setEdit(id)
        // setSubtitleName(child_subtitle)
    }


    const publishArticlethroughConvertImageIntoUrl = (imgurl, data, title, value) => {

        let val = {
            name: "",
            userId: Number(puid.userId),
            metadata: {
                author: UserService.getUserId(),
                dateTime: new Date(),
                location: getLoc(),
                versionId: 1,
                securityCodes: null
            },
            coAuthors: data?.coAuthors,
            articleTitle: title,
            contributors: data?.contributors,
            organisation: data?.organisations,
            articleDescription: value,
            sourceLink: imgurl,
            pageId: Number(puid.pageId),
            tags: data?.tags
        }

        axios.post(`${URL.addArticle}`, val, headers()).then((res) => {
            setAlert({ open: true, msg: "Successfully Uploaded" });
            setOpenArticle(false);
            history.push("/");

        }).catch((err) => {
            setAlert({ open: true, msg: "Not Successfully Uploaded" })
            console.log(err)
        });
    }

    return (
        <div style={{ backgroundColor: 'white', minHeight: '100vh', position: "realtive" }}>
            {console.log('sdfghjkoloiuytrew')}
            <Dialog open={expand}>
                <DialogContent sx={{ px: 0, py: 1 }}>
                    <List>
                        {LOES?.map((val, i) => (
                            <ListItem sx={{ px: '16px!important' }} button onClick={() => addLOE(val?.id)} key={i}>{val?.id} - {val?.value}</ListItem>
                        ))}
                    </List>
                </DialogContent>
            </Dialog>
            <Publish open={open} close={() => setOpen(false)} />
            <Create open={openArticle} post={post} postPrivacy={(value) => setPostPrivacy(value)} value={postPrivacy} postUser={(uid, pid) => setPUid({ userId: uid, pageId: pid })} close={() => setOpenArticle(false)} title={title} url={url} draft={() => setOpen(true)} publish={publish} />
            <div className="article-cover cover-img flx-center justify-center" style={{ backgroundImage: `url(${url})` }}>

                {/* <p className="a-image ">
                    <div className='bg_color_editarticle'>
                        <IconButton>
                            <input type="file" name="uploadfile" id="img" style={{ display: 'none' }} onChange={(e) => uploadFile(e)} />
                            <label htmlFor="img" className="follow-link font-weight add-cover cursor">
                                <img src={Edit} alt="edit" />
                            </label>
                        </IconButton>
                    </div>
                </p> */}

                {url ? '' : <p className='cover-link'>
                    {/* <Link href="#" className='follow-link'>Add Cover Photo</Link> */}
                    {/* <input type="file" /> */}
                    <input type="file" name="uploadfile" id="img" style={{ display: 'none' }} onChange={(e) => { uploadFile(e) }}
                    />
                    <label htmlFor="img" className="follow-link font-weight add-cover cursor">Add Cover Photo</label>
                </p>}
            </div>

            <div style={{ position: "absolute", top: 0, right: 0 }}>
                <p className="a-image ">
                    <div className='bg_color_editarticle'>
                        <IconButton onClick={handleOpenMenu}>
                            <label htmlFor="img" className="follow-link font-weight add-cover cursor">
                                <MoreVertIcon />
                            </label>
                        </IconButton>
                    </div>
                </p>
            </div>
            {/* <Container> */}
            <div style={{ padding: "10px" }}>
                <input className="article-title form-input" value={title} onChange={(e) => setTitle(e.target.value)} type="text" placeholder="Add Title to this article?" />
                <SunEditor setContents={value} onChange={(editorState) => editState(editorState)}
                    // onImageUpload={handleImageUpload}
                    onImageUploadBefore={uploadImageCallBack}
                    // onImageUploadError={handleImageUploadError}
                    //   defaultValue={richTextEditorHtml}
                    setOptions={{
                        // katex : katex ,
                        plugins: [
                            align,
                            font,
                            fontColor,
                            fontSize,
                            formatBlock,
                            hiliteColor,
                            horizontalRule,
                            lineHeight,
                            list,
                            paragraphStyle,
                            table,
                            template,
                            textStyle,
                            image,
                            link,
                            // katex
                        ],
                        buttonList: [
                            ["undo", "redo"],
                            ["font", "fontSize", "formatBlock"],
                            ["paragraphStyle"],
                            //   ['paragraphStyle', 'blockquote'],
                            [
                                "bold",
                                "underline",
                                "italic",
                                "strike",
                                "subscript",
                                "superscript"
                            ],
                            ["fontColor", "hiliteColor"],
                            ["align", "list", "lineHeight"],
                            ["outdent", "indent"],

                            ["table", "horizontalRule", "link", "image"],
                            // ["table", "horizontalRule", "link", "image", "video"],
                            // ['math'] //You must add the 'katex' library at options to use the 'math' plugin.
                            // ['imageGallery'], // You must add the "imageGalleryUrl".
                            // ["fullScreen", "showBlocks", "codeView"],
                            // ["preview", "print"],
                            ["removeFormat"]

                            // ['save', 'template'],
                            // '/', Line break
                        ], // Or Array of button list, eg. [['font', 'align'], ['image']]
                        defaultTag: "div",
                        minHeight: "300px",
                        showPathLabel: false,
                        font: sortedFontOptions
                    }}
                />
                {/* <Editor
                    editorState={editorState}
                    toolbarClassName="toolbarClassName"
                    wrapperClassName="wrapperClassName"
                    editorClassName="editorClassName"
                    // toolbarCustomButtons={[<CustomOption handleExpand={handleExpand}/>]}
                    toolbar={{
                        // options: ['fontFamily','inline','colorPicker','link','textAlign','history','image']
                        image: {
                            urlEnabled: true,
                            uploadEnabled: true,
                            alignmentEnabled: true,
                            uploadCallback: uploadImageCallBack,
                            // alt: {defaultValue: "article"},
                            previewImage: true,
                            defaultSize: {
                                height: 200,
                                width: 200,
                            },

                        }
                    }}
                    onEditorStateChange={(editorState) => editState(editorState)}
                // onEditorStateChange={(editorState)=>{console.log(draftToHtml(convertToRaw(editorState.getCurrentContent()))) ; setEditorState(editorState)}}
                /> */}

                <div className="article-style" style={{ display: "flex", justifyContent: "flex-end" }} >
                    <Button variant="outlined" className="publish-article article-common" style={{ marginRight: "10px" }} >Save Draft</Button>
                    {title !== '' && value?.length > 250 ? <Button variant="outlined" className="publish-article article-common" onClick={() => setOpenArticle(true)}>Publish</Button>
                        : <Button disabled variant="outlined" className="publish-article article-common" >Publish</Button>}

                </div>
            </div>
            {/* </Container> */}


            {/* old code */}

            <div className="article-style" >
                {/* <Button className="close-article article-common" onClick={() => setOpen(true)}>Close</Button> */}
                {/* {title !== '' && value?.length > 250 ? <Button variant="outlined" className="publish-article article-common" onClick={() => setOpenArticle(true)}>Publish</Button>
                    : <Button disabled variant="outlined">Publish</Button>} */}
                {/* {title !== '' && draftToHtml(convertToRaw(editorState.getCurrentContent())).length > 250 ? <Button variant="outlined" className="publish-article article-common" onClick={() => setOpenArticle(true)}>Publish</Button>
                        : <Button disabled variant="outlined">Publish</Button>} */}
            </div>



            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={openMenu}
                onClose={handleCloseMenu}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}

                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                <MenuItem>
                    <div className="flx-center">
                        <>
                            <ListItemText sx={{ color: "#0D80D8" }}>
                                <input type="file" name="uploadfile" id="img" style={{ display: 'none' }} onChange={(e) => uploadFile(e)} />
                                <label htmlFor="img" className="cursor" style={{ display: "flex", alignItems: "center" }}>
                                    <EditIcon

                                        sx={{
                                            color: "#0D80D8"
                                        }} fontSize="small" />
                                    {url ? "Edit" : "Upload"}
                                </label>
                            </ListItemText>
                        </>

                    </div>
                </MenuItem>


                <MenuItem onClick={() => { history.push("/") }}>
                    <div className="flx-center">
                        <CloseIcon
                            sx={{
                                color: "#0D80D8"
                            }} fontSize="small" />
                        <ListItemText sx={{ color: "#0D80D8" }}> Close</ListItemText>
                    </div>
                </MenuItem>

                {/* <MenuItem onClick={() => { }}>
                    <div className="flx-center">
                        <DeleteIcon
                            sx={{
                                color: "#0D80D8"
                            }} fontSize="small" />
                        <ListItemText sx={{ color: "#0D80D8" }}> Delete</ListItemText>
                    </div>
                </MenuItem> */}

            </Menu >

            <Snackbar
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                autoHideDuration={alert.post ? null : 2000}
                open={alert.open}
                onClose={() => setAlert({ open: false, msg: '' })}
                message={alert.msg}
            />

        </div >
    )
}