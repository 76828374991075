
import { Checkbox, Table, TableBody, TableCell, TableHead, TableRow, TextField } from '@mui/material'
import React, { Fragment, useEffect, useState } from 'react'
import { TextValidator } from 'react-material-ui-form-validator'
import { AddCircle, MedicalServices, RemoveCircle } from "@mui/icons-material";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CloseIcon from '@mui/icons-material/Close';
import moment from "moment";
import { useDispatch, useSelector } from 'react-redux';
import { getHealthServices, updateHealthcare, updateLocation } from '../../../../common/functions/orgfun';
import { ValidatorForm } from 'react-form-validator-core';
import { FormDivider, Actions } from '../../../../common/components/layout';
import ScheduleAvailability from '../AdminOrganization/scheduleAvailability';
import { D_API } from '../../../../../../src/services/APIService';
import { getLocation } from "../../../../../redux/Actions/regActions";
import { useParams } from 'react-router-dom';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { URI } from '../../../../utility/uri';
import { handleHealthDeptLoc } from "../../../../../../src/redux/reducer/regReducer";
import { handleHealthServiceLoc, handleHealthServiceOrgInfo, } from "../../../../../redux/Actions/regActions";



const header = ['Days of Week', '', 'Opening Time', 'Closing Time', '']
// const header = ['Days of Week', 'Opening Time', 'Closing Time',]

const weeks = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday']

const TimeInput = ({ handleChange, ...props }) => {
    const value = props.value
    return (
        <TextValidator
            id="time"
            {...props}
            InputLabelProps={{
                shrink: true,
            }}
            value={value || ''}
            // onChange={e => handleDateChange('startTime', e.target.value, index)}
            onChange={handleChange}
            placeholder='hours:minutes:am/pm'
            type="time"
            fullWidth
            validators={['required']}
            errorMessages={['Select Time']}
        />
    )
}

const WorkTable = ({ week, submitTime, data }) => {

    console.log(week, submitTime, data, "5555")

    const [check, setCheck] = useState(null)
    const [time, setTime] = useState([])
    const [fields, setFields] = useState({ start: null, end: null })
    console.log(fields, "fields")


    useEffect(() => {
        if (data) {
            setCheck(data.open)
            setTime(data.time)
        }
    }, [data])

    const handleCheck = (val) => (e) => {

        if (e.target.checked) {
            setCheck(val)
        }
        else {
            setCheck(null)
        }
        setTime([])
    }



    // const handleTime = (name, value) => {

    //     setFields({ ...fields, [name]: value })
    //     // setTime([...time].map((t,ind) => ind === i ? {...t,[name]: value} : {...i}))
    // }
    const handleTime = (name, newValue) => {
        if (newValue && newValue.isValid()) {
            const formattedTime = newValue.format("HH:mm");
            setFields((prev) => ({ ...prev, [name]: formattedTime }));
        }
    };



    const addTimeValues = () => {
        if (fields.start && fields.end)
            setTime([...time, fields])
    }

    const deleteTime = (ind) => () => {
        setTime([...time].filter((t, i) => ind !== i))
    }

    const addFields = () => {
        setFields({ start: null, end: null })
    }

    const handleFields = () => {
        if (time.length > 0)
            setFields({})
        else
            setFields({ start: null, end: null })
    }

    useEffect(() => {
        handleFields()
    }, [time])

    useEffect(() => {
        // if (time.length > 0 || check !== null)
        submitTime(time, check)
    }, [time, check])


    console.log(time, "time")

    console.log(time, check, fields, "qqq")

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <TableRow>
                <TableCell>
                    <p style={{ padding: "12px" }} className="pl2">
                        {week}
                    </p>
                </TableCell>
                <TableCell>
                    <p>
                        <Checkbox
                            checked={check === `close`}
                            onChange={handleCheck(`close`)}
                        />{" "}
                        Close
                    </p>
                    <p>
                        <Checkbox
                            checked={check === `open`}
                            onChange={handleCheck(`open`)}
                        />{" "}
                        All Day{" "}
                    </p>
                </TableCell>
                {/* <TableCell><Checkbox checked={check === `open`} onChange={handleCheck(`open`)} /> All Day</TableCell> */}
                {!check ? (
                    <TableCell>
                        {time.map((val, i) => (
                            <p key={i}>
                                <div
                                    style={{ margin: "10px 0", display: "flex" }}
                                    className="mv1"
                                >
                                    <RemoveCircle
                                        onClick={deleteTime(i)}
                                        style={{ color: "#FF0000", fontSize: "22px", flex: 0.4 }}
                                    />
                                    &nbsp;
                                    <span>{moment(val.start, "HH:mm").format("hh:mm A")}</span>
                                </div>
                            </p>
                        ))}
                        {/* {Object.keys(fields).length > 0 ? <div><TimeInput className={`${fields.start && fields.end ? 'sfield_left' : 'sf_left'} wh_time`} handleChange={(e) => handleTime('start', e.target.value)} placeholder="Start Time" value={fields.start}  /></div>
          : <div className="" style={{display:'flex'}}><AddCircle onClick={addFields} style={{ flex: 0.55, fontSize: '22px', color: '#00C73D' }} />&nbsp;<span>Add Schedule</span>    </div>
        } */}
                        {Object.keys(fields).length > 0 ? (
                            <div>
                                {/* <TimePicker
                                    ampm={false}
                                    sx={{ ".MuiButtonBase-root": { padding: "0px !important" } }}
                                    className={`${fields.start && fields.end ? "sfield_left" : "sf_left"
                                        } wh_time`}
                                    onChange={(newValue) =>
                                        handleTime(
                                            "start",
                                            moment(newValue.$d, "HH:mm").format("HH:mm")
                                        )
                                    }
                                    placeholder="Start Time"
                                    value={moment(fields.start, "HH:mm").format("hh:mm")}
                                /> */}
                                <TimePicker
                                    ampm={false}
                                    sx={{ ".MuiButtonBase-root": { padding: "0px !important" } }}
                                    className={`${fields.start && fields.end ? "sfield_left" : "sf_left"
                                        } wh_time`}
                                    onChange={(newValue) => handleTime("start", newValue)}
                                    value={fields.start ? moment(fields.start, "HH:mm") : null}
                                    renderInput={(params) => <TextField {...params} />}
                                />
                            </div>
                        ) : (
                            <div className="" style={{ display: "flex" }}>
                                <AddCircle
                                    onClick={addFields}
                                    style={{ flex: 0.55, fontSize: "22px", color: "#00C73D" }}
                                />
                                &nbsp;<span>Add Schedule</span>{" "}
                            </div>
                        )}
                    </TableCell>
                ) : (
                    <TableCell align="center" sx={{ verticalAlign: "middle!important" }}>
                        {check === "open" ? "24 hours" : check === "close" && "Closed"}
                    </TableCell>
                )}
                <TableCell>
                    {!check && (
                        <>
                            {time.map((val, i) => (
                                <p key={i}>
                                    <div
                                        style={{ margin: "10px 0", display: "flex" }}
                                        className="mv1"
                                    >
                                        <RemoveCircle
                                            style={{
                                                visibility: "hidden",
                                                fontSize: "22px",
                                                flex: 0.4,
                                            }}
                                        />
                                        &nbsp;
                                        <span>{moment(val.end, "HH:mm").format("hh:mm A")}</span>
                                    </div>
                                </p>
                            ))}
                            {/* {Object.keys(fields).length > 0 ? <div><TimeInput className={`${fields.start && fields.end ? 'efield_left' : 'ef_left'} wh_time `} handleChange={(e) => handleTime('end', e.target.value)} placeholder="End Time" value={fields.end} /></div> :
              <><AddCircle style={{ visibility: 'hidden' }} /> </>} */}
                            {Object.keys(fields).length > 0 ? (
                                <div>
                                    {/* <TimePicker
                                        ampm={false}
                                        sx={{
                                            ".MuiButtonBase-root": { padding: "0px !important" },
                                        }}
                                        className={`${fields.start && fields.end ? "efield_left" : "ef_left"
                                            } wh_time `}
                                        onChange={(newValue) =>
                                            handleTime(
                                                "end",
                                                moment(newValue.$d, "HH:mm").format("HH:mm")
                                            )
                                        }
                                        placeholder="End Time"
                                        value={moment(fields.end, "HH:mm").format("hh:mm")}
                                    /> */}
                                    <TimePicker
                                        ampm={false}
                                        sx={{
                                            ".MuiButtonBase-root": { padding: "0px !important" },
                                        }}
                                        className={`${fields.start && fields.end
                                            ? "efield_left"
                                            : "ef_left"
                                            } wh_time `}
                                        onChange={(newValue) => handleTime("end", newValue)}
                                        value={fields.end ? moment(fields.end, "HH:mm") : null}
                                        renderInput={(params) => <TextField {...params} />}
                                    />
                                </div>
                            ) : (
                                <>
                                    <AddCircle style={{ visibility: "hidden" }} />{" "}
                                </>
                            )}
                        </>
                    )}
                </TableCell>
                <TableCell >
                    {time.map((val, i) => (
                        <p key={i}>
                            <div style={{ margin: "10px 0" }} className="mv1">
                                <RemoveCircle style={{ visibility: "hidden" }} />
                            </div>
                        </p>
                    ))}
                    {!check && fields.start && fields.end && (
                        <div
                        >
                            <CheckCircleIcon onClick={addTimeValues} sx={{ color: '#0700C1', display: "flex", mt: 1 }} />
                            &nbsp;&nbsp;
                            <CloseIcon onClick={handleFields} sx={{ color: "red" }} />
                        </div>
                    )}
                </TableCell>
            </TableRow>
        </LocalizationProvider>
    );
}

const HealthServiceWorkhour = (props) => {

    const location = useSelector(state => state.registration.healthServiceLocInfo);
    const healthServiceOrg = useSelector(state => state.registration.healthServiceOrgInfo);
    console.log(healthServiceOrg, "03")



    console.log(location, "healthDeptLocDetails");


    const [hours, setHours] = useState([])

    const [data, setData] = useState([])
    const [id, setId] = useState('')

    const [tabs, setTabs] = useState(false)
    const [servicesList, setServicesList] = useState([]);

    const [healthCareService, setHealthCareServices] = React.useState([]);

    // let service = healthCareService?.[0]?.resource
    const params = useParams()

    // const location = useSelector(state => state.registration.location)

    let service = healthServiceOrg;
    // let service = healthServiceOrg;

    console.log(service, "service")

    const orgData = useSelector(state => state.registration.healthServiceOrgInfo);

    const dispatch = useDispatch()

    // useEffect(() => {
    //     geServicesList()
    // }, [location?.id])

    // const geServicesList = () => {
    //     D_API.get(`Location/partof/${location?.id}?count=50&page=1`).then((res) => {
    //         setServicesList(res?.data?.entry?.map(val => val?.resource) || [])
    //     }).catch(err => { alert(err?.response?.data?.message || 'Error.Please try after sometime') })
    // }

    console.log(servicesList, "servicesList")


    const handleData = (week) => (data, check) => {
        console.log(week, data, check, "work")

        if (data.length > 0 || check !== null) {

            if (hours.some(hour => hour.week.slice(0, 3) === week.slice(0, 3))) {
                setHours([...hours].map(hour => hour.week.slice(0, 3) === week.slice(0, 3) ? { ...hour, time: data, open: check } : { ...hour }))
            }
            else
                setHours([...hours, { time: data, open: check, week: week.slice(0, 3) }])
            // console.log([...hours].filter(hour => hour.week === week ? {...hour,time: data,open: check} : {time: data,open: check,week: week}))
        }
        else
            setHours(hours.filter(h => h.week.slice(0, 3) !== week.slice(0, 3)))
    }

    useEffect(() => {

        // const whours = props.service
        const whours = service

        setId(whours?.id || '')

        if (whours?.availableTime?.length > 0) {
            let w = whours?.availableTime || setData([]); setHours([])
            let open = [...w]?.[0]?.daysOfWeek?.map(k => ({ week: k, time: [], open: w[0].allDay ? 'open' : 'close' })) || []
            let close = [...w]?.[1]?.daysOfWeek?.map(k => ({ week: k, time: [], open: w[1].allDay ? 'open' : 'close' })) || []

            let time = [...w].splice(2, w.length - 1)
            let ti = []

            time.map(t => {
                if (ti.some(k => k.week === t.daysOfWeek[0])) {
                    ti = ti.map(v => v.week === t.daysOfWeek[0] ? { ...v, time: [...v.time, { start: t.availableStartTime, end: t.availableEndTime }] } : { ...v })
                }
                else
                    ti.push({ week: t.daysOfWeek[0], open: null, time: [{ start: t.availableStartTime, end: t.availableEndTime }] })
            })

            let a = []
            weeks.map(w => [...ti, ...open, ...close].map(v => w.slice(0, 3) === v.week ? a.push({ ...v, week: w }) : ''))
            setData(a)
            setHours(a.map(v => ({ ...v, week: v.week.slice(0, 3) })))
            // })
        }
        // if (whours?.availableTime?.length > 0) {
        //   let w = whours?.availableTime || setData([]); setHours([])

        //   let open = [...w]?.[0]?.daysOfWeek?.map(k => ({ week: k, time: [], open: w[0].allDay ? 'open' : 'close' })) || []
        //   let close = [...w]?.[1]?.daysOfWeek?.map(k => ({ week: k, time: [], open: w[1].allDay ? 'open' : 'close' })) || []

        //   let time = [...w].splice(2, w.length - 1)

        //   let ti = []

        //   time.map(t => {
        //     if (ti.some(k => k.week === t.daysOfWeek[0])) {
        //       ti = ti.map(v => v.week === t.daysOfWeek[0] ? { ...v, time: [...v.time, { start: t.availableStartTime, end: t.availableEndTime }] } : { ...v })
        //     }
        //     else
        //       ti.push({ week: t.daysOfWeek[0], open: null, time: [{ start: t.availableStartTime, end: t.availableEndTime }] })
        //   })

        //   let a = []
        //   weeks.map(w => [...ti, ...open, ...close].map(v => w.slice(0, 3) === v.week ? a.push({ ...v, week: w }) : ''))
        //   setData(a)
        //   setHours(a.map(v => ({ ...v, week: v.week.slice(0, 3) })))
        //   // })
        // }
    }, [])




    const handleSubmit = async (required) => {

        let timings = [];

        hours.filter(h => h.time.length > 0).map(v => v.time.map(t => timings.push({
            daysOfWeek: [v.week],
            availableStartTime: moment(t.start, "HH:mm").format("HH:mm:ss"),
            availableEndTime: moment(t.end, "HH:mm").format("HH:mm:ss"),
        })
        ))



        // let data = {
        //     ...service,
        //     hoursOfOperation: [
        //         {
        //             daysOfWeek: hours.filter(val => val.open === 'open').map(h => h.week),
        //             allDay: true,
        //         },
        //         {
        //             daysOfWeek: hours.filter(val => val.open === 'close').map(h => h.week),
        //             allDay: false,
        //         },
        //         ...timings,
        //     ],
        // }


        let data = {
            ...service,
            availableTime: [
                {
                    daysOfWeek: hours.filter(val => val.open === 'open').map(h => h.week),
                    allDay: true,
                },
                {
                    daysOfWeek: hours.filter(val => val.open === 'close').map(h => h.week),
                    allDay: false,
                },
                ...timings,
            ],
        }
        // console.log(data)
        // let dataUpdated = await updateHealthcare(data)
        if (healthServiceOrg?.id) {
            D_API.put(`${URI.updateHealthServiceDept}/${data?.id}`, data).then((res) => {
                console.log(res.data, "7")
                D_API.get(`${URI.getHealthCareService}/${res.data}`).then((res) => {
                    dispatch(handleHealthServiceOrgInfo(res.data));
                    // switchTabs();
                    props.clsn();
                    props.Reset();
                    console.log(res.data)
                }).catch((err) => console.log(err));

            }).catch((err) => console.log(err));
        }




        // let dataUpdated = await updateLocation(data);


    }

    //   if (dataUpdated) {

    //         // D_API.get(`${URI.addLocation}/${data?.id}`).then(res => {
    //         //     console.log(res.data)
    //         //     // let data = res.data?.entry?.[0]?.resource
    //         //     data = res.data;
    //         //     // need to be apply
    //         //     // dispatch(handleHealthDeptLoc(data))
    //         //     dispatch(handleHealthServiceLoc(data));
    //         //     // dispatch({
    //         //     //     type: 'location',
    //         //     //     payload: { ...data }
    //         //     // })

    //         // }).catch(() => {
    //         //     // dispatch({
    //         //     //     type: 'location',
    //         //     //     payload: {}
    //         //     // })
    //         // })



    //         // dispatch(getLocation(orgData.id))
    //         // dispatch(getLocation(userOrg.id))
    //         // props.close()
    //     }
    // const handleOnSubmit=()=>{
    //   setTabs(true)
    //   props.close()
    // }
    return (
        <div>
            {tabs ? (
                <ScheduleAvailability
                    workHours={hours}
                    appointmentRequired={props?.service?.appointmentRequired}
                    close={props.clsn}
                    submit={handleSubmit}
                    className='tohideScrollbar'
                />
            ) : (
                <ValidatorForm onSubmit={() => handleSubmit()} >
                    <div style={{ height: "410px", overflow: "auto" }} className='tohideScrollbar'>
                        <Table id="wh_table">
                            <TableHead className="wh_head">
                                <TableRow>
                                    {header.map((th, i) => (
                                        <TableCell
                                            sx={{ minWidth: { xs: "80px", sm: "80px", md: "0" } }}
                                            key={i}
                                        >
                                            {th}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody className="wh_body">
                                {weeks.map((week, i) => (
                                    <Fragment key={i}>
                                        <WorkTable
                                            week={week}
                                            submitTime={handleData(week)}
                                            data={data[i]}
                                        />
                                    </Fragment>
                                ))}
                            </TableBody>
                        </Table>
                    </div>
                    <FormDivider />
                    <div className="mr2" style={{ marginRight: "10px" }}>
                        <Actions
                            name="submit"
                            disabled={hours.length !== 7}
                            // close={props.clsn}
                            close={() => {
                                props.clsn();
                                props.Reset()

                            }}
                        />
                    </div>
                </ValidatorForm>
            )}
        </div>
    );
}
export default HealthServiceWorkhour;










