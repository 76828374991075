import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Doc from '../../../images/Logo2.svg'
import { fetchProfile } from '../../../redux/Actions/actions';
import { useSelector, useDispatch } from 'react-redux';
import Typography from '@mui/material/Typography'
import { useHistory } from 'react-router-dom';
import UserService from "../../../services/UserService";
import { Link } from 'react-router-dom';
import { Avatar, Divider } from '@mui/material';
import './dropdown.css'
import Notifications from "./notifications";
import useMediaQuery from '@mui/material/useMediaQuery';
import { createTheme } from '@mui/material';
import ProfileIcon from '../../../images/profile.svg'
import AccountsIcon from '../../../images/accounticon.svg'
import LogoutIcon from '../../../images/settings.svg';
import { socket } from '../../../socket.js'
import { getPractitionerFromFhir, getUserOrg } from "../../../redux/Actions/regActions";
import OrgLogo from '../../../images/orglogo.svg'
import { CreateOrg } from "../../Layout/MyProfile/organisation/AdminOrganization/create";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { MdDomain } from "react-icons/md";
import axios from "axios";
import { jwtDecode } from "jwt-decode";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  paper: {
    boxShadow: '0px 2px 3px lightgrey',
    top: '8px!important',
    overflow: 'hidden',
    [theme.breakpoints.up('xl')]: {
      width: '17%!important',
    },
    [theme.breakpoints.up('lg')]: {
      width: '19%!important',
    },
    [theme.breakpoints.up('md')]: {
      width: '22.5%',
    },
    // border: '1px solid red',

  }
}));

export default function PositionedMenu() {
  const matches = useMediaQuery('(max-width:769px)');
  const minScreen = useMediaQuery('(min-width:960px)');
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const [data, setData] = useState({})
  const styles = useStyles();
  const history = useHistory()
  const state = useSelector(state => state.userProfile.userProfile)
  const pages = useSelector(state => state.userProfile.pages);

  const practitionerDetails = useSelector(state => state.registration.fhirUserData);


  const userID = UserService.getUserId()
  const [createOrgPage, setCreateOrgPage] = useState(false)

  const dispatch = useDispatch();

  console.log(practitionerDetails, "1001")

  console.log(state, "userprofile")
  console.log(pages, "pages")
  const handleOrgNavigate = (page) => {
    // href={`/admin/organization/${page.id}`}
    history.push(`/admin/organization/${page?.id}`);
    dispatch(getUserOrg(page))
  }






  useEffect(() => {
    if (Object?.keys(state)?.length > 0) {

      setData({
        // photo: state?.practioner?.photo?.[0]?.url,
        ...data,
        coverPic: state?.coverPic,
        fullName: state?.fullName,
        captionAndTaglines: state?.captionAndTaglines,
      })
    }
    else {
      dispatch(fetchProfile(sessionStorage?.getItem("userId")))
    }
  }, [state])



  useEffect(() => {
    dispatch(getPractitionerFromFhir());
  }, [])


  useEffect(() => {
    let val = practitionerDetails;
    if (Object.keys(val)?.length > 0) {
      setData((prev) => {
        return {
          ...prev, photo: val?.photo?.[0]?.url ? val?.photo?.[0]?.url : ""
        }
      })
    }

  }, [practitionerDetails?.id])

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null)
  };

  const handlesocketremove = (id) => {
    // console.log("ghfgh")
    socket?.disconnect(id)
  }


  const logout = () => {
    handleClose()
    history.push('/');

    sessionStorage.removeItem('kctoken')
    sessionStorage.removeItem('userId')
    handlesocketremove(userID)
    sessionLogout()
    UserService.doLogout()
  }

  const sessionLogout = () => {
    let decode = jwtDecode(UserService.getToken())
    axios.get(`https://api.docsteth.com/api/v1/session/logout/${decode?.sid}`, {
      headers: {
        Authorization: `Bearer ${UserService.getToken()}`
      }
    }).then((res) => {

    }).catch((err) => console.log(err))
  }

  const handleClickOrg = () => {
    alert('Please Verify your Profile')
  }

  console.log(data, "data1")
  return (
    <>

      {/*-------------DESKTOP-----------------*/}

      {createOrgPage && <CreateOrg close={() => setCreateOrgPage(false)} branch="mainBranch" />}
      <div id="dropdown__Desktop">
        <div className="d-flex e-align profile__top">
          {!matches ? <Button
            id="demo-positioned-button"
            aria-controls="demo-positioned-menu"
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={handleClick}
            endIcon={<KeyboardArrowDownIcon />}
            className="e-align"
            sx={{ flex: { md: 1, xs: 'none' }, justifyContent: { md: 'flex-start' } }}
          >
            {/* {data ? data.photo ? <Avatar style={{ height: "40px", width: "40px", borderRadius: '7px' }} src={data.photo} /> : <img src={Doc} alt='logo' height="40px" width="40px" /> :
              <img src={Doc} alt='logo' height="40px" width="40px" />} */}
            {data?.photo ? <Avatar style={{ height: "40px", width: "40px", borderRadius: '7px' }} src={data?.photo} /> :
              <img src={Doc} alt='logo' height="40px" width="40px" />}
            {/* } */}
            <Typography component="div" sx={{ textAlign: "left", width: '65px', display: { md: 'block', xs: 'none' }, flex: { md: 1, xs: 'none' } }}>
              <div
                style={{
                  fontSize: "16px",
                  color: "#2E3B43",
                  fontWeight: "600",
                  paddingLeft: "10px",
                  textTransform: 'capitalize'
                }}
                className="user-data"
              >
                {data?.fullName}
              </div>
              <div
                style={{ fontSize: "12px", color: "#2E3B43", paddingLeft: "10px", textTransform: 'capitalize' }}
              // className="user-data fontItalic"
              >
                {data?.captionAndTaglines ? data?.captionAndTaglines?.length > 30 ? data?.captionAndTaglines?.slice(0, 29) + "...." : data?.captionAndTaglines : 'No caption'}
              </div>
            </Typography>
          </Button> :
            <Button
              id="demo-positioned-button"
              aria-controls="demo-positioned-menu"
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              onClick={handleClick}
              // endIcon={<KeyboardArrowDownIcon />}
              className="e-align"
              sx={{ flex: { md: 1, xs: 'none' }, justifyContent: { md: 'flex-start' } }}
            >
              {data ? data.photo ? <Avatar style={{ height: "40px", width: "40px", borderRadius: '7px' }} src={data?.photo} /> : <img src={Doc} alt='logo' height="40px" width="40px" /> :
                <img src={Doc} alt='logo' height="40px" width="40px" />
              }
              {/* } */}
              <Typography component="div" sx={{ textAlign: "left", width: '65px', display: { md: 'block', xs: 'none' }, flex: { md: 1, xs: 'none' } }}>
                <div
                  style={{
                    fontSize: "16px",
                    color: "#2E3B43",
                    fontWeight: "600",
                    paddingLeft: "10px",
                    textTransform: 'capitalize'
                  }}
                  className="user-data"
                >
                  {data?.fullName}
                </div>
                <div
                  style={{ fontSize: "12px", color: "#2E3B43", paddingLeft: "10px", textTransform: 'capitalize' }}
                  className="user-data fontItalic"
                >
                  {data?.captionAndTaglines ? data?.captionAndTaglines : 'No caption'}
                </div>
              </Typography>
            </Button>
          }
          <Typography
            variant="h6"
            // noWrap
            component="div"
            sx={{ display: { md: 'flex', xs: 'none' }, mr: '18px!important' }}
          >
            {minScreen ? <Notifications /> : ''}

          </Typography>
          <Menu
            id="demo-positioned-menu"
            aria-labelledby="demo-positioned-button"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            classes={{ paper: styles.paper }}
          // sx={{width: {xl: '17%',xs: '220px'}}}
          // sx={{ height: '450px', width: '19% !important', }}
          >
            <div style={{ display: "flex", padding: "10px" }} className="e-align cursor" onClick={handleClose}>
              {data ? data?.photo ? <Avatar style={{ height: "40px", width: "40px", borderRadius: '7px' }} src={data?.photo} /> : <img src={Doc} alt='logo' height="40px" width="40px" /> :

                <img src={Doc} alt='logo' height="40px" width="40px" />}

              <div style={{ textAlign: "left", display: "flex", flex: 1 }}>
                <div style={{ width: '135px', flex: 1 }}>
                  <div
                    style={{
                      fontSize: "16px",
                      color: "#2E3B43",
                      fontWeight: "600",
                      paddingLeft: "10px",
                      textTransform: 'capitalize'
                    }}
                    className="user-data"
                  >
                    {data?.fullName}
                  </div>
                  <div
                    style={{
                      fontSize: "12px",
                      color: "#2E3B43",
                      paddingLeft: "10px",
                      textTransform: 'capitalize',
                    }}
                    className="user-data fontItalic"
                  >
                    {data?.captionAndTaglines ? data?.captionAndTaglines : 'No caption'}
                  </div>
                </div>
                <KeyboardArrowDownIcon />
              </div>
            </div>
            <div style={{ overflowY: "scroll", maxHeight: "180px" }}>
              {pages ? pages?.length > 0 ? pages?.map(page =>
                <a href={`/admin/organization/${page.id}`} key={page.id}>
                  {/* <div onClick={()=>handleOrgNavigate(page)} key={page.id}> */}
                  <div style={{ display: "flex", padding: "10px" }} className=" cursor" onClick={handleClose}>

                    {page?.logo ? <Avatar style={{ height: "40px", width: "40px", borderRadius: '7px' }} src={page?.logo} /> :
                      // <img src={Doc} alt='logo' height="40px" width="40px" />
                      <div style={{ backgroundColor: '#A8E8FE', width: '40px', height: "40px", borderRadius: '7px', display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <img src={OrgLogo} alt="organisation" width='25px' height="20px" />
                      </div>
                    }

                    <div style={{ textAlign: "left", display: "flex", flex: 1 }}>
                      <div style={{ width: '150px', flex: 1 }}>
                        <div
                          style={{
                            fontSize: "14px",
                            color: "#2E3B43",
                            fontWeight: "600",
                            paddingLeft: "10px",
                            textTransform: 'capitalize',
                            marginTop: '5px'
                          }}
                          className="user-data"
                        >
                          {page?.name}
                        </div>
                        <div
                          style={{
                            fontSize: "13px",
                            color: "#2E3B43",
                            paddingLeft: "10px",
                            textTransform: 'capitalize',
                          }}
                          className="user-data fontItalic"
                        >
                          {page?.captionAndTagline || 'No caption'}
                        </div>
                      </div>
                    </div>
                  </div>
                </a>
              ) : '' : ''}
            </div>

            <Divider sx={{ borderTop: '1px solid #B1EDF4', borderBottom: '0' }} />
            {
              (state?.kycStatus === "PENDING" || state?.kycStatus === "REJECTED") ?
                <MenuItem style={{ display: 'flex', justifyContent: 'flex-start', justifyContent: "space-between" }} onClick={handleClickOrg}>
                  {/* <img src={AccountsIcon} alt="Add Organization" width="15px" />&nbsp; */}
                  <div style={{ display: "flex", gap: "5px", alignItems: "start" }}>
                    <MdDomain style={{ color: "#70B3DF" }} />
                    <span style={{ fontSize: "14px" }}>Add Organization</span>
                  </div>
                  <AddCircleIcon fontSize="small" sx={{ color: "#008114" }} />
                </MenuItem> : <MenuItem onClick={() => setCreateOrgPage(true)} style={{ display: 'flex', justifyContent: 'flex-start', justifyContent: "space-between" }}>
                  {/* <img src={AccountsIcon} alt="Add Organization" width="15px" />&nbsp; */}
                  <div style={{ display: "flex", gap: "5px", alignItems: "start" }}>
                    <MdDomain style={{ color: "#70B3DF" }} />
                    <span style={{ fontSize: "14px" }}>Add Organization</span>
                  </div>
                  <AddCircleIcon fontSize="small" sx={{ color: "#008114" }} />
                </MenuItem>
            }
            <MenuItem style={{ display: 'flex', justifyContent: 'flex-start' }}>
              <Link to={`/myprofile/${state?.fullName ? state?.fullName?.replace(/ /g, '')?.toLowerCase() : ''}-${state?.id}`}
                onClick={() => { sessionStorage?.setItem("active", 0); sessionStorage?.setItem("profile-active", 0); handleClose() }}
                style={{ width: '100%' }} className="d-flex ta__left">
                <img src={ProfileIcon} alt="profile" width="15px" />&nbsp;
                <span style={{ fontSize: "14px" }}>
                  Profile
                </span></Link>
            </MenuItem>
            <MenuItem style={{ display: 'flex', justifyContent: 'flex-start' }}>
              <a href={`${process.env.REACT_APP_API_ACCOUNTS}`} onClick={handleClose} target='_blank' className="h-100 w-100 d-flex ta__left">
                <img src={AccountsIcon} alt="account" width="15px" />&nbsp;
                <span style={{ fontSize: "14px" }}>Account</span>
              </a>
            </MenuItem>
            <MenuItem onClick={() => logout()} style={{ display: 'flex', justifyContent: 'flex-start' }}>
              <img src={LogoutIcon} alt="logout" width="15px" />&nbsp;
              <span style={{ fontSize: "14px" }}>Logout</span>
            </MenuItem>
          </Menu>
        </div>
      </div>
      <div>

      </div>
    </>
  );
}
