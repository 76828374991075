import { Box, Button, Card, CardContent, Grid, Tab, Tabs, Tooltip, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom'
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'
import org from '../../../../../images/Group 13144.svg'
import mail from '../../../../../images/mail.svg'
import call from '../../../../../images/call.svg'
import Hospital from '../../../../../images/Hospital.svg'
import AMINITIES from '../../../../../images/AMINITIES.svg'
import PublicIcon from '@mui/icons-material/Public';
import Photo from '../amenities/overviewgallery/Photo'
import { DOC_API_WITHOUT_TOKEN, D_API_FHIR_WITHOUT_TOKEN, D_WITHOUT_TOKEN_API } from '../../../../../services/APIService'
import { BiChevronRightCircle } from "react-icons/bi";
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import axios from 'axios'
import URL from '../../../../apiurl'
import { getLocation, getLocation_withouttoken } from '../../../../../redux/Actions/regActions';
import Stethoscope from '../../../../../images/stethoscope1.svg';
import './publicProfile.css';
import PublicFacilities from "./PublicFacilities/publicFacilties";

const days = ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun']
const daysCompare = { 'mon': 1, 'tue': 2, 'wed': 3, 'thu': 4, 'fri': 5, 'sat': 6, 'sun': 7 }

const Instances = ({ id }) => {
    const [allinstance, setAllInstance] = useState(null)

    useEffect(() => {

        // if (id) {
        //     D_API.get(`Location/partof/${id}`)
        //         .then(res => {
        //             console.log(res.data, "check please")

        //             if (res.data?.entry?.[0]?.resource?.id) {
        //                 // instance(res.data?.entry?.[0]?.resource?.id)
        //             }

        //         }).catch(err => { })
        // }

    }, [id])

    // const instance = (id) => {
    //     if (id) {
    //         D_API.get(`Location/partof/${id}`)
    //             .then(res => {
    //                 console.log(res?.data?.entry, "check please gotcha")
    //                 setAllInstance(res?.data?.entry)
    //             }).catch(err => { })
    //     }
    // }

    return (
        <>
            <Typography sx={{ color: '#5C5C5C' }}>Instances</Typography>
            <Typography>{allinstance?.[0]?.resource?.physicalType?.text ? allinstance?.[0]?.resource?.physicalType?.text : 0} Consultation Rooms</Typography>
        </>
    )

}

const TabsComponent = ({ hoursOfOperation, id }) => {
    const [value, setValue] = React.useState('1');
    const [WorkingDays, setWorkingDays] = useState([])
    const [timings, setTimings] = useState({})
    const [activeDay, setActiveDay] = useState(null)

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    useEffect(() => {
        let data = []
        const date = new Date()
        const today = days[date.getDay() - 1]
        {
            hoursOfOperation?.map((item, i) => {
                let openingTime = item.openingTime
                let closingTime = item.closingTime
                if (item?.allDay == true) {
                    (item?.daysOfWeek?.map((child) => {
                        data?.push({ day: child, allDay: item.allDay, today: child == today ? true : false })
                    }))
                } else if (item.allDay == false) {
                    (item?.daysOfWeek?.map((child) => {
                        data?.push({ day: child, allDay: item.allDay, today: child == today ? true : false })
                    }))
                } else {
                    (item?.daysOfWeek?.map((child) => {
                        data?.push({ day: child, workingHours: [{ openingTime: openingTime, closingTime: closingTime }], today: child == today ? true : false })

                    }))

                }
            })
        }

        const mergedSchedule = [];

        for (let i = 0; i < data.length; i++) {
            let day = data[i].day;
            let hours = data[i].workingHours;
            // Check if the day already exists in the merged data
            let existingDay = mergedSchedule.find(item => item.day === day);
            if (existingDay) {
                // If the day exists, merge the working hours
                existingDay.workingHours = existingDay.workingHours.concat(hours);

            } else {
                // If the day doesn't exist, add it to the merged schedule
                mergedSchedule.push(data[i]);
            }
        }
        const sorted = mergedSchedule.sort((a, b) => {
            return daysCompare[a.day] - daysCompare[b.day];
        })
        setWorkingDays(sorted)
    }, [hoursOfOperation])

    const d = new Date();

    let isToday = days[d.getDay() - 1]

    return (
        <>
            <Box sx={{ width: '100%', typography: 'body1' }}>
                <TabContext value={value} >
                    <Box sx={{ background: '#E9F3F8' }}>
                        <Tabs value={value} variant="fullWidth" onChange={handleChange} indicatorColor={""} sx={{
                            alignItems: 'center', gap: "10px", justifyContent: 'space-around ', '& .MuiTab-root': { minHeight: '30px' }, '& .MuiTabs-scroller': {
                                minHeight: '30px',
                            },
                        }}>
                            <Tab label="Availability" value="1"
                                sx={{
                                    '&.MuiTab-root': { background: '#348FC6', borderRadius: '8px', minHeight: '40px', background: '#E9F3F8', color: 'black', textTransform: 'capitalize' },
                                    '&.Mui-selected': { color: 'white', borderBottom: 'none', backgroundColor: '#348fc6' },
                                }} />
                            <Tab label="Amenities" value="2"
                                sx={{
                                    '&.MuiTab-root': { background: '#348FC6', borderRadius: '8px', minHeight: '40px', background: '#E9F3F8', color: 'black', textTransform: 'capitalize' },
                                    '&.Mui-selected': { color: 'white', borderBottom: 'none', backgroundColor: '#348fc6' },
                                }} />
                            <Tab label="Services" value="3"
                                sx={{
                                    '&.MuiTab-root': { background: '#348FC6', borderRadius: '8px', minHeight: '40px', background: '#E9F3F8', color: 'black', textTransform: 'capitalize' },
                                    '&.Mui-selected': { color: 'white', borderBottom: 'none', backgroundColor: '#348fc6' },
                                }} />
                        </Tabs>
                    </Box>
                    <TabPanel value="1" sx={{ height: "165px", overflow: "scroll", padding: "10px 20px" }}>
                        <Instances id={id} />
                        <div style={{ display: 'flex', gap: '5px', overflowY: 'scroll', margin: '5px 0 12px' }}>
                            {WorkingDays?.map((item, i) => {
                                // return <Button variant='contained' onClick={() => setTimings(item)} disabled={!item.allDay && !item.hasOwnProperty('workingHours')} sx={{ minWidth: "25px", background: item.today ? "black" : '', padding: '0px 7px', }}><Typography variant="caption" sx={{ color: 'white', textTransform: 'capitalize' }}>{item.day}</Typography></Button>
                                return <Button key={i} variant='contained' onClick={() => { setTimings(item); setActiveDay(i) }} sx={{ minWidth: "25px", background: activeDay == i && activeDay !== null ? "black" : item.today && activeDay == null ? "black" : !item.allDay && !item.hasOwnProperty('workingHours') ? "#A9A9A9" : '#197FC4', padding: '0px 7px', }}><Typography variant="caption" sx={{ color: 'white', textTransform: 'capitalize' }}>{item.day}</Typography></Button>
                            })}

                        </div>

                        {timings?.day &&
                            <Typography variant='body2'>
                                Timings - {timings?.day == "mon" ? "Monday" : timings?.day == "tue" ? "Tuesday" : timings?.day == "wed" ? "Wednesday" : timings?.day == "thu" ? "Thursday" : timings?.day == "fri" ? "Friday" : timings?.day == "sat" ? "Saturday" : "Sunday"} {timings?.day == isToday ? "(Today)" : ""}
                            </Typography>
                        }

                        {
                            timings?.allDay ?
                                <Typography variant='body2' className='timing' >All Day</Typography>
                                : timings.allDay == false ? <Typography variant='body2' className='timing'>No Slot avalable</Typography>
                                    :
                                    <div style={{ display: 'flex', gap: "10px", flexWrap: 'wrap' }}>
                                        {
                                            timings?.workingHours && timings?.workingHours?.map((item, i) => {
                                                return <Typography key={i} mb={1} variant='body2' className='timing'>{moment(item?.openingTime, 'hh:mm').format('HH:mm A')}&nbsp;&nbsp;-&nbsp;&nbsp;{moment(item?.closingTime, 'hh:mm').format('HH:mm A')}</Typography>
                                            })
                                        }
                                    </div>

                            // timings?.workingHours && <Typography variant='body2' className='timing'>{moment(timings?.workingHours?.openingTime, 'hh:mm').format('HH:mm A')}&nbsp;&nbsp;-&nbsp;&nbsp;{moment(timings?.workingHours?.closingTime, 'hh:mm').format('HH:mm A')}</Typography>
                        }
                        {/* <Typography variant='body2' sx={{ marginBottom: '10px' }}>Timings - Tuesday  (Today)</Typography>
                        <Typography variant='body2' className='timing'>9:30 AM - 5:00 PM</Typography> */}
                    </TabPanel>
                    <TabPanel value="2" sx={{ height: "165px", overflow: "scroll", padding: "10px 20px" }}>
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <Typography sx={{ color: '#5C5C5C' }}>Clinical</Typography>
                            <Typography >Qty</Typography>
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <Typography >Oxygen Ventilator</Typography>
                            <Typography>36</Typography>
                        </div>
                        <Typography sx={{ color: '#5C5C5C', marginTop: '10px' }}>Non Medical</Typography>
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <Typography>Bed</Typography>
                            <Typography>36</Typography>
                        </div>
                    </TabPanel>
                    <TabPanel value="3" sx={{ height: "165px", overflow: "scroll", padding: "10px 20px" }}>
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <Typography>Specialities Medical Consultation</Typography>
                            <Typography><BiChevronRightCircle /></Typography>
                        </div>
                    </TabPanel>
                </TabContext>
            </Box>
        </>
    )
}

const PublicOverview = () => {
    const [about, setabout] = useState(false)

    const [WorkingDays, setWorkingDays] = useState([])
    const [timings, setTimings] = useState({})
    const [images, setImages] = useState([])

    const [activeDay, setActiveDay] = useState(null)

    const [pageAdminId, setPageAdminId] = useState("")
    const [userOrg, setUserOrg] = useState({})
    const [allServices, setAllServices] = useState([])
    const [allspecialty, setAllSpeciality] = useState([])

    // const userOrg = useSelector(state => state.registration.userOrg)
    const location = useSelector(state => state.registration.location_withouttoken)

    const { id } = useParams()
    const dispatch = useDispatch()


    const [activeAccordion, setActiveAccordion] = useState(-1)

    const handleAccordion = (index) => {
        if (activeAccordion == index) {
            setActiveAccordion(-1)
            return
        }
        setActiveAccordion(index)
    }

    useEffect(() => {
        if (id) {
            axios.get(`${URL.getbyPageId}${id}`, headers()).then(res => {
                console.log(res.data?.entry?.[0]?.resource, '281')
                if (res?.data?.entry?.[0]?.resource?.id) {
                    dispatch(getLocation_withouttoken(res?.data?.entry?.[0]?.resource?.id))
                }
                setUserOrg(res.data?.entry?.[0]?.resource)
            }).catch(err => {
                console.log(err, "PagesByIdWithoutKeycloak")
            })
        }
    }, [id])

    useEffect(() => {
        defaultSpecialtyVal()
    }, [userOrg])

    const defaultSpecialtyVal = async () => {
        if (userOrg?.id) {
            D_API_FHIR_WITHOUT_TOKEN.get(
                `HealthcareService?organization=${userOrg?.id}`,
                headers()
            ).then((res) => {
                setAllSpeciality(res?.data?.entry[0]?.resource?.specialty);
                console.log(res?.data?.entry, "hhhhhhl");
            }).catch((err) => {
                console.log(err);
            });
        }
    };

    const headers = () => {
        return (
            {
                headers: {
                    'X-FHIR-TENANT-ID': 'docsteth',
                    'X-FHIR-DSID': 'fhirDocsteth',
                    'Realm': 'docsteth'
                }
            }
        )
    }


    useEffect(() => {
        if (location?.id) {
            D_WITHOUT_TOKEN_API.get(`Location/without_token/partof/${location.id}`, headers())
                .then(res => {
                    // console.log(res.data.entry,"check")
                    setAllServices(res.data.entry)
                }).catch((err) => console.log(err))
        }
    }, [location])


    useEffect(() => {
        if (location) {
            let data = []
            const date = new Date()
            const today = days[date.getDay() - 1]
            {
                location?.hoursOfOperation?.map((item, i) => {
                    let openingTime = item.openingTime
                    let closingTime = item.closingTime
                    if (item.allDay == true) {
                        (item.daysOfWeek.map((child) => {
                            data.push({ day: child, allDay: item.allDay, today: child == today ? true : false })
                        }))
                    } else if (item.allDay == false) {
                        (item.daysOfWeek?.map((child) => {
                            data.push({ day: child, allDay: item.allDay, today: child == today ? true : false })
                        }))
                    } else {
                        (item.daysOfWeek.map((child) => {
                            data.push({ day: child, workingHours: [{ openingTime: openingTime, closingTime: closingTime }], today: child == today ? true : false })

                        }))

                    }
                })
            }

            const mergedSchedule = [];

            for (let i = 0; i < data.length; i++) {
                let day = data[i].day;
                let hours = data[i].workingHours;
                let existingDay = mergedSchedule.find(item => item.day === day);
                if (existingDay) {
                    existingDay.workingHours = existingDay.workingHours.concat(hours);
                    // console.log(existingDay, "existingDay11")

                } else {
                    mergedSchedule.push(data[i]);
                }
            }
            const sorted = mergedSchedule.sort((a, b) => {
                return daysCompare[a.day] - daysCompare[b.day];
            })
            setWorkingDays(sorted)
        }

    }, [location])

    useEffect(() => {
        const date = new Date()
        const today = days[date.getDay() - 1]

        WorkingDays?.find((item) => {

            if (item.day == today) {
                setTimings(item)
            }
        })

    }, [WorkingDays])

    const getImagePost = () => {
        if (userOrg?.id) {
            // D_API.get(`https://devwebhooks.docsteth.com/api/v1/getImage/${userOrg.id}/${UserService.getUserId()}`, {
            DOC_API_WITHOUT_TOKEN.get(`getImageByOrgIdWithoutToken/${userOrg?.id}`, headers()
            ).then(res => {
                if (res?.data?.length > 0) {
                    setImages(res.data)
                }
            }).catch(err => {
                console.log(err)
            })
        }
    }


    useEffect(() => {
        getImagePost()
    }, [userOrg])

    const d = new Date();

    let isToday = days[d.getDay() - 1]

    return (
        // <div>publicOverview</div>
        <Grid container spacing={5}>

            <Grid item xs={12} sm={12} md={3}>
                <Card sx={{ padding: '1rem' }}>
                    <Typography variant='body2' sx={{ margin: '0' }}>Organization Type</Typography>
                    <Typography sx={{ marginBottom: '12px', fontWeight: '500', fontSize: '18px' }}>{userOrg?.type?.[0]?.coding?.[0]?.display}</Typography>

                    {
                        userOrg?.endpoint?.[0]?.display &&
                        <>
                            <div style={{ display: 'flex', alignItems: 'center', gap: '10px', fontSize: '13px' }}><PublicIcon sx={{ color: '#01CDE5' }} fontSize='small' /><Typography sx={{ color: '#5C5C5C' }}>Website</Typography></div>
                            <a href={`https://${userOrg?.endpoint?.[0]?.display}`} target='_blank' style={{ fontStyle: 'italic', fontSize: '16px', color: '#6F94E6' }}>{userOrg?.endpoint?.[0]?.display}</a>
                        </>
                    }

                    <div style={{ display: 'flex', gap: '10px', margin: '12px 0' }}>
                        <Card sx={{ display: 'flex', flex: 1 }} ><a className='call' href={`tel:${userOrg?.telecom?.[0]?.value}`}><img width="24px" height='17px' src={call} />Call</a></Card>
                        <Card sx={{ display: 'flex', flex: 1 }} ><a className='call' href={`mailto:${userOrg?.telecom?.[1]?.value}`}><img width="24px" height='17px' src={mail} />Email</a></Card>
                    </div>

                    <Card sx={{ margin: '12px 0' }}><a className='direction' target='_blank' href={`https://www.google.com/maps?q=${userOrg?.address?.[0]?.line[3]?.split(",")[0]},${userOrg?.address?.[0]?.line[3]?.split(",")[1]}`}><img width="24px" height='17px' src={mail} /><Typography sx={{ color: '#0243D6', fontWeight: '500', }}>Get directions</Typography></a></Card>

                    <div className='workinghours'><img width="24px" height='17px' src={org} /><Typography sx={{ color: '#5C5C5C' }}>Working Hours</Typography></div>

                    <div style={{ display: 'flex', gap: '5px', overflowY: 'scroll', margin: '12px 0' }}>
                        {WorkingDays?.map((item, i) => {
                            // return <Button variant='contained' onClick={() => setTimings(item)} disabled={!item.allDay && !item.hasOwnProperty('workingHours')} sx={{ minWidth: "25px", background: item.today ? "black" : '', padding: '0px 7px', }}><Typography variant="caption" sx={{ color: 'white', textTransform: 'capitalize' }}>{item.day}</Typography></Button>
                            return <Button key={i} variant='contained' onClick={() => { setTimings(item); setActiveDay(i) }} sx={{ minWidth: "25px", background: activeDay == i && activeDay !== null ? "black" : item.today && activeDay == null ? "black" : !item.allDay && !item.hasOwnProperty('workingHours') ? "#A9A9A9" : '#97FC4', padding: '0px 7px', }}><Typography variant="caption" sx={{ color: 'white', textTransform: 'capitalize' }}>{item.day}</Typography></Button>

                        })}
                    </div>


                    {/* {timings?.allDay || timings?.workingHours && <Typography variant='body2'>Timings</Typography>} */}
                    {timings.day &&
                        <Typography variant='body2' sx={{ marginBottom: "5px" }}>
                            Timings - {timings?.day == "mon" ? "Monday" : timings?.day == "tue" ? "Tuesday" : timings?.day == "wed" ? "Wednesday" : timings?.day == "thu" ? "Thursday" : timings?.day == "fri" ? "Friday" : timings?.day == "sat" ? "Saturday" : timings?.day == "sun" ? "Sunday" : ""} {timings?.day == isToday ? "(Today)" : ""}
                        </Typography>
                    }
                    {
                        timings && timings.allDay ?
                            <Typography variant='body2' className='timing' >All Day</Typography>
                            : timings.allDay == false ? <Typography variant='body2' className='timing'>No Slot avalable</Typography>
                                : timings?.workingHours && timings?.workingHours?.map((item, i) => {
                                    return <Typography key={i} mb={1} variant='body2' className='timing'>{moment(item?.openingTime, 'hh:mm').format('HH:mm A')}&nbsp;&nbsp;-&nbsp;&nbsp;{moment(item?.closingTime, 'hh:mm').format('HH:mm A')}</Typography>
                                })
                    }

                    {/* 
                    <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '30px' }}>
                        <img src={facebook} alt="facebook" />
                        <img src={linkedin} alt="linkedin" />
                        <img src={twitter} alt="twitter" />
                        <img src={whatsapp} alt="whatsapp" />
                        <img src={youtube} alt="youtube" />
                    </div> */}


                </Card>

                <PublicFacilities />

            </Grid>

            <Grid item xs={12} sm={12} md={9}>
                <Typography mt={2} variant='h5' sx={{ color: '#6E5989', fontWeight: "600" }}>About Hospital</Typography>

                {/* <Typography mt={2} variant='h5' sx={{ color: '#6E5989', fontWeight: "600" }}>About Hospital &nbsp;&nbsp;<EditIcon sx={{cursor:'pointer'}} onClick={()=>setEditAbout(!editAbout)}/> </Typography>
                {editAbout && 
                <div style={{display:'flex',alignItems:'center',gap:'10px'}}>
                <TextField value={editAboutText} onChange={(e)=>setEditAboutText(e.target.value)}></TextField>
                <Button fontSize="small" variant='contained' onClick={editUpdate}>Update</Button>
                </div>
                } */}

                {/* {userOrg?.alias?.[0] &&
                    <Typography my={1}>{about ? userOrg?.alias[0] : `${userOrg?.alias[0].slice(0, 400)} ${userOrg?.alias[0].length > 400 ? "..." : ''}`}
                        {userOrg?.alias[0].length > 400 && <Button sx={{ textTransform: 'capitalize', padding: '0 !important', paddingLeft: '10px !important', color: '#393B64', fontWeight: '600' }} onClick={() => setabout(!about)}>{about ? "Learn less" : "Learn more"}</Button>}
                    </Typography>
                } */}

                {location?.description &&

                    <Typography my={1}>

                        <div
                            dangerouslySetInnerHTML={{ __html: about ? location?.description : location?.description.slice(0, 500) }}
                            style={{ whiteSpace: 'pre-wrap' }}
                        />
                        {location?.description.length > 400 &&
                            <Button sx={{ textTransform: 'capitalize', padding: '0 !important', paddingLeft: '10px !important', color: '#393B64', fontWeight: '600' }} onClick={() => setabout(!about)}>{about ? "Learn less" : "Learn more"}</Button>}
                    </Typography>
                }

                {images.length > 0 &&
                    <>
                        <Typography variant='h6' mb={1} mt={2} sx={{ color: '#3084BE' }}><img src={Hospital} height="18" /> &nbsp;Facility Tour</Typography>
                        <Grid container>
                            <Grid item xs={12} sm={12} md={9}>
                                <Photo url={images} id={id} />
                            </Grid>
                        </Grid>
                    </>
                }

                {/* Specialities */}
                {allspecialty?.length > 0 &&
                    <>
                        <Typography variant='h6' sx={{ color: '#750083', fontWeight: "600", display: 'flex', alignItems: 'center', margin: '12px 0', marginTop: "15px !important" }}><img src={Stethoscope} />&nbsp;&nbsp;Specialities</Typography>
                        <Grid container mt={1} mb={3} spacing={2}>
                            {allspecialty?.map((item, index) =>
                                <Grid item md={2} >
                                    {console.log(item)}
                                    <Box className="box">
                                        <img src={Stethoscope} alt='Stethoscope' width="30%" height="30%" />
                                        <Typography sx={{ position: 'absolute', bottom: '10px', fontSize: "12px", textAlign: 'center' }}  >{item.text}</Typography>
                                    </Box>
                                </Grid>
                            )}
                        </Grid>
                    </>
                }
                {/* Service Area */}
                {/* <Typography variant='h6' sx={{ color: '#750083', fontWeight: "600", display: 'flex', alignItems: 'center', margin: '12px 0', marginTop: "15px !important" }}><img src={AMINITIES} />&nbsp;&nbsp;Service Area</Typography>

                <Grid container spacing={2}>
                    {allServices?.map((item, index) => {
                        return (
                            <Grid key={index} item xs={12} sm={12} md={6}>
                                <Card >
                                    <CardContent>
                                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                            <Tooltip title={item?.resource?.name} placement="top-start">
                                                <Typography variant='h6' sx={{ fontWeight: '600', fontSize: "18px", width: "300px", whiteSpace: "nowrap", overflow: 'hidden', textOverflow: 'ellipsis' }}>{item?.resource?.name}</Typography>
                                            </Tooltip>
                                            {item?.resource?.status && <Typography sx={{ display: 'flex', alignItems: 'center', color: '#0B6F07' }}><TaskAltIcon fontSize='small' />&nbsp;{item?.resource?.status}</Typography>}
                                        </div>
                                        <div style={{ marginTop: "8px", display: 'flex', justifyContent: 'space-between', alignItems: "center", height: "2em", }}>
                                            <Typography>{item?.resource?.name}</Typography>
                                            <div style={{ display: 'flex', gap: '15px' }}>
                                                <img src={call} alt="call" />
                                                <img src={mail} alt="call" />
                                            </div>
                                        </div>
                                    </CardContent>
                                    <Button onClick={() => handleAccordion(index)} sx={{ display: activeAccordion == index ? "none" : "block", width: "100%", borderRadius: 0, background: 'rgba(52, 143, 198, 0.1)', color: "#197FC4", ':hover': { background: 'rgba(52, 143, 198, 0.1)', color: "#197FC4", }, textTransform: "capitalize" }} variant='contained'>Learn More</Button>
                                    <Box sx={{ display: activeAccordion == index ? "block" : "none" }}>
                                        <TabsComponent hoursOfOperation={item.resource.hoursOfOperation} id={item.resource.id} />
                                    </Box>
                                </Card>
                            </Grid>

                        )
                    })}

                </Grid> */}

            </Grid>
        </Grid>
    )
}

export default PublicOverview