import React, { useEffect, useState } from 'react';
import { Grid, Button, Box } from "@mui/material";
import { Autocomplete } from '@mui/material';
import TextField from '@mui/material/TextField';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { getMetaData } from '../../../redux/Actions/actions';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import blackUpload from '../../../icons/blackupload.svg'
import InputField from '../../Fields/TextFields';
import axios from 'axios';
import Dialog from "@mui/material/Dialog";
import CssTextField from '../../Fields/CssTextField';
import Snackbar from '@mui/material/Snackbar';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import DeleteIcon from '@mui/icons-material/Delete';
import CloseIcon from '@mui/icons-material/Close';
import URL from '../../apiurl'
import { Link } from 'react-router-dom';
import { userProfile } from '../../../redux/Actions/actions';
// import { useSelector } from 'react-redux';
import { useDispatch, useSelector } from 'react-redux';
import ErrorIcon from '@mui/icons-material/Error';
import './profile.css';
import { document } from '../../../redux/Actions/actions'



function Verifyprofile(props) {
    const [personal, setPersonal] = useState([])
    const [professional, setProfessional] = useState([])
    const [idpersonal, setIdpersonal] = useState(0)
    const [idprofessional, setIdprofessional] = useState(0)
    const [uploadpersonal, setUploadperonal] = useState(false)
    const [uploadprofessional, setUploadprofessional] = useState(false)
    const [form1, setForm1] = useState("")
    const [form2, setForm2] = useState("")
    const [form3, setForm3] = useState("")
    const [form4, setForm4] = useState("")
    const [name, setName] = useState("")
    const [filename, setFilename] = useState("")
    const [open, setOpen] = React.useState(false);
    const [edit, setEdit] = useState(false)
    const [edit1, setEdit1] = useState(false)
    const [filesize, setFilesize] = useState(0)
    const [filetype, setFiletype] = useState("")
    const [image, setImage] = useState("")
    const [alert, setAlert] = useState({ open: false, msg: '' })
    const category = ["Aadhar", "Electrol ID", "Tax ID"]
    const category1 = ["Practice licence", "Degree certificate", "student ID"]
    // const setdata = useSelector(state => state.userProfile.userProfile)
    // const dispatch = useDispatch()

    const [state, setState] = useState({
        idtype: "",
        issuedby: "",
        idnumber: "",
    })
    const [val, setVal] = useState({
        idtype: "",
        issuedby: "",
        idnumber: "",
    })

    // useEffect(() => {
    //     dispatch(document())
    // }, [])

    // useEffect(() => {
    //     dispatch(document())
    // }, [recall, recall1])


    // const getdocumentdata = useSelector(state => state.userProfile.document)
    // console.log("line73", getdocumentdata)

    const handleChange = (name, value) => {
        setState({ ...state, [name]: value })
    }
    const handleform = (name, value) => {
        setVal({ ...val, [name]: value })
    }

    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    const imageHandler = (e, imgFile) => {
        if (e.target.files && e.target.files[0]) {
            const reader = new FileReader();
            const fsize = e.target.files[0].size
            const filename = e.target.files[0].name
            // console.log(filename)
            let type = e.target.files[0].type.split("/");
            // console.log(type)
            reader.readAsDataURL(e.target.files[0]);
            reader.onload = x => {
                let file = [];
                file.push(reader.result.split(',')[1])
                handleimagesubmit(file, type[1], fsize, filename, imgFile)
            }
        }
    };
    const handleimagesubmit = (Image, type, fsize, filename, imgFile) => {
        let picdata = {
            "file": Image,
            "fileType": type,
            "multiple": false,
            "subType": "profilePic",
            "fileSize": fsize,
            "type": "userProfilePics"
        }
        let auth = `Bearer ${sessionStorage.getItem('kctoken')}`;
        axios.post(`${process.env.REACT_APP_API_URL}v1/upload/file`, picdata, { headers: { "Authorization": auth } }).then(res => {
            if (res.data.status === 200) {
                setAlert({ open: true, msg: "Uploaded Succesfully" });
                setImage(res.data.data[0])
                setFilesize(fsize)
                setFiletype(type)
                if (imgFile === 'personal') {
                    // console.log("personal")
                    setName(filename)
                    setUploadperonal(true)
                }
                if (imgFile === 'professional') {
                    setFilename(filename)
                    setUploadprofessional(true)
                }
            }
        })
    }

    const handleadddocument = async () => {
        const userId = `${sessionStorage.getItem("userId")}`
        let data = {
            "dateTime": new Date(),
            "active": true,
            "reason": "",
            "documentAddress": image,
            "documentName": state.idtype,
            "issuedBy": state.issuedby,
            "documentId": state.idnumber,
            "id": 0,
            "kycStatus": "PENDING",
            "metadata": await getMetaData(),
            "fileSize": filesize,
            "fileType": filetype,
            "userId": Number(userId),
            "formType": "personal",
            "reviewedBy": 0,
            "pageId": 0
        }
        let auth = `Bearer ${sessionStorage.getItem('kctoken')}`;
        axios.post(`${process.env.REACT_APP_API_URL}v1/addDocument`, data, { headers: { "Authorization": auth } }).then(res => {
            // console.log(res.data)
            if (res.data.status === 201 || 200) {
                setAlert({ open: true, msg: "Submitted Succesfully" });
                getstatus()
            }
        })
        setImage("")
        setFilesize(0)
        setFiletype("")
    }
    const handledocumentedu = async () => {
        const userId = `${sessionStorage.getItem("userId")}`
        let data = {
            "dateTime": new Date(),
            "documentAddress": image,
            "documentName": val.idtype,
            "active": true,
            "reason": "",
            "issuedBy": val.issuedby,
            "documentId": val.idnumber,
            "id": 0,
            "kycStatus": "PENDING",
            "metadata": await getMetaData(),
            "fileSize": filesize,
            "fileType": filetype,
            "userId": Number(userId),
            "formType": "professional",
            "reviewedBy": 0,
            "pageId": 0
        }
        let auth = `Bearer ${sessionStorage.getItem('kctoken')}`;
        axios.post(`${process.env.REACT_APP_API_URL}v1/addDocument`, data, { headers: { "Authorization": auth } }).then(res => {
            if (res.data.status === 201 || 200) {
                setAlert({ open: true, msg: "Submitted Succesfully" });
                getstatus()
            }
        })
        setImage("")
        setFilesize(0)
        setFiletype("")
    }
    const handlesubmit = () => {
        if (image.length > 0) {
            handleadddocument()
        }
    }
    const handlesubmitform = () => {
        if (image.length > 0) {
            handledocumentedu()
        }
    }
    const handledelete = () => {
        setImage("")
        setFilesize(0)
        setFiletype("")
        setUploadperonal(false)
    }
    const handledelete1 = () => {
        setImage("")
        setFilesize(0)
        setFiletype("")
        setUploadprofessional(false)
    }

    const getstatus = () => {
        const userId = `${sessionStorage.getItem("userId")}`
        let auth = `Bearer ${sessionStorage.getItem('kctoken')}`;
        axios.get(`${URL.getdocument}/${userId}`, { headers: { "Authorization": auth } }).then(res => {
            // console.log(res)
            // console.log(res.data.data)
            if (res.data.data.length > 0) {
                if (res.data.data[0].formType === "personal") {
                    // console.log(res.data.data[0].formType, "print")
                    setForm1("personal")
                    setForm3(res.data.data[0].status)
                    setIdpersonal(res.data.data[0].id)
                    setPersonal(res.data.data[0])
                }
                if (res.data.data[0].formType === "professional") {
                    // console.log(res.data.data[0].formType, "professional")
                    setForm2("professional")
                    setForm4(res.data.data[0].status)
                    setIdprofessional(res.data.data[0].id)
                    setProfessional(res.data.data[0])
                }
            }
            if (res.data.data.length > 0) {
                if (res.data.data[1].formType === "personal") {
                    // console.log(res.data.data[1].formType, "personal")
                    setForm1("personal")
                    setForm3(res.data.data[1].status)
                    setIdpersonal(res.data.data[1].id)
                    setPersonal(res.data.data[1])
                }
                if (res.data.data[1].formType === "professional") {
                    // console.log(res.data.data[1].formType, "professional")
                    setForm2("professional")
                    setForm4(res.data.data[1].status)
                    setIdprofessional(res.data.data[1].id)
                    setProfessional(res.data.data[1])
                }
            }

        })
    }

    useEffect(() => {
        getstatus()
    }, [])



    const handleditpersonal = async () => {
        const userId = `${sessionStorage.getItem("userId")}`
        // console.log(personal)
        let data = {
            "dateTime": new Date(),
            "active": false,
            "id": idpersonal,
            "documentAddress": personal.documentAddress,
            "documentName": personal.documentName,
            "issuedBy": personal.issuedBy,
            "documentId": personal.documentId,
            "kycStatus": "PENDING",
            "metadata": await getMetaData(),
            "fileSize": personal.fileSize,
            "fileType": personal.fileType,
            "userId": Number(userId),
            "formType": "personal",
            "reviewedBy": 0,
            "pageId": 0
        }
        // console.log(data)
        let id = idpersonal
        let auth = `Bearer ${sessionStorage.getItem('kctoken')}`;
        axios.put(`${process.env.REACT_APP_API_URL}v1/updateUserKycDocumentsById/${id}`, data, { headers: { "Authorization": auth } }).then(res => {
            if (res.data.status === 201 || 200) {
                setAlert({ open: true, msg: "Re-Submit" });
                getstatus()
                setForm3("")
            }
        })
    }
    // console.log(professional)
    const handleditprofessional = async () => {
        const userId = `${sessionStorage.getItem("userId")}`
        let data = {
            "dateTime": new Date(),
            "active": false,
            "id": idprofessional,
            "documentAddress": professional.documentAddress,
            "documentName": professional.documentName,
            "issuedBy": professional.issuedBy,
            "documentId": professional.documentId,
            "kycStatus": "PENDING",
            "metadata": await getMetaData(),
            "fileSize": professional.fileSize,
            "fileType": professional.fileType,
            "userId": Number(userId),
            "formType": "professional",
            "reviewedBy": 0,
            "pageId": 0
        }
        let id = idprofessional
        let auth = `Bearer ${sessionStorage.getItem('kctoken')}`;
        axios.put(`${process.env.REACT_APP_API_URL}v1/updateUserKycDocumentsById/${id}`, data, { headers: { "Authorization": auth } }).then(res => {
            if (res.data.status === 201 || 200) {
                setAlert({ open: true, msg: "Re-Submit" });
                getstatus()
                setForm4("")
            }
        })
    }

    // useEffect(() => {
    //     if (getdocumentdata.length > 0 && getdocumentdata[1].formType === "professional") {
    //         setShow1(true)
    //     }
    // }, [recall1])

    return (
        <div className='scroll_y_direction'>
            <Grid container>
                <Grid item md={12} xs={12} sm={12}>
                    <div>
                        <div className='toggle_clear'>
                            <CloseIcon className='cursor' onClick={props.toggleDrawer} />
                        </div>
                        <div>
                            <h4 id="heading_verify">Profile Verification</h4>
                            <h4 className='verify_heading_text'>Help Keep this network, safe from spam users,fake news & misinformation</h4>
                        </div>
                    </div>
                </Grid>

                {(form3.length === 0) ?
                    <Grid item md={12} xs={12} sm={12} className="kyc_margin">
                        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", padding: "10px" }}>
                            <div>
                                <h5 className='kyc_text'>Personal KYC Verification </h5>
                            </div>
                            <div>
                                <InfoOutlinedIcon />
                            </div>
                        </div>
                        <h3 className='government_text'>Use Any Government Issued Photo ID (Aadhar,Electrol ID,Tax ID) </h3>
                        <ValidatorForm
                            onSubmit={handlesubmit}
                            style={{ marginTop: "8px" }}
                            onError={errors => { }}
                        >
                            <Grid container style={{ marginTop: "-12px" }}>
                                {/* <Grid item md={1}></Grid> */}
                                <Grid item md={5} sm={5} xs={12} className="id_type_padding">
                                    <Autocomplete
                                        disablePortal
                                        id="combo-box-demo"
                                        options={category}
                                        isOptionEqualToValue={(option, value) => option.value === value || option.value === value.value || ''}
                                        getOptionLabel={(option) => option}
                                        onChange={(event, newValue) => {
                                            // console.log(newValue, "data")
                                            handleChange('idtype', newValue)
                                        }}
                                        value={state.idtype || null}
                                        renderInput={(params) => (
                                            <CssTextField {...params}
                                                label="Select ID Type"
                                                placeholder="Select ID Type"
                                                validators={['required']}
                                                errorMessages={['Please Select ID Type']}
                                                value={state.idtype || null}
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid item md={1} sm={1}></Grid>
                                <Grid item md={5} sm={5} xs={12} className="issued_margin">
                                    <InputField
                                        size='small'
                                        id="margin-none"
                                        label="Issued By"
                                        name="issuedby"
                                        placeholder="Enter Issuedby"
                                        value={state.issuedby}
                                        // handleChange={(e) => handleChange("issuedby", e.target.value)}
                                        handleChange={handleChange}
                                        validators={['required']}
                                        errorMessages={['Please Enter Issued By']}
                                    />
                                </Grid>
                                <Grid item md={1} sm={1} ></Grid>
                            </Grid>
                            <Grid container style={{ display: "flex", alignItems: "center" }} className="responsive_input_margin">
                                <Grid item md={5} sm={5} xs={6} className="id_number_margin">
                                    <InputField
                                        // validators={['required']}
                                        // errorMessages={['Please Enter Title']}
                                        label="ID number"
                                        placeholder="Enter ID number"
                                        size='small'
                                        id="margin-none"
                                        value={state.idnumber}
                                        name="idnumber"
                                        handleChange={handleChange}
                                    // handleChange={(e) => handleChange("idnumber", e.target.value)}
                                    />
                                </Grid>
                                <Grid item md={1} sm={1}>

                                </Grid>
                                <Grid item md={5} sm={5} xs={6} className="upload_btn">
                                    {/* {console.log(uploadpersonal, "ghj")} */}
                                    {uploadpersonal ?
                                        <div style={{ display: "flex", justifyContent: "space-evenly", alignItems: "center" }}>
                                            <h1 className='tag_name'>{name ? name : ""}</h1>
                                            <button className='del_btn' onClick={handledelete}><DeleteIcon /></button>
                                        </div> :
                                        <div>
                                            <input type="file" name="uploadfile" multiple id="post-img2" accept=".png, .jpg, .jpeg, .pdf" style={{ display: 'none' }}
                                                onChange={(e) => imageHandler(e, 'personal')}
                                            />
                                            <label htmlFor="post-img2" className="cursor">
                                                <div className="inputOption" style={{ display: "flex" }}>
                                                    <img src={blackUpload} alt="media" width="20px" height="20px" />
                                                    <div className='upload_text'>Upload File</div>
                                                </div>
                                            </label>
                                        </div>
                                    }
                                </Grid>
                                <Grid item md={1} sm={1} xs={12}>

                                </Grid>
                            </Grid>
                            <Grid item md={12} xs={12}>
                                <div style={{ textAlign: 'right', margin: '5px 10px 0px 0px ', display: "flex", justifyContent: "flex-end" }}>
                                    <Button type="submit" className='form1_submit cursor'>submit</Button>
                                </div>
                            </Grid>
                        </ValidatorForm>
                    </Grid>
                    :
                    <Grid container className='kyc_margin'>
                        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", padding: "0px 0px 8px 10px" }}>
                            <h5 className='kyc_text'>Personal KYC Verification </h5>
                        </div>
                        <h3 className='government_text'>Use Any Government Issued Photo ID (Aadhar,Electrol ID,Tax ID) </h3>
                        <Grid item md={12} xs={12}>
                            <h4 className='submt_text'>Submited</h4>
                        </Grid>
                        {(form3 === "PENDING") ?
                            (<div style={{ padding: "10px 20px" }}>
                                <h3 className='await_text'>Awaiting Verification</h3>
                            </div>)
                            :
                            (form3 === "REJECTED") ?
                                <Grid item md={12} xs={12} style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                    <Grid item md={12} xs={12} style={{ display: "flex", justifyContent: "space-around", alignItems: "center", marginTop: "8px" }}>
                                        <div style={{ display: "flex", alignItems: "center" }}>
                                            <span className='await_text'>Verification Failed <ErrorIcon className='error_icon1' /></span>
                                        </div>
                                        <div>
                                            <Button onClick={handleditpersonal} type='submit' className='re_submit cursor'>Re-Submit</Button>
                                        </div>
                                    </Grid>
                                </Grid>
                                :
                                (form3 === "APPROVED") ?
                                    <Grid item md={12} xs={12} style={{ padding: "8px 0px 0px 23px" }}>
                                        <span className='verify_text_success'>Verified Successfully</span><TaskAltIcon className='task_icon' />
                                    </Grid> : ""
                        }
                    </Grid>
                }
                {/* ---------- Form2 ----------*/}


                <Grid item md={12} xs={12} sm={12} className="kyc_margin">
                    {(form4.length === 0) ?
                        <Grid item md={12} xs={12} sm={12}>
                            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", padding: "10px" }}>
                                <div>
                                    <h5 className='kyc_text'>Professional KYC Verification </h5>
                                </div>
                                <div>
                                    <InfoOutlinedIcon />
                                </div>
                            </div>
                            <h3 className='government_text'>Practice Licence,Degree certificate, student ID,Institutional Email </h3>
                            <ValidatorForm
                                onSubmit={handlesubmitform}
                                style={{ marginTop: "8px" }}
                            >
                                <Grid container style={{ marginTop: "-12px" }}>
                                    {/* <Grid item md={1}></Grid> */}
                                    <Grid item md={5} sm={5} xs={12} className='id_type_padding'>
                                        <Autocomplete
                                            disablePortal
                                            id="combo-box-demo"
                                            options={category1}
                                            isOptionEqualToValue={(option, value) => option.value === value || option.value === value.value || ''}
                                            getOptionLabel={(option) => option}
                                            onChange={(event, newValue) => {
                                                // console.log(newValue, "data")
                                                handleform('idtype', newValue)
                                            }}
                                            value={val.idtype || null}
                                            renderInput={(params) => (
                                                <CssTextField {...params}
                                                    label="Select ID Type"
                                                    placeholder="Select ID Type"
                                                    validators={['required']}
                                                    errorMessages={['Please Select ID Type']}
                                                    value={val.idtype || null}
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item md={1} sm={1}></Grid>
                                    <Grid item md={5} sm={5} xs={12} className="issued_margin">
                                        <InputField
                                            className='input_width_xs'
                                            size='small'
                                            id="margin-none"
                                            label="Issued By"
                                            name="issuedby"
                                            placeholder="Enter Issuedby"
                                            value={val.issuedby}
                                            handleChange={handleform}
                                            // handleChange={(e) => handleform("issuedby", e.target.value)}
                                            validators={['required']}
                                            errorMessages={['Please Enter Issued By']}
                                        />
                                    </Grid>
                                    <Grid item md={1} sm={1}></Grid>
                                </Grid>
                                <Grid container style={{ display: "flex", alignItems: "center" }} className="responsive_input_margin">
                                    <Grid item md={5} sm={5} xs={6} className="id_number_margin">
                                        <InputField
                                            // validators={['required']}
                                            // errorMessages={['Please Enter Title']}
                                            className='input_width_xs'
                                            size='small'
                                            id="margin-none"
                                            label="ID number"
                                            name="idnumber"
                                            placeholder="Enter ID number"
                                            value={val.idnumber}
                                            handleChange={handleform}
                                        // handleChange={(e) => handleform("idnumber", e.target.value)}
                                        />
                                    </Grid>
                                    <Grid item md={1} sm={1}>

                                    </Grid>
                                    <Grid item md={5} sm={5} xs={6} className="upload_btn">
                                        {uploadprofessional ?
                                            <div style={{ display: "flex", justifyContent: "space-evenly", alignItems: "center" }}>
                                                <h1 className='tag_name'>{filename ? filename : ""}</h1>
                                                <button className='del_btn' onClick={handledelete1}><DeleteIcon /></button>
                                            </div>
                                            :
                                            <div>
                                                <input type="file" name="uploadfile" multiple id="post-img1" accept=".png, .jpg, .jpeg, .pdf" style={{ display: 'none' }}
                                                    onChange={(e) => imageHandler(e, "professional")}
                                                />
                                                <label htmlFor="post-img1" className="cursor">
                                                    <div className="inputOption" style={{ display: "flex" }}>
                                                        <img src={blackUpload} alt="media" width="20px" height="20px" />
                                                        <div className='upload_text'>Upload File</div>
                                                    </div>
                                                </label>
                                            </div>
                                        }

                                    </Grid>
                                    <Grid item md={1}>

                                    </Grid>
                                </Grid>
                                <Grid item md={12} xs={12}>
                                    <div style={{ textAlign: 'right', margin: '5px 10px 0px 0px ', display: "flex", justifyContent: "flex-end" }}>
                                        <Button type="submit" className='form1_submit cursor'>submit</Button>
                                    </div>
                                </Grid>
                            </ValidatorForm>
                        </Grid>
                        :
                        <Grid container>
                            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", padding: "0px 0px 8px 10px" }}>
                                <div>
                                    <h5 className='kyc_text'>Professional KYC Verification </h5>
                                </div>
                            </div>
                            <h3 className='government_text'>Practice Licence,Degree certificate, student ID,Institutional Email </h3>
                            <Grid item md={12} xs={12}>
                                <h4 className='submt_text'>Submited</h4>
                            </Grid>
                            {(form4 === "PENDING") ?
                                (
                                    <>
                                        <div style={{ padding: "10px 20px" }}>
                                            <h3 className='await_text'>Awaiting Verification</h3>
                                        </div>
                                    </>
                                )
                                :
                                (form4 === "REJECTED") ?
                                    <Grid item md={12} xs={12} style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                        <Grid item md={12} xs={12} style={{ display: "flex", justifyContent: "space-around", alignItems: "center", marginTop: "8px" }}>
                                            <div style={{ display: "flex", alignItems: "center" }}>
                                                <span className='await_text'>Verification Failed <ErrorIcon className='error_icon1' /></span>
                                            </div>
                                            <div>
                                                <Button onClick={handleditprofessional} type='submit' className='re_submit cursor'>Re-Submit</Button>
                                            </div>
                                        </Grid>
                                    </Grid>
                                    :
                                    (form4 === "APPROVED") ?
                                        <Grid item md={12} xs={12} style={{ padding: "8px 0px 10px 23px" }}>
                                            <span className='verify_text_success'>Verified Successfully</span><TaskAltIcon className='task_icon' />
                                        </Grid> : ""
                            }
                        </Grid>
                    }
                </Grid>
            </Grid>
            <Grid container className='need_profile'>
                <Grid item md={12} xs={12}>
                    <div className='need_heading'>
                        <h4 className='need_help_text'>Need Help ?</h4>
                        <div className='need_display'>
                            <a href="/privacypolicy" target="_blank"><h2 style={{ padding: "8px 0px 0px 0px" }} className='privacY_text cursor'>Privacy and Security</h2></a>
                            <h2 style={{ padding: "8px 0px 0px 0px" }} className='privacY_text cursor'>Verification Process</h2>
                            <h2 onClick={handleClickOpen} style={{ padding: "8px 0px 0px 0px" }} className='privacY_text cursor'>Contact us</h2>
                        </div>
                    </div>
                </Grid>
            </Grid>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <Box className='contact_details'>
                    <div className='space_btw_center'>
                        <h5>Contact Details</h5>
                    </div>
                    <div className='contact_info_margin'>
                        <div style={{ display: "flex", justifyContent: "space-between", padding: "10px" }}>
                            <h3 className='phone_text'>
                                Phone:
                            </h3>
                            <h2 className='mobile_num'>+91 911 911 4934</h2>
                        </div>
                        <div style={{ display: "flex", justifyContent: "space-between", padding: "10px" }}>
                            <h3 className='phone_text'>
                                Email:
                            </h3>
                            <h2 className='email_support'>connect@docsteth.com</h2>
                        </div>
                    </div>
                </Box>
            </Dialog>
            <Snackbar
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                autoHideDuration={2000}
                open={alert.open}
                onClose={() => setAlert({ open: false, msg: '' })}
                message={alert.msg}
            />
        </div >
    )
}

export default Verifyprofile