import React, { Fragment, useEffect, useState } from 'react'
// import { Dialog, Grid } from '@mui/material';
import { Avatar, Button, Card, Checkbox, Dialog, DialogContent, DialogTitle, Fab, FormControlLabel, FormGroup, Grid, IconButton, Menu, MenuItem, Tab, Typography, CircularProgress, Snackbar, DialogActions } from '@mui/material'
import { useSelector } from 'react-redux';
import { format, parseISO } from 'date-fns';
import axios from 'axios';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
// import icons
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
// import {OrgLayout,FormDivider} from '../common/components/layout';
import { FiLink } from 'react-icons/fi';
import { FiSend } from 'react-icons/fi';
import { BsMessenger } from 'react-icons/bs';
import { ValidatorForm } from 'react-material-ui-form-validator';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import SmsIcon from '@mui/icons-material/Sms';
import MailIcon from '@mui/icons-material/Mail';
import SearchIcon from '@mui/icons-material/Search';
import { getHealthServices, getHealthServicesData } from '../../../common/functions/orgfun'
import { FormDivider, OrgLayout } from '../../../common/components/layout'
import URL from '../../../apiurl'
import API, { DOC_API, DOC_API_WITHOUT_TOKEN, D_API, D_API_FHIR } from '../../../../services/APIService';
import UserService from '../../../../services/UserService';
import { URI } from '../../../utility/uri';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

// import PractitionerRoleAdding from './PractitionerRoleAdding';
import WorkhoursPractitioner, { PractitionerAvailability, PractitionerRoleAdding, ServiceInfo } from './PractitionerRoleAdding';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import CssTextField from '../../../Fields/CssTextField';
import dayjs from 'dayjs';
// import Workhours from './AdminOrganization/Workhours';
import PhoneInput from '../../../common/inputs/phoneInput';
import CustomInput from '../../../common/inputs/customInput';
import WorkhoursAmenities from './amenities/WorkhoursAmenities';

import moment from 'moment';
import { deleteKeys } from '../../../common/functions/functions';
import DoctorsInfoCard from '../../../common/components/doctorsInfoCard';
import { userProfile } from '../../../../redux/Actions/actions';
import { useDispatch } from 'react-redux';
import { headers } from '../../../../redux/Actions/connectActions';
import { AddCircleOutlined, Close, KeyboardArrowDown, Delete } from '@mui/icons-material';
import DeleteIcon from '@mui/icons-material/Delete';
import CloseIcon from '@mui/icons-material/Close';
import Skeleton from '@mui/material/Skeleton';

const prRole = [

    {
        "code": "doctor",
        "display": "Doctor",
        "system": "http://terminology.hl7.org/CodeSystem/practitioner-role"
    },
    {
        "code": "nurse",
        "display": "Nurse",
        "system": "http://terminology.hl7.org/CodeSystem/practitioner-role"
    },
    {
        "code": "pharmacist",
        "display": "Pharmacist",
        "system": "http://terminology.hl7.org/CodeSystem/practitioner-role"
    },
    {
        "code": "researcher",
        "display": "Researcher",
        "system": "http://terminology.hl7.org/CodeSystem/practitioner-role"
    },
    {
        "code": "teacher",
        "display": "Teacher/educator",
        "system": "http://terminology.hl7.org/CodeSystem/practitioner-role"
    },
    {
        "code": "ict",
        "display": "ICT professional",
        "system": "http://terminology.hl7.org/CodeSystem/practitioner-role"
    },

]

const formatPh = (value) => value?.trim()?.replace(/\+91 /, '')?.replace(/-/g, '') || ''


const ContactPoint = ({ name, handleChange, type, value, i, deleteContact, ConDetails, onMouseOvers, onMouseLeaves }) => {
    const [isHovered, setIsHovered] = useState(false);

    console.log(value, 'vgvg')
    // const deleteContactss = (i) => {
    //     console.log(i)
    // }
    return (
        <Grid item xs={12} md={12} container spacing={2} >
            {type === 'phone' ?
                <>
                    <Grid item xs={3.2}>
                        <label style={{ paddingLeft: '10px' }}>{name}</label>
                        {/* <PhoneInput name="value" onMouseOver={onMouseOvers} onMouseLeave={onMouseLeaves} handleChange={handleChange} value={value} placeholder="Phone Number" required={i === 0} errorMessages={i === 0 ? ['Enter Phone Number'] : []} /> */}
                    </Grid>
                    <Grid item xs={7.5}>
                        {/* <label>{name}</label> */}
                        <PhoneInput name="value" onMouseOver={onMouseOvers} onMouseLeave={onMouseLeaves} handleChange={handleChange} value={value} placeholder="Phone Number" required={i === 0} errorMessages={i === 0 ? ['Enter Phone Number'] : []} />
                    </Grid>
                </>
                : type === 'email' &&
                <>
                    <Grid item xs={3.2}>
                        <label style={{ paddingLeft: '10px' }}>{name}</label>
                        {/* <CustomInput name="value" handleChange={handleChange} value={value} placeholder="Email ID" validators={['isEmail']} errorMessages={['Enter valid email']} /> */}
                    </Grid>
                    <Grid item xs={7.5}>
                        {/* <label>{name}</label> */}
                        <CustomInput name="value" handleChange={handleChange} value={value} placeholder="Email ID" validators={['isEmail']} errorMessages={['Enter valid email']} />
                    </Grid>
                </>
            }
            {i > 1 && <Grid item xs={1} justifyContent={'end'} alignContent={"center"} ><Delete className="cursPoint" style={{ fontSize: '20px' }} onClick={deleteContact} /></Grid>}
        </Grid>
    )
}


const TabsListEdit = [
    { name: <span style={{ fontSize: '16px' }}><span> Role Details</span></span>, Component: "PractitionerRoleAdding" },
    { name: <span style={{ fontSize: '16px' }}><span> Service Information</span></span>, Component: "BasicDetailsHFR" },
    { name: <span style={{ fontSize: '16px' }}><span> Availability</span></span>, Component: "LocationHFR" },
    // { name: <span style={{ fontSize: '16px' }}><span> Facility Info</span></span>, Component: FacilityInfo },
    // { name: <span style={{ fontSize: '16px' }}><span> Work hours</span></span>, Component: "WorkHoursHFR" },
]

const TabsList = [
    // { name: <span style={{ fontSize: '18px' }}><span> Login</span></span>, Component: LoginHFR },

    { name: <span style={{ fontSize: '18px' }}><span> Basic Details</span></span>, Component: "BasicDetailsHFR" },
    // { name: <span style={{ fontSize: '18px' }}><span> Hospital Facility Location</span></span>, Component: "LocationHFR" },
    // { name: <span style={{ fontSize: '18px' }}><span> Healthcare Facility KYC</span></span>, Component: "UploadKYC" },
]

const PeopleOrg = () => {

    // const userOrg = useSelector(state => state.registration.userOrg)


    // useEffect(() => {
    //   // https://dev.api.docsteth.com/api/v1/GetListOfApprovedWithoutToken/Practitioner/{id}
    //   // D_API.get(`GetListOfApprovedWithoutToken/Practitioner/${userOrg.id}`, {
    //   axios.get(`https://dev.api.docsteth.com/api/v1/GetListOfApprovedWithoutToken/Practitioner/${userOrg.id}`, {
    //     headers: {
    //       Authorization: `bearer ${UserService.getToken()}`,
    //       'X-Fhir-Tenant-Id': 'docsteth',
    //       'X-Fhir-Dsid' : 'fhirDocsteth',
    //       'Realm' : 'docsteth',

    //     },
    //   }).then(res => {
    //     console.log(res.data.entry, "people")
    //   })
    // }, [])

    const [approvePeople, setApprovePeople] = useState({ open: false, data: null });
    const [invitePeople, setInvitePeople] = useState(false);
    const [usersInfo, setUsersInfo] = useState([]);
    const [details, setDetails] = useState({
        role: "",
        speciality: [],
        service: [],
        PerformingRole: [],
        startDate: null,
        endDate: null,
        check: false,
        location: []


    });

    const [requests, setRequests] = useState([])
    const [practitioner, setPractitioner] = useState([])
    const [approvedList, setApprovedList] = useState([])
    const [services, setServices] = useState([])
    const [locationOrg, setLocationOrg] = useState([])
    const [specialities, setSpecialities] = useState([])
    const [ibmOrgdata, setIBMOrgdata] = useState()
    const [anchorElContact, setAnchorElContact] = useState(null);
    const openContact = Boolean(anchorElContact);
    const [contacts, setContacts] = useState([
        // { system: 'phone', use: 'home', value: '' },
        // { system: 'email', use: 'home', value: '' },
    ])
    const [getTimings, setGetTimings] = useState()
    const [getTimingsData, setGetTimingsData] = useState()
    const [tab, setTab] = useState('1')
    const [particularPractitionerInfo, setParticularPractitionerInfo] = useState({});
    const [particularPractitionerWorkHours, setParticularPractitionerWorkHours] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [locationsInfo, setLocationsInfo] = useState([]);
    const [alert, setAlert] = useState({
        open: false,
        msg: ""
    })

    const [showSekeleton, setShowSekeleton] = useState(false);
    const [showEditPractitionerRoleBtn, setShowEditPractitionerRoleBtn] = useState(null);


    const orgData = useSelector(state => state.registration.userOrg)
    const location = useSelector(state => state.registration.location)
    const userOrg = useSelector(state => state.userProfile.publicorgdata)
    const userData = useSelector(state => state.userProfile.userProfile)
    // const state = useSelector(state => state.userProfile.userProfile)
    const dispatch = useDispatch();

    console.log(userOrg, "userOrguserOrg", orgData, userData)
    console.log(ibmOrgdata, "0@")
    console.log(showEditPractitionerRoleBtn, "rrr")

    // console.log(getTimings, "valuevalue")
    // useEffect(() => {
    //     console.log(getTimings, "valuevalue")
    //     setGetTimingsData(getTimings)
    // }, [getTimings])


    useEffect(() => {
        getPendingRequests()
    }, [])


    useEffect(() => {
        listOfApprovedPractitioners();
    }, [orgData, ibmOrgdata])


    const listOfApprovedPractitioners = () => {

        if (ibmOrgdata?.id) {
            setIsLoading(true)
            DOC_API.get(`GetListOfApprovedWithToken/Practitioner/${ibmOrgdata?.id}`, {
                headers: {
                    'X-FHIR-TENANT-ID': process.env.REACT_APP_HEADER_DOCSTETH.toLowerCase(),
                    'X-FHIR-DSID': `fhir${process.env.REACT_APP_HEADER_DOCSTETH}`,
                    'Realm': process.env.REACT_APP_REALM
                }
            }).then((res) => {
                console.log(res.data, "zzzz")
                setApprovedList(res.data);
                setIsLoading(false);
            }).catch((err) => {
                setIsLoading(false);
                console.log(err);
            })

        }
    }



    useEffect(() => {


        // if (ibmOrgdata?.id) {
        //     // console.log("hi")
        //     DOC_API.get(`GetListOfApprovedWithToken/Practitioner/${ibmOrgdata?.id}`).then((res) => {
        //         setApprovedList(res.data)
        //     }).catch((err) => console.log(err))
        //     // DOC_API_WITHOUT_TOKEN.get(`GetListOfApprovedWithToken/Practitioner/${ibmOrgdata?.id}`, {
        //     //     headers: {

        //     //         'X-FHIR-TENANT-ID': process.env.REACT_APP_HEADER_DOCSTETH.toLowerCase(),
        //     //         'X-FHIR-DSID': `fhir${process.env.REACT_APP_HEADER_DOCSTETH}`,
        //     //         'Realm': process.env.REACT_APP_REALM
        //     //     }
        //     // }).then((res) => {
        //     //     console.log(res.data, "0-") 
        //     // }).catch((err) => console.log(err))
        // }
        // if (UserService.getToken() && orgData?.identifier?.[0]?.value == UserService.getUserId()) {

        //     getPendingRequests()
        //     getApprovedPractitioners()
        //     setApprovePeople({ open: false, data: null })
        // } else {
        //      if (ibmOrgdata?.id) {
        //          DOC_API_WITHOUT_TOKEN.get(`GetListOfApprovedWithoutToken/Practitioner/${ibmOrgdata?.id}`, {
        //          headers: {
        //              'X-FHIR-TENANT-ID': process.env.REACT_APP_HEADER_DOCSTETH.toLowerCase(),
        //                  'X-FHIR-DSID': `fhir${process.env.REACT_APP_HEADER_DOCSTETH}`,
        //                'Realm': process.env.REACT_APP_REALM
        //          }
        //     }).then((res) => {
        //            setApprovedList(res.data)
        //       }).catch((err) => console.log(err))
        //     }

        // }

    }, [orgData, ibmOrgdata])




    console.log(approvedList, "approvedList")
    console.log(requests, "requests");
    console.log(locationsInfo, "locationsInfo")

    useEffect(() => {
        axios.get(`${URL.getbyPageId}${orgData?.identifier?.[1]?.value}`, {
            headers: {
                'X-FHIR-TENANT-ID': process.env.REACT_APP_HEADER_DOCSTETH.toLowerCase(),
                'X-FHIR-DSID': `fhir${process.env.REACT_APP_HEADER_DOCSTETH}`,
                'Realm': process.env.REACT_APP_REALM
            }
        })
            .then((res) => {
                setIBMOrgdata(res.data?.entry[0]?.resource)
            }).catch((err) => console.log(err))
    }, [])

    console.log(userData, "userDatatatat")

    useEffect(() => {
        D_API.get(`Location/organization/${orgData?.id}`).then(res => {
            console.log(res?.data?.entry, "400")
            // setLocationOrg(res.data?.entry || [])
        }).catch(err => err)

        D_API.get(`Organization/partof/${orgData?.id}`).then(res => {
            console.log(res)
            setLocationOrg(res.data?.entry || [])
        }).catch(err => err)

    }, [])



    // this useEffect is used to show whether edit practitioner Role edit button

    useEffect(() => {
        if (orgData?.id) {
            D_API.get(`${URI.getPermissionForPractitionerRole}${orgData?.id}`).then((res) => {
                setShowEditPractitionerRoleBtn(res.data);
            }).catch((err) => console.log(err))
        } else {
            setShowEditPractitionerRoleBtn(null);
        }

    }, [orgData])

    useEffect(() => {

        let orgId = details?.location?.map((e) => e?.resource?.id ? e?.resource?.id : e?.id ? e?.id : "").filter((val) => val != "");
        console.log(orgId, "3993");
        if (orgId?.length > 0) {
            D_API.post(`Organization/Location/Bundle/List`, [...orgId]).then((res) => {
                setLocationsInfo(res.data?.entry)
            }).catch((err) => console.log(err));
        }

    }, [details.location])


    // useEffect(() => {
    //     D_API.get(`Location/organization/${location?.id}`).then(res => {
    //         console.log(res?.data?.entry, "400")
    //         // setLocationOrg(res.data?.entry || [])
    //     }).catch(err => err)


    // }, [location])




    console.log(practitioner, "vinay")

    console.log(locationOrg, "LocOrg")
    const getservicesBasedOnOrgId = async (id) => {
        let dataSer = await getHealthServicesData(id)
        console.log(dataSer, "dataSer")
        setServices(dataSer)
    }

    useEffect(() => {
        getservicesBasedOnOrgId(orgData?.id);
    }, [])

    const fetchProfileByUserId = (id) => {
        setShowSekeleton(true);
        axios.get(`${URL.findUser}/${id}`, headers())
            .then((response) => response.data)
            .then(data => {
                // console.log(data, "vvvinayyyyyyyyyyyyyy")
                let exp = data.data.experience ? data.data.experience.length > 0 ? data.data.experience : {} : {}
                dispatch(userProfile(data.data.user, exp, data.data.pages, data.data.events, data.data));
                setApprovePeople((prev) => {
                    return {
                        ...prev, data: data.data
                    }
                })
                setShowSekeleton(false);
            })
            .catch((error) => {
                setShowSekeleton(false);
                console.log(error.message)
            })
    }
    const getPendingRequests = () => {
        DOC_API.get(URI.getPendingRequests + orgData?.id).then(res => {
            {
                console.log(res.data, "audio")
                setRequests(res.data);

                // D_API_FHIR.get(`Practitioner?identifier=https://dev.docsteth.com|${res?.data?.[0]?.userId}`)
                //     .then(res => {
                //         console.log(res?.data?.entry?.[0], "practitioner11");
                //         setPractitioner(res?.data?.entry?.[0])
                //     })
                //     .catch(err => err);
            }
        })
    }

    console.log(practitioner, "7000");

    const getApprovedPractitioners = () => {
        DOC_API.get(URI.approvedPractitioners + orgData?.id).then(res => setApprovedList(res.data))
    }

    const handleDetails = (name, value) => {
        setDetails({ ...details, [name]: value })
    }

    console.log(details, "details")

    const handleInvitePeople = async () => {
        DOC_API.get(URI.getAllUsers).then(res => {
            console.log(res)
            setUsersInfo(res.data.data)
        })
        setInvitePeople(true)
    }

    const handleApprove = (userData) => async () => {
        setApprovePeople({ open: true, data: userData })

        if (userData?.userId) {
            D_API_FHIR.get(`Practitioner?identifier=https://dev.docsteth.com|${userData?.userId}`)
                .then(res => {
                    // console.log(res?.data?.entry?.[0], "practitioner11");
                    setPractitioner(res?.data?.entry?.[0])
                }).catch(err => err);
        }

        let data = await getHealthServices(location?.id)
        let dataSer = await getHealthServicesData(orgData?.id)
        // console.log(dataSer, "dataSer")
        setServices(dataSer)

        // D_API_FHIR.get(URI.getSpecialities).then(res => setSpecialities(res?.data?.entry?.[0]?.resource?.compose?.include?.[0]?.concept || [])).catch(err => console.log(err))
        axios.get(process.env.REACT_APP_TERMINOLOGY_VALUESET + `$expand?url=http://hl7.org/fhir/ValueSet/c80-practice-codes`).then(res => {
            // console.log(res, "73894893nnnn")
            setSpecialities(res?.data?.expansion?.contains || [])
        }
        )
            .catch(err => console.log(err))
    }

    const changeStatus = (val, id) => () => {
        console.log(val, id, details?.speciality)

        DOC_API.get(URI.changeStatus + `${val}/${id || approvePeople?.data.userId}/?IbmOrgId=${orgData?.id}`).then(res => {
            setApprovePeople({ open: false, data: null })
            setDetails({
                role: "",
                speciality: [],
                service: []
            })
            getPendingRequests()
            // getApprovedPractitioners()
            listOfApprovedPractitioners();
        }).catch(err => alert(err?.data?.error || `Couldn\'t ${val === 0 ? 'accept' : 'reject'} request.`))
    }

    const excludeDuplicateUser = (val, id) => {
        console.log('kkk', services, id)
        D_API.post(URI.approveDoctor + `${approvePeople?.data?.userId}?speciality=${details?.speciality[0]?.display}&IbmOrgId=${orgData?.id}&healthCareId=${services[0]?.resource?.id}`).then(res => {
            console.log(res)
        }).catch(err => alert(err?.data?.error || `Couldn\'t ${val === 0 ? 'accept' : 'reject'} request.`))
    }
    console.log(tab, "ttttyyyyyy")

    const [value, setValue] = React.useState("1");

    const handleChangeTab = (event, newValue) => {
        setValue(newValue);

    };
    console.log(approvePeople, "approvePeople", value)
    const handleNext = () => {
        const currentValue = parseInt(value);
        const nextValue = String(currentValue + 1);
        setValue(nextValue > '3' ? '1' : nextValue);
    }
    const handleClickContact = (event) => {
        setAnchorElContact(event.currentTarget);
    };
    const handleCloseContact = () => {
        setAnchorElContact(null);
    };
    const addContact = (val) => {
        if (val === 'phone') {
            setContacts([...contacts, { system: 'phone', use: 'home', value: '' }])
        } else if (val === 'email') {
            setContacts([...contacts, { system: 'email', use: 'home', value: '' }])
        }
        handleCloseContact()
    }

    const handleContactChange = (index, ind) => (name, value) => {
        console.log(ind, 'wwwe')
        let prev_con = [...contacts]
        prev_con = prev_con.map((con, i) => i === index ? { ...con, [name]: con.system === 'phone' ? formatPh(value) : value } : { ...con })
        setContacts(prev_con)

    }

    const dataGetting = (get) => {
        console.log(get, "dataGetting")
    }

    const toOpenEdit = (val, role) => {
        console.log(role, "role")
        setApprovePeople((prev) => {
            return { ...prev, open: true, }
        })

        if (val?.resource?.identifier?.[0]?.value) {
            fetchProfileByUserId(val?.resource?.identifier?.[0]?.value)
        }
        setParticularPractitionerInfo(role?.resource);

        if (role?.resource?.practitioner?.reference?.split("/")?.[1]) {
            let Id = role?.resource?.practitioner?.reference?.split("/")?.[1];
            if (Id != undefined && Id != null && Id != "") {
                PractitionerInfo(Id)
            }
        }

        let healthservicesListOfId = role?.resource?.healthcareService?.map((e) => e?.reference?.split("/")?.[1])?.filter((value) => value != undefined && value != null && value != "");
        if (healthservicesListOfId?.length > 0) {
            getHealthServicesForcurrentPractitioner(healthservicesListOfId)
        }

        let healthDeptsLocListOfIds = role.resource?.location?.map((e) => e?.reference?.split("/")?.[1])?.filter((value) => value != undefined && value != null && value != "");
        if (healthDeptsLocListOfIds?.length > 0) {
            getHealthDeptForcurrentPractitioner(healthDeptsLocListOfIds);
        }
        if (role?.resource?.availableTime) {
            setParticularPractitionerWorkHours([...role?.resource?.availableTime])
        }

        setDetails((prev) => {
            return {
                ...prev,
                role: role.resource?.identifier?.[0]?.value ? role?.resource?.identifier?.[0]?.value : "",
                speciality: role?.resource?.specialty ? role?.resource?.specialty.map((e) => {
                    return { ...e?.coding?.[0] }
                }) : [],
                PerformingRole: role?.resource?.code ? role?.resource?.code.map((e) => {
                    return {
                        ...e?.coding?.[0]
                    }
                }) : [],
                startDate: role?.resource?.period?.start ? role?.resource?.period?.start : "",
                endDate: role?.resource?.period?.end ? role?.resource?.period?.end : "",
                check: role?.resource?.active ? role?.resource?.active : false,

            }
        })

        setContacts(role?.resource?.telecom ? role?.resource?.telecom : []);

    }



    console.log(particularPractitionerWorkHours, "01")

    const PractitionerInfo = (practitionerId) => {

        D_API.get(`Practitioner/${practitionerId}`).then((res) => {
            console.log(res.data);
            setPractitioner(res.data);
        }).catch((err) => err)
    }

    console.log(practitioner, "0000")
    const getHealthServicesForcurrentPractitioner = async (Ids) => {

        let dataSer = await getHealthServicesData(orgData?.id)
        console.log(dataSer, "dataSer")
        setServices(dataSer)

        D_API.post("HealthcareService/List", [...Ids]).then((res) => {
            setDetails((prev) => {
                return {
                    ...prev, service: res.data
                }
            })
        })

    }


    const getHealthDeptForcurrentPractitioner = async (Ids) => {
        D_API.post("/Location/Organization/Bundle/List", [...Ids]).then((res) => {
            // console.log(res.data, "vuppula")
            setDetails((prev) => {
                return {
                    ...prev, location: res.data?.entry
                }
            })
        }).catch((err) => {
            setDetails((prev) => {
                return {
                    ...prev, location: []
                }
            })
        })



    }


    const submitPractitionerRole = () => {
        console.log("first", getTimings)



        let timings = [];

        console.log(getTimings)

        getTimings.filter(h => h.time.length > 0).map(v => v.time.map(t => timings.push({
            daysOfWeek: [v.week],
            availableStartTime: moment(t.start, "HH:mm").format("HH:mm:ss"),
            availableEndTime: moment(t.end, "HH:mm").format("HH:mm:ss"),
        })
        ))

        console.log(timings, 'hiiiiiiiiiiii')

        let datatm = {
            availableTime: [
                {
                    daysOfWeek: getTimings.filter(val => val.open === 'open').map(h => h.week),
                    allDay: true,
                },
                {
                    daysOfWeek: getTimings.filter(val => val.open === 'close').map(h => h.week),
                    allDay: false,
                },

                ...timings,
            ],

            // "notAvailableTime": [{
            //     "description": "Public Holidays"
            // }],
        }
        console.log(datatm,)


        let datas = {
            ...(particularPractitionerInfo?.id && { id: particularPractitionerInfo?.id }),
            "resourceType": "PractitionerRole",

            "identifier": details.role ? [{
                "value": details?.role
            }] : "",
            "active": details?.check,
            "period": Object?.keys(deleteKeys({
                "start": details.startDate ? details.startDate : "",
                "end": (!details.check && details.endDate) ? details.endDate : ""
            })).length > 0 ? deleteKeys({
                "start": details.startDate ? details.startDate : "",
                "end": (!details.check && details.endDate) ? details.endDate : ""
            }) : "",
            "practitioner": {
                "reference": practitioner?.resource?.id ? `Practitioner/${practitioner?.resource?.id}` : `Practitioner/${practitioner?.id}`,
                "display": practitioner?.resource?.name?.[0]?.text ? practitioner?.resource?.name?.[0]?.text : practitioner?.name?.[0]?.text,
            },

            "organization": {
                "reference": `Organization/${orgData?.id}`
            },
            ...(details?.PerformingRole?.length > 0 && {
                code: details?.PerformingRole.map(e => (
                    {
                        "coding": [{
                            "system": e?.system,
                            "code": e?.code,
                            display: e?.display
                        }]
                    }
                ))
            }),

            ...(details?.speciality?.length > 0 && {
                "specialty": details?.speciality.map(e => (
                    {
                        "coding": [
                            {
                                "system": "http://snomed.info/sct",
                                "code": e?.code,
                                "display": e?.display
                            }
                        ]
                    }))
            }
            ),
            ...(details?.service?.length > 0 && {
                healthcareService: details?.service?.map(e => {
                    if (e?.resource?.id) {
                        return {
                            reference: `HealthcareService/${e?.resource?.id}`
                        }
                    } else if (e?.id) {
                        return {
                            reference: `HealthcareService/${e?.id}`
                        }
                    }

                })
            }),
            ...(locationsInfo?.length > 0 && {
                location: locationsInfo?.map((e) => {
                    if (e?.resource?.id) {
                        return {
                            "reference": `Location/${e?.resource?.id}`,
                            "display": e?.resource?.name
                        }
                    } else if (e?.id) {
                        return {
                            "reference": `Location/${e?.id}`,
                            "display": e?.name
                        }
                    }
                })
                //old code
                // location: details?.location.map(e => {
                //     if (e?.resource?.id) {
                //         return {
                //             "reference": `Location/${e?.resource?.id}`,
                //             "display": e?.resource?.name
                //         }
                //     } else if (e?.id) {
                //         return {
                //             "reference": `Location/${e?.id}`,
                //             "display": e?.name
                //         }
                //     }

                // })
            }),
            ...(contacts?.length > 0 && {
                telecom: contacts.map(e => ({
                    ...(e.system === 'phone' && {
                        "system": "phone",
                        "value": e?.value,
                        "use": "work"
                    }),
                    ...(e.system === 'email' && {
                        "system": "email",
                        "value": e?.value,
                        "use": "work"
                    })
                }))
            }),

            ...datatm

        }
        console.log(datas, "check data", practitioner)

        console.log(deleteKeys(datas), "zooha")
        if (particularPractitionerInfo?.id) {
            D_API.put(`PractitionerRole/${particularPractitionerInfo?.id}`, deleteKeys(datas)).then((res) => {
                console.log(res, "res")
                setApprovePeople({ open: false, data: null })
                listOfApprovedPractitioners();
                // DOC_API.get(URI.changeStatus + `0/${approvePeople?.data.userId}/?IbmOrgId=${orgData?.id}`)
                //     .then(res => {
                //         console.log(res)
                //         getApprovedPractitioners()
                //         getPendingRequests()
                //         setApprovePeople({ open: false, data: null })
                //         setContacts([
                //             { system: 'phone', use: 'home', value: '' },
                //             { system: 'email', use: 'home', value: '' },
                //         ])
                //         setDetails({})
                //         setValue('1')
                //     })
                //     .catch(err => err)
            }).catch((err) => {
                setAlert({
                    open: true,
                    msg: "SomeThing went wrong unable to update practitionerRole"
                })
                setApprovePeople({ open: false, data: null });
                console.log(err)
            });

        } else {
            if (practitioner?.resource?.id || practitioner?.id) {
                D_API.post('PractitionerRole', deleteKeys(datas))
                    .then(res => {
                        console.log("res", res)
                        DOC_API.get(URI.changeStatus + `0/${approvePeople?.data.userId}/?IbmOrgId=${orgData?.id}`)
                            .then(res => {
                                console.log(res)
                                // getApprovedPractitioners()
                                listOfApprovedPractitioners();
                                getPendingRequests()
                                setApprovePeople({ open: false, data: null })
                                setContacts([
                                    { system: 'phone', use: 'home', value: '' },
                                    { system: 'email', use: 'home', value: '' },
                                ])
                                setDetails({})
                                setValue('1')
                            })
                            .catch(err => err)
                    }
                    )
                    .catch(err => {
                        if (err?.response?.status == 422) {
                            setAlert({
                                open: true,
                                msg: "You have already raised role request on this organization.You can't raise another request on this organization"
                            })
                            // alert("You have already raised role request on this organization. You can't raise another request on this organization")
                        } else {
                            setAlert({
                                open: true,
                                msg: "Something went wrong unable to create PractitionerRole request"
                            })
                        }

                        console.log(err)
                    });

            } else {
                setAlert({
                    open: true,
                    msg: "SomeThing went wrong unable to create practitioner role because practitioner Id is not refering"
                })
                setApprovePeople({ open: false, data: null });

            }
        }

    }
    const deleteContact = (i) => () => {
        let tele = contacts.filter((con, ind) => ind !== i)
        setContacts(tele)
        console.log(tele, "iiiiiiiiiiiiiiii33", i)
    }
    console.log(details, "valuevalue")

    const deletePractitionerFn = (valId) => {
        if (valId) {
            D_API.delete(`PractitionerRole/${valId}`).then((res) => {
                console.log(res.data);
                setAlert({
                    open: true,
                    msg: "Successfully Deleted PractitionerRole"
                })
                setApprovePeople({
                    open: false, data: null
                })
                setParticularPractitionerInfo({});

            }).catch((err) => {
                setAlert({
                    open: true,
                    msg: "SomeThing went wrong unable to Delete"
                })
            })
        }
        else {
            setAlert({
                open: true,
                msg: "Their is no PractitionerRole Id"
            })
        }


    }

    // dont change the code place it should above the return only
    const handleCloseSnack = () => {
        setAlert({
            open: false,
            msg: ""
        })

    }
    const action = (
        <React.Fragment>

            <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={handleCloseSnack}
            >
                <CloseIcon fontSize="small" />
            </IconButton>
        </React.Fragment>
    );

    return (
        <Card sx={{ p: { xs: "10px", sm: " 10px " } }}>
            <Grid container>
                {/* Dialogs */}
                {approvePeople.open &&
                    <Dialog id="approvePeople"

                        maxWidth='sm' open={approvePeople.open} onClose={() => setApprovePeople({ open: false, data: null })} fullWidth>
                        <Grid container justifyContent={"space-between"}>
                            <DialogTitle sx={{ py: 1 }}>
                                <p className='mb0 flx-center fntBold'> Assign Practitioner Role</p>
                            </DialogTitle>
                            <IconButton
                                aria-label="close"
                                onClick={() => setApprovePeople({ open: false, data: null })}
                                sx={{
                                    position: 'absolute',
                                    right: 8,
                                    top: 8,
                                    color: (theme) => theme.palette.grey[500],
                                }}
                            >
                                <Close />
                            </IconButton>

                        </Grid>
                        <FormDivider />
                        <DialogContent sx={{ px: 2, py: 1 }}>
                            <Grid container className='space_btw_center'>
                                <Grid item xs={12}>
                                    <Box fullWidth className='ph1 pv0-5' sx={{ borderRadius: '12px', backgroundColor: '#E7FAFF' }}>
                                        <Grid container className='d-flex' spacing={3} >
                                            <Grid item className='align-self'>
                                                <Avatar sx={{ borderRadius: '10px !important', width: '45px !important', height: '45px !important' }} src={approvePeople?.data?.practioners?.photo?.[0]?.url || ''} />
                                            </Grid>
                                            <Grid item >

                                                {
                                                    showSekeleton ? <Skeleton variant="rounded" color='#E7FAFF' width={210} height={30} /> :
                                                        <div className='fntBold '>{approvePeople?.data?.practioners?.name?.[0]?.given || approvePeople?.data?.user?.practioner?.name?.[0]?.text || approvePeople?.data?.name?.[0]?.text}&nbsp;
                                                            <span style={{ fontSize: '12px', color: '#7B7B7B' }}>{approvePeople?.data?.practioners?.qualification?.[0]?.code?.coding?.[0]?.code || approvePeople?.data?.user?.practioner?.qualification?.[0]?.code?.coding?.[0]?.code}</span>
                                                        </div>
                                                }

                                                <div className='fntSm fntLighter'>{userData?.captionAndTaglines}</div>
                                                <div className='fntSm fntLighter'></div>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Grid>


                            </Grid>
                            <ValidatorForm
                            // onSubmit={changeStatus(0)}
                            // onSubmit={submitPractitionerRole}
                            >
                                <Box sx={{ width: '100%', typography: 'body1', height: "450px" }}>
                                    <TabContext value={value}>
                                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                            <TabList onChange={handleChangeTab} aria-label="lab API tabs example" sx={{ display: 'flex', justifyContent: 'space-between' }}>

                                                <Tab className='capitalize m-auto' label="Role Details" value="1" />
                                                <Tab className='capitalize m-auto' label="Service Information" value="2" />
                                                <Tab className='capitalize m-auto' label="Availability" value="3" />
                                            </TabList>
                                        </Box>
                                        <TabPanel value="1" sx={{ padding: '0px' }}>
                                            <Grid container spacing={2} className="ph1 pv2 flx-center" sx={{
                                                overflowY: 'scroll', height: '400px',
                                                '&::-webkit-scrollbar': {
                                                    display: 'none',
                                                },
                                                scrollbarWidth: 'none',
                                                msOverflowStyle: 'none',
                                            }}>
                                                <OrgLayout label="Practitioner Role Name" value={details.role} name="role" handleChange={handleDetails} placeholder="Name the Role(ex: consultant Physician)" />
                                                <OrgLayout label="Performing Role(s)" input="select" multiple options={prRole} getOptionLabel={option => option.display || ''} value={details.PerformingRole} name="PerformingRole" handleChange={handleDetails} placeholder="Select Performing Role" />
                                                <OrgLayout label="Speciality" input="select" multiple options={specialities} getOptionLabel={option => option.display || ''} value={details.speciality} name="speciality" handleChange={handleDetails} placeholder="Select Speciality" />
                                                <Grid container spacing={2} style={{ padding: "10px 10px 15px", marginTop: "0px" }}>
                                                    <Grid item xs={6} style={{ paddingLeft: "20px" }}>
                                                        <Typography className="">Start date of this role</Typography>
                                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                            <DatePicker

                                                                value={dayjs(details.startDate)}
                                                                onChange={(value) =>
                                                                    handleDetails("startDate", new Date(value))
                                                                }
                                                                // inputFormat="DD/MM/YYYY"
                                                                format="DD/MM/YYYY"
                                                                views={["day", "month", "year"]}
                                                                disableFuture
                                                                renderInput={(params) => (
                                                                    <CssTextField
                                                                        fullWidth
                                                                        {...params}
                                                                    // value={params.inputProps.value}
                                                                    // validators={["required"]}
                                                                    // errorMessages={["Please Select start date"]}
                                                                    />
                                                                )}
                                                            />
                                                        </LocalizationProvider>
                                                    </Grid>
                                                    <Grid item xs={6} style={{ paddingLeft: "20px" }}>
                                                        {!details?.check && <>
                                                            <Typography className="">End date of this role</Typography>
                                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                                <DatePicker
                                                                    // label="From"
                                                                    value={dayjs(details.endDate)}
                                                                    onChange={(value) =>
                                                                        handleDetails("endDate", new Date(value))
                                                                    }
                                                                    // inputFormat="MM/DD/YYYY"
                                                                    format="DD/MM/YYYY"
                                                                    views={["day", "month", "year"]}
                                                                    disableFuture
                                                                    renderInput={(params) => (
                                                                        <CssTextField
                                                                            fullWidth
                                                                            {...params}
                                                                        // value={details.endDate}
                                                                        // validators={["required"]}
                                                                        // errorMessages={["Please Select start date"]}
                                                                        />
                                                                    )}
                                                                />
                                                            </LocalizationProvider>
                                                        </>}
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <FormGroup>
                                                        <FormControlLabel
                                                            sx={{ alignItems: "flex-start", mt: 0 }}
                                                            control={
                                                                <Checkbox
                                                                    className="r-check"
                                                                    disableRipple
                                                                    checked={details.check}
                                                                    name="check"
                                                                    onChange={(e) => handleDetails('check', e.target.checked)}
                                                                    inputProps={{ "aria-label": "controlled" }}
                                                                />
                                                            }
                                                            label="Active (Currently Working in This Role)"
                                                        />
                                                    </FormGroup>
                                                </Grid>

                                            </Grid>
                                        </TabPanel>
                                        <TabPanel value="2" sx={{ padding: '0px' }}>
                                            <Grid container spacing={2} className="ph1 pv2 flx-center" sx={{ overflow: 'auto' }}>
                                                <OrgLayout label="Location" sx={{ '.MuiOutlinedInput-root': { paddingRight: '10px !important' } }} multiple input="select" Icon={<SearchIcon />} options={locationOrg} getOptionLabel={option => option?.resource?.name || option?.name || ''} value={details.location} name="location" handleChange={handleDetails} placeholder="Select Location" />
                                                <OrgLayout label="Healthcare Service" sx={{ '.MuiOutlinedInput-root': { paddingRight: '10px !important' } }} multiple input="select" Icon={<SearchIcon />} options={services} getOptionLabel={option => option?.resource?.name || option?.name || ''} value={details.service} name="service" handleChange={handleDetails} placeholder="Select Healthcare Service" />
                                            </Grid>

                                            <div style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
                                                {contacts?.map((contact, i) => (
                                                    <div key={i} >
                                                        <ContactPoint type={contact.system} i={i} name={contact.system === 'phone' ? "Mobile" : "Email Id"}
                                                            value={contact.value}
                                                            handleChange={handleContactChange(i, i)}
                                                            deleteContact={deleteContact(i)}
                                                        //  ConDetails={value1} 
                                                        />
                                                    </div>
                                                ))}
                                            </div>

                                            <Grid container pt={2}>
                                                {/* <Grid item xs={12}>
                                                </Grid> */}
                                                <Grid item xs={6}>
                                                    <Button onClick={handleClickContact} sx={{ color: '#333 !important', borderColor: '#cbcbcb', padding: '0px 5px', marginTop: '25px' }} className="flx-center mb1 cursPoint capitalize"
                                                        startIcon={<AddCircleOutlined sx={{ color: '#079F33', size: '14px' }} />} endIcon={<KeyboardArrowDown />} >
                                                        Work Place Contact
                                                    </Button>

                                                    <Menu
                                                        anchorEl={anchorElContact}
                                                        open={openContact}
                                                        onClose={handleCloseContact}
                                                        anchorOrigin={{
                                                            vertical: 'bottom',
                                                            horizontal: 'right',
                                                        }}
                                                        transformOrigin={{
                                                            vertical: 'top',
                                                            horizontal: 'right',
                                                        }}
                                                    >
                                                        <MenuItem onClick={() => addContact('phone')}>Phone</MenuItem>
                                                        <MenuItem onClick={() => addContact('email')}>Email</MenuItem>
                                                    </Menu>
                                                </Grid>
                                            </Grid>
                                            {/* <ServiceInfo /> */}
                                        </TabPanel>





                                        <TabPanel value="3" sx={{ padding: '0px' }}><WorkhoursPractitioner setGetTimings={setGetTimings} dataGetting={dataGetting} workHours={particularPractitionerWorkHours} /></TabPanel>
                                    </TabContext>
                                </Box>



                            </ValidatorForm>
                        </DialogContent>
                        <FormDivider />
                        {/* <DialogActions> */}

                        <Grid container className='flx-center justify-between capitalize ph2 pv0-5'>
                            <Grid item>
                                {
                                    particularPractitionerInfo?.id && <DeleteIcon sx={{ color: "red" }} onClick={() => deletePractitionerFn(particularPractitionerInfo?.id)} />
                                }

                            </Grid>
                            <Grid item>
                                {value == 3 ? <Button className="capitalize q" onClick={() => submitPractitionerRole()} sx={{ borderRadius: '15px !important', color: '#fff', justifyContent: 'left', backgroundColor: '#004D76 !important', marginBottom: { sm: '3px' }, padding: '5px 20px !important' }} aria-label="add">
                                    Create
                                </Button> :
                                    <Button onClick={() => handleNext()} size='small' sx={{ color: '#333 !important', borderColor: '#cbcbcb', padding: '0px 5px', marginBottom: '2px' }} variant='outlined' className="flx-center  cursPoint capitalize"
                                    >
                                        Next
                                    </Button>
                                }
                            </Grid>
                        </Grid>
                        {/* </DialogActions> */}
                    </Dialog>}

                {/* Send Invitation  Dialog*/}
                {invitePeople && <Dialog id="approvePeople" open={invitePeople} onClose={() => setInvitePeople(false)} maxWidth="sm" fullWidth>
                    <DialogTitle sx={{ py: 1 }}>
                        <p className='mb0 flx-center fntBold'>Search People To Send Invite</p>
                    </DialogTitle>
                    <FormDivider />
                    <DialogContent sx={{ px: 2, py: 1 }}>
                        <Grid container className='flx' sx={{ minHeight: '350px', overflow: 'auto' }}>
                            <Autocomplete
                                id="country-select-demo"
                                sx={{ width: '100%' }}
                                icon={<SearchIcon />}
                                options={usersInfo}
                                autoHighlight
                                getOptionLabel={(option) => option?.fullName}
                                renderOption={(props, option) => (
                                    <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props} key={props["data-option-index"]} >
                                        <Grid container className='d-flex justify-between align-center' >
                                            <Grid item className='align-self' xs={2}>
                                                <Avatar sx={{ borderRadius: '10px !important', width: '45px !important', height: '45px !important' }} src={option?.practioner?.photo?.[0]?.url} />
                                            </Grid>
                                            <Grid item lg={8} xs={7}>
                                                <div className='fntMd capitalize '>{option?.fullName} <sub>{option?.practioner?.name?.[0]?.suffix}</sub> </div>
                                                <div className='fntSm fntLighter'>{option?.captionAndTaglines}</div>
                                                <div className='fntSm fntLighter'>speciality</div>
                                            </Grid>
                                            <Grid item xs={3}>
                                                <Fab className="ph4 pv0 capitalize" sx={{ borderRadius: '10px !important', border: '1px solid #3241B7', backgroundColor: '#fff', color: '#3241B7' }} onClick={(() => console.log('first'))} variant="extended" size="small" aria-label="add" >
                                                    <FiSend sx={{ mr: 1, }} />
                                                    invite
                                                </Fab>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                )}
                                renderInput={(params) => (
                                    <TextField
                                        sx={{ '.MuiOutlinedInput-root': { padding: '0px 10px!important' } }}
                                        {...params}
                                        icon={<SearchIcon />}
                                        placeholder="Search Practitioner on Docsteth (Add Practitioner)"
                                        InputProps={{
                                            ...params.InputProps,
                                            // autoComplete: 'new-password',
                                            endAdornment: <SearchIcon />
                                        }}
                                    />
                                )}
                            />
                        </Grid>
                        <FormDivider />
                        <Grid className='text-align-center'>
                            <div>Not Found on Docsteth - Send Direct Invitation</div>
                            <div className='d-flex justify-center align-center'>
                                <div className='ph1 flx' style={{ color: '#0B18B9' }}><BsMessenger className='pr0-5' size={22} /> Messenger</div>
                                <div className='ph1 flx' style={{ color: 'green' }}> <WhatsAppIcon className='pr0-5' /> WhatsApp</div>
                                <div className='ph1 flx' style={{ color: '#6F5757' }}><SmsIcon className='pr0-5' /> SMS</div>
                                <div className='ph1 flx'><MailIcon className='pr0-5' /> Email</div>
                            </div>
                        </Grid>
                    </DialogContent>
                </Dialog>}

                {/* page Layout */}
                {/* left side */}
                {UserService.getToken() && orgData?.identifier?.[0]?.value == UserService.getUserId() &&
                    <Grid item lg={3} md={4} xs={12}>
                        <Card sx={{ borderRadius: 0, boxShadow: '0px 3px 6px #00000029', height: '100%' }} >
                            {['Practitioners', 'Requests', 'Invites', 'Followers']?.map((val, i) =>
                                <Grid container key={i}>
                                    <p className='capitalize ph2 pv1 cursor width__100'>{val}</p>
                                </Grid>
                            )}
                        </Card>
                    </Grid>
                }

                {/* right side */}
                <Grid item lg={9} md={8} xs={12} sx={{ paddingLeft: { sm: 3 }, mt: { md: 0, xs: 2 } }}>
                    {UserService.getToken() && orgData?.identifier?.[0]?.value == UserService.getUserId() &&
                        <>
                            <div className='d-flex justify-between'>
                                <p className='fntBold fntLg'>Requests</p>
                                <Fab className="mb1 capitalize ph3" sx={{ borderRadius: '10px !important', color: '#fff', height: '24px', justifyContent: 'left', backgroundColor: '#004D76 !important', marginBottom: { sm: '10px' }, paddingLeft: '10px', paddingRight: '10px' }} onClick={handleInvitePeople} variant="extended" size="small">
                                    Send Invitation
                                </Fab>
                            </div>
                            {(requests && showEditPractitionerRoleBtn === "PERMIT" && requests?.length > 0) ? requests?.map(req => (
                                <Card fullWidth className='ph1 pv1 mt1' sx={{ borderRadius: '12px' }}>
                                    <Grid container justifyContent={'space-between'}>
                                        {/* {console.log(req, "reqreq")} */}
                                        <Grid item xs sm={1}>
                                            <Avatar id='img_people' sx={{ borderRadius: '10px !important', width: '45px !important', height: '45px !important' }} src={req?.practioners?.photo?.[0]?.url || ''} />
                                        </Grid>
                                        <Grid item lg={8} sm={6} xs={8}>
                                            <div className='fntLg fntBold'>{req?.practioners?.name?.[0]?.text} <sub>{req?.practioners?.name?.[0]?.suffix}</sub></div>
                                            <span className='fntSm fntLighter'>{userData?.captionAndTaglines}</span>
                                            {/* <div className='fntlg'>specaility</div> */}
                                        </Grid>
                                        <Grid item xs={12} sm={3} className="align-self" sx={{ display: { sm: 'block', xs: 'flex' }, justifyContent: { xs: 'space-between' }, paddingTop: { xs: '10px', sm: '0px' } }}>
                                            <Fab className="mb1 capitalize" sx={{ borderRadius: '10px !important', width: '100%', color: '#fff', justifyContent: 'left', backgroundColor: '#3DA31A !important', marginBottom: { sm: '10px' }, marginRight: { xs: '15px', sm: '0px' } }} onClick={handleApprove(req)} variant="extended" size="small">
                                                <CheckCircleOutlineIcon sx={{ mr: 1, }} />
                                                Approve
                                            </Fab>
                                            <Fab sx={{ borderRadius: '10px !important', width: '100%', justifyContent: 'left', border: '1px solid #EF2B26', backgroundColor: '#fff', color: '#EF2B26' }}
                                                onClick={changeStatus(1, req?.userId)}
                                                variant="extended" size="small" aria-label="add" className="capitalize">
                                                <HighlightOffIcon sx={{ mr: 1, }} />
                                                Reject
                                            </Fab>
                                        </Grid>
                                    </Grid>
                                </Card>
                            ))
                                : showEditPractitionerRoleBtn == null ? <p>No Approve List</p> : <p>No Pending Requests</p>
                            }
                        </>
                    }
                    <p className='fntBold fntLg pt1-5'>Practitioners</p>
                    {
                        isLoading && <div className="flx-center jc__center " style={{ height: "150px", }}> <CircularProgress />
                        </div>
                    }

                    {approvedList && approvedList?.length > 0 &&
                        <Grid container spacing={2} className='pt2'>
                            {approvedList?.map((pract, i) => (
                                <Grid item key={i} xs={12} sm={12} md={12} lg={6} >
                                    <DoctorsInfoCard
                                        practitioner={pract.practitioner} practitionerRole={pract.practitionerRole}

                                        // practitioner={{
                                        //     ibmOrgId: pract.ibmOrgId, roleStatus: pract.roleStatus, userId: pract.userId, id: pract.id, resource: { ...pract?.practioners }
                                        // }} 


                                        type="people" opnEdit={toOpenEdit} AllowEdit={showEditPractitionerRoleBtn} />
                                </Grid >
                                // <Grid key={i} item mx={1} lg={2.3} md={4} sm={6} xs={12}>
                                //     <Card sx={{ borderRadius: 3, boxShadow: '0px 3px 6px #00000029', height: '230px' }} className="flx">
                                //         <Grid container className='width100 pv1 flx'>
                                //             <Grid item className='d-flex width100 justify-flexEnd'>
                                //                 <HighlightOffIcon sx={{ mr: 1, backgroundColor: '#fff', color: '#EF2B26' }} />
                                //             </Grid>
                                //             <Grid item className='justify-center d-flex width100' xs={12}>
                                //                 <Avatar style={{ height: '60px', width: '60px', borderRadius: '10px' }} src={pract?.practioners?.photo?.[0]?.url || ''} />
                                //             </Grid>
                                //             <Grid item className='justify-center text-align-center width100' >
                                //                 <div className='fntMd fntBold'>{pract?.practioners?.name?.[0]?.given}</div>
                                //                 {pract?.practioners?.name?.suffix && <div className='fntSm fntLighter'>{pract?.practioners?.name?.suffix}</div>}
                                //                 {/* <div className='fntSm fntLighter pt1'>caption and Tag line</div> */}
                                //             </Grid>
                                //             <Grid item className="mv1 justify-center flx-center width100">
                                //                 <Fab sx={{
                                //                     borderRadius: '10px !important', width: '150px',
                                //                     border: '1px solid #5536DA',
                                //                     backgroundColor: '#fff', color: '#5536DA'
                                //                 }} onClick={(() => console.log('first'))} variant="extended" size="small" className="capitalize">
                                //                     Connected
                                //                 </Fab>
                                //             </Grid>
                                //         </Grid>
                                //     </Card>
                                // </Grid>
                            ))}
                        </Grid>
                        // :
                        // <div className='pt1'> No Practitioners</div>
                    }

                    {
                        (!isLoading && approvedList?.length <= 0) &&
                        <div className="flx-center justify-center fntMd  fntSemiBold " style={{ height: "150px", }} >
                            <div >
                                No Practitioners
                            </div>

                        </div>
                    }
                </Grid>
            </Grid>

            <Snackbar
                open={alert?.open}
                autoHideDuration={6000}
                onClose={handleCloseSnack}
                message={alert.msg}
                action={action}
            />
        </Card>
    )
}

export default PeopleOrg