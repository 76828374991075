// import React from 'react'

// function PdfViewer(props) {
//     const url = props.location.state
//     return (
//         <div>
//             <iframe height="650px" width="100%" src={url} scrolling="no" frameBorder="0" />
//         </div>
//     )
// }

// export default PdfViewer


// import React, { useState } from 'react'
// import { Document, Page, pdfjs } from 'react-pdf';
// import { Button } from "@mui/material";
// import { useHistory } from 'react-router-dom';
// import './Post.css';
// import './inputOption.css';

// function PdfViewer(props) {
//     console.log(props)
//     const url = props.location.state
//     const previous = "< previous";
//     const next = "next >";
//     const history = useHistory()
//     pdfjs.GlobalWorkerOptions.workerSrc =
//         `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
//     const [numPages, setNumPages] = useState(null);
//     const [pageNumber, setPageNumber] = useState(1);

//     function onDocumentLoadSuccess({ numPages }) {
//         setNumPages(numPages);
//         setPageNumber(1);
//     }
//     function changePage(offset) {
//         setPageNumber(prevPageNumber => prevPageNumber + offset);
//     }

//     function previousPage() {
//         changePage(-1);
//     }

//     function nextPage() {
//         changePage(1);
//     }


//     return (
//         <>
//             <div className=''>
//                 <Document
//                     // className="pdf_view"
//                     // canvasBackground="transparent"
//                     file={`${url}`}
//                     renderMode="canvas"
//                     options={{ workerSrc: "/pdf.worker.js" }}
//                     onLoadSuccess={onDocumentLoadSuccess}
//                 // onLoadProgress={onpreload}
//                 // className={`${stateview ? "react-pdf__Document" : "pdf__view"}`}
//                 >
//                     <Page width={600}
//                         height={600}
//                         scale={0.5}
//                         pageNumber={pageNumber}
//                         renderAnnotationLayer={false}
//                         renderTextLayer={false}
//                     />
//                     {/* <Page width={580} scale={0.9} pageNumber={pageNumber} /> */}
//                 </Document>
//             </div>
//             <div className='bg_transperency1'>
//                 <div>
//                     <Button
//                         type="button"
//                         disabled={pageNumber <= 1}
//                         onClick={previousPage}
//                         className="cursor buttonc"
//                     >
//                         {`${previous}`}
//                     </Button>
//                 </div>
//                 <div className="pagec">
//                     {pageNumber || (numPages ? 1 : '--')} / {numPages || '--'}
//                 </div>
//                 <div>
//                     <Button
//                         type="button"
//                         disabled={pageNumber >= numPages}
//                         onClick={nextPage}
//                         className="cursor buttonc"
//                     >
//                         {`${next}`}
//                     </Button>
//                 </div>
//             </div>
//         </>
//     )
// }

// export default PdfViewer

import React from 'react';
import { Worker } from '@react-pdf-viewer/core';
// Import the main component
import { Viewer } from '@react-pdf-viewer/core';
import { SpecialZoomLevel } from '@react-pdf-viewer/core';
// Import the styles
import '@react-pdf-viewer/core/lib/styles/index.css';
import './Post.css'

// import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';

// Import styles
// import '@react-pdf-viewer/default-layout/lib/styles/index.css';

function PdfViewer(props) {
    const url = props.location.state;
    // console.log(url)
    // const defaultLayoutPluginInstance = defaultLayoutPlugin();
    return (
        <div className='width_pdf'>
            <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.13.216/build/pdf.worker.min.js">
                <Viewer
                    // plugins={[defaultLayoutPluginInstance]}
                    fileUrl={url[0]}
                    defaultScale={SpecialZoomLevel.PageFit}
                />
            </Worker>
        </div>
    )
}

export default PdfViewer