import React, { useState, useEffect } from 'react';
import "../../utility/style.css";
import './ProfileViewSidebar.css'
import certificate from "../../Atom/svg/certificate.svg";
import ribbon from "../../Atom/svg/ribbon.svg";
import medal from "../../Atom/svg/medal.svg";
import Organisation from "../../Atom/modifiedicons/Organisations.svg"
import call from '../../Atom/modifiedicons/call.svg'
import '../Sidebar/Sidebar.css'
import moment from 'moment';
import { useHistory } from 'react-router';
import Doc from '../../../images/Logo2.svg'
import Avatar from '@mui/material/Avatar';
import birthday from '../../Atom/modifiedicons/birthday.svg'
import { IoMdInfinite } from 'react-icons/io'
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import DialogBox from '../../DialogBox/dialog';
import TabPanel from '@mui/lab/TabPanel';
import TabContext from '@mui/lab/TabContext';
import URL from '../../apiurl';
import Dialog from "@mui/material/Dialog";
import UserService from '../../../services/UserService';
import axios from 'axios';
import location_on from '../../Atom/modifiedicons/location_on.svg'
import { useParams } from 'react-router-dom';
import hashtag_icon from '../../Atom/modifiedicons/hashtag.svg'
import CloseIcon from '@mui/icons-material/Close';
import { Divider, Grid } from '@mui/material';
import Mail from '../../Atom/modifiedicons/Mail.svg'
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import ClearIcon from '@mui/icons-material/Clear';
import TwitterIcon from '@mui/icons-material/Twitter';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import FacebookIcon from '@mui/icons-material/Facebook';
import EmailIcon from '@mui/icons-material/Email';
import { MdOutlineKeyboardArrowDown } from 'react-icons/md';
import qualificationlogo from '../../../icons/qualificationlogo.svg';
import { useSelector } from 'react-redux';
import Languageimg from '../../../icons/langimage.svg';
import '../../Layout/MyProfile/updateprofile.css'
import { userProfile } from '../../../redux/Actions/actions';
import profimage from '../../../icons/Userprofilelogo.svg'

const MutualsView = (props) => {

    const history = useHistory()

    return (
        props?.data?.length > 0 ? props?.data?.map(user => (
            <div className="d-flex sidebar__tmb" key={user.id}>
                <Avatar src={props?.type === 'user' ? user?.profilePic : user?.profilePic || Doc} style={{ borderRadius: '7px', border: '1px solid lightgrey' }} />
                <div className="post__info e-align">
                    <div>
                        <h2 className="cursor capitalize" onClick={() => {
                            props?.type === 'user' ? history.push(`/${(user?.name?.replace(/ /g, '')).toLowerCase()}-${user?.id}`) : history.push(`/organization/${user?.id}`);
                            props?.close()
                        }
                        }>{user?.name}</h2>
                        <p className="capitalize">{user?.captionAndTagline}</p>
                    </div>
                </div>
            </div>
        )) : <p>No Mutuals</p>
    )
}

const MutualHashtag = (props) => {

    return (
        props?.data?.length > 0 ? props?.data?.map(tag => (
            <div className="d-flex sidebar__tmb" key={tag.id}>
                {/* <Avatar src={hashtag_icon} style={{borderRadius: '7px',border: '1px solid lightgrey'}}/> */}
                <img src={hashtag_icon} alt="hashtag" />
                <div className="post__info e-align">
                    <div>
                        <h2 className="cursor">{tag?.hashtag}</h2>
                    </div>
                </div>
            </div>
        )) : <p>No Mutual Topics</p>
    )
}

function ProfileViewSidebar(props) {
    const history = useHistory()
    const [profileData, setProfileData] = React.useState()
    const [value, setValue] = React.useState("1");
    const [count, setCount] = React.useState(3)
    const [open, setOpen] = React.useState(false)
    const [contactemail, setContactemail] = useState([])
    const [phone, setPhone] = useState([])
    const [dialogopen, setDialogopen] = useState(false)
    const state = useSelector(state => state.userProfile.userProfile)
    // console.log(state.kycStatus)
    const [hashtags, setHashtags] = React.useState([])
    const { id } = useParams()
    React.useEffect(() => {
        if (props?.profileData !== undefined) {
            setProfileData(props?.profileData)
        }
    }, [props])

    const address = profileData ? profileData?.user?.practioner?.address?.[0] : " "
    const contact = props.profileData?.user
    // console.log(contact)

    // console.log(profileData)
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const edu = profileData && profileData?.user ? profileData?.user?.practioner?.qualification : ""
    // console.log(edu)
    React.useEffect(() => {
        if (UserService.getToken()) {
            axios.get(`${URL.mutualHashtags}/${id}`, {
                headers: {
                    Authorization: `Bearer ${UserService.getToken()}`
                }
            }).then(res => setHashtags(res.data.data))
                .catch(err => setHashtags([]))
        }
        return () => { console.log("") }
    }, [props.id])

    const showmore = () => {
        setDialogopen(true)
    }
    const handledialogclose = () => {
        setDialogopen(false)
    }


    useEffect(() => {
        if (UserService.getToken()) {
            if (contact.hasOwnProperty('practioner') && contact?.practioner?.telecom && contact?.practioner?.telecom?.length > 0) {
                const telecomdata = contact?.practioner?.telecom
                let data1 = telecomdata.filter((element) => {
                    return element.system === "email"
                })
                // console.log(data1)
                setContactemail(data1)
            }
            if (contact?.hasOwnProperty('practioner') && contact?.practioner?.telecom && contact?.practioner?.telecom?.length > 0) {
                const telecomdata = contact?.practioner?.telecom
                let data2 = telecomdata.filter((element) => {
                    return element.system === "phone"
                })
                // console.log(data2)
                setPhone(data2)
            }
        }
        return () => { console.log("overviewpublic") }
    }, [contact])


    return (
        <>
            {open && <DialogBox open={open} handleDialog={() => setOpen(false)} style={{ padding: '0px' }}
                Title={<h4 className='newcontact-fieldheader e-align website_header'>Mutuals <CloseIcon onClick={() => setOpen(false)} className="cursor" /></h4>}
                Content={
                    <TabContext value={value}>
                        <Divider />
                        <Box sx={{ width: '100%', bgcolor: 'background.paper' }}>
                            <Tabs value={value} variant="fullWidth" onChange={handleChange} sx={{ justifyContent: 'space-between' }}>
                                <Tab label="Connections" value="1" />
                                <Tab label="Organizations" value="2" />
                                <Tab label="Topics" value="3" />
                            </Tabs>
                            <TabPanel value="1">
                                <MutualsView data={profileData?.mutuals?.mutualPeers} close={() => setOpen(false)} type="user" />
                            </TabPanel>
                            <TabPanel value="2">
                                <MutualsView data={profileData?.mutuals?.mutualOrganisation} close={() => setOpen(false)} type="org" />
                            </TabPanel>
                            <TabPanel value="3">
                                <MutualHashtag data={hashtags} />
                            </TabPanel>
                        </Box>
                    </TabContext>
                } />
            }
            {profileData !== undefined && !profileData?.blocked ? (
                <div className='profile_view_sidebar_desktop'>
                    <div className='mt-10'>
                        {/* <div style={{ position: "relative" }}>
                                <div className="profile__left">
                                    <Avatar style={{ height: "144px", width: "144px", position: 'absolute', top: '-100px', marginLeft: '30px', borderRadius: '20px' }} src={(profileData.user.practioner.photo[0].url) ? profileData.user.practioner.photo[0].url : profimage} />
                                </div>
                            </div> */}
                        {/* <div className="profile_view_sidebar__left"> */}
                        {/*   div for user details*/}
                        {/* <div className="sidebar__icons" style={{ padding: '10px 0px' }}>
                                    <div className="sidebar__icon">
                                        <div className="sidebar__title sidebar__pb fw__normal">Cetifications</div>
                                        <img src={certificate} alt="certificate" />
                                        <div className="sidebar__statNumber">{profileData.user.certificatesCount}</div>
                                    </div>
                                    <div className="sidebar__icon">
                                        <div className="sidebar__title sidebar__pb fw__normal">Badges</div>
                                        <img src={ribbon} alt="certificate" />
                                        <div className="sidebar__statNumber">{profileData.user.badgesCount}</div>
                                    </div>
                                    <div className="sidebar__icon">
                                        <div className="sidebar__title sidebar__pb fw__normal">Achievements</div>
                                        <img src={medal} alt="certificate" />
                                        <div className="sidebar__statNumber">{profileData.user.achievementsCount}</div>
                                    </div>
                                </div> */}

                        {/* <Divider></Divider> */}

                        {/*  experience */}

                        {/* education */}

                        <div className='border_img'>
                            <div>
                                <div className='sidebar__padding'>
                                    <div className="sidebar__stat" style={{ padding: "5px 0px 10px 0px" }}>
                                        <div className="sidebar__substat" style={{ alignItems: "center" }}>
                                            <img src={qualificationlogo} width="25px" height="25px"></img>
                                            <div style={{ paddingLeft: "10px" }} className="heading_public__text">Qualification</div>
                                        </div>
                                    </div>
                                    {profileData?.user?.practioner?.qualification && edu !== null && edu?.length > 0 ?
                                        <div>
                                            {edu?.slice(0, count)?.map((e, i) => (
                                                <div className="d-flex sidebar__pt" key={i}>
                                                    <div className="sidebar__substat ta__center" style={{ width: "100%" }}>
                                                        <div className="my-img domain-icon" id="education_img" >
                                                            <img src={Organisation} alt="organisation" width='25px' height="20px" />
                                                        </div>
                                                        <div className="sidebar__ml capitalize" style={{ fontSize: '13px', marginLeft: '20px', width: "80%" }}>
                                                            {/* {console.log(e)} */}
                                                            {/* <p style={{ fontWeight: 'bold', paddingBottom: '3px' }}>{e.code.coding[0].display}</p> */}
                                                            {(e?.code && e?.code?.coding?.[0]?.code === "BS") ? <span className='edu_text'></span> :
                                                                <span className='edu_text'>{e?.code?.coding?.[0]?.code}{" "}{"-"}{" "}</span>}
                                                            <span className='edu_text'>{e.code?.text}</span>
                                                            <p className='edu_sub_text'>{e.issuer?.display || e.issuer}</p>
                                                            <p className='edu_date_text'>{e.period && e?.period?.start && moment(e?.period?.start)?.format('MMM yyyy')} - {e?.period && e?.period?.end ? moment(e?.period?.end)?.format('MMM yyyy') : 'present'}</p>
                                                            {/* <p className='edu_date_text'>{moment(e.period.start).format('MMM yyyy')} - {e.period.end ? moment(e.period.end).format('MMM yyyy') : 'present'}</p> */}
                                                        </div>
                                                    </div>
                                                </div>
                                                // </div>
                                            ))}
                                            <div className="work__space sidebar__padding bg__white e-align icon-show">
                                                <div className="sidebar__substat ta__center">
                                                    {edu !== null && edu?.length > 3 ? count === 3 ? <p id="border_exp_btn" onClick={() => setCount(edu?.length)} className="cursor d-flex e-align" style={{ color: '#0137CB' }}>{edu?.length - 3} More Qualification{(edu?.length - 3) > 1 ? 's' : ''}&nbsp;
                                                        <MdOutlineKeyboardArrowDown />
                                                    </p> : <p onClick={() => setCount(3)} className="cursor" style={{ color: '#0137CB' }}>Hide</p> : ''}
                                                    {/* {exp.length > 3 ? count === 3 ? <p onClick={() => setCount(exp.length)} className="cursor" style={{ textDecoration: 'underline', color: '#700eca' }}>2 More in Experience</p> : <p onClick={() => setCount(3)} className="cursor" style={{ textDecoration: 'underline', color: '#700eca' }}>See Less</p> : ''} */}
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        <div style={{ padding: "15px" }}>
                                            <h2 style={{ fontSize: "18px", fontWeight: "500", textAlign: "center", color: "#36454F" }}>No Data</h2>
                                        </div>
                                    }
                                </div>
                            </div>
                            <div className='contact_left'>
                                <div style={{ display: "flex", alignItems: "center", backgroundColor: "#fff" }}>
                                    <h3 className='fw__normal' style={{ fontSize: "18px", fontWeight: "550", color: "rgb(35, 122, 196)", padding: "12px 12px 0px 20px" }}>Contact Details</h3>
                                </div>
                                <div className='sidebar__padding' style={{ backgroundColor: "#fff" }}>
                                    {/* location */}
                                    <div className="sidebar__stat sidebar__pt ">
                                        <div className="sidebar__substat">
                                            <img src={location_on} alt="" style={{ fontSize: "16px" }} />
                                            <div className="sidebar__title ml-2" style={{ fontSize: "16px", fontWeight: "400", color: "#36454F" }}>
                                                {address?.city || address?.state || address?.country ?
                                                    <>
                                                        <span>{address?.city ? address?.city : ''}</span>{" "}

                                                        {address?.city && address?.state ? ',' : ''}<span>{address?.state ? address?.state + ',' : ''}</span>{" "}

                                                        <span>{address?.country ? address?.country : ''}</span>

                                                    </> : 'N/A'}
                                            </div>
                                        </div>
                                    </div>
                                    {/* {console.log(profileData.user.contactInfo)} */}
                                    {/* email */}
                                    <div className="sidebar__stat pt-2 sidebar__pt ">
                                        {UserService.getToken() !== undefined ?
                                            <div className="sidebar__substat ta__center">
                                                <img src={Mail} alt="" style={{ fontSize: "16px" }} />
                                                <div className="ml-2" >
                                                    {contactemail?.length > 0 && state?.kycStatus === "APPROVED" ?
                                                        <div >
                                                            <span style={{ fontSize: "16px", fontWeight: "400", color: "#36454F" }}>
                                                                {contactemail?.[0]?.value}{" "}
                                                            </span>
                                                            {contactemail?.length > 1 ?
                                                                <span onClick={showmore} className="cursor" style={{ fontSize: "16px", fontWeight: "400", color: "#36454F" }}>
                                                                    {"+" + (contactemail?.length - 1) + "more"}
                                                                </span> : ""
                                                            }
                                                        </div>
                                                        :
                                                        <div>
                                                            {profileData?.user?.contactInfo ?
                                                                <p style={{ fontSize: "16px", fontWeight: "400", color: "#36454F" }}>
                                                                    {profileData?.user?.contactInfo}
                                                                </p>
                                                                :
                                                                <p style={{ fontSize: "16px", fontWeight: "400", color: "#36454F" }}>N/A</p>
                                                            }
                                                        </div>
                                                    }
                                                </div>
                                                <Dialog
                                                    open={dialogopen}
                                                    onClose={handledialogclose}
                                                    aria-labelledby="alert-dialog-title"
                                                    aria-describedby="alert-dialog-description"
                                                >
                                                    <Box className='contact_details'>
                                                        <div className='space_btw_center'>
                                                            <h5>Contact Details</h5>
                                                            <ClearIcon className='cursor' onClick={handledialogclose} />
                                                        </div>
                                                        <div className="pt-2 sidebar__pt ">
                                                            <ul className="ta__center" id="mobilecontact_list">
                                                                {contactemail?.map((element, index) => {
                                                                    return (
                                                                        <div className="mt-10" key={index}>
                                                                            <li style={{ fontSize: "16px", fontWeight: "400", color: "#36454F" }}>
                                                                                {element?.value}
                                                                            </li>
                                                                        </div>
                                                                    )
                                                                })}
                                                            </ul>
                                                        </div>
                                                    </Box>
                                                </Dialog>
                                            </div> :
                                            <div className="space_btw_center" >
                                                <img src={Mail} alt="" style={{ fontSize: "16px" }} />
                                                <p className='ml-2' style={{ fontSize: "16px", fontWeight: "400", color: "#36454F" }}>
                                                    {profileData?.user ? profileData?.user?.contactInfo : ""}
                                                </p>
                                            </div>
                                        }
                                    </div>
                                    {/* phone */}
                                    <div className="sidebar__stat sidebar__pt ">
                                        {UserService.getToken() !== undefined ?
                                            <div className="sidebar__substat ta__center">
                                                <img src={call} alt="" style={{ fontSize: "16px" }} />
                                                <div className="ml-2" >
                                                    {phone?.length > 0 && state.kycStatus === "APPROVED" ?
                                                        <div>
                                                            <span style={{ fontSize: "16px", fontWeight: "400", color: "#36454F" }}>
                                                                {phone?.[0]?.value}{" "}
                                                            </span>
                                                            {phone?.length > 1 ?
                                                                <span onClick={showmore} className="cursor" style={{ fontSize: "16px", fontWeight: "400", color: "#36454F" }}>
                                                                    {"+" + (phone?.length - 1) + "more"}
                                                                </span> : ""
                                                            }
                                                        </div>
                                                        :
                                                        <div>
                                                            {profileData?.user?.contactInfo ?
                                                                <p style={{ fontSize: "16px", fontWeight: "400", color: "#36454F" }}>
                                                                    {profileData?.user?.contactInfo}
                                                                </p>
                                                                :
                                                                <p style={{ fontSize: "16px", fontWeight: "400", color: "#36454F" }}>N/A</p>
                                                            }
                                                        </div>
                                                    }
                                                </div>
                                                <Dialog
                                                    open={dialogopen}
                                                    onClose={handledialogclose}
                                                    aria-labelledby="alert-dialog-title"
                                                    aria-describedby="alert-dialog-description"
                                                >
                                                    <Box className='contact_details'>
                                                        <div className='space_btw_center'>
                                                            <h5>Contact Details</h5>
                                                            <ClearIcon className='cursor' onClick={handledialogclose} />
                                                        </div>
                                                        <div className="pt-2 sidebar__pt ">
                                                            <ul className="ta__center" id="mobilecontact_list">
                                                                {phone?.map((element, index) => {
                                                                    return (
                                                                        <div className="mt-10" key={index}>
                                                                            <li style={{ fontSize: "16px", fontWeight: "400", color: "#36454F" }}>
                                                                                {element?.value}
                                                                            </li>
                                                                        </div>
                                                                    )
                                                                })}
                                                            </ul>
                                                        </div>
                                                    </Box>
                                                </Dialog>
                                            </div>
                                            :
                                            <div className="space_btw_center" >
                                                <img src={call} alt="" style={{ fontSize: "16px" }} />
                                                <p className='ml-2' style={{ fontSize: "16px", fontWeight: "400", color: "#36454F" }}>
                                                    {profileData?.user ? profileData?.user?.contactInfo : ""}
                                                </p>
                                            </div>
                                        }
                                    </div>
                                    <div className='mt-20 pl-3'>
                                        <h3 className='fw__normal' style={{ color: "#000000", fontSize: "18px" }}>Social Accounts</h3>
                                    </div>
                                    <div className='mt-10 pl-3' style={{ display: "flex", alignItems: "center" }}>
                                        <div>
                                            <TwitterIcon style={{ color: 'rgb(29, 155, 240)', width: "25px", height: "25px", paddingRight: "10px" }} />
                                        </div>
                                        <div>
                                            <FacebookIcon style={{ color: '#385898', width: "25px", height: "25px", paddingRight: "10px" }} />
                                        </div>
                                        <div>
                                            <LinkedInIcon style={{ color: '#007DCD', width: "25px", height: "25px", paddingRight: "10px" }} />
                                        </div>
                                        <div>
                                            <WhatsAppIcon style={{ color: 'green', width: "25px", height: "25px", paddingRight: "10px" }} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="sidebar__padding bg__white" style={{ paddingBottom: "20px" }}>
                                <div className='mt-10' style={{ display: "flex", alignItems: "center" }}>
                                    <div><img src={Languageimg} height="30px" width="30px"></img></div>
                                    <div style={{ paddingLeft: "10px" }}>
                                        <span style={{ fontSize: "18px", fontWeight: "550", color: "rgb(35, 122, 196)" }}>Communication (Language)</span>
                                        <p style={{ padding: "8px 0px 0px 10px" }}>English</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* {(profileData.mutuals && (profileData.mutuals.mutualPeers.length > 0 || profileData.mutuals.mutualOrganisation.length > 0 || hashtags.length > 0)) ?
                            <div className="sidebar__padding " style={{ marginTop: '10px' }}>
                                <div className="sidebar__stat ta__left d-flex butons">
                                    <div className="d-flex e-align">
                                        <IoMdInfinite style={{ fontSize: "25px" }} />
                                        <div className="sidebar__title sidebar__ml">
                                            {profileData.mutuals && profileData.mutuals.mutualPeers.length > 0 ? <p className="pb-1">{profileData.mutuals.mutualPeers.length} Mutual Connection</p> : ''}
                                            <p>{profileData.mutuals && profileData.mutuals.mutualOrganisation.length > 0 ? `${profileData.mutuals.mutualOrganisation.length} ${profileData.mutuals.mutualOrganisation.length > 1 ? 'Organisations' : 'Organisation'}` : ''}
                                                {hashtags.length > 0 ? `, ${hashtags.length} ${hashtags.length > 1 ? 'Topics' : 'Topic'}` : ''}</p>
                                        </div>
                                    </div>
                                    <div className="sidebar__statNumber">
                                        <p className="ta__right follow-link add-cover cursor" style={{ fontSize: "13px", float: "right" }} onClick={() => setOpen(true)}>See All</p>
                                    </div>
                                </div>
                            </div> : ''} */}

                        {profileData && profileData?.pages?.length > 0 ?
                            <div className='border_img' style={{ marginTop: "15px" }}>
                                <div className="sidebar__padding border_img">
                                    <div className="sidebar__substat" style={{ alignItems: "center" }}>
                                        {/* <img src={Pages} style={{ fontSize: "20px" }} /> */}
                                        <div className="heading_public__text">Organisation Pages</div>
                                    </div>
                                    {/* <div className="sidebar__title sidebar__ml flex__end">Likes</div> */}
                                </div>
                                <div className="height_public_pages" style={{ paddingTop: '0px' }}>
                                    {profileData?.pages?.map((item, ind) => {
                                        return (
                                            <div key={ind} className="sidebar_statPage" style={{ borderBottom: "0px" }}>
                                                <div className="sidebar_pages e-align" style={{ flexGrow: 1 }}>
                                                    <div style={{ width: '20%' }}><Avatar style={{ height: "32px", width: "32px", borderRadius: "7px" }} src={item?.logo ? item?.logo : Doc} /></div>
                                                    <div className="cursor capitalize" style={{ fontSize: "14px", width: '80%' }}>
                                                        {/* <b>{item.name}</b> */}
                                                        <p className="name_hover text-ellipsis" onClick={() => history.push(`/organization/${item.id}`)}> {item?.name}</p>
                                                        <p className="fontItalic text-ellipsis" style={{ color: '#635660', marginTop: '5px', fontSize: '13px' }}>{item?.captionAndTagline}</p>

                                                    </div>
                                                </div>
                                                <p className="d-flex e-align" style={{ marginTop: "0px" }}>{item.count}</p>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div> : ''}

                        {/* Events */}

                        {profileData?.events && profileData?.events?.length > 0 ?
                            <>
                                <div className="sidebar__padding">
                                    <div className="sidebar__substat" style={{ alignItems: "center" }}>
                                        {/* <img src={Pages} style={{ fontSize: "20px" }} /> */}
                                        <div className="font-caption fw__semibold sidebar__lh">Events</div>
                                    </div>
                                </div>
                                <div className="" style={{ paddingTop: '0px' }}>
                                    {profileData?.events?.map((item, ind) => {
                                        return (
                                            <div key={ind} className="sidebar_statPage" style={{ borderBottom: "0px" }}>
                                                <div className="sidebar_pages e-align" style={{ flexGrow: 1 }}>
                                                    <div style={{ width: '20%', backgroundColor: '#3b10cc', color: 'white', padding: '3px' }} className="textCenter">
                                                        <p>{moment.utc(item?.openingTime).local().format('DD')}</p>
                                                        <p>{moment.utc(item?.openingTime).local().format('MMM')}</p>
                                                    </div>
                                                    <div className="cursor capitalize" style={{ fontSize: "14px", width: '77%', marginLeft: '3%' }}>
                                                        {/* <b>{item.name}</b> */}
                                                        <p className="name_hover text-ellipsis"> {item?.title}</p>
                                                        <p className="fontItalic text-ellipsis" style={{ color: '#635660', marginTop: '5px', fontSize: '13px' }}>{item?.eventType}</p>

                                                    </div>
                                                </div>
                                                {/* <p className="d-flex e-align" style={{marginTop: "0px"}}><FiEdit3/></p> */}
                                            </div>
                                        )
                                    })}
                                </div>
                            </> : ''}
                    </div>
                </div>

            ) : ("")
            }

        </>
    )
}

export default ProfileViewSidebar
