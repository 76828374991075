import * as React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import PublicIcon from '@mui/icons-material/Public';
import { ReactComponent as Users } from "../../Atom/svg/stetch.svg";
import { ReactComponent as Peers } from '../../Atom/svg/peers.svg';
import '../postingdropdown/postingdropdown.css'

const options = [{ Img: PublicIcon, option: 'Anyone (Public)', value: 'ANYONE' },
{ Img: Users, option: 'Docsteth Users', value: 'DOCSTETH_USERS' },
{ Img: Peers, option: 'Peers Connects', value: 'PEERS_CONNECT' }];

export default function SimpleListMenu(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selectedIndex, setSelectedIndex] = React.useState(0);
  const open = Boolean(anchorEl);


  React.useEffect(() => {
    options?.map((op, i) => op?.value === props?.value ? setSelectedIndex(i) : '')
  }, [])


  const handleClickListItem = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuItemClick = (event, index) => {
    setSelectedIndex(index);
    setAnchorEl(null);
    props.postPrivacy(options?.[index]?.value)
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const Image = options?.[selectedIndex]?.Img

  return (
    <div className='public_view'>
      <List
        component="nav"
        aria-label="Device settings"
        className='public_who_can_see'
      >
        <ListItem
          button
          id="lock-button"
          aria-haspopup="listbox"
          aria-controls="lock-menu"
          aria-label="when device is locked"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClickListItem}
          className='device__locked'
        >
          <ListItemIcon sx={{ minWidth: '40px', color: '#0070cd', pr: 1 }}>
            <Image style={{ color: 'black', width: '40px' }} />
          </ListItemIcon>
          <ListItemText
            primary={<p id='public_mobile_view' style={{ color: '#2E3B43', fontSize: '12px' }}>Who Can See</p>}
            secondary={<span style={{ color: 'black' }}>{options?.[selectedIndex]?.option}</span>}
          />
          <ListItemIcon sx={{ minWidth: 'auto', color: '#0070cd', pl: 1 }}>
            <KeyboardArrowDownIcon />
          </ListItemIcon>
        </ListItem>
      </List>
      <Menu
        id="lock-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'lock-button',
          role: 'listbox',
        }}
      >
        {options?.map(({ option, Img }, index) => (
          <MenuItem
            key={index}
            // disabled={index === 0}
            selected={index === selectedIndex}
            onClick={(event) => handleMenuItemClick(event, index)}
            sx={{ color: 'black', width: { sm: '220px', xs: 'calc(100vw - 115px)' } }}
          >
            <ListItemIcon sx={{ minWidth: 'auto', color: '#0070cd' }}>
              {/* <img src={option.img} alt="public"/> */}
              {/* {img} */}
              <Img sx={{ width: '45px!important', color: 'black', fontSize: '28px!important' }} />
            </ListItemIcon>
            {option}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
}
