import React, { useState, useEffect } from 'react';
import { Button, Grid, Box, Tab, Card, CardContent, IconButton } from '@mui/material';
import './Feed.css';
import InputOption from "../Feed/InputOption";
import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import Post from "../Post/Post";
import '../../utility/style.css';
import '../../Molecule/KnowledgeQuiz/QuizModal.css'
import description from '../../../images/article.svg'
import event_icon from '../../../images/event.svg'
import celebration from '../../../images/celebration.svg'
import { styled } from '@mui/material/styles';
import poll_icon from '../../../images/poll.svg'
import video from '../../../images/evideo.svg'
import CLEAR from '../../../images/clear.svg'
import clear from '../../../images/clear_meta.svg'
import bgimage from "../../../images/userfact.jpg";
// import sutucase from '../../Atom/modifiedicons/sutucase.svg'
import axios from 'axios';
// import URL from '../../apiurl'
import URL from '../../apiurl';
import { useRouteMatch, useHistory, useParams } from 'react-router';
import Snackbar from '@mui/material/Snackbar'
import UserService from '../../../services/UserService';
import CircularProgress from '@mui/material/CircularProgress';
import '../../Layout/publish/publish.css'
import AddIcon from '@mui/icons-material/Add';
import CreatePost from './createpost';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Development from '../EbmKit/development';
import Tabs from '@mui/material/Tabs';
// import { styled } from '@mui/material/styles';
import Hashtagtrending from './Hashtagtrending';
import { useDispatch, useSelector } from 'react-redux';
import { getLinkedAccounts } from '../../../redux/Actions/connectActions';
import { headers } from '../../../redux/Actions/connectActions';
import InfiniteScroll from 'react-infinite-scroll-component';
import { doRefresh } from '../../../redux/Actions/actions';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { LensOutlined, LensRounded } from '@mui/icons-material';
// import TabsUnstyled from '@mui/base/TabsUnstyled';

export const getLoc = async () => {
    const position = await getCurrentPosition()
    const loc = position.coords ? `${position.coords.latitude},${position.coords.longitude}` : null
    return loc
}
function getCurrentPosition() {
    return new Promise((resolve, reject) => {
        const Location = window.navigator && window.navigator.geolocation
        if (Location) {
            Location.getCurrentPosition(
                position => { resolve(position) },
                error => { resolve({}) }
            )
        }
    })
}
export const MTabs = styled(TabList)({
    '& .MuiTabs-indicator': {
        background: "none",
    },
});

const responsive = {
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 3,
        slidesToSlide: 3 // optional, default to 1.
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 2,
        slidesToSlide: 2 // optional, default to 1.
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1,
        slidesToSlide: 1 // optional, default to 1.
    }
};

const ButtonGroup = ({ next, previous, goToSlide, ...rest }) => {
    const { carouselState: { currentSlide } } = rest;
    return (
        <div className="unique-button-group-container">
            <IconButton
                aria-label="arrow-left"
                className={`carousel-arrow-button ${currentSlide === 0 ? 'disable' : ''}`}
                onClick={() => previous()}
            >
                <KeyboardArrowLeftIcon fontSize="inherit" />
            </IconButton>
            <IconButton
                aria-label="arrow-right"
                className="carousel-arrow-button"
                onClick={() => next()}
            >
                <KeyboardArrowRightIcon fontSize="inherit" />
            </IconButton>
        </div>
    );
};

const CustomDot = ({ onMove, index, onClick, active }) => {
    return (
        <li
            style={{
                cursor: "pointer",
                color: active ? "#134265" : "#fff",
                marginTop: '10px'
            }}
            onClick={() => onClick()}
        >
            {active ? (
                <LensRounded style={{ fontSize: '14px' }} />
            ) : (
                <LensOutlined style={{ fontSize: '14px', color: '#000' }} />
            )}
        </li>
    );
};

export default function Feed({ post, visible, create, view }) {
    // console.log("view", view)
    // console.log("post", post)
    const { path } = useRouteMatch();
    const [keyState, setKeyState] = useState('')
    const [value, setValue] = React.useState('1');
    const [alert, setAlert] = useState({ open: false, msg: '', post: false })
    const [load, setLoad] = useState(false)
    const [posts, setPosts] = useState([]);
    const [pageno, setPageno] = useState(0)
    const [noMore, setNoMore] = useState(true)
    const accounts = useSelector(state => state.connect.linkedAccounts)
    console.log(posts, "all posts")
    console.log(keyState, "ddddddddddddd");
    console.log(load, "load");

    const { id } = useParams()
    const history = useHistory()

    const getRefreshFeed = useSelector(state => state.userProfile.getRefreshFeed)
    const orgData = useSelector(state => state.registration.userOrg);
    console.log(orgData, "orgDatav")
    const dispatch = useDispatch()
    const limit = 25

    useEffect(() => {
        if (getRefreshFeed === 'refreshFeed') {
            setLoad(false)
            setPageno(0)
            setLoad(true)
            // getFeed()
            // getPosts()
            setTimeout(() => {
                dispatch(doRefresh(''))
            }, 2000)
        }
    }, [getRefreshFeed])
    const handleClose = () => {
        setKeyState('')
    };


    // useEffect(async () => {
    //     setLoad(true)
    //     setPageno(0)
    // }, [id]);

    useEffect(() => {
        const fetchData = async () => {
            setLoad(true);
            setPageno(0);
        };
        fetchData();
    }, [id]);

    useEffect(() => {
        if (pageno === 0) {
            getPosts()
        }
    }, [pageno])

    useEffect(() => {
        if (accounts.length === 0)
            dispatch(getLinkedAccounts())
    }, [])

    const postSuccess = (data) => {
        const nextdata = data.results ? data.results : data
        setPageno(pageno + limit)
        if (pageno === 0) {
            console.log("first")
            { (post) ? setPosts(post) : setPosts(nextdata) }
        } else {
            console.log("first88")
            setPosts([...posts, ...nextdata])
        }
        if (nextdata.length === 0 || nextdata.length < limit) {
            setNoMore(false)
        }
        setAlert({ open: false, msg: '', post: false })
        setLoad(false)
    }
    console.log(post, "dskjfshdfkjshkj")
    const postFailure = () => {
        setLoad(false)
        setAlert({ open: true, msg: 'Internal Server Error.Try after sometime' })
    }

    const postAPIS = async (key) => {


        const APIS = {
            timeline: `${URL.getPosts}/${UserService.getUserId()}`,
            feedPosts: URL.feed,
            // orgPosts: id?.length >= 3 ? `${URL.findPostByPageId}/${id}` : ""
            orgPosts: `${URL.findPostByPageId}/${id}`
        }

        await axios.get(`${APIS[key]}?limit=${limit}&offset=${pageno}`, headers())
            .then((res) => {
                console.log("first", res.data)
                postSuccess(res.data)
            }
            )
            .catch(() => {
                postFailure()
            })
    }

    // const getPosts = async () => {
    //     if (path.includes('/myprofile')) {
    //         await postAPIS('timeline')
    //     }
    //     else if (path.includes('/admin/organization')) {
    //         getOrgFeed()
    //     }
    //     else {
    //         getFeed()
    //     }
    // }
    const getPosts = async () => {
        try {
            if (path.includes('/myprofile')) {
                await postAPIS('timeline');
                console.log("first111")
            } else if (path.includes('/admin/organization')) {
                console.log("first222")
                await getOrgFeed();
            } else {
                console.log("first333")
                await getFeed();
            }
        } catch (error) {
            console.error('Error fetching posts:', error);
        }
    };


    // const getFeed = async () => {
    //     await postAPIS('feedPosts')
    // }
    const getFeed = async () => {
        try {
            const response = await postAPIS('feedPosts');
            // Optionally process or manipulate the response data here
            return response; // Return the response data if needed
        } catch (error) {
            console.error('Error fetching feed posts:', error);
            throw error; // Rethrow the error to handle it further up the call stack
        }
    };

    const getOrgFeed = async () => {
        await postAPIS('orgPosts')
    }

    // const getOrgFeed = async () => {
    //     try {
    //         const response = await postAPIS('orgPosts');
    //         // Optionally process or manipulate the response data here
    //         return response; // Return the response data if needed
    //     } catch (error) {
    //         console.error('Error fetching organization feed posts:', error);
    //         throw error; // Rethrow the error to handle it further up the call stack
    //     }
    // };


    const handlePopup = (key) => {
        setKeyState(key)
    }

    const handleNavigate = (e) => {
        console.log(e)
        e.preventDefault();
        history.replace('/newarticle');
        document.location.reload()
    }

    return (
        <div
        // className="feed"
        >
            {keyState ? (
                <CreatePost
                    keystate={keyState}
                    handleClose={handleClose}
                    getPosts={getPosts}
                    getFeed={getFeed}
                    getOrgFeed={getOrgFeed}
                />
            ) : (
                ""
            )}

            {/*----------------- Desktop------------------------- */}

            {!create ? (
                <Grid container>
                    <Grid item md={12} xs={12}>
                        <Box>
                            <TabContext value={value}>
                                {!view ? (
                                    <MTabs
                                        id="tab_design"
                                        aria-label="lab API tabs example"
                                        value={value}
                                        onChange={(e, newValue) => setValue(newValue)}
                                        textColor="inherit"
                                        scrollButtons={false}
                                    >
                                        <Tab
                                            className={`${value == 1 ? "tab_circle" : "tab_circle1"
                                                }`}
                                            sx={{ fontSize: "17px", textTransform: "capitalize" }}
                                            value="1"
                                            label="suggested"
                                        />
                                        <Tab
                                            className={`${value == 1 ? "tab_circle2" : "tab_circle3"
                                                }`}
                                            sx={{ fontSize: "17px", textTransform: "capitalize" }}
                                            value="2"
                                            label="trending"
                                        />

                                    </MTabs>
                                ) : (
                                    ""
                                )}
                                <TabPanel value="1" sx={{ p: "0px" }}>
                                    {!view ? (
                                        <div className="feed_desktop">
                                            <div className="feed__inputContainer border_img">
                                                <div
                                                    className="feed__input cursor"
                                                    onClick={() => {
                                                        handlePopup("text");
                                                    }}
                                                >
                                                    <p>Write Something...</p>
                                                </div>
                                                <div className="feed__inputOptions border__rr border__lr">
                                                    <div className="newcontact-fieldheader">
                                                        <div
                                                            className="feed__inputOption justify-between"
                                                            style={{ marginLeft: "0px", width: "70%" }}
                                                        >
                                                            <InputOption
                                                                src={celebration}
                                                                alt="Celebrate"
                                                                title="Celebrate"
                                                                handleClick={() => handlePopup("celebrate")}
                                                            />
                                                            <InputOption
                                                                src={poll_icon}
                                                                title="Create Poll"
                                                                alt="Poll"
                                                                handleClick={() => handlePopup("poll_form")}
                                                            />
                                                            {/* <InputOption src={event_icon} title="Create Event" alt="Event"
                                                            handleClick={
                                                                () => handlePopup('event_form')}
                                                        /> */}
                                                            <InputOption
                                                                src={description}
                                                                title="Write Article"
                                                                alt="Article"
                                                                handleClick={(event) => {
                                                                    handleNavigate(event);
                                                                }}
                                                            />
                                                        </div>
                                                        <div className="">
                                                            <Button
                                                                className="add-option evnt-btn"
                                                                sx={{ borderRadius: "10px!important" }}
                                                                onClick={() => {
                                                                    handlePopup("text");
                                                                }}
                                                            >
                                                                New Post
                                                            </Button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ) : (
                                        ""
                                    )}
                                    {load ? (
                                        <div
                                            style={{ height: "300px" }}
                                            className="elib-right__content"
                                        >
                                            <CircularProgress disableShrink />
                                        </div>
                                    ) : (
                                        <>
                                            {!view ? (
                                                <InfiniteScroll
                                                    dataLength={posts.length}
                                                    next={getPosts}
                                                    hasMore={noMore}
                                                    style={{ overflow: "hidden" }}
                                                    scrollThreshold="75%"
                                                    loader={
                                                        <div style={{ textAlign: "center" }}>
                                                            <CircularProgress disableShrink />
                                                        </div>
                                                    }
                                                    endMessage={
                                                        <p
                                                            style={{ textAlign: "center", margin: "20px" }}
                                                        >
                                                            {" "}
                                                            No more posts
                                                        </p>
                                                    }
                                                >
                                                    {posts?.map((post, index) => (
                                                        <div key={index}>
                                                            <Post
                                                                post={post}
                                                                getPosts={() => {
                                                                    setAlert({
                                                                        open: true,
                                                                        msg: "Deleting...",
                                                                        post: true,
                                                                    });
                                                                    getPosts();
                                                                }}
                                                            />
                                                        </div>
                                                    ))}
                                                </InfiniteScroll>
                                            ) : (

                                                <>
                                                    <CardContent>
                                                        <div
                                                            style={{
                                                                display: "flex",
                                                                justifyContent: "space-between",
                                                                alignItems: "center",
                                                            }}
                                                        >
                                                            <div
                                                                style={{
                                                                    display: "flex",
                                                                    alignItems: "center",
                                                                    gap: 20,
                                                                }}
                                                            >
                                                                <h6
                                                                    style={{
                                                                        color: "#000",
                                                                        fontSize: 18,
                                                                        fontWeight: 500,
                                                                        marginTop: 5,
                                                                    }}
                                                                >
                                                                    Timeline
                                                                </h6>
                                                                <IconButton
                                                                    aria-label="add"
                                                                    size="medium"
                                                                    color="primary"
                                                                    onClick={() => handlePopup('text')}
                                                                    style={{ margin: "0px" }}
                                                                >
                                                                    <AddCircleRoundedIcon fontSize="inherit" />
                                                                </IconButton>
                                                            </div>
                                                        </div>
                                                    </CardContent>
                                                    <div style={{ marginTop: "10px" }}>
                                                        {posts?.map((post, index) => (
                                                            <div key={index} style={{ marginTop: "10px" }}>
                                                                <Post
                                                                    fromAdminOrg="adminOrg"
                                                                    post={post}
                                                                    getPosts={() => { setAlert({ open: true, msg: 'Deleting...', post: true }); getPosts() }} />
                                                            </div>

                                                        ))}
                                                    </div>
                                                </>

                                                // </Card>

                                                // <Card elevation={0}>
                                                //     <CardContent>
                                                //         <div
                                                //             style={{
                                                //                 display: "flex",
                                                //                 justifyContent: "space-between",
                                                //                 alignItems: "center",
                                                //             }}
                                                //         >
                                                //             <div
                                                //                 style={{
                                                //                     display: "flex",
                                                //                     alignItems: "center",
                                                //                     gap: 20,
                                                //                 }}
                                                //             >
                                                //                 <h6
                                                //                     style={{
                                                //                         color: "#000",
                                                //                         fontSize: 18,
                                                //                         fontWeight: 500,
                                                //                         marginTop: 5,
                                                //                     }}
                                                //                 >
                                                //                     Timeline
                                                //                 </h6>
                                                //                 <IconButton
                                                //                     aria-label="add"
                                                //                     size="medium"
                                                //                     color="primary"
                                                //                     onClick={() => handlePopup('text')}
                                                //                     style={{ margin: "0px" }}
                                                //                 >
                                                //                     <AddCircleRoundedIcon fontSize="inherit" />
                                                //                 </IconButton>
                                                //             </div>
                                                //         </div>
                                                //         <div>
                                                //             <Carousel

                                                //                 swipeable={false}
                                                //                 draggable={false}
                                                //                 showDots={true}
                                                //                 responsive={responsive}
                                                //                 ssr={true}
                                                //                 keyBoardControl={true}
                                                //                 containerClass="carousel-container-3"
                                                //                 removeArrowOnDeviceType={["tablet", "mobile",]}
                                                //                 // customButtonGroup={<ButtonGroup />}
                                                //                 renderButtonGroupOutside={true}
                                                //                 customDot={<CustomDot />}
                                                //                 arrows={false}
                                                //             >
                                                //                 {posts?.map((post, index) => (
                                                //                     <div key={index} >
                                                //                         <Post
                                                //                             fromAdminOrg="adminOrg"
                                                //                             post={post}
                                                //                             getPosts={() => { setAlert({ open: true, msg: 'Deleting...', post: true }); getPosts() }} />
                                                //                     </div>))}
                                                //             </Carousel>
                                                //         </div>
                                                //     </CardContent>
                                                //     {/* <Divider
                                                //         sx={{
                                                //             borderBottomWidth: 5,
                                                //             borderRadius: 20,
                                                //             color: "#F7F7F7",
                                                //         }}
                                                //     /> */}
                                                //     {/* <CardActions>
                                                //         <Button
                                                //             variant="contained"
                                                //             sx={{
                                                //                 backgroundColor: "transparent",
                                                //                 color: "#A3A3A3",
                                                //                 boxShadow: "none",
                                                //                 textTransform: "capitalize",
                                                //                 marginX: "auto",
                                                //                 alignItems: "center",
                                                //                 "&:hover": {
                                                //                     backgroundColor: "transparent",
                                                //                 },
                                                //             }}
                                                //         >
                                                //             Show All Details
                                                //             <ArrowRightAltIcon />
                                                //         </Button>
                                                //     </CardActions> */}
                                                // </Card>
                                            )}
                                        </>
                                    )}

                                    {/* <div
                                        className="button__add_icon"
                                        onClick={() => {
                                            handlePopup("text");
                                        }}
                                    >
                                        <div className="addicon">
                                            <div className="fabdetail">
                                                <span><AddIcon style={{ marginBottom: '-5px' }} /></span>
                                            </div>
                                        </div>
                                    </div> */}
                                </TabPanel>
                                <TabPanel value="2" sx={{ m: 0, p: 0 }}>
                                    <Grid>
                                        <Hashtagtrending />
                                    </Grid>
                                    {/* <Development /> */}
                                </TabPanel>
                            </TabContext>
                        </Box>
                    </Grid>
                </Grid>
            ) : (
                ""
            )
            }


            <Snackbar
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                autoHideDuration={alert.post ? null : 2000}
                open={alert.open}
                onClose={() => setAlert({ open: false, msg: '', post: false })}
                message={alert.msg}
            />
        </div >

    )
}