import React, { useState, useEffect } from 'react'
import './AboutWriteBox.css'
import Stethscope from '../../../icons/stethscope.svg';
import Organisation from "../../Atom/modifiedicons/Organisations.svg"
import Avatar from '@mui/material/Avatar';
import accomplishments from '../../Atom/modifiedicons/accomplishments.svg';
import { useHistory } from 'react-router';
import certificate from "../../Atom/svg/certificate.svg";
import ribbon from "../../Atom/svg/ribbon.svg";
import medal from "../../Atom/svg/medal.svg";
import { Box } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import { accomplishment } from '../../../redux/Actions/actions';
import { useDispatch, useSelector } from 'react-redux';
import { MdOutlineKeyboardArrowDown } from 'react-icons/md';
import UserService from '../../../services/UserService';
import moment from 'moment';
import Dialog from "@mui/material/Dialog";
import Navigate from '../../../icons/navigate.svg'
import Vollmond from '../../../icons/vollmond.svg';
import qualificationlogo from '../../../icons/qualificationlogo.svg';
import Certificate from '../../Atom/svg/certificate.svg';
import achevments from '../../Atom/modifiedicons/achevments.svg';
import Badge from "../../Atom/svg/ribbon.svg";
import location_on from '../../Atom/modifiedicons/location_on.svg'
import birthday from '../../Atom/modifiedicons/birthday.svg'
import MailIcon from '../../Atom/modifiedicons/Mail.svg'


import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import TwitterIcon from '@mui/icons-material/Twitter';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import FacebookIcon from '@mui/icons-material/Facebook';
import call from '../../Atom/modifiedicons/call.svg';
import Languageimg from '../../../icons/langimage.svg';
import Doc from '../../../images/Logo2.svg'
import '../../Layout/MyProfile/updateprofile.css'
import axios from 'axios';
import URL from '../../apiurl';
import { useParams } from 'react-router-dom';

function Overviewpublic(profileData) {
    // console.log(profileData.profileData)
    const history = useHistory()
    const [count, setCount] = useState(3)
    const [educount, setEducount] = useState(3)
    const [acount, setAcount] = useState(5)
    const [phone, setPhone] = useState([])
    const [isReadMore, setIsReadMore] = useState(true);
    const [dialogopen, setDialogopen] = useState(false)
    const [acc, setAcc] = useState([])
    const contact = profileData?.profileData?.user
    // console.log(contact)
    const [contactemail, setContactemail] = useState([])
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(accomplishment())
    }, [])
    // const acc = useSelector(state => state.userProfile.accomplishment)
    const acomplishmentdata = profileData?.profileData?.accomplishment
    // console.log(acc)
    const toggleReadMore = () => {
        setIsReadMore(!isReadMore);
    };
    const showmore = () => {
        setDialogopen(true)
    }
    const handledialogclose = () => {
        setDialogopen(false)
    }
    const exp = profileData?.profileData?.experience
    const edu = profileData && profileData?.profileData?.user ? profileData?.profileData?.user?.practioner?.qualification : ""
    const address = profileData?.profileData ? profileData?.profileData?.user?.practioner?.address?.[0] : " "
    // const telecom = profileData.profileData ? profileData.profileData.user.practioner.telecom[0] : " "
    const state = useSelector(state => state.userProfile.userProfile)
    // console.log(state)

    const { id } = useParams()
    // console.log(id)
    useEffect(() => {
        axios.get(`${URL.accomplishments}/${id}`, {
            headers: {
                Authorization: `Bearer ${sessionStorage.getItem('kctoken')}`
            }
        }).then(res => {
            // console.log(res)
            setAcc(res.data.data)
        })
    }, [])

    useEffect(() => {
        if (UserService.getToken() !== undefined) {
            if (contact?.hasOwnProperty('practioner') && contact?.practioner?.telecom && contact?.practioner?.telecom?.length > 0) {
                const telecomdata = contact?.practioner?.telecom
                let data1 = telecomdata?.filter((element) => {
                    return element?.system === "email"
                })
                // console.log(data1)
                setContactemail(data1)
            }
            if (contact?.hasOwnProperty('practioner') && contact?.practioner?.telecom && contact?.practioner?.telecom?.length > 0) {
                const telecomdata = contact?.practioner?.telecom
                let data2 = telecomdata?.filter((element) => {
                    return element?.system === "phone"
                })
                // console.log(data2)
                setPhone(data2)
            }
        }
        return () => { console.log("overviewpublic") }
    }, [contact])

    return (
        <div>
            <div className='about_page about__write__box mt-10'>
                {profileData?.profileData !== undefined ? (
                    <div className="sidebar__padding bg__white">
                        <div className="sidebar__substat">
                            <p className="heading_public__text">About</p>
                        </div>
                        {profileData?.profileData?.user?.about !== undefined ?
                            <div className='sidebar__padding'>
                                <span style={{ textTransform: "inherit", fontWeight: "400", fontSize: "14px" }}>{isReadMore && profileData?.profileData?.user?.about ? profileData?.profileData?.user?.about.substr(0, 300) : profileData?.profileData?.user?.about}</span>
                                <span onClick={toggleReadMore} className="cursor" style={{ fontWeight: "550", fontSize: "14px" }}>{isReadMore && profileData?.profileData?.user?.about > 300 ? "...Read More" : ""}</span>
                            </div> :
                            <p className="about___text">what would you like to share about you ?</p>
                        }
                    </div>
                ) : ("")}
            </div>
            {/* Experience*/}
            <div>
                <div className="sidebar__stats">
                    <div className="flex_center">
                        <div className="sidebar__substat">
                            <img src={Stethscope} style={{ width: "25px", height: "25px", paddingRight: "10px" }}></img>
                            <div className="heading_public__text">Experience</div>
                        </div>
                    </div>
                    {exp?.length > 0 ?
                        <div>
                            {exp?.slice(0, count)?.map((e, i) => (
                                <div key={i}>
                                    <div className="sidebar__substat ta__center w-100" style={{ justifyContent: "space-between", padding: "10px 0px" }}>
                                        <div className="sidebar__substat ta__center" style={{ width: "100%", justifyContent: "space-between" }}>
                                            <div style={{ display: "flex" }}>
                                                <div className="my-img domain-icon margin_exp" style={{ height: '55px', width: '55px', borderRadius: '7px' }}>
                                                    {/* <DomainIcon style={{fontSize: '30px'}}/> */}
                                                    {e?.logo ?
                                                        <Avatar src={e.logo} style={{ width: '100%', height: "100%", borderRadius: '7px' }} />
                                                        :
                                                        <img src={Organisation} alt="organisation" width='25px' height="20px" />
                                                    }
                                                </div>
                                                <div>
                                                    <div className="sidebar__ml capitalize" style={{ fontSize: '13px', marginLeft: '20px' }}>
                                                        <p className='des_text' style={{ paddingBottom: '3px', }}>{e?.designation}</p>
                                                        <p className='unofficial_text' style={{ paddingBottom: '3px' }}>{`${(e?.unofficialOrganisationName) ? e?.unofficialOrganisationName : e?.organisationName}`} </p>
                                                        <p className='city_text' style={{ paddingBottom: '3px' }}>{(e?.city) ? e?.city : ""}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* {console.log(e)} */}
                                            <div style={{ display: "flex", justifyContent: "space-between" }}>
                                                <div>
                                                    <p className='date_text' style={{ color: "gray" }}>{" "}{moment(e.fromDate).format('MMM yyyy')}{" "}{e?.toDate ? moment(e?.toDate).format('- MMM yyyy') : '(Till Date)'}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                            <div className="work__space sidebar__padding bg__white e-align icon-show">
                                <div className="sidebar__substat ta__center">
                                    {exp?.length > 3 ? count === 3 ? <p id="border_exp_btn" onClick={() => setCount(exp?.length)} className="cursor d-flex e-align" style={{ color: '#0137CB' }}>{exp?.length - 3} More Experience{(exp?.length - 3) > 1 ? 's' : ''}&nbsp;
                                        <MdOutlineKeyboardArrowDown />
                                    </p> : <p onClick={() => setCount(3)} className="cursor" style={{ color: '#0137CB' }}>Hide</p> : ''}
                                    {/* {exp.length > 3 ? count === 3 ? <p onClick={() => setCount(exp.length)} className="cursor" style={{ textDecoration: 'underline', color: '#700eca' }}>2 More in Experience</p> : <p onClick={() => setCount(3)} className="cursor" style={{ textDecoration: 'underline', color: '#700eca' }}>See Less</p> : ''} */}
                                </div>
                            </div>
                        </div>
                        :
                        <div style={{ padding: "15px" }}>
                            <h2 style={{ fontSize: "18px", fontWeight: "500", textAlign: "center", color: "#36454F" }}>No Data</h2>
                        </div>
                    }
                </div>
                {/* Education */}
                <div>
                    {profileData?.profileData?.user?.practioner?.qualification && edu !== null && edu?.length > 0 ?
                        <div className='sidebar__padding'>
                            <div className="sidebar__stat">
                                <div className="sidebar__substat" style={{ alignItems: "center" }}>
                                    <img src={qualificationlogo} width="25px" height="25px"></img>
                                    <div style={{ paddingLeft: "10px" }} className="heading_public__text">Qualification</div>
                                </div>
                            </div>
                            <div>
                                {edu?.slice(0, educount)?.map((e, i) => (
                                    <div className="d-flex sidebar__pt" key={i}>
                                        <div className="sidebar__substat ta__center" style={{ width: "100%" }}>
                                            <div className="my-img domain-icon" id="education_img">
                                                <img src={Organisation} alt="organisation" width='25px' height="20px" />
                                            </div>
                                            <div className="sidebar__ml capitalize" style={{ fontSize: '13px', marginLeft: '20px', width: "80%" }}>
                                                {/* <p style={{ fontWeight: 'bold', paddingBottom: '3px' }}>{e.code.coding[0].display}</p> */}
                                                {(e?.code && e?.code?.coding?.[0]?.code === "BS") ? <span></span> :
                                                    <span className='edu_text'>{e?.code?.coding?.[0]?.code}{" "}{"-"}{" "}</span>}
                                                <span className='edu_text'>{e?.code?.text}</span>
                                                <p className='edu_sub_text'>{e?.issuer?.display || e?.issuer}</p>
                                                <p className='edu_date_text'>{e?.period && e?.period?.start && moment(e?.period?.start)?.format('MMM yyyy')} - {e?.period && e?.period?.end ? moment(e?.period?.end).format('MMM yyyy') : 'present'}</p>
                                                {/* <p className='edu_date_text'>{moment(e.period.start).format('MMM yyyy')} - {e.period.end ? moment(e.period.end).format('MMM yyyy') : 'present'}</p> */}
                                            </div>
                                        </div>
                                    </div>
                                ))}
                                <div className="work__space sidebar__padding bg__white e-align icon-show">
                                    <div className="sidebar__substat ta__center">
                                        {edu !== null && edu?.length > 3 ? educount === 3 ? <p id="border_exp_btn" onClick={() => setEducount(edu?.length)} className="cursor d-flex e-align" style={{ color: '#0137CB' }}>{edu?.length - 3} More Qualification{(edu?.length - 3) > 1 ? 's' : ''}&nbsp;
                                            <MdOutlineKeyboardArrowDown />
                                        </p> : <p onClick={() => setEducount(3)} className="cursor" style={{ color: '#0137CB' }}>Hide</p> : ''}
                                        {/* {exp.length > 3 ? count === 3 ? <p onClick={() => setCount(exp.length)} className="cursor" style={{ textDecoration: 'underline', color: '#700eca' }}>2 More in Experience</p> : <p onClick={() => setCount(3)} className="cursor" style={{ textDecoration: 'underline', color: '#700eca' }}>See Less</p> : ''} */}
                                    </div>
                                </div>
                            </div>
                        </div>
                        :
                        <div>
                            <div className="sidebar__substat" style={{ alignItems: "center", padding: "10px 20px" }}>
                                <img src={qualificationlogo} width="25px" height="25px"></img>
                                <div style={{ paddingLeft: "10px" }} className="heading_public__text">Qualification</div>
                            </div>
                            <div style={{ padding: "15px" }}>
                                <h2 style={{ fontSize: "18px", fontWeight: "500", textAlign: "center", color: "#36454F" }}>No Data</h2>
                            </div>
                        </div>
                    }
                </div>
            </div>

            <div>
                <div style={{ display: "flex", alignItems: "center", padding: "10px 20px" }}>
                    <img src={accomplishments} style={{ marginRight: "10px" }} width="25px" height="25px" /><span className='acc_Heading_text'>Accomplishments</span>
                </div>
                <div className='badges_responsive border_img pt-2'>
                    <div className="sidebar__icon1" id='flex_align'>
                        <img src={certificate} alt="certificate" />
                        {/* <div className="sidebar__statNumber">{user.certificatesCount}</div> */}
                        <div className="sidebar__statNumber"><span>{profileData?.profileData?.user?.certificatesCount}</span></div>
                        <div className="sidebar__title sidebar__pb fw__normal">Certifications</div>
                    </div>
                    <div className="sidebar__icon1" id='flex_align'>
                        <img src={ribbon} alt="certificate" />
                        {/* <div className="sidebar__statNumber">{user.badgesCount}</div> */}
                        <div className="sidebar__statNumber"><span>{profileData?.profileData?.user?.badgesCount}</span></div>
                        <div className="sidebar__title sidebar__pb fw__normal">Badges</div>
                    </div>
                    <div className="sidebar__icon1" id='flex_align'>
                        <img src={medal} alt="certificate" />
                        {/* <div className="sidebar__statNumber">{user.achievementsCount}</div> */}
                        <div className="sidebar__statNumber"><span>{profileData?.profileData?.user?.achievementsCount}</span></div>
                        <div className="sidebar__title sidebar__pb fw__normal">Achievements</div>
                    </div>
                </div>
                {UserService.getToken() !== undefined ?
                    <div className='border_img' id="margin_acc">
                        {acc?.length > 0 ?
                            acc?.slice(0, acount)?.map((e, id) => (
                                <div style={{ display: "flex", justifyContent: "space-between", width: "100%" }} className="bg__white e-align icon-show" key={e.id}>
                                    <div className="sidebar__substat ta__center">
                                        <p style={{ boxShadow: 'none', borderRadius: '0px' }} className="my-img domain-icon">
                                            <img src={e?.typeOfAccomplishment === "BADGES" ? Badge : e?.typeOfAccomplishment === 'CERTIFICATES' ? Certificate : achevments} alt="ribbon"></img>
                                        </p>
                                    </div>
                                    <div style={{ display: "flex", justifyContent: "space-between", width: "65%" }}>
                                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                                            <div className="sidebar__ml " style={{ textTransform: 'capitalize', fontSize: '13px' }}>
                                                <p style={{ fontWeight: 'normal', paddingBottom: '3px', fontSize: "14px" }}>{e.title ? e.title : 'N/A'} </p>
                                                {/* <p style={{ paddingBottom: '3px', fontSize: "12px", color: "rgb(118, 118, 118)" }}>{e.summary ? e.summary : 'N/A'}</p> */}
                                                {isReadMore ?
                                                    <span style={{ paddingBottom: '3px', fontSize: "12px", color: "rgb(118, 118, 118)" }}>{e?.summary?.slice(0, 150)}</span> : <span style={{ paddingBottom: '3px', fontSize: "12px", color: "rgb(118, 118, 118)" }}>{e?.summary}</span>
                                                }
                                                {e?.summary && e?.summary?.length > 100 ?
                                                    <span onClick={toggleReadMore} className="read-or-hide">
                                                        {isReadMore ? "...read more" : " show less"}
                                                    </span> : ""
                                                }
                                                <p style={{ paddingBottom: '3px', fontSize: "12px", textTransform: "lowercase", color: "rgb(118, 118, 118)" }}>{e?.link ? (e?.link)?.substr(0, 100) : 'N/A'}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div style={{ width: "20%", textAlign: "center" }}>
                                        <p className='date_acc_text'>{(e?.date) ? moment(e?.date).format('DD MMM yyyy') : ""}</p>
                                    </div>
                                </div>
                            ))
                            :

                            <div className="sidebar__padding bg__white e-align icon-show">
                                <div className="ta__center">
                                    <p style={{ textAlign: "center" }}>No Accomplishments</p>
                                </div>
                            </div>
                        }
                        <div className="work__space sidebar__padding bg__white e-align icon-show">
                            <div className="sidebar__substat ta__center">
                                {acc?.length > 5 ? acount === 5 ?
                                    <p id="border_exp_btn" onClick={() => setAcount(acc?.length)} className="cursor d-flex e-align" style={{ color: '#0137CB' }}>
                                        All Accomplishments
                                        <MdOutlineKeyboardArrowDown />
                                    </p> :
                                    <p onClick={() => setAcount(5)} className="cursor" style={{ color: '#0137CB' }}>Hide</p> : ''}
                            </div>
                        </div>
                    </div>
                    :
                    <div className='border_img' id="margin_acc">
                        {acomplishmentdata?.length > 0 ?
                            acomplishmentdata?.slice(0, acount)?.map((e, id) => (
                                <div style={{ display: "flex", justifyContent: "space-between", width: "100%" }} className="bg__white e-align icon-show" key={e.id}>
                                    <div className="sidebar__substat ta__center">
                                        <p style={{ boxShadow: 'none', borderRadius: '0px' }} className="my-img domain-icon">
                                            <img src={e?.typeOfAccomplishment === "BADGES" ? Badge : e?.typeOfAccomplishment === 'CERTIFICATES' ? Certificate : achevments} alt="ribbon"></img>
                                        </p>
                                    </div>
                                    <div style={{ display: "flex", justifyContent: "space-between", width: "65%" }}>
                                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                                            <div className="sidebar__ml " style={{ textTransform: 'capitalize', fontSize: '13px' }}>
                                                <p style={{ fontWeight: 'normal', paddingBottom: '3px', fontSize: "14px" }}>{e?.title ? e?.title : 'N/A'} </p>
                                                {/* <p style={{ paddingBottom: '3px', fontSize: "12px", color: "rgb(118, 118, 118)" }}>{e.summary ? e.summary : 'N/A'}</p> */}
                                                {isReadMore ?
                                                    <span style={{ paddingBottom: '3px', fontSize: "12px", color: "rgb(118, 118, 118)" }}>{e?.summary?.slice(0, 150)}</span> : <span style={{ paddingBottom: '3px', fontSize: "12px", color: "rgb(118, 118, 118)" }}>{e?.summary}</span>
                                                }
                                                {e?.summary && e?.summary?.length > 100 ?
                                                    <span onClick={toggleReadMore} className="read-or-hide">
                                                        {isReadMore ? "...read more" : " show less"}
                                                    </span> : ""
                                                }
                                                <p style={{ paddingBottom: '3px', fontSize: "12px", textTransform: "lowercase", color: "rgb(118, 118, 118)" }}>{e?.link ? (e?.link)?.substr(0, 100) : 'N/A'}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div style={{ width: "20%", textAlign: "center" }}>
                                        <p className='date_acc_text'>{(e?.date) ? moment(e?.date).format('DD MMM yyyy') : ""}</p>
                                    </div>
                                </div>
                            ))
                            :

                            <div className="sidebar__padding bg__white e-align icon-show">
                                <div className="ta__center">
                                    <p style={{ textAlign: "center" }}>No Accomplishments</p>
                                </div>
                            </div>
                        }
                        <div className="work__space sidebar__padding bg__white e-align icon-show">
                            <div className="sidebar__substat ta__center">
                                {acomplishmentdata?.length > 5 ? acount === 5 ?
                                    <p id="border_exp_btn" onClick={() => setAcount(acomplishmentdata?.length)} className="cursor d-flex e-align" style={{ color: '#0137CB' }}>
                                        {acomplishmentdata?.length - 3}Accomplishment{(acomplishmentdata?.length - 3) > 1 ? 's' : ''}&nbsp;
                                        <MdOutlineKeyboardArrowDown />
                                    </p> :
                                    <p onClick={() => setAcount(5)} className="cursor" style={{ color: '#0137CB' }}>Hide</p> : ''}
                            </div>
                        </div>
                    </div>}
            </div>
            {/* RecentActivity */}

            {/* <div className='border_img' >
                <div className='recent_activity dflex_al_jc' style={{ padding: "15px" }}>
                    <h2 style={{ fontSize: "18px", fontWeight: "400" }}>Recent Activity (28)</h2>
                    <h4 style={{ fontSize: "15px", fontWeight: "400" }}>seeAll</h4>
                </div>
                <div style={{ padding: "10px 20px" }}>
                    <div className='dflex_al_jc'>
                        <div style={{ fontSize: "15px", fontWeight: "400" }}>
                            <p>Started following</p>
                        </div>
                        <div style={{ fontSize: "15px", fontWeight: "400" }}>
                            <p>Activity time</p>
                        </div>
                    </div>
                    <div className='mt-10' style={{ display: "flex" }}>
                        <div>
                            <img src={Vollmond} height="65px" width="65px">
                            </img>
                        </div>
                        <div className='mt-10' style={{ color: "#000000" }}>
                            <h3 style={{ fontSize: "15px", fontWeight: "400" }}>Organization/Person</h3>
                            <p style={{ fontSize: "13px", fontWeight: "300" }}>Caption</p>
                            <p style={{ fontSize: "13px", fontWeight: "300" }}>Followers</p>
                        </div>
                    </div>
                </div>
                <div style={{ padding: "10px 20px" }}>
                    <div className='dflex_al_jc'>
                        <div style={{ fontSize: "15px", fontWeight: "400" }}>
                            <p>Created a Post</p>
                        </div>
                        <div style={{ fontSize: "15px", fontWeight: "400" }}>
                            <p>Activity time</p>
                        </div>
                    </div>
                    <div className='mt-10' style={{ display: "flex" }}>
                        <div>
                            <img src={Vollmond} height="65px" width="65px">
                            </img>
                        </div>
                        <div className='mt-10' style={{ color: "#000000" }}>
                            <h3 style={{ fontSize: "14px", fontWeight: "400" }}>Post Meta data</h3>
                            <p style={{ fontSize: "13px", fontWeight: "300" }}>Caption</p>
                            <p style={{ fontSize: "13px", fontWeight: "300" }}>Followers</p>
                        </div>
                    </div>
                </div>
                <div style={{ padding: "10px 20px" }}>
                    <div className='dflex_al_jc'>
                        <div style={{ fontSize: "15px", fontWeight: "400" }}>
                            <p>Registered For a event</p>
                        </div>
                        <div style={{ fontSize: "15px", fontWeight: "400" }}>
                            <p>Activity time</p>
                        </div>
                    </div>
                    <div className='mt-10' style={{ display: "flex" }}>
                        <div>
                            <img src={Vollmond} height="65px" width="65px">
                            </img>
                        </div>
                        <div className='mt-10' style={{ color: "#000000" }}>
                            <h3 style={{ fontSize: "15px", fontWeight: "400" }}>Event Title</h3>
                            <p style={{ fontSize: "13px", fontWeight: "300" }}>Timing</p>
                            <p style={{ fontSize: "13px", fontWeight: "300" }}>Organisers</p>
                        </div>

                    </div>
                </div >
            </div> */}

            {/* publication */}

            {/* <div className='sidebar__padding'>
                <h1 style={{ fontSize: "18px", fontWeight: "400" }}>Publications</h1>
                <div className="sidebar__pt">
                    <div className="space_btw_center" style={{ padding: "8px" }}>
                        <h4 className="sidebar__title sidebar__ml" style={{ fontSize: "16px", fontWeight: "400" }}>Research Articles (8)</h4>
                        <img src={Navigate} width="20px" height="20px"></img>
                    </div>
                    <div className="space_btw_center" style={{ padding: "8px" }}>
                        <h4 className="sidebar__title sidebar__ml" style={{ fontSize: "16px", fontWeight: "400" }}>Patient Education Articles (3)</h4>
                        <img src={Navigate} width="20px" height="20px"></img>
                    </div>
                    <div className="space_btw_center" style={{ padding: "8px" }}>
                        <h4 className="sidebar__title sidebar__ml" style={{ fontSize: "16px", fontWeight: "400" }}>Docsteth Articles (!4)</h4>
                        <img src={Navigate} width="20px" height="20px"></img>
                    </div>
                    <div className="space_btw_center" style={{ padding: "8px" }}>
                        <h4 className="sidebar__title sidebar__ml" style={{ fontSize: "16px", fontWeight: "400" }}>Evidence Review (9)</h4>
                        <img src={Navigate} width="20px" height="20px"></img>
                    </div>
                </div>
            </div> */}

            {/* Contact Details */}
            <div className='border_img' style={{ marginTop: "10px" }}>
                <div style={{ display: "flex", alignItems: "center", backgroundColor: "#fff" }}>
                    <h3 className='fw__normal' style={{ fontSize: "18px", fontWeight: "550", color: "rgb(35, 122, 196)", padding: "12px 12px 0px 20px" }}>Contact Details</h3>
                </div>
                <div className='sidebar__padding' style={{ backgroundColor: "#fff" }}>
                    {/* location */}
                    <div className="sidebar__stat sidebar__pt ">
                        <div className="sidebar__substat">
                            <img src={location_on} alt="" style={{ fontSize: "16px" }} />
                            <div className="sidebar__title ml-2" style={{ fontSize: "16px", fontWeight: "400", color: "#36454F" }}>
                                {address?.city || address?.state || address?.country ?
                                    <>
                                        <span>{address?.city ? address?.city : ''}</span>{" "}

                                        {address?.city && address?.state ? ',' : ''}<span>{address?.state ? address?.state + ',' : ''}</span>{" "}

                                        <span>{address?.country ? address?.country : ''}</span>

                                    </> : 'N/A'}
                            </div>
                        </div>
                    </div>
                    {/*cake */}
                    {/* <div className="sidebar__stat sidebar__pt ">
                        <div className="sidebar__substat sidebar__pt">
                            <img src={birthday} alt="" style={{ fontSize: "16px" }} />
                            <div className="sidebar__title ml-2" style={{ fontSize: "16px", fontWeight: "400", color: "#36454F" }}>
                                {.profileData.user.practioner ? moment(profileData.profileData.user.practioner.dateOfBirth).format('DD-MM-YYYY') : 'N/A'}
                            </div>
                        </div>
                    </div> */}
                    {/* email */}
                    {/* {console.log(profileData)} */}
                    <div className="sidebar__stat pt-2 sidebar__pt ">
                        {UserService.getToken() !== undefined ?
                            <div className="sidebar__substat ta__center">
                                <img src={MailIcon} alt="" style={{ fontSize: "16px" }} />
                                <div className="ml-2" >
                                    {contactemail?.length > 0 && state?.kycStatus === "APPROVED" ?
                                        <div >
                                            <span style={{ fontSize: "16px", fontWeight: "400", color: "#36454F" }}>
                                                {contactemail?.[0]?.value}{" "}
                                            </span>
                                            {contactemail?.length > 1 ?
                                                <span onClick={showmore} className="cursor" style={{ fontSize: "16px", fontWeight: "400", color: "#36454F" }}>
                                                    {"+" + (contactemail?.length - 1) + "more"}
                                                </span> : ""
                                            }
                                        </div>
                                        :
                                        <div>
                                            {profileData?.profileData?.user?.contactInfo ?
                                                <p style={{ fontSize: "16px", fontWeight: "400", color: "#36454F" }}>
                                                    {profileData?.profileData?.user?.contactInfo}
                                                </p>
                                                :
                                                <p style={{ fontSize: "16px", fontWeight: "400", color: "#36454F" }}>N/A</p>
                                            }
                                        </div>
                                    }
                                </div>
                                <Dialog
                                    open={dialogopen}
                                    onClose={handledialogclose}
                                    aria-labelledby="alert-dialog-title"
                                    aria-describedby="alert-dialog-description"
                                >
                                    <Box className='contact_details'>
                                        <div className='space_btw_center'>
                                            <h5>Contact Details </h5>
                                            <ClearIcon className='cursor' onClick={handledialogclose} />
                                        </div>
                                        <div className="pt-2 sidebar__pt ">
                                            <ul className="ta__center" id="mobilecontact_list">
                                                {contactemail?.map((element, index) => {
                                                    return (
                                                        <div className="mt-10" key={index}>
                                                            <li style={{ fontSize: "16px", fontWeight: "400", color: "#36454F" }}>
                                                                {element?.value}
                                                            </li>
                                                        </div>
                                                    )
                                                })}
                                            </ul>
                                        </div>
                                    </Box>
                                </Dialog>
                            </div> :
                            <div className="space_btw_center" >
                                <img src={MailIcon} alt="" style={{ fontSize: "16px" }} />
                                <p className='ml-2' style={{ fontSize: "16px", fontWeight: "400", color: "#36454F" }}>
                                    {profileData?.profileData?.user ? profileData?.profileData?.user?.contactInfo : ""}
                                </p>
                            </div>
                        }
                    </div>
                    {/* phone */}
                    <div className="sidebar__stat sidebar__pt ">
                        {UserService.getToken() !== undefined ?
                            <div className="sidebar__substat ta__center">
                                <img src={call} alt="" style={{ fontSize: "16px" }} />
                                <div className="ml-2">
                                    {phone?.length > 0 && state?.kycStatus === "APPROVED" ?
                                        <div>
                                            <span style={{ fontSize: "16px", fontWeight: "400", color: "#36454F" }}>
                                                {phone?.[0]?.value}{" "}
                                            </span>
                                            {phone?.length > 1 ?
                                                <span onClick={showmore} className="cursor" style={{ fontSize: "16px", fontWeight: "400", color: "#36454F" }}>
                                                    {"+" + (phone?.length - 1) + "more"}
                                                </span> : ""
                                            }
                                        </div>
                                        :
                                        <div>
                                            {profileData.profileData.user.contactInfo ?
                                                <p style={{ fontSize: "16px", fontWeight: "400", color: "#36454F" }}>
                                                    {profileData?.profileData?.user?.contactInfo}
                                                </p>
                                                :
                                                <p style={{ fontSize: "16px", fontWeight: "400", color: "#36454F" }}>N/A</p>
                                            }
                                        </div>
                                    }
                                </div>
                                <Dialog
                                    open={dialogopen}
                                    onClose={handledialogclose}
                                    aria-labelledby="alert-dialog-title"
                                    aria-describedby="alert-dialog-description"
                                >
                                    <Box className='contact_details'>
                                        <div className='space_btw_center'>
                                            <h5>Contact Details</h5>
                                            <ClearIcon className='cursor' onClick={handledialogclose} />
                                        </div>
                                        <div className="pt-2 sidebar__pt ">
                                            <ul className="ta__center" id="mobilecontact_list">
                                                {phone?.map((element, index) => {
                                                    return (
                                                        <div className="mt-10" key={index}>
                                                            <li style={{ fontSize: "16px", fontWeight: "400", color: "#36454F" }}>
                                                                {element?.value}
                                                            </li>
                                                        </div>
                                                    )
                                                })}
                                            </ul>
                                        </div>
                                    </Box>
                                </Dialog>
                            </div>
                            :
                            <div className="space_btw_center" >
                                {/* <div> */}
                                <img src={call} alt="" style={{ fontSize: "16px" }} />
                                {/* </div> */}
                                <p className='ml-2' style={{ fontSize: "16px", fontWeight: "400", color: "#36454F" }}>
                                    {profileData?.profileData?.user ? profileData?.profileData?.user?.contactInfo : ""}
                                </p>
                            </div>
                        }
                    </div>
                    {/* </div> */}
                    <div className='mt-20 pl-3'>
                        <h3 className='fw__normal' style={{ color: "#000000", fontSize: "18px" }}>Social Accounts</h3>
                    </div>
                    <div className='mt-10 pl-3' style={{ display: "flex", alignItems: "center" }}>
                        <div>
                            <TwitterIcon style={{ color: 'rgb(29, 155, 240)', width: "25px", height: "25px", paddingRight: "10px" }} />
                        </div>
                        <div>
                            <FacebookIcon style={{ color: '#385898', width: "25px", height: "25px", paddingRight: "10px" }} />
                        </div>
                        <div>
                            <LinkedInIcon style={{ color: '#007DCD', width: "25px", height: "25px", paddingRight: "10px" }} />
                        </div>
                        <div>
                            <WhatsAppIcon style={{ color: 'green', width: "25px", height: "25px", paddingRight: "10px" }} />
                        </div>
                    </div>
                </div>
            </div>
            {/* Communication */}
            <div className="sidebar__padding bg__white border_img" style={{ paddingBottom: "20px", marginTop: "10px" }}>
                {/* <div>
                                        <h3 className='fw__normal' style={{ color: "#5119A2", fontSize: "18px", color: "#000000", display: "flex", alignItems: "center" }}><img style={{ paddingRight: "15px" }} src={LinkedAccount}></img>Linked Accounts</h3>
                                    </div */}
                < div className='mt-10' style={{ display: "flex", alignItems: "center" }}>
                    <div><img src={Languageimg} height="30px" width="30px"></img></div>
                    <div style={{ paddingLeft: "10px" }}>
                        <span style={{ fontSize: "18px", fontWeight: "550", color: "rgb(35, 122, 196)" }}>Communication (Language)</span>
                        <p style={{ padding: "8px 0px 0px 10px" }}>English</p>
                    </div>
                </div>
            </div >
            {
                profileData?.profileData && profileData?.profileData?.pages?.length > 0 ?
                    <div className='border_img' style={{ marginTop: "10px" }}>
                        <div className="sidebar__padding border_img">
                            <div className="sidebar__substat" style={{ alignItems: "center" }}>
                                {/* <img src={Pages} style={{ fontSize: "20px" }} /> */}
                                <div className="heading_public__text">Organisation Pages</div>
                            </div>
                            {/* <div className="sidebar__title sidebar__ml flex__end">Likes</div> */}
                        </div>
                        <div className="height_public_pages" style={{ paddingTop: '0px' }}>
                            {profileData?.profileData?.pages?.map((item, ind) => {
                                return (
                                    <div key={ind} className="sidebar_statPage" style={{ borderBottom: "0px", padding: "0px 10px" }}>
                                        <div className="sidebar_pages e-align" style={{ flexGrow: 1 }}>
                                            <div style={{ width: '20%' }}><Avatar style={{ height: "32px", width: "32px", borderRadius: "7px" }} src={item?.logo ? item?.logo : Doc} /></div>
                                            <div className="cursor capitalize" style={{ fontSize: "14px", width: '80%' }}>
                                                {/* <b>{item.name}</b> */}
                                                <p className="name_hover text-ellipsis" onClick={() => history.push(`/organization/${item.id}`)}> {item?.name}</p>
                                                <p className="fontItalic text-ellipsis" style={{ color: '#635660', marginTop: '5px', fontSize: '13px' }}>{item?.captionAndTagline}</p>

                                            </div>
                                        </div>
                                        <p className="d-flex e-align" style={{ marginTop: "0px" }}>{item?.count}</p>
                                    </div>
                                )
                            })}
                        </div>
                    </div> : ''
            }
        </div >
    )
}

export default Overviewpublic