import React, { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import axios from 'axios';
// import DatePicker from '@mui/lab/DatePicker';
// import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
// import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
// import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { ValidatorForm } from 'react-material-ui-form-validator';
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import Autocomplete from '@mui/material/Autocomplete';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import InputField from '../../../Fields/TextFields'
import CssTextField from '../../../Fields/CssTextField';
import URL from '../../../apiurl';
import Collegedropdown from './Collegedropdown'
import UserService from '../../../../services/UserService';
import { Link } from 'react-router-dom';
import { Actions, FormLayout } from '../../../common/components/layout';
import CustomAutocomplete from '../../../common/inputs/Autocomplete';
import { FormDivider } from '../../../utility/utils';
import { D_API_FHIR } from '../../../../services/APIService';

const GridItem = (props) => {
  // console.log(props)
  return (
    <Grid item sm={12} xs={12} style={{ padding: '0px 8px' }}>
      <InputField
        label={props.label}
        // placeholder="Enter Address" 
        value={props.value} name={props.name} handleChange={props.handleChange}
        // validators={['required']}
        // errorMessages={['Please Enter Address']}
        disabled={props.disabled}
      />
    </Grid>
  )
}

export default function Crud({ state, handleChange, handleSubmit, submit, check, handleCheck, handleOrg, cancel, nextbtn, education, degreeCoursesList }) {
  console.log(submit, nextbtn, "itttttttttttt");
  console.log(state, "///////");
  console.log(education, "education")
  const [loading, setLoading] = useState(false);
  const [view, setView] = useState([]);
  const [open, toggleOpen] = React.useState(false);
  const [degreeCourses, setDegreeCourses] = useState([]);
  const [degreeDetails, setDegreeDetails] = useState([]);
  const [degreeType, setDegreeType] = useState([]);
  // const [dialogValue, setDialogValue] = React.useState({
  //     organisationName: '',
  //     addressId: '',
  //     description: ''
  // });
  // const degree = [
  //   { code: "BS", display: "Bachelor of Science (Medical)", },
  //   { code: "MD", display: "Doctor of Medicine" },
  //   { code: "MS", display: "Master of Surgery" },
  //   { code: "DNB", display: "Diplomate of National Board" },
  //   { code: "D.M.", display: "Doctorate of Medicine" },
  //   { code: "M.Ch.", display: "Master of Chirurgiae" },
  // ];



  const courses = [
    "Anaesthesiology",
    "Anaesthesiology, Pain Medicine and Critical Care",
    "Anatomy",
    "BAMS - Bachelor of Ayurvedic Medicine and Surgery",
    "BDS - Bachelor of Dental Surgery",
    "BHMS - Bachelor of Homeopathy Medicine and Surgery",
    "Biochemistry",
    "BUMS - Bachelor of Unani Medicine and Surgery",
    "BYNS- Bachelor of Yoga and Naturopathy Sciences",
    "Cardiac-Anaesthesiology",
    "Cardiac-Radiology",
    "Cardiology",
    "Cardiothoracic and Vascular Surgery",
    "Community Health",
    "Dermatology",
    "Dermatology, Venerology and Leprosy",
    "Ear, Nose and Throat",
    "Emergency and Critical care",
    "Emergency Medicine",
    "Endocrinology",
    "ENT",
    "Family Medicine",
    "Field Epidemiology",
    "Forensic Medicine",
    "Gastroenterology",
    "Gastrointestinal Surgery",
    "General Medicine",
    "General Surgery",
    "Haematology",
    "Health Administration",
    "Immunohematology and transfusion medicine",
    "Maternal and Child Health",
    "MBBS – Bachelor of Medicine, Bachelor of Surgery",
    "Medicine and Microbiology",
    "Microbiology",
    "Nephrology",
    "Neuro Surgery",
    "Neuro-Anaesthesiology and Critical Care",
    "Neurology",
    "Nuclear Medicine",
    "Obstetrics and Gynaecology",
    "Onco-Anesthesiology and Palliative Medicine",
    "Ophthalmology",
    "Orthopaedic Surgery",
    "Orthopaedics",
    "Oto-Rhino Laryngology",
    "Paediatrics",
    "Palliative Medicine",
    "Pathology",
    "Pediatric Surgery",
    "Pharmacology",
    "Physical Medicine and Rehabilitation",
    "Physiology",
    "Plastic and Reconstructive Surgery",
    "Preventive and Social Medicine",
    "Psychiatry",
    "Pulmonary and Sleep disorders",
    "Radio-Diagnosis",
    "Radio-Therapy",
    "Respiratory diseases",
    "Rural Surgery",
    "Skin and Venereal diseases",
    "Social and Preventive Medicine",
    "Surgery",
    "Surgery Trauma Centre",
    "Surgical Oncology",
    "Transfusion Medicine",
    "Tropical Medicine",
    "Tuberculosis and Respiratory diseases",
    "Urology",
  ];


  // new code
  const systemsData = [
    {
      system: "https://sandbox.abdm.gov.in/sandbox/Master_Data/doctor-degrees/Nurse-Courses",
      display: "Nurse-Councils"
    },
    {
      system: "https://sandbox.abdm.gov.in/sandbox/Master_Data/doctor-degrees/Modern-Medicine",
      display: "Modern-Medicine"
    },
    {
      system: "https://sandbox.abdm.gov.in/sandbox/Master_Data/doctor-degrees/Dentistry",
      display: 'Dentistry'
    },
    {
      system: "https://sandbox.abdm.gov.in/sandbox/Master_Data/doctor-degrees/Ayurveda",
      display: 'Ayurveda'
    },
    {
      system: "https://sandbox.abdm.gov.in/sandbox/Master_Data/doctor-degrees/Unani",
      display: 'Unani'
    },
    {
      system: "https://sandbox.abdm.gov.in/sandbox/Master_Data/doctor-degrees/Siddha",
      display: 'Siddha'
    },
    {
      system: "https://sandbox.abdm.gov.in/sandbox/Master_Data/doctor-degrees/Sowa-Rigpa",
      display: 'Sowa-Rigpa'
    },
    {
      system: "https://sandbox.abdm.gov.in/sandbox/Master_Data/doctor-degrees/Homeopathy",
      display: 'Homeopathy'
    }
  ];
  const systemsData2 = [
    {
      display: "Nurse-Councils",
      system: [
        { url: "https://hpr.abdm.gov.in/apis/v1/masters/Nurse-Councils" }
      ]
    },
    {

      display: "Modern-Medicine",
      system: [
        { url: "https://hpr.abdm.gov.in/apis/v1/masters/Modern-Medicine" }
      ]
    },
    {

      display: 'Dentistry',
      system: [
        { url: "https://hpr.abdm.gov.in/apis/v1/masters/Dentistry" }
      ]
    },
    {

      display: 'Ayurveda',
      system: [
        { url: "https://hpr.abdm.gov.in/apis/v1/masters/Ayurveda" }
      ]
    },

  ];
  const bscode = [
    'BAMS - Bachelor of Ayurvedic Medicine and Surgery',
    'BDS - Bachelor of Dental Surgery',
    'BHMS - Bachelor of Homeopathy Medicine and Surgery',
    'BUMS - Bachelor of Unani Medicine and Surgery',
    'BYNS- Bachelor of Yoga and Naturopathy Sciences',
    'MBBS – Bachelor of Medicine, Bachelor of Surgery',
  ]


  const medicalcounsil = [
    'National Medical Commission(erstwhile Medical Council of India)',
    'Andhra Pradesh Medical Council',
    'Arunachal Pradesh Medical Council',
    'Bihar Medical Council',
    'Chhattisgarh Medical Council',
    'Delhi Medical Council',
    'Goa Medical Council',
    'Gujarat Medical Council',
    'Haryana Medical Council',
    'Himanchal Pradesh Medical Council',
    'Jammu and  Kashmir Medical Council',
    'Jharkhand Medical Council',
    'Karnataka Medical Council',
    'Madhya Pradesh Medical Council(Bhopal Medical Council / Mahakaushal Medical Council)',
    'Maharashtra Medical Council',
    'Manipur Medical Council',
    'Mizoram Medical Council',
    'Nagaland Medical Council',
    'Orissa Council of Medical Registration',
    ' Punjab Medical Council ',
    'Rajasthan Medical Council',
    'Sikkim Medical Council ',
    'Tamil Nadu Medical Council',
    'Telangana State Medical Council',
    'Kerala State Medical Councils',
    'Tripura State Medical Council',
    'Uttarakhand Medical Council ',
    'Uttar Pradesh Medical Council',
    ' West Bengal Medical Council ',
    'State Medical Council, Meghalaya ',
    'Assam Medical Council',
    'CHD MEDICAL COUNCIL',
  ]

  // useEffect(() => {
  //   // console.log(state.degree.display)
  //   if (state.degree !== null) {
  //     if (state.degree.display === "Bachelor of Science (Medical)") {
  //       setView(bscode);
  //     } else {
  //       setView(courses);
  //     }
  //   }
  // }, [state.degree]);

  // useEffect(() => {
  //   console.log(education?.length, "length")

  //   // if (education?.length <= 0 || education == null) {
  //   //   handleChange("degree", "BS-Bachelor of Science (Medical)");
  //   // }
  //   // else {
  //   //   handleChange("degree", "")
  //   //   setDegreeDetails(degree)
  //   // }

  // }, [education])
  console.log(degreeDetails, "iiiiiiiii")

  useEffect(() => {

    // D_API_FHIR.get(`ValueSet?name=DoctorDegrees`).then(res => {
    //   setDegreeCourses(res?.data?.entry?.[0]?.resource?.compose?.include || []);

    // }).catch(err => console.log(err, "hihihihii1"))
    const fetchData = async () => {
      try {
        const res = await D_API_FHIR.get(`ValueSet?name=DoctorDegrees`);
        setDegreeCourses(res?.data?.entry?.[0]?.resource?.compose?.include || []);
        const matchingSystems = res?.data?.entry?.[0]?.resource?.compose?.include?.filter(each => state?.degree?.system === each?.system);
        if (matchingSystems?.length > 0) {
          setDegreeType(matchingSystems || [])
          // handleChange('course', "")
        }
      } catch (err) {
        console.log(err, "hihihihii1");
      }
    };

    fetchData();
  }, [state])




  useEffect(() => {
    // getDegreeTypeFn()
  }, [state.degree, state.course])

  const getDegreeTypeFn = (() => {
    const matchingSystems = degreeCourses?.filter(each => state?.degree?.system === each?.system);
    console.log(degreeCourses, matchingSystems, "438")
    if (matchingSystems?.length > 0) {
      setDegreeType(matchingSystems || [])
      handleChange('course', "")

    } else {
      console.log("No matched system found.");
      // setDegreeType([])
    }
  })



  console.log(degreeType, "DegreeType", state)












  console.log(state.organisation, state.issuer, "organisation");

  // const [colleges, setColleges] = useState([])
  const sub = () => {
    if (state.organisation === undefined) {
      alert("Please add organisation");
    } else {
      handleSubmit();
    }
  };
  useEffect(() => {
    // console.log("loading................")
    setLoading(true);
  }, []);

  // const searchCollege = (value) => {
  //     axios.get(`${URL.searchCollege}/${value}`, {
  //         headers: {
  //             Authorization: `Bearer ${UserService.getToken()}`
  //         }
  //     }).then(res => setColleges(res.data.data))
  // }
  // console.log(view, "vvvvvvvvvvvv")
  // console.log(newValue, "data")
  return (
    <>
      {loading ? (


        // new code
        <ValidatorForm >
          <React.Fragment key={"i"}>
            {/* <Grid item xs={12}>
             <p className="w-100 flex__end">
            <DoDisturbOnIcon sx={{ color: "red" }} onClick={() => deleteEducation(i)} />
          </p> </Grid> */}

            <Grid container spacing={2} pt={1}>
              < Grid item md={6} xs={12} >
                <label>University (from which degree is completed)</label>
                {/* <CustomAutocomplete placeholder="Search University Name" value={data.issuer} name="issuer" handleOrg={handleQua(i)} required errorMessages={['Please enter university name']} /> */}
                <CustomAutocomplete placeholder="Search University Name" value={state.issuer} name="issuer" handleOrg={handleChange} required errorMessages={['Please enter university name']} />
              </Grid>

              <FormLayout
                value={state.identifier}
                handleChange={handleChange}
                name="identifier" label="Identity Number" type='Number' placeholder="Certification No./Registration No." required errorMessages={['Enter Certification/Registration No.']} />

            </Grid>
            <Grid container spacing={2} pt={1}>

              <FormLayout type="dateIn" views={['year', 'month']} value={dayjs(state.dateIss)} name="dateIss" label="Date of Issue" handleChange={handleChange}
                required errorMessages={['Select Date of registration']}
              />

              <Grid item container md={6} sm={12} xs={12} sx={{ boxShadow: '2px 3px 10px white' }}>

                <Grid item xs={5} sm={5} md={5} >
                  <FormLayout input="select"
                    name="degree"
                    id="select-option"
                    handleChange={handleChange}
                    onInputChange={(e) => "filterBodySiteFn(e?.target?.value)"}
                    fullWidth
                    label="System of Medicine"
                    value={state?.degree}
                    placeholder="Select"
                    options={systemsData}
                    getOptionLabel={option => option.display || ""}
                    size={12}
                    required
                    sx={{
                      borderRadius: "0px",
                      '.MuiInputBase-root': {
                        borderRadius: '10px 0px 0px 10px',
                        borderRight: '1px solid black',
                      },
                      '.MuiOutlinedInput-notchedOutline': { border: "none", },
                    }}
                  />
                </Grid>
                <Grid item xs={7} sm={7} md={7}>
                  <FormLayout sx={{
                    borderRadius: "0px",
                    '.MuiInputBase-root': {
                      borderRadius: '0px 10px 10px 0px',
                    },
                    '.MuiOutlinedInput-notchedOutline': { border: "none", }
                  }}
                    input="select"
                    name="course"
                    label="Select Type of course"
                    id="select-option"
                    handleChange={handleChange}
                    fullWidth
                    value={state.course}
                    placeholder="Select"
                    options={degreeType?.[0]?.concept || []}
                    getOptionLabel={option => option.display || ""}
                    size={12}
                  />
                </Grid>
              </Grid>

            </Grid>

            <Grid container className='mv1'>
              <FormDivider />
            </Grid>
            {/* <Actions close={cancel} name={"Update"} /> */}
            <Grid container spacing={2} justifyContent="flex-end">
              <Grid item>
                <Button variant="outlined" className="capitalize cursor" sx={{ padding: "4px 20px !important" }} onClick={cancel}>cancel</Button>
              </Grid>
              <Grid item>

                <Button variant="contained" className="capitalize cursor" sx={{ padding: "4px 20px !important" }} disabled={UserService.getPractitionerFhirId() ? false : true} onClick={handleSubmit}>submit</Button>
              </Grid>

            </Grid>
          </React.Fragment >
        </ValidatorForm >









        // old code
        // <Grid container spacing={2}>
        //   <Grid item xs={12}>
        //     <ValidatorForm onSubmit={sub} onError={(errors) => { }}>
        //       <Grid container spacing={2}>
        //         <Grid item sm={6} xs={12} style={{ padding: "0px 8px" }}>
        //           <Autocomplete
        //             disablePortal
        //             id="combo-box-demo"
        //             options={degreeDetails.map(
        //               (opt, idx) => `${opt.code}-${opt.display}`
        //             )}

        //             onChange={(event, newValue) => {
        //               handleChange("degree", newValue);
        //             }}
        //             value={state.degree || null}
        //             fullWidth
        //             renderInput={(params, data) => (
        //               <CssTextField
        //                 sx={{ mt: "16px" }}
        //                 {...params}
        //                 label="Degree"
        //                 validators={["required"]}
        //                 errorMessages={["Please Select degree"]}
        //                 value={state.degree}
        //               />
        //             )}
        //           />
        //         </Grid>

        //         <Grid item sm={6} xs={12} style={{ padding: "0px 8px" }}>
        //           <Autocomplete
        //             disablePortal
        //             id="combo-box-demo"
        //             options={view}
        //             getOptionLabel={(option) => option}
        //             onChange={(event, newValue) => {
        //               handleChange("course", newValue);
        //             }}
        //             value={state.course || null}
        //             fullWidth
        //             renderInput={(params, data) => (
        //               <CssTextField
        //                 sx={{ mt: "16px" }}
        //                 {...params}
        //                 label="Course"
        //                 value={state.course}
        //               />
        //             )}
        //           />
        //         </Grid>

        //         <Grid item sm={12} xs={12} style={{ padding: "0px 8px" }}>
        //           <Collegedropdown
        //             handleOrg={handleOrg}
        //             submit={submit}
        //             value={
        //               state.organisationName || state.unofficialOrganisationName
        //             }
        //             label="Organisation"
        //             name="organisation"
        //           />

        //         </Grid>

        //         {state.organisationName ? (
        //           " "
        //         ) : (
        //           <GridItem
        //             label="Issuer (University)"
        //             value={state?.issuer ? state?.issuer : ""}
        //             disabled={true}
        //             name="issuer"
        //             handleChange={handleChange}
        //           />
        //         )}


        //         <Grid item xs={12} style={{ padding: "10px 10px 20px" }}>
        //           <Typography className="font-bold">During</Typography>
        //         </Grid>
        //         <Grid item sm={6} xs={12} style={{ padding: "0px 8px" }}>
        //           <LocalizationProvider dateAdapter={AdapterDayjs}>
        //             <DatePicker
        //               label="From"
        //               value={dayjs(state.from)}
        //               onChange={(value) =>
        //                 handleChange("from", new Date(value))
        //               }
        //               inputFormat="MM/yyyy"
        //               views={["month", "year"]}
        //               disableFuture
        //               renderInput={(params) => (
        //                 <CssTextField
        //                   fullWidth
        //                   {...params}
        //                   value={params.inputProps.value}
        //                   validators={["required"]}
        //                   errorMessages={["Please Select start date"]}
        //                 />
        //               )}
        //             />
        //           </LocalizationProvider>
        //         </Grid>
        //         {!check ? (
        //           <Grid item sm={6} xs={12} style={{ padding: "0px 8px" }}>
        //             <LocalizationProvider dateAdapter={AdapterDayjs}>
        //               <DatePicker
        //                 label="To"
        //                 value={dayjs(state.to)}
        //                 views={["month", "year"]}
        //                 onChange={(value) =>
        //                   handleChange("to", new Date(value))
        //                 }
        //                 inputFormat="MM/yyyy"
        //                 disableFuture
        //                 minDate={dayjs(state.from)}
        //                 disabled={state.from === null ? true : false}
        //                 renderInput={(params) => (
        //                   <CssTextField
        //                     fullWidth
        //                     {...params}
        //                     value={params.inputProps.value}
        //                     validators={["required"]}
        //                     errorMessages={["Please Select end date"]}
        //                   />
        //                 )}
        //               />
        //             </LocalizationProvider>
        //           </Grid>
        //         ) : (
        //           ""
        //         )}
        //         <Grid item xs={12}>
        //           <FormGroup>
        //             <FormControlLabel
        //               sx={{ alignItems: "flex-start", mt: 1 }}
        //               control={
        //                 <Checkbox
        //                   className="r-check"
        //                   disableRipple
        //                   checked={check}
        //                   name="check"
        //                   onChange={handleCheck}
        //                   inputProps={{ "aria-label": "controlled" }}
        //                 />
        //               }
        //               label="Pursuing / Ongoing"
        //             />
        //           </FormGroup>
        //         </Grid>
        //         <Grid item xs={12}>
        //           <div style={{ textAlign: "right" }}>
        //             {submit ? (
        //               <Button
        //                 onClick={cancel}
        //                 sx={{ p: "0px!important" }}
        //                 className="close-article article-common"
        //               >
        //                 Cancel
        //               </Button>
        //             ) : null}
        //             {submit ? (
        //               <Button
        //                 type="submit"
        //                 sx={{
        //                   borderRadius: "10px!important",
        //                   mt: "0px!important",
        //                 }}
        //                 className="add-option evnt-btn"
        //               >
        //                 {submit}
        //               </Button>
        //             ) : null}
        //           </div>
        //           {nextbtn ? (
        //             <Grid item xs={12} md={12}>
        //               <div
        //                 className="skip_btn"
        //                 style={{ marginTop: "15px", marginBottom: "90px" }}
        //               >

        //                 <Button
        //                   className="next__btn"
        //                   variant="contained"
        //                   type="submit"

        //                 >
        //                   <ArrowForwardIcon
        //                     style={{
        //                       color: "white",
        //                       fontSize: "18px",
        //                       paddingRight: "10px",
        //                     }}
        //                   />
        //                   next
        //                 </Button>
        //               </div>
        //             </Grid>
        //           ) : null}
        //           <Grid item xs={12} md={12}>
        //             <div className="skip_btn" style={{ marginTop: "-90px" }}>
        //               <Button
        //                 type="submit"
        //                 style={{
        //                   color: "#47AEE1",
        //                   textTransform: "capitalize",
        //                   margin: "10px 0px",
        //                 }}
        //                 onClick={handleSubmit}
        //               >
        //                 skip
        //               </Button>
        //             </div>
        //           </Grid>
        //         </Grid>
        //       </Grid>
        //     </ValidatorForm>
        //   </Grid>
        // </Grid>

      ) : null
      }
    </>
  );
}