import React, { useEffect, useState } from 'react'
import './AboutWriteBox.css'
import Stethscope from '../../../icons/stethscope.svg';
import Organisation from "../../Atom/modifiedicons/Organisations.svg"
import Avatar from '@mui/material/Avatar';
import accomplishments from '../../Atom/modifiedicons/accomplishments.svg';
import { useHistory } from 'react-router';
import certificate from "../../Atom/svg/certificate.svg";
import ribbon from "../../Atom/svg/ribbon.svg";
import medal from "../../Atom/svg/medal.svg";
import { accomplishment } from '../../../redux/Actions/actions';
import { useDispatch, useSelector } from 'react-redux';
import { MdOutlineKeyboardArrowDown } from 'react-icons/md';
import UserService from '../../../services/UserService';
import moment from 'moment';
import Certificate from '../../Atom/svg/certificate.svg';
import achevments from '../../Atom/modifiedicons/achevments.svg';
import Badge from "../../Atom/svg/ribbon.svg";
import axios from 'axios';
import { useParams } from 'react-router-dom';
import URL from '../../apiurl';


function AboutWriteBox(profileData) {
    // console.log(profileData.profileData)
    const history = useHistory()
    const [count, setCount] = useState(3)
    const [acount, setAcount] = useState(5)
    const [acc, setAcc] = useState([])
    const [isReadMore, setIsReadMore] = useState(true);
    const dispatch = useDispatch();
    // useEffect(() => {
    //     dispatch(accomplishment())
    // }, [])
    // const acc = useSelector(state => state.userProfile.accomplishment)
    const acomplishmentdata = profileData?.profileData?.accomplishment
    // console.log(acc)
    const toggleReadMore = () => {
        setIsReadMore(!isReadMore);
    };
    const exp = profileData?.profileData?.experience


    const { id } = useParams()
    // console.log(id)
    useEffect(() => {
        if (UserService.getToken()) {
            axios.get(`${URL.accomplishments}/${id}`, {
                headers: {
                    Authorization: `Bearer ${sessionStorage.getItem('kctoken')}`
                }
            }).then(res => {
                // console.log(res)
                setAcc(res.data?.data)
            })
        }
    }, [])

    return (
        <>
            <div className='about_page about__write__box mt-10'>
                {profileData?.profileData !== undefined ? (
                    <div className="sidebar__padding bg__white">
                        <div className="sidebar__substat">
                            <p className="heading_public__text">About</p>
                        </div>
                        {profileData?.profileData?.user?.about !== undefined ?
                            <div className='sidebar__padding'>
                                <span style={{ textTransform: "inherit", fontWeight: "400", fontSize: "14px" }}>{isReadMore && profileData?.profileData?.user?.about ? profileData?.profileData?.user?.about?.substr(0, 300) : profileData?.profileData?.user?.about}</span>
                                <span onClick={toggleReadMore} className="cursor" style={{ fontWeight: "550", fontSize: "14px" }}>{isReadMore && profileData?.profileData?.user?.about > 300 ? "...Read More" : ""}</span>
                            </div> :
                            <p className="about___text">what would you like to share about you ?</p>
                        }
                    </div>
                ) : ("")}
            </div>
            {/* Experience*/}
            <div>
                <div className="sidebar__stats">
                    <div className="flex_center">
                        <div className="sidebar__substat">
                            <img src={Stethscope} style={{ width: "25px", height: "25px", paddingRight: "10px" }}></img>
                            <div className="heading_public__text">Experience</div>
                        </div>
                    </div>
                    {exp?.length > 0 ?
                        <div>
                            {exp?.slice(0, count)?.map((e, i) => (
                                <div key={i}>
                                    <div className="sidebar__substat sidebar__padding ta__center w-100" style={{ justifyContent: "space-between" }}>
                                        <div className="sidebar__substat ta__center" style={{ width: "100%", justifyContent: "space-between" }}>
                                            <div style={{ display: "flex" }}>
                                                <div className="my-img domain-icon margin_exp" style={{ height: '55px', width: '55px', borderRadius: '7px' }}>
                                                    {/* <DomainIcon style={{fontSize: '30px'}}/> */}
                                                    {e.logo ?
                                                        <Avatar src={e.logo} style={{ width: '100%', height: "100%", borderRadius: '7px' }} />
                                                        :
                                                        <img src={Organisation} alt="organisation" width='25px' height="20px" />
                                                    }
                                                </div>
                                                <div>
                                                    <div className="sidebar__ml capitalize" style={{ fontSize: '13px', marginLeft: '20px' }}>
                                                        <p className='des_text' style={{ paddingBottom: '3px', }}>{e.designation}</p>
                                                        <p className='unofficial_text' style={{ paddingBottom: '3px' }}>{`${(e?.unofficialOrganisationName) ? e?.unofficialOrganisationName : e?.organisationName}`} </p>
                                                        <p className='city_text' style={{ paddingBottom: '3px' }}>{(e.city) ? e.city : ""}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* {console.log(e)} */}
                                            <div style={{ display: "flex", justifyContent: "space-between" }}>
                                                <div>
                                                    <p style={{ color: "gray" }}>{" "}{moment(e.fromDate).format('MMM yyyy')}{" "}{e?.toDate ? moment(e?.toDate).format('- MMM yyyy') : '(Till Date)'}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                            <div className="work__space sidebar__padding bg__white e-align icon-show">
                                <div className="sidebar__substat ta__center">
                                    {exp?.length > 3 ? count === 3 ? <p id="border_exp_btn" onClick={() => setCount(exp?.length)} className="cursor d-flex e-align" style={{ color: '#0137CB' }}>{exp?.length - 3} More Experience{(exp?.length - 3) > 1 ? 's' : ''}&nbsp;
                                        <MdOutlineKeyboardArrowDown />
                                    </p> : <p onClick={() => setCount(3)} className="cursor" style={{ color: '#0137CB' }}>Hide</p> : ''}
                                    {/* {exp.length > 3 ? count === 3 ? <p onClick={() => setCount(exp.length)} className="cursor" style={{ textDecoration: 'underline', color: '#700eca' }}>2 More in Experience</p> : <p onClick={() => setCount(3)} className="cursor" style={{ textDecoration: 'underline', color: '#700eca' }}>See Less</p> : ''} */}
                                </div>
                            </div>
                        </div>
                        :
                        <div style={{ padding: "15px" }}>
                            <h2 style={{ fontSize: "18px", fontWeight: "500", textAlign: "center", color: "#36454F" }}>No Data</h2>
                        </div>
                    }
                </div>
            </div>
            <div>
                <div style={{ display: "flex", alignItems: "center", padding: "10px 20px" }}>
                    <img src={accomplishments} style={{ marginRight: "10px" }} width="25px" height="25px" /><span className='acc_Heading_text'>Accomplishments</span>
                </div>
                <div className='badges_responsive border_img pt-2'>
                    <div className="sidebar__icon1" id='flex_align'>
                        <img src={certificate} alt="certificate" />
                        {/* <div className="sidebar__statNumber">{user.certificatesCount}</div> */}
                        <div className="sidebar__statNumber"><span>{profileData?.profileData?.user?.certificatesCount}</span></div>
                        <div className="sidebar__title sidebar__pb fw__normal">Certifications</div>
                    </div>
                    <div className="sidebar__icon1" id='flex_align'>
                        <img src={ribbon} alt="certificate" />
                        {/* <div className="sidebar__statNumber">{user.badgesCount}</div> */}
                        <div className="sidebar__statNumber"><span>{profileData?.profileData?.user?.badgesCount}</span></div>
                        <div className="sidebar__title sidebar__pb fw__normal">Badges</div>
                    </div>
                    <div className="sidebar__icon1" id='flex_align'>
                        <img src={medal} alt="certificate" />
                        {/* <div className="sidebar__statNumber">{user.achievementsCount}</div> */}
                        <div className="sidebar__statNumber"><span>{profileData?.profileData?.user?.achievementsCount}</span></div>
                        <div className="sidebar__title sidebar__pb fw__normal">Achievements</div>
                    </div>
                </div>
                {UserService?.getToken() !== undefined ?
                    <div className='border_img' id="margin_acc">
                        {acc?.length > 0 ?
                            acc?.slice(0, acount)?.map((e, id) => (
                                <div style={{ display: "flex", justifyContent: "space-between", width: "100%" }} className="sidebar__padding bg__white e-align icon-show" key={e.id}>
                                    <div className="sidebar__substat ta__center">
                                        <p style={{ boxShadow: 'none', borderRadius: '0px' }} className="my-img domain-icon">
                                            <img src={e?.typeOfAccomplishment === "BADGES" ? Badge : e?.typeOfAccomplishment === 'CERTIFICATES' ? Certificate : achevments} alt="ribbon"></img>
                                        </p>
                                    </div>
                                    <div style={{ display: "flex", justifyContent: "space-between", width: "65%" }}>
                                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                                            <div className="sidebar__ml " style={{ textTransform: 'capitalize', fontSize: '13px' }}>
                                                <p style={{ fontWeight: 'normal', paddingBottom: '3px', fontSize: "14px" }}>{e?.title ? e?.title : 'N/A'} </p>
                                                {/* <p style={{ paddingBottom: '3px', fontSize: "12px", color: "rgb(118, 118, 118)" }}>{e.summary ? e.summary : 'N/A'}</p> */}
                                                {isReadMore ?
                                                    <span style={{ paddingBottom: '3px', fontSize: "12px", color: "rgb(118, 118, 118)" }}>{e?.summary?.slice(0, 150)}</span> : <span style={{ paddingBottom: '3px', fontSize: "12px", color: "rgb(118, 118, 118)" }}>{e?.summary}</span>
                                                }
                                                {e?.summary && e?.summary?.length > 100 ?
                                                    <span onClick={toggleReadMore} className="read-or-hide">
                                                        {isReadMore ? "...read more" : " show less"}
                                                    </span> : ""
                                                }
                                                <p style={{ paddingBottom: '3px', fontSize: "12px", textTransform: "lowercase", color: "rgb(118, 118, 118)" }}>{e?.link ? (e?.link).substr(0, 100) : 'N/A'}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div style={{ width: "20%", textAlign: "center" }}>
                                        <p className='date_acc_text'>{(e?.date) ? moment(e?.date).format('DD MMM yyyy') : ""}</p>
                                    </div>
                                </div>
                            ))
                            :

                            <div className="sidebar__padding bg__white e-align icon-show">
                                <div className="ta__center">
                                    <p style={{ textAlign: "center" }}>No Accomplishments</p>
                                </div>
                            </div>
                        }
                        <div className="work__space sidebar__padding bg__white e-align icon-show">
                            <div className="sidebar__substat ta__center">
                                {acc?.length > 5 ? acount === 5 ?
                                    <p id="border_exp_btn" onClick={() => setAcount(acc?.length)} className="cursor d-flex e-align" style={{ color: '#0137CB' }}>
                                        All Accomplishments
                                        <MdOutlineKeyboardArrowDown />
                                    </p> :
                                    <p onClick={() => setAcount(5)} className="cursor" style={{ color: '#0137CB' }}>Hide</p> : ''}
                            </div>
                        </div>
                    </div> :
                    <div className='border_img' id="margin_acc">
                        {acomplishmentdata?.length > 0 ?
                            acomplishmentdata?.slice(0, acount)?.map((e, id) => (
                                <div style={{ display: "flex", justifyContent: "space-between", width: "100%" }} className="sidebar__padding bg__white e-align icon-show" key={e.id}>
                                    <div className="sidebar__substat ta__center">
                                        <p style={{ boxShadow: 'none', borderRadius: '0px' }} className="my-img domain-icon">
                                            <img src={e?.typeOfAccomplishment === "BADGES" ? Badge : e?.typeOfAccomplishment === 'CERTIFICATES' ? Certificate : achevments} alt="ribbon"></img>
                                        </p>
                                    </div>
                                    <div style={{ display: "flex", justifyContent: "space-between", width: "65%" }}>
                                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                                            <div className="sidebar__ml " style={{ textTransform: 'capitalize', fontSize: '13px' }}>
                                                <p style={{ fontWeight: 'normal', paddingBottom: '3px', fontSize: "14px" }}>{e.title ? e.title : 'N/A'} </p>
                                                {/* <p style={{ paddingBottom: '3px', fontSize: "12px", color: "rgb(118, 118, 118)" }}>{e.summary ? e.summary : 'N/A'}</p> */}
                                                {isReadMore ?
                                                    <span style={{ paddingBottom: '3px', fontSize: "12px", color: "rgb(118, 118, 118)" }}>{e?.summary?.slice(0, 150)}</span> : <span style={{ paddingBottom: '3px', fontSize: "12px", color: "rgb(118, 118, 118)" }}>{e?.summary}</span>
                                                }
                                                {e.summary && e?.summary?.length > 100 ?
                                                    <span onClick={toggleReadMore} className="read-or-hide">
                                                        {isReadMore ? "...read more" : " show less"}
                                                    </span> : ""
                                                }
                                                <p style={{ paddingBottom: '3px', fontSize: "12px", textTransform: "lowercase", color: "rgb(118, 118, 118)" }}>{e?.link ? (e?.link).substr(0, 100) : 'N/A'}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div style={{ width: "20%", textAlign: "center" }}>
                                        <p className='date_acc_text'>{(e?.date) ? moment(e.date).format('DD MMM yyyy') : ""}</p>
                                    </div>
                                </div>
                            ))
                            :

                            <div className="sidebar__padding bg__white e-align icon-show">
                                <div className="ta__center">
                                    <p style={{ textAlign: "center" }}>No Accomplishments</p>
                                </div>
                            </div>
                        }
                        <div className="work__space sidebar__padding bg__white e-align icon-show">
                            <div className="sidebar__substat ta__center">
                                {acomplishmentdata?.length > 5 ? acount === 5 ?
                                    <p id="border_exp_btn" onClick={() => setAcount(acomplishmentdata?.length)} className="cursor d-flex e-align" style={{ color: '#0137CB' }}>
                                        {acomplishmentdata?.length - 3}Accomplishment{(acomplishmentdata?.length - 3) > 1 ? 's' : ''}&nbsp;
                                        <MdOutlineKeyboardArrowDown />
                                    </p> :
                                    <p onClick={() => setAcount(5)} className="cursor" style={{ color: '#0137CB' }}>Hide</p> : ''}
                            </div>
                        </div>
                    </div>

                }

            </div>
        </>
    )
}

export default AboutWriteBox

