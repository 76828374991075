import React, { useState, useEffect, useRef } from 'react';
import { Avatar, Button, DialogContent, DialogTitle, Divider, Grid, Typography, Box, IconButton, Paper, Popover, TextField } from '@mui/material';
import QuizModal from '../KnowledgeQuiz/QuizModal';
import './Feed.css';
import Dialog from '@mui/material/Dialog';
import InputOption from "./InputOption";
import Postingdd from '../../Atom/postingdropdown/postingdropdown';
import Publicdd from '../../Atom/publicdd/publicdd';
import Post from "../Post/Post";
import '../../utility/style.css';
import PollForm from '../feedposts/poll/pollForm';
import Photo from "../feedposts/photo";
import EventsForm from '../feedposts/events/eventForm';
import Article from '../../Article/modal.js'
import '../../Molecule/KnowledgeQuiz/QuizModal.css'
import { MdOutlineBadge } from 'react-icons/md';
import ArticlePage from '../ShareownArticle/Article';
import Celebrate from '../CelebratePage/Celebrate';
import media from '../../../images/media.svg';
import description from '../../../images/article.svg'
import event_icon from '../../../images/event.svg'
import celebration from '../../../images/celebration.svg'
import note_add from '../../../images/attach.svg'
import poll_icon from '../../../images/poll.svg'
import video from '../../../images/evideo.svg'
import clear from '../../../images/clear_meta.svg'
import axios from 'axios';
import URL from '../../apiurl'
import { useHistory, useRouteMatch } from 'react-router';
import Snackbar from '@mui/material/Snackbar'
import { ValidatorForm } from 'react-material-ui-form-validator'
import moment from 'moment'
import CloseIcon from '@mui/icons-material/Close';
import { useDispatch, useSelector } from 'react-redux';
import UserService from '../../../services/UserService';
import CircularProgress from '@mui/material/CircularProgress';
import { useParams } from 'react-router';
import { makeStyles } from '@mui/styles';
import '../../Layout/publish/publish.css'
import { MentionsInput, Mention } from "react-mentions";
import ShowMoreText from "react-show-more-text";
import { getHashtags, getMentions, headers } from '../../../redux/Actions/connectActions';
import { accomplishment, doRefresh, fetchProfile, getMetaData } from '../../../redux/Actions/actions';
import { ThemeProvider, createTheme } from '@mui/material';
import PollPost, { expiryFun } from '../feedposts/poll/pollPost';
import EventView from '../feedposts/events/eventView';
import Accomplishments from '../../Layout/MyProfile/organisation/accomplishments';
import DisplayAcc from '../../Layout/MyProfile/organisation/DisplayAcc';
import { Document, Page, pdfjs } from 'react-pdf';
import { BootstrapTooltip, sendPushNotification } from '../Post/commonFun';
import LinkIcon from '@mui/icons-material/Link';
import DevicesIcon from '@mui/icons-material/Devices';
import LaptopMacIcon from '@mui/icons-material/LaptopMac';
import { Close } from '@mui/icons-material';


export const theme = createTheme({
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 960,
            lg: 1280,
            xl: 1536,
        },
    },
});

const DeleteImg = ({ handledelete }) => {
    // console.log(props)
    return (
        <>
            <div onClick={handledelete} style={{ position: "relative" }}>
                <img style={{ position: "absolute", right: "10px", top: "5px" }} src={clear}></img>
            </div>
        </>
    )
}

const useStyles = makeStyles(() => ({
    paper: {
        width: '600px'
    }
}))

const PhotoFile = (props) => {
    const ref = props.inputRef
    return (
        <>
            <input type="file" name="uploadfile" ref={props.inputRef} multiple id="post-img2" accept=".png, .jpg, .jpeg" style={{ display: 'none' }} onChange={props.uploadPhoto} />
            <label htmlFor="post-img2" className="cursor">
                <div className="inputOption media_class">
                    <img src={media} alt="media" style={{ fontSize: "16px" }} />
                    <div className="input__title icon__ml">Photo</div>
                </div>
            </label>
        </>
    )
}

export const getLoc = async () => {
    const position = await getCurrentPosition()
    const loc = position.coords ? `${position.coords.latitude},${position.coords.longitude}` : null

    return loc
}

function getCurrentPosition() {
    return new Promise((resolve, reject) => {
        const Location = window.navigator && window.navigator.geolocation
        if (Location) {
            Location.getCurrentPosition(
                position => { resolve(position) },
                error => { resolve({}) }
            )
        }
    })
}

const SubmitButton = ({ text }) => {
    return (
        <Button className="post_btn color_white capitalize" type="submit" >{text}</Button>
    )
}

const DialogForm = ({ UploadComp, ...props }) => {

    return (
        <DialogContent sx={{ p: 0 }}>
            <div className='display_ebm sidebar1__padding'>
                <Typography variant="h6" className='color_head font-bold d-flex e-align'>
                    <img src={props.icon} alt={'icon'} width="30px" height="30px" className="celebrate" />&nbsp;{props.title}
                </Typography>
                <Close onClick={props.close} className="cursor" />
            </div>
            <ValidatorForm
                onSubmit={props.handleSubmit}
                onError={errors => { }}>
                {props.children}
                <Divider sx={{ mt: 1.5 }} />
                <div className='newcontact-fieldheader' style={{ paddingLeft: '10px' }}>
                    <UploadComp />
                    <p className='text-right sidebar1__padding' style={{ flexGrow: 1 }}>
                        <SubmitButton text="Submit" />
                    </p>
                </div>
            </ValidatorForm>
        </DialogContent>
    )
}

export default function CreatePost(props) {
    console.log(props, "createPostprops")
    const [expand, setExpand] = useState(false);
    const [metatags, setMetatags] = useState({})
    const [name, setName] = useState("")
    const [show, setShow] = useState(false)
    const [text, setText] = useState({})
    const { path } = useRouteMatch();
    const [open, setOpen] = React.useState(false);
    // const theme = useTheme();
    const classes = useStyles()
    const history = useHistory()
    const [input, setInput] = useState('');
    const [state, setState] = useState(
        {
            photo: false,
            file: false,
            case: false,
            poll: false,
            event: false,
            celebrate: false,
            article: false,
            opinionpoll: false,
            subarticle: false,
            text: false,
            video: false,
            acc: false,
            form: { open: false, state: '' }
        }
    )

    console.log(state, 'state1')
    // const [state1, setState1] = useState({

    // })
    const [filetype, setFiletype] = useState('')
    const user = useSelector(state => state.userProfile.userProfile)
    const page = useSelector(state => state.userProfile.pages)
    const [data, setData] = useState({ coAuthors: '', peers: '', contributors: '', organisations: '', tags: '' })
    const [url, setUrl] = useState([])
    const [filesize, setFilesize] = useState([])
    const [celebratesize, setCelebratesize] = useState('')
    const [imgtype, setImgtypes] = useState([])
    const [alert, setAlert] = useState({ open: false, msg: '', post: false })
    const [pdfFile, setPdfFile] = useState('')
    const [pollForm, setPollForm] = useState({})
    const [celebrate, setCelebrate] = useState({})
    const [eventForm, setEventForm] = useState({})
    const [load, setLoad] = useState(false)
    const [imgLoad, setImgLoad] = useState(false)
    const storeData = useSelector(state => state.userProfile.userProfile)
    const { id } = useParams()
    const [edit, setEdit] = useState(false)
    const [getPost, setGetPost] = useState(false)
    const [puid, setPUid] = useState({ userId: path.includes('/admin') ? 0 : UserService.getUserId(), pageId: path.includes('/admin') ? id : 0 })
    const [orgdata, setOrgdata] = useState([])
    const [share, setShare] = useState({ state: false, post: {} })
    const [postPrivacy, setPostPrivacy] = useState('');
    const [posts, setPosts] = useState([]);
    const [hashtag, setHashtag] = useState({ key: false, value: '' })
    const [users, setUsers] = useState([])
    const [hashtags, setHashtags] = useState([])
    const storeHash = useSelector(state => state.connect.hashtags)
    const mentions = useSelector(state => state.connect.mentions)
    // console.log(storeData)
    const [meta, setMeta] = useState({})
    const celeb = [
        { val: 3, name: 'New Publication / Research' }, { val: 2, name: 'Certification Completion' }, { val: 7, name: 'Starting Job / Position' }, { val: 8, name: 'Work Anniversary' },
        { val: 4, name: 'Honour / Award' },
        // { val: 7, name: 'Send Thanks and Appreciations' }, 
        { val: 5, name: 'Practice Accomplishment' }, { val: 6, name: 'Volunteer Work' }
    ]
    const [celVal, setCelVal] = useState(20)
    const [celform, setCelForm] = useState({})
    const [videoDeviceOrUrl, setVideoDeviceOrUrl] = useState(null)
    const [videoDeviceOrSelect, setVideoDeviceSelect] = useState(null)

    const opens = Boolean(videoDeviceOrUrl);
    const ids = opens ? 'video-popup' : undefined;
    const ref = useRef()

    const mentionsdata = () => {
        if (input !== '') {
            let org = input.split(' ')
            org = org[org.length - 1].replace(/\n/g, '').startsWith('@') ? org[org.length - 1].replace(/@/g, '') : ''
            if (org && !org.includes('href') && !org.includes('</a>')) {
                axios.get(`${URL.mentions}/${org}`, headers()).then(res => {
                    // console.log(res.data)
                    let list = []
                    list = res.data.data.map(data => {
                        // console.log(data)
                        return {
                            ...data,
                            display: data.name.replace(/ /g, ''),
                            name: data.name,
                            id: data.type === 'user' ?
                                `/profile/${data.name.replace(/ /g, '').toLowerCase()}-${data.id}` : `/organization/${data.id}`
                        }
                    })
                    // console.log(list)
                    setUsers(list)
                })
            }
        }
    }

    useEffect(() => {
        mentionsdata()
    }, [input])




    const [anchorEll, setAnchorEll] = React.useState(null);

    const handleClickk = (event) => {
        setAnchorEll(event.currentTarget);

    };

    const handleClosee = () => {
        setAnchorEll(null);
    };

    const openn = Boolean(anchorEll);
    const idd = open ? 'simple-popover' : undefined;


    useEffect(() => {
        let pagedata = []
        // console.log(page)
        page !== undefined ? page?.map(p => pagedata.push({ pageId: p.id, userId: 0, Img: p.logo, option: p.name, caption: p.captionAndTagline })) : pagedata = []
        const options = Object.keys(user)?.length > 0 ? [{ userId: user.id, Img: user ? user.practioner ? user.practioner.photo?.[0].url : '' : '', option: user.fullName, pageId: 0 }, ...pagedata] : [...pagedata]
        // console.log(options)
        let filtereddata = options.filter((element) => {
            // console.log(element)
            // console.log(puid)
            if (element.pageId == puid.pageId) {
                // console.log("first", element.pageId == puid.pageId)
                return element
            }
            else {
                // console.log("userid", puid.pageId, puid.userId)
            }
        })
        // console.log(filtereddata)
        setOrgdata(filtereddata[0])

    }, [puid])

    // console.log(orgdata)



    const dispatch = useDispatch()

    const onClick = () => {
        setExpand(!expand);
    };

    useEffect(() => {
        if (props.handleOpen === 'shareEdit') {
            handleShare(props.postdata, false)
            setInput(props.postdata.sharedDescription)
            setPostPrivacy(props.postdata.postPrivacy)
        }
        else if (props.handleOpen === 'share') {
            handleShare(props.postdata, true)
        }
    }, [props.handleOpen])

    // console.log(input)

    useEffect(() => {
        // console.log(props.post, "gfhjk", props.handleopen)
        if (props.handleOpen === 'edit') {
            const post = props.postdata

            setPostPrivacy(post.postPrivacy)

            if (post.postType === 'IMAGE' || post.postType === 'VIDEO') {
                setUrl(post.sourceLink.split(',').slice(0, -1))
            }
            else if (post.postType === 'FILE') {
                setPdfFile(post.sourceLink.split(',').slice(0, -1))
            }
            else if (post.postType === 'EVENT') {
                setPollForm(post.eventData)
                setUrl(post.eventData.eventImage)
            }
            else if (post.postType === 'CELEBRATE' || post.postType === 'POLL') {
                setCelVal(post.content ? post.content.includes('Work Anniversary') ? 8 : 7 : 10)
                setPollForm(post.content ? post : post.accomplishmentData)
                setCelForm(post.accomplishmentData)
                // console.log(post.accomplishmentData)
            }
            setInput(post.description ? post.description : "")
            if (post.tags !== "null" && post.tags !== null) {
                setHashtag({ key: true, value: post.tags })
            }
            let post_type = post.postType === 'IMAGE' ? 'photo' : post.postType === 'EVENT' ? 'event_form' : post.postType.toLowerCase()
            handlePopup(post_type, true, post.id, post.metadata)
        }

    }, [props.handleOpen])

    useEffect(() => {
        if (props.openpopup)
            handlePopup('acc')
    }, [props.openpopup])

    useEffect(() => {
        if (props.keystate) {
            handlePopup(props.keystate)
        }
    }, [props.keystate])


    const handleChange = (name, value) => {
        setData({ ...data, [name]: value })
    }

    const handleSubarticle = () => {
        setOpen(true)
        setState({ event: false, photo: false, file: false, case: false, poll: false, celebrate: false, article: false, opinionpoll: false, subarticle: true })
    }

    const handleOpinionpoll = () => {
        setOpen(true)
        setState({ event: false, photo: false, file: false, case: true, poll: false, celebrate: false, article: false, opinionpoll: true })
    }
    const handleClose = () => {
        setTimeout(() => {
            setOpen(false);
            setUrl([])
            setPdfFile('')
            setPollForm({})
            setCelForm({})
            setEventForm({})
            setInput('')
            setShare({ state: false, post: {}, value: false })
            setPostPrivacy('')
            setHashtag({ key: false, value: '' })
            setCelVal(20)
            props.handleClose()
        }, 200)
    };



    useEffect(() => {

        if (props.post && props.post.eventData) {
            handlePopup('event_form', true, props.post.id, props.post.metadata)
            // setEdit({ open: !open, id: props.post.id,metadata: props.post.metadata })
            setPollForm(props.post.eventData)
            setUrl(props.post.eventData.eventImage)
            setInput(props.post.description)
            setPostPrivacy(props.post.postPrivacy)
            setPUid({ userId: props.post.userId, pageId: props.post.pageId })
            if (props.post.tags !== null && props.post.tags !== '' && props.post.tags !== 'null') {
                setHashtag({ key: true, value: props.post.tags })

            }
        }
    }, [props.post])

    useEffect(() => {
        let hash = []
        storeHash.map((data) => hash.push({ id: data.id, display: data.hashtag }))
        setHashtags(hash)
    }, [storeHash])

    const [stat, setStat] = React.useState()
    useEffect(() => {
        if (share.state === false) {
            const timeoutId = setTimeout(() => {
                const url = input.replace(/\n/g, ' ').split(' ')
                const regex = new RegExp('^(http|https|ftp)\://[a-zA-Z0-9\-\.]+\.[a-zA-Z]{2,3}(:[a-zA-Z0-9]*)?/?([a-zA-Z0-9\-\._\?\,\'/\\\+&amp;%\$#\=~])*$');
                let val1 = regex.test(url[url.length - 1])
                setStat(val1)
                if (val1 === true) {
                    // axios.post('http://localhost:3001/scrapUrl', { url: url[i] }).then(res => {
                    axios.post(process.env.REACT_APP_API_SCRAPE, { url: url[url.length - 1] }).then(res => {
                        setShow(true)
                        // setView(true)
                        if (res.data !== null) {
                            setMetatags(res.data.og)
                            setMeta(res.data.meta)
                            setText(res.data.og.url || res.data.meta.url)
                        }
                    })
                } else {
                    setMetatags({})
                    setMeta({})
                    setText("")
                }
                // console.log(val1, "vvvvvvvvvvv")
            }, 1000);
            return () => clearTimeout(timeoutId);
        }
    }, [input])

    const getFeed = () => {
        axios.get(`${URL.feed}`, headers())
            .then((response) => response.data)
            .then(data => {
                setPosts(data.data)
                setOpen(false)
                setAlert({ open: false, msg: '', post: false })
                setUrl([])
                setPdfFile('')
                setPollForm({})
                setCelForm({})
                setInput('')
                setLoad(false)

            })
            .catch((error) => {
                setLoad(false)
                setAlert({ open: true, msg: 'Internal Server Error.Try after sometime' })
            })
    }

    const getPosts = () => {
        let userid = sessionStorage.getItem("userId")
        if (path.includes('/myprofile')) {
            axios.get(`${URL.getPosts}/${userid}`, headers())
                .then((response) => response.data)
                .then(data => {
                    // console.log(data)
                    setPosts(data)
                    setOpen(false)
                    setAlert({ open: false, msg: '', post: false })
                    setUrl([])
                    setPdfFile('')
                    setPollForm({})
                    setCelForm({})
                    setInput('')
                    setLoad(false)
                })
                .catch((error) => {
                    setLoad(false)
                    setAlert({ open: true, msg: 'Internal Server Error.Try after sometime' })
                })
        }
        else {
            getFeed()
        }
    }

    const handleDialog = (data) => {
        // console.log(data)
        setState(data)
        setImgLoad(false)
        setOpen(true)
    }
    // console.log(state)
    const handlePopup = (key, val, id, metadata) => {
        // console.log(key,val,id)
        if (val) {
            setEdit({ open: true, id: id, metadata: metadata })
        }
        else {
            setEdit({ open: false, id: '' })
            setPollForm({})
            setEventForm({})
            if (key === 'event_form') {
                setUrl([])
            }
            setHashtag({ key: false, value: '' })
        }
        var k = {}

        Object.keys(state).map((keyName, keyIndex) => {
            if (keyName === 'form' && (key === 'poll_form' || key === 'event_form' || key === 'celebrate_form')) {
                k = { ...k, form: { open: true, state: key.split('_')[0] } }
            }
            else if (keyName === key) {

                k = { ...k, [keyName]: true }
            }
            else {
                k = { ...k, [keyName]: false }

            }
            if (keyName !== 'form' && (key !== 'poll_form' && key !== 'event_form' && key !== 'celebrate_form')) {
                k = { ...k, form: { open: false, state: '' } }
            }
            handleDialog(k)
        })

        if (storeHash.length === 0) {
            dispatch(getHashtags())
        }
        if (mentions.length === 0) {
            // dispatch(getMentions())
        }
    }

    const uploadPhoto = (e, fileType) => {
        setImgLoad(true)
        if (e.target.files.length > 0) {
            if (e.target.files.length < 6) {
                let a = []
                let b = []
                let c = []
                for (let i = 0; i < e.target.files.length; i++) {
                    let types = e.target.files[i].type.split('/')[1]
                    const eTypes = types === 'jpg' || types === 'png' || types === "jpeg"
                    const imgTypes = eTypes || types === 'gif'
                    if (fileType === 'event' ? eTypes : fileType === 'photo' ? imgTypes : types === "mp4") {
                        const reader = new FileReader()
                        reader.readAsDataURL(e.target.files[i]);

                        reader.onloadend = () => {
                            a.push(reader.result)
                            // const media = new Audio(reader.result);
                            let base64 = reader.result.split(",")[1].split("=")[0];
                            var strLength = base64.length;
                            var fileLength = strLength - (strLength / 8) * 2;
                            console.log(fileLength, "vvvvvvvvvvv")
                            b.push(fileLength)
                            // console.log(b)
                            const [, type] = reader.result.split(';')[0].split('/');
                            // console.log(type)
                            c.push(type)
                            // console.log(c)
                            // media.onloadedmetadata = () => console.log(media.duration);
                            if (a.length || b.length || c.length === e.target.files.length) {
                                // console.log(a)
                                setUrl(a)
                                setFilesize(b)
                                setImgtypes(c)
                                if (fileType === 'event') {
                                    setEventForm({ ...eventForm, eventImage: a })
                                    setPollForm({ ...pollForm, eventImage: a })
                                    setUrl(a)
                                    setImgLoad(false)
                                    document.getElementById('post-img7').scrollIntoView()
                                }
                                else {
                                    handlePopup(fileType)
                                }
                            }
                            // console.log(url)
                        }
                    }
                    else {
                        setAlert({ open: true, msg: 'Only .jpg, .png, .jpeg, .gif extensions allowed' })
                        setImgLoad(false)
                    }
                }
            }
            else {
                setAlert({ open: true, msg: 'Can upload only upto 5 images' })
                setImgLoad(false)
            }

        }
    }

    const handlePollForm = (form, type) => {
        // console.log(form)
        if (type === 'celebrate') {
            setCelebrate(form)
        }
        else if (type === 'poll') {
            setPollForm(form)
        }
        else if (type === 'event') {
            setEventForm({ ...form, eventImage: url })
            // setUrl(form.image)
        }
    }

    const uploadFile = (e) => {
        setImgLoad(true)
        if (e.target.files.length > 0) {
            let types = e.target.files[0].type.split('/')[1]
            if (types === "pdf") {
                const reader = new FileReader()

                reader.readAsDataURL(e.target.files[0]);

                reader.onloadend = () => {
                    setPdfFile(reader.result)
                    setUrl(e.target.files[0])
                    handlePopup('file')
                }
            }

            else {
                setAlert({ open: true, msg: 'Only .pdf extensions allowed' })
            }
        }
    }

    const uploadText = async (type, id) => {
        let loc = await getLoc()
        setAlert({ open: true, msg: 'Uploading...', post: true })
        var text = ''
        if (type === 'CELEBRATE') {
            celebrate.occ ? celebrate.occ.includes('Work Anniversary') ? text = `Celebrating Work Anniversary at ${celebrate.org}` : text = `Celebrating New Position at ${celebrate.org}` : text = null
        }
        let data =
        {
            "createDate": new Date().toISOString(),
            "lastUpdated": new Date().toISOString(),
            "description": `${input} ${hashtag.value}`,
            "accomplishment_id": type === 'CELEBRATE' && !text ? id || celform.accomplishmentId || 0 : 0,
            "content": text,
            "mentions": null,
            "location": loc,
            "metadata": await getMetaData(),
            "pageId": Number(puid.pageId),
            "postPrivacy": postPrivacy || 'ANYONE',
            "postType": type,
            "sourceLink": null,
            "tags": `${hashtag.value}`,
            "userData": {
                "fullName": storeData?.practioner?.name[0].text,
                "profilePic": storeData?.practioner?.photo[0]?.url,
                "caption": storeData.captionAndTaglines
            },
            "pageData": (puid.pageId) ? {
                "fullName": orgdata?.option,
                "profilePic": orgdata?.Img,
                "caption": orgdata.caption
            } : null,
            "userId": puid.userId,
        }
        timeline(data)
    }

    const handlePost = (e) => {
        Object.keys(state).map((keyName, keyIndex) => {
            if (state[keyName] === true) {
                switch (keyName) {
                    case 'text': uploadText('TEXT');
                        break;

                    case 'photo':
                    case 'video': postImage(keyName === "photo" ? "IMAGE" : 'VIDEO');
                        break;

                    case 'poll': uploadPoll()
                        break;
                    case 'file': postFile()
                        break;
                    case 'case': console.log('case')
                        break;
                    case 'event': postEventimage('EVENT')
                        break;
                    case 'celebrate': celVal === 10 ? url.length > 0 ? postImage('ACCOMPLISHMENT', true) : handleAccomplishment("") : uploadText('CELEBRATE');
                        break;
                    case 'acc': uploadText('CELEBRATE', props.postdata.id);
                        break;
                    default: console.log('No match')
                }
            }
        })
    }

    const handleUpdate = (e) => {
        let d = Object.keys(state).map((keyName, keyIndex) => {
            if (state[keyName] === true) {
                switch (keyName) {
                    case 'text':
                    case 'celebrate': uploadText(keyName.toUpperCase());
                        break;

                    case 'photo':
                    case 'video': uploadPost(url, keyName === "photo" ? "IMAGE" : 'VIDEO');
                        break;

                    case 'poll': uploadPoll()
                        break;
                    case 'file': uploadPost([pdfFile], 'FILE')
                        break;
                    case 'event': eventForm.eventImage && typeof (eventForm.eventImage) !== 'string' ? postEventimage('EVENT') : updateEvent([eventForm.eventImage])
                        break;
                    default: console.log('No match')
                }
            }
        })
    }

    const uploadLink = async (data, type) => {
        // console.log(data)
        let loc = await getLoc()

        setAlert({ open: true, msg: 'Uploading...', post: true })

        if (type === 'EVENT' && data.files.length === 0) {
            uploadEvent([""], loc)
        }
        else {
            axios.post(`${URL.upload}`, data, headers()).then(res => {
                // console.log(res)
                if (res.status === 200) {
                    if (type === 'EVENT') {
                        if (edit.id) {
                            updateEvent(res.data, loc)
                        }
                        else {
                            uploadEvent(res.data, loc)
                        }

                    }
                    else if (type === 'ACCOMPLISHMENT') {
                        // console.log("acc")
                        handleAccomplishment(res.data)
                    }
                    else {
                        // console.log('dfghjkl')
                        uploadPost(res.data, type, loc)
                    }

                }
                else {
                    setAlert({ open: true, msg: 'Error while uploading...' })
                    handleClose()
                }
            }).catch(error => {
                setAlert({ open: true, msg: 'Error while uploading...' })
                handleClose()
            })
        }
    }

    const postImage = (type, value) => {
        let images = []
        // console.log(url)
        for (let i = 0; i < url.length; i++) {
            images.push(url[i].split(',')[1])
        }
        // types = url[0].split(',')[0].split(';')[0].split('/')[1]
        // typeimg.push(url[0].split(',')[0].split(';')[0].split('/')[1])
        // console.log(images)
        // console.log(imgtype)
        // console.log(images)
        const filedata = images.map((value, i) => {
            return (
                {
                    "file_size": Number((filesize[i] / 1048576).toFixed(3)) || Number((celebratesize / 1048576).toFixed(3)),
                    "file_extension": imgtype[i] || filetype,
                    "data": value.toString()
                }
            )
        })
        // console.log(filedata)
        let data = {
            "file_type": value ? filetype === 'pdf' ? 'PDF' : 'IMAGE' : type,
            "files": filedata
        }
        // console.log(data)
        uploadLink(data, type)
    }

    const postEventimage = (type) => {
        let images = []
        for (let i = 0; i < url.length; i++) {
            images.push(url[i].split(',')[1])
        }
        const filedata = images.map((value, i) => {
            return (
                {
                    "file_size": Number((filesize[i] / 1048576).toFixed(3)),
                    "file_extension": imgtype[i],
                    "data": value.toString()
                }
            )
        })
        // console.log(filedata)
        let data = {
            "file_type": 'IMAGE',
            "files": filedata
        }
        uploadLink(data, type)
    }

    const postFile = () => {
        let images = [], types = ''

        images.push(pdfFile.split(',')[1])
        types = pdfFile.split(',')[0].split(';')[0].split('/')[1]
        let base64 = pdfFile.split(",")[1].split("=")[0];
        var strLength = base64.length;
        var fileLength = strLength - (strLength / 8) * 2;
        // console.log(images)
        // console.log(types)
        // console.log((fileLength / 1048576).toFixed(3))
        let data = {
            "file_type": 'PDF',
            "files": [
                {
                    "file_size": Number((fileLength / 1048576).toFixed(3)),
                    "file_extension": types,
                    "data": images.toString()
                }
            ]
        }
        // console.log(data)
        uploadLink(data, 'FILE')
    }

    const uploadPost = async (sourceLink, postType, location) => {
        // console.log(sourceLink.toString())
        // console.log(sourceLink.toString() + ",")
        // console.log)
        // let updatesourcelink = sourceLink
        let loc = location !== undefined ? location : await getLoc()
        if (edit) {
            setAlert({ open: true, msg: 'Uploading...', post: true })
        }
        let data = {
            "createDate": new Date().toISOString(),
            "lastUpdated": new Date().toISOString(),
            "description": `${input} ${hashtag.value}`,
            "location": loc,
            "metadata": await getMetaData(),
            "pageId": Number(puid.pageId),
            "postPrivacy": postPrivacy || 'ANYONE',
            "postType": postType,
            "sourceLink": (props.handleOpen === 'edit') ? `${sourceLink.toString() + ","}` : sourceLink,
            "tags": '',
            "userData": {
                "fullName": storeData?.practioner?.name[0].text,
                "profilePic": storeData?.practioner?.photo[0]?.url,
                "caption": storeData.captionAndTaglines
            },
            "pageData": (puid.pageId) ? {
                "fullName": orgdata?.option,
                "profilePic": orgdata?.Img,
                "caption": orgdata.caption
            } : null,
            "userId": puid.userId
        }
        // console.log(data)
        timeline(data)
    }

    const uploadPoll = async () => {
        // console.log("poll")
        let loc = await getLoc()
        setAlert({ open: true, msg: 'Uploading...', post: true })
        // let expiryDate = new Date()
        // expiryDate.setDate(expiryDate.getDate() + pollForm.duration.value);
        // console.log(moment(expiryDate).format('YYYY-MM-DD'),pollForm.duration.value)
        let data = {
            "expiry": moment(pollForm.pollExpiry).format('YYYY-MM-DD'),
            "options": pollForm.pollOptions.map(op => op.option).toString(),
            "question": pollForm.pollQuestion,
            "createDate": new Date().toISOString(),
            "description": `${input} ${hashtag.value}`,
            "lastUpdated": new Date().toISOString(),
            "location": loc,
            "metadata": await getMetaData(),
            "pageId": Number(puid.pageId),
            "postPrivacy": postPrivacy || 'ANYONE',
            "postType": "POLL",
            "pollTitle": pollForm.pollTitle,
            "tags": '',
            "userId": puid.userId,
            "userData": {
                "fullName": storeData?.practioner?.name[0].text,
                "profilePic": storeData?.practioner?.photo[0]?.url,
                "caption": storeData.captionAndTaglines
            },
            "pageData": (puid.pageId) ? {
                "fullName": orgdata?.option,
                "profilePic": orgdata?.Img,
                "caption": orgdata.caption
            } : null,
        }
        // console.log(data)
        timeline(data)
    }

    const uploadEvent = async (image, location) => {
        // console.log(image)
        let loc = location !== undefined ? location : await getLoc()
        setAlert({ open: true, msg: 'Uploading...', post: true })
        let data =
        {
            "closingTime": eventForm.e_date ? eventForm.e_date.toISOString() : null,
            "eventImage": image[0],
            "eventMode": eventForm.mode,
            "eventType": eventForm.type,
            "link": eventForm.link,
            "eventLocation": eventForm.loc,
            "openingTime": eventForm.s_date ? eventForm.s_date.toISOString() : null,
            "organiser": eventForm.organiser,
            "preregistration": eventForm.pre_reg,
            "registrationForm": eventForm.reg_form,
            "sponsor": eventForm.sponsor,
            "title": eventForm.title,
            "description": `${input} ${hashtag.value}`,
            "location": loc,
            "event_id": 0,
            "metadata": await getMetaData(),
            "pageId": Number(puid.pageId),
            "postPrivacy": postPrivacy || 'ANYONE',
            "postType": "EVENT",
            "tags": '',
            "userId": puid.userId,
            "webinarPlatform": eventForm.web_platform,
            "eventAdmin": Number(puid.userId),
            "organiserId": null,
            "paymentLink": null,
            "certification": null,
            "paymentMode": null,
            "slot": null,
            "payment": null,
            "userData": {
                "fullName": storeData?.practioner?.name[0].text,
                "profilePic": storeData?.practioner?.photo[0]?.url,
                "caption": storeData.captionAndTaglines
            },
            "pageData": (puid.pageId) ? {
                "fullName": orgdata?.option,
                "profilePic": orgdata?.Img,
                "caption": orgdata.caption
            } : null,
            eventPermission: {
                administrator: {
                    unmute: true,
                    video: true,
                    screenShare: true,
                    record: true,
                },
                speaker: {
                    unmute: true,
                    video: true,
                    screenShare: true,
                    record: false,
                },
                participant: {
                    unmute: true,
                    video: true,
                    screenShare: true,
                    record: false,
                },
            },
        }
        timeline(data)
    }

    const updateEvent = async (image, location) => {
        // console.log("updateevnt", eventForm)
        let loc = location !== undefined ? location : await getLoc()
        let metadata = await getMetaData()
        setAlert({ open: true, msg: 'Uploading...', post: true })
        let data =
        {
            "id": edit.id,
            "description": `${input} ${hashtag.value}`,
            "location": loc,
            "metadata": metadata,
            "pageId": Number(puid.pageId),
            "postPrivacy": postPrivacy || 'ANYONE',
            "postType": "EVENT",
            "tags": '',
            "userId": puid.userId,
            "event_id": 0,
            "mentions": '',
            shared: false,
            "eventData":
            {
                "id": props.postdata.eventData.id,
                "metadata": metadata,
                "closingTime": eventForm.e_date ? eventForm.e_date.toISOString() : null,
                "eventImage": image[0],
                "eventMode": eventForm.mode,
                "eventType": eventForm.type,
                "link": eventForm.link,
                "eventLocation": eventForm.loc,
                "openingTime": eventForm.s_date ? eventForm.s_date.toISOString() : null,
                "organiser": eventForm.organiser,
                "preregistration": eventForm.pre_reg,
                "registrationForm": eventForm.reg_form,
                "sponsor": eventForm.sponsor,
                "title": eventForm.title,
                "webinarPlatform": eventForm.web_platform,
                "eventAdmin": Number(puid.userId),
                "organiserId": null,
                "paymentLink": eventForm.paymentLink,
                "certification": eventForm.certification,
                "paymentMode": eventForm.paymentMode,
                "slot": eventForm.slot,
                "payment": eventForm.payment,
                registrationTemplate: eventForm.registrationTemplate,
                eventDescription: eventForm.eventDescription,
                eventPermission: eventForm.eventPermission
            },
            "userData": {
                "fullName": storeData?.practioner?.name[0].text,
                "profilePic": storeData?.practioner?.photo[0]?.url,
                "caption": storeData.captionAndTaglines
            },
            "pageData": (puid.pageId) ? {
                "fullName": orgdata?.option,
                "profilePic": orgdata?.Img,
                "caption": orgdata.caption
            } : null,
        }
        // console.log(data)
        timeline(data)
    }

    const timeline = async (data) => {
        if (edit.open) {
            // console.log({ ...data, id: edit.id }, "edit")
            // console.log(edit)
            let post = { ...data, metadata: edit.metadata ? { ...edit.metadata } : await getMetaData() }
            // console.log(post)
            axios.put(`${URL.updatePost}/${edit.id}`, post, headers())
                .then(res => {
                    if (res.status === 200) {
                        UpdatePostCache("update_post", res.data, edit.id)

                        setEdit({ open: false, id: res.data.id })
                        setGetPost(true)
                        handleClose()
                        setAlert({ open: false, msg: '', post: false })
                        if (props.post) {
                            // console.log("hello", props.post)
                            dispatch(fetchProfile())
                        }

                    }
                    else {
                        setAlert({ open: true, msg: 'Error while uploading...' })
                        handleClose()
                    }
                })
                .catch(err => {
                    setAlert({ open: true, msg: 'Error while uploading...' })
                    handleClose()
                })
        }
        else {
            axios.post(`${URL.addPost}`, { ...data }, headers())
                .then(async res => {
                    if (res.status === 201) {
                        // UpdateChacheFeed(data)

                        if (data.pageId === 0) {
                            if (path.includes('/myprofile')) {
                                // console.log("mtprofile")
                                // await props.getPosts();

                            }
                            else if (path.includes('/admin')) {
                                // console.log("123456")
                                history.push(`/myprofile/${storeData.fullName.replace(/ /g, '').toLowerCase()}-${data.userId}`)
                            }
                            else {
                                // await props.getFeed()
                            }
                        }
                        else {
                            if (parseInt(id) === data.pageId) {
                                // console.log("admin org")
                                await props.getOrgFeed();
                            }
                            else {
                                history.push(`/admin/organization/${data.pageId}`)
                            }
                        }
                        setAlert({ open: false, msg: '', post: false })
                        handleClose()

                        UpdatePostCache("new_post", res.data, res.data.id)
                        // dispatch(doRefresh("refreshFeed"))
                    }
                })
                .catch(err => {
                    setAlert({ open: true, msg: 'Error while uploading...' })
                    handleClose()
                })
        }
    }

    const UpdatePostCache = async (type, newPostData, id) => {
        let CelebrateData = "";
        if (state.celebrate || state.event || state.poll) {
            //getpostbyid
            await axios.get(`${URL.postById}/${id}`, headers())
                .then((response) => {
                    CelebrateData = response.data
                })
                .catch((error) => {
                    // console.log(error)
                    setAlert({ open: true, msg: 'Internal Server Error.Try after sometime' })
                })
        }

        let data = {
            update: type,
            new_post: CelebrateData ? CelebrateData : newPostData,
            post_id: id ? id : '',
        }
        UpdateChacheFeed(data)
    }



    const UpdateChacheFeed = async (data) => {

        await axios.post(`${URL.updateChacheFeed}`, data, headers())
            .then(res => {
                // console.log(res)
                dispatch(doRefresh("refreshFeed"))

            }).catch(err => {
                console.log(err)
                setAlert({ open: true, msg: 'Internal Server Error.Try after sometime' })
            })
    }


    const updateSharedPost = async () => {
        // console.log("updatesharepost", share)
        setAlert({ open: true, msg: 'Uploading...', post: true })
        let data = { ...share.post, postPrivacy: postPrivacy, sharedDescription: input, sharedTags: hashtag.value, metadata: await getMetaData() }
        // console.log(data)
        // console.log(share.post.id, "id")
        axios.put(`${URL.updatePost}/${share.post.id}`, data, headers())
            .then(res => {
                UpdatePostCache("update_post", res.data, share.post.id)
                if (res.data.status === 200) {
                    setEdit({ open: false, id: data.id })
                    setGetPost(true)
                    setAlert({ open: false, msg: '', post: false })
                    handleClose()

                }
                else {
                    setAlert({ open: true, msg: 'Error while uploading...' })
                    handleClose()
                }
            })
            .catch(err => {
                setAlert({ open: true, msg: 'Error while uploading...' })
                handleClose()
            })
    }

    const handleShare = (post, val) => {
        // console.log("handleshare", val, post)
        setOpen(true)
        if (Object.keys(storeHash).length === 0) {
            dispatch(getHashtags())
        }

        if (Object.keys(mentions).length === 0) {
            // dispatch(getMentions())
        }
        setShare({ state: true, post: post, value: val })
        setEdit({ open: !val })
        if (val === false && post.sharedTags !== null && post.sharedTags !== '') {
            setHashtag({ key: true, value: post.sharedTags })
        }
    }
    // console.log(share)
    const sharePost = async () => {
        setAlert({ open: true, msg: 'Uploading...', post: true })
        let sid = puid.userId !== 0 ? puid.userId : puid.pageId !== 0 ? puid.pageId : ''
        let stype = puid.userId !== 0 ? 'user' : 'page'
        const data =
        {
            "postId": share.post.id,
            // "postId": share.post.shared ? share.post.sharedData.refPostId : share.post.id,

            // "postId": share.post.shared ? share.post.referencePostId : share.post.id,
            metadata: await getMetaData(),
            "id": sid,
            "type": stype,
            "postPrivacy": postPrivacy || 'ANYONE',
            "sharedDescription": `${input} ${hashtag.value}`,
            "sharedTags": '',
            "userData": (stype === "user") ? {
                "fullName": storeData?.practioner?.name[0].text,
                "profilePic": storeData?.practioner?.photo[0]?.url,
                "caption": storeData.captionAndTaglines
            } : null,
            "pageData":
                (stype === "page") ? {
                    "fullName": orgdata?.option,
                    "profilePic": orgdata?.Img,
                    "caption": orgdata.caption
                } : null
        }
        // console.log(data)
        // let postId = share.post.shared ? share.post.sharedData.refPostId : share.post.id

        axios.post(`${URL.sharePost}`, data, headers()).then(res => {
            if (res.data.status === 200 || 201) {
                if (puid.pageId === 0) {
                    setAlert({ open: false, msg: '', post: false })
                    if (path.includes('/myprofile')) {
                        getPosts();
                    }
                    else if (path.includes('/organization') || path.includes('/viewarticle')) {
                        history.push(`/myprofile/${res.data.data.fullName.replace(/ /g, '').toLowerCase()}-${UserService.getUserId()}`)
                    }
                    else {
                        getFeed()
                    }

                    if (Number(UserService.getUserId()) !== Number(res.data.sharedFromUser)) {
                        sendPushNotification("post_shared", res.data.sharedFromUser, res.data.userData.fullName, res.data.id)
                    }
                    UpdatePostCache("shared_post", res.data, share.post.id)
                    // dispatch(doRefresh("refreshFeed"))

                }
                else {
                    history.push(`/admin/organization/${sid}`)
                }
            }
        })
            .catch(err => {
                setAlert({ open: true, msg: 'Error while uploading...' })
                handleClose()
            })
    }

    const removecontent = () => {
        setMetatags([])
        setMeta([])
        setInput('')
        setShow(false)
    }
    const handledelete = (index) => {
        // console.log("sdfghj", index)
        let myArr = url.filter((ele, ind) => {
            return ind !== index
        })
        // console.log(myArr)
        setUrl(myArr)
    }
    // console.log(url)


    // console.log(eventForm, url)

    const handleAddAcc = (data, createpost, metatags, meta) => {
        // console.log(data, createpost, metatags, meta)
        const linktype1 = createpost !== undefined && createpost.length > 0 ? createpost[0].split(';') : ""
        const variable = linktype1 ? linktype1[0].split('/') : ""
        const type1 = variable ? variable[1] : ""
        // console.log(type1)
        const strLength = createpost.length;
        const fileLength = strLength - (strLength / 8) * 2;
        setFiletype(type1)
        setCelebratesize(fileLength)
        setUrl(createpost)
        setCelForm({
            ...data,
            date: new Date(),
            title: data.title || metatags?.title || meta?.title,
            // attachment: image[0] || metatags?.image || meta?.image,
        })
        setCelVal(10)
    }

    // console.log(celVal)
    // console.log(celform)

    useEffect(() => {
        if (celform && Object.keys(celform).length > 0 && celform.title && !celform.accomplishmentId) {
            // console.log(celform, 'testttttttttt')
            handlePopup('celebrate')
        }
        if (celform && Object.keys(celform).length > 0 && celform.link && !celform.accomplishmentId) {
            handlePopup('celebrate')
        }
        // console.log(celform, 'testttttttttt11')
    }, [celform])

    const handleAccomplishment = async (image) => {
        // uploadLink()
        // postImage()
        // console.log(image)
        setAlert({ open: true, msg: 'Uploading...', post: true })
        let loc = await getLoc()
        // const data1 = celform.coAuthors?.split('<')
        // const data2 = data1[1]?.split('@')
        let data = {
            "date": celform.date ? (moment(celform.date).format('YYYY-MM-DD')) : "",
            "accomplishmentCategory": celform.accomplishmentCategory.value,
            "summary": celform.summary || metatags?.description || meta?.description,
            "attachment": celform.attachment || image[0] || metatags?.image || meta?.image,
            "title": celform.title || metatags?.title || meta?.title,
            "userId": Number(sessionStorage.getItem("userId")),
            "status": "SHOW",
            "metadata": await getMetaData(),
            "coAuthors": celform.coAuthors,
            "link": celform.link,
            "organisation": {
                "displayName": celform.organisationName,
                "id": celform.organisation,
                "system": ""
            }
        }
        // console.log(data)
        axios.post(`${URL.addAccomplishment}`, data, headers())
            .then((response) => response.data)
            .then(data => {
                // console.log(data.data)
                // setBadges(data.data)
                dispatch(accomplishment(data.data))
                uploadText('CELEBRATE', data.data.id)
            })
            .catch((error) => {
                setAlert({ open: true, msg: 'Error while uploading...' })
                handleClose()
            })
        // setCheck(true)
        // setOpen(false)
    }

    const handleClickVideo = () => {
        setVideoDeviceOrUrl(prev => !prev)
        setVideoDeviceSelect(true)
        // handleClickVideo(null)
    }
    const handleUploadFromYouTube = (e) => {
        console.log(e, "jkhgfdsfghj")
        // setInput(e.target.value)
        // handleClickVideo();
        setVideoDeviceSelect(true)
        handleClosee()
    };
    console.log(videoDeviceOrUrl, "firstsssssssss", videoDeviceOrSelect)
    const handleNavigate = (e) => {
        console.log(e)
        e.preventDefault();
        history.replace('/newarticle');
        // history.push('/newarticle')
        document.location.reload()
    }

    console.log(hashtag, "hashtag")
    return (
        <div className="feed">

            {/*----------------- Desktop------------------------- */}
            {open ?
                <ThemeProvider theme={theme}>
                    <Dialog
                        // fullScreen={true}
                        open={open}
                        onClose={handleClose}
                        aria-labelledby="responsive-dialog-title"
                        sx={{ p: 0 }}
                        maxWidth="md"
                        // fullWidth
                        classes={{ paper: classes.paper }}
                    >
                        {/* {console.log(state.form.open, "form.open")}
                        {console.log(state.form.state, "form.state")} */}
                        {state.form.open && state.form.state === 'celebrate' ?
                            <Box sx={{ p: 2 }}>
                                <Accomplishments add={true} value={celVal} handleAdd={handleAddAcc} close={() => { handlePopup('celebrate'); setCelVal(20) }} />
                            </Box>

                            : state.form.open ?
                                <DialogForm
                                    title={`${edit.open ? 'Edit' : 'New'} ${state.form.state}`} icon={state.form.state === 'poll' ? poll_icon : state.form.state === 'event' ? event_icon : ''}
                                    handleSubmit={() => setState(prev => ({ ...state, form: { open: false, state: '' }, [prev.form.state]: true }))}
                                    close={handleClose} UploadComp={() => {
                                        return (
                                            state.form.state === 'event' ?
                                                <>
                                                    <input type="file" name="uploadfile" ref={ref} multiple id="post-img7" accept=".png, .jpg, .jpeg" style={{ display: 'none' }} onChange={(e) => uploadPhoto(e, 'event')} />
                                                    <label htmlFor="post-img7" className="cursor">
                                                        <div className="inputOption media_class">
                                                            <img src={media} alt="media" style={{ fontSize: "16px" }} />
                                                            <div className="input__title icon__ml">Photo</div>
                                                        </div>
                                                    </label>
                                                </> : <></>
                                        )
                                    }}
                                >
                                    <Box sx={{ maxHeight: 'calc(100vh - 300px)', overflow: 'scroll' }}>
                                        {state.form.state === 'poll' ? <PollForm handlePollForm={handlePollForm} formData={pollForm} /> :
                                            state.form.state === 'event' ? <EventsForm handleEvent={handlePollForm} formData={pollForm}>
                                                {eventForm.eventImage && eventForm.eventImage.length > 0 ? <Grid container direction="row" sx={{ maxHeight: '500px', overflow: 'hidden', marginTop: '10px', p: '16px!important' }}>
                                                    <Grid item xs={12} style={{ height: '100%', position: 'relative' }} className="elib-right__content">
                                                        <img alt="Event Image" style={{
                                                            objectFit: 'cover',
                                                            borderRadius: 5,
                                                            width: '100%',
                                                            height: '100%'
                                                        }} src={eventForm.eventImage}></img>
                                                        <IconButton style={{ position: 'absolute', top: '9px', right: '9px', backgroundColor: 'black', opacity: 0.5, color: 'white' }} onClick={() => { setEventForm({ ...eventForm, eventImage: '' }); ref.current.value = "" }}><CloseIcon /></IconButton>
                                                    </Grid>
                                                </Grid> : ''}
                                            </EventsForm>
                                                : ''
                                        }
                                    </Box>

                                </DialogForm>
                                :
                                <>
                                    <DialogTitle style={{ padding: '0px' }}>
                                        <div className="font-caption d-flex profile__top fw__semibold sidebar1__padding">
                                            <Typography className='b-color' variant="h6">{edit.open ? 'Edit' : share.state ? 'Share' : 'Create'}  Post</Typography>
                                            <CloseIcon onClick={handleClose} className="cursor" />
                                        </div >
                                    </DialogTitle>
                                    <ValidatorForm
                                        onSubmit={(e) => edit.open && share.state ? updateSharedPost() : edit.open ? handleUpdate(e) : share.state ? sharePost() : handlePost(e)}
                                        onError={errors => { }}>
                                        <DialogContent sx={{ p: 0, width: '100%', mb: '20px' }} className="dialog_content">
                                            {/* <div className="dropDown"> */}
                                            <Grid container sx={{ p: 3, justifyContent: 'space-between!important' }}>
                                                <Grid item sm={5} xs={12}>
                                                    <Postingdd postUser={(uid, pid) => setPUid({ userId: uid, pageId: pid })} edit={edit.open} />
                                                </Grid>
                                                <Grid item sm={5} xs={12}>
                                                    <Publicdd postPrivacy={(value) => setPostPrivacy(value)} value={postPrivacy} />
                                                </Grid>
                                            </Grid>

                                            {/* </div> */}
                                            {!edit.open && !share.state ? <Grid container rowSpacing={2} sx={{ px: '15px' }}>
                                                <Grid item sm={3} xs={6}><InputOption src={poll_icon} title="Create Poll" handleClick={() => handlePopup('poll_form')} /></Grid>
                                                <Grid item sm={3} xs={6}><InputOption src={event_icon} title="Create Event" color='505A00' handleClick={() => handlePopup('event_form')} /></Grid>
                                                <Grid item sm={3} xs={6}><InputOption src={celebration} title="Celebrate" color="EDDC0C" handleClick={() => { handlePopup('celebrate'); setCelVal(20) }} /></Grid>
                                                {/* <Grid item sm={3} xs={6}><InputOption src={description} alt="Article" title="Write Article" handleClick={() => history.push('/newarticle')} /></Grid> */}
                                                <Grid item sm={3} xs={6}><InputOption src={description} alt="Article" title="Write Article" handleClick={(event) => { handleNavigate(event) }} /></Grid>
                                            </Grid> : ''}
                                            {/*clini cases */}
                                            {state.case ?
                                                <>
                                                    <div className="clini_cases">
                                                        <div>
                                                            <MdOutlineBadge style={{ paddingTop: '20px', paddingLeft: '30px' }} />
                                                        </div>
                                                        <div>
                                                            <input type="text" placeholder="Clini Cases" />
                                                        </div>
                                                    </div>
                                                </> : ""
                                            }
                                            <Grid container sx={{ px: '15px', mt: '15px' }} className="h-250">
                                                <Grid item xs={12}>
                                                    {videoDeviceOrSelect ?
                                                        <TextField id="outlined-basic" variant="outlined"
                                                            fullWidth
                                                            placeholder='Enter YouTube link here...'
                                                            sx={{
                                                                boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)', // Add box shadow
                                                                '.MuiOutlinedInput-input': { // Add padding to the input
                                                                    padding: '8px 8px',
                                                                },
                                                            }}
                                                            value={input}
                                                            onChange={(e) => setInput(e.target.value)} />
                                                        :
                                                        <MentionsInput
                                                            value={input}
                                                            placeholder="What would you like to share ?"
                                                            autoFocus
                                                            onChange={(e) => setInput(e.target.value)}
                                                            allowSuggestionsAboveCursor={true}
                                                            // allowSpaceInQuery={true}
                                                            className={videoDeviceOrSelect ? "mentions mentionBackgrnd" : "mentions"}
                                                        >
                                                            <Mention trigger="@" data={users} type="user"
                                                                // appendSpaceOnAdd={true}
                                                                markup="<a class='mentions_text' href='__id__'>__display__</a> "
                                                                renderSuggestion={(entry, search, highlightedDisplay, index, focused) => { return (<div className="d-flex e-align"><Avatar src={entry.profilePic} width="20px" height="20px" />&nbsp;{entry.name}</div>) }}
                                                                className="mentions__mention"
                                                                displayTransform={(id, display) => `@${display} `}
                                                            />
                                                            {/* <Mention trigger="#" data={hashtags} type="user"
                                                            // appendSpaceOnAdd={true}
                                                            markup={`@@@____id__^^____display__@@@^^^ `}

                                                            className="mentions__mention"
                                                            displayTransform={(id, display) => `${display} `}
                                                        /> */}
                                                        </MentionsInput>
                                                    }

                                                    {hashtag.key ?
                                                        <MentionsInput
                                                            value={hashtag.value}
                                                            // value={input}
                                                            // onChange={(e) => setInput(e.target.value)}
                                                            placeholder="Add Hashtag"
                                                            autoFocus
                                                            onChange={(e, val, newValue, mentions) => setHashtag({ ...hashtag, value: newValue })}
                                                            // allowSpaceInQuery={true}
                                                            allowSuggestionsAboveCursor={true}
                                                            className="mentions"
                                                        >
                                                            <Mention trigger="#" data={hashtags} type="user"
                                                                // appendSpaceOnAdd={true}
                                                                markup={`@@@____id__^^____display__@@@^^^ `}
                                                                className="mentions__mention"
                                                                displayTransform={(id, display) => `${display} `}
                                                            />
                                                        </MentionsInput>
                                                        : <Button onClick={() => setHashtag({ key: true, value: '' })}>Add Hashtag</Button>
                                                    }
                                                    {console.log(stat, "99999999999999999", metatags, "99999999999999999999999999989", meta)}
                                                    <div style={{ paddingTop: "20px", maxHeight: "350px" }} >
                                                        {
                                                            // (!view) ? <div style={ display: "flex", justifyContent: "center" }}><CircularProgress /></div> :
                                                            // ((!videoDeviceOrSelect && stat && metatags.url || meta.url)) ?
                                                            ((!videoDeviceOrSelect && stat && metatags.url)) ?
                                                                // <div style={{ width: '100%', height: '500px' }}>
                                                                //     <iframe src={`https://www.youtube.com/embed/${metatags?.url.split('=')[1]}`} width="100%" height="100%" title='YouTube video' allowFullScreen ></iframe>

                                                                // </div>
                                                                (< div style={{ border: "0.65px solid black", borderRadius: "12px", padding: "5px" }}>

                                                                    <div style={{ position: "relative" }}>
                                                                        <div>
                                                                            {(metatags.image) ? <img className='meta_preview' src={(metatags.image) ? metatags.image : (meta.image) ? (meta.image) : ""}></img> : ""}
                                                                        </div>
                                                                        <div onClick={removecontent} style={{ position: "absolute", top: "10px", right: "15px" }}>
                                                                            <img src={clear}></img>
                                                                        </div>
                                                                    </div>
                                                                    <div style={{ paddingLeft: "10px", backgroundColor: "white" }} >
                                                                        <div style={{ display: "flex", paddingTop: "8px" }}>
                                                                            <h3 style={{ fontSize: "15px", color: "blue", fontWeight: "300" }}>{(metatags.url) ? metatags.url : (meta.url) ? meta.url : ""}</h3>
                                                                        </div>
                                                                        <div style={{ display: "flex", paddingTop: "8px" }}>
                                                                            <h3 style={{ fontSize: "18px", color: "black", fontWeight: "400" }}>{(metatags.title) ? metatags.title : (meta.title) ? meta.title : ""}</h3>
                                                                        </div>
                                                                        <div style={{ display: "flex", paddingTop: "8px" }}>
                                                                            <ShowMoreText
                                                                                lines={1}
                                                                                more="Seemore"
                                                                                less="...Showless"
                                                                                className='text_color1'
                                                                                // anchorClass="oooeeer"
                                                                                onClick={onClick}
                                                                                expanded={expand}
                                                                            >
                                                                                {(metatags.description) ? metatags.description : (meta.description) ? meta.description : ""}
                                                                            </ShowMoreText>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                )
                                                                :
                                                                null
                                                        }
                                                    </div>
                                                    <div style={{ paddingTop: "20px", maxHeight: "350px" }} >
                                                        {
                                                            // (!view) ? <div style={ display: "flex", justifyContent: "center" }}><CircularProgress /></div> :
                                                            ((videoDeviceOrSelect && metatags.url)) ?
                                                                <div style={{ border: "0.65px solid lightgray", borderRadius: "1px" }}>
                                                                    <div style={{ position: "relative" }}>
                                                                        <div style={{ width: '100%', height: '500px' }}>
                                                                            <iframe src={`https://www.youtube.com/embed/${metatags?.url.split('=')[1]}`} width="100%" height="100%" title='YouTube video' allowFullScreen allow='autoplay' ></iframe>

                                                                        </div>
                                                                        <div onClick={removecontent} style={{ position: "absolute", top: "10px", right: "5px" }}>
                                                                            <img src={clear}></img>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                :
                                                                null
                                                        }
                                                    </div>
                                                    {console.log(share, "checkkkshare", state)}
                                                    {share.state ? <Post post={share.post} share={share.state} display={!share.value} /> :
                                                        imgLoad ? <div style={{ height: '120px' }} className='textCenter'><CircularProgress disableShrink /></div>
                                                            :
                                                            <>
                                                                {state.photo && url.length > 0 ?
                                                                    // <Photo url={url} imgContent={(e) => (
                                                                    //     <div onClick={handledelete} style={{ position: "relative" }}>
                                                                    //         <img style={{ position: "absolute", right: "10px", top: "5px" }} src={clear}></img>
                                                                    //     </div>
                                                                    // )}>
                                                                    // </Photo>
                                                                    !edit.open ? <Photo url={url} ImgContent={DeleteImg} handledelete={handledelete} />
                                                                        :
                                                                        <div style={{ pointerEvents: 'none' }}>
                                                                            <Photo url={url} />
                                                                        </div>
                                                                    : ""
                                                                }
                                                                {state.video ?
                                                                    <video width="100%" height="500" controls style={{ border: '1px solid lightgrey' }}>
                                                                        <source src={url[0]} type="video/mp4" />
                                                                    </video> : ''}
                                                                {state.file ? <p className="follow-link add-cover textAlign mt-20" >{url.name}
                                                                    {/* {console.log(pdfFile)} */}
                                                                    <embed width="100%" height="300px" className="mt-20" src={pdfFile} type="application/pdf" />
                                                                </p> : ''}
                                                                {state.poll ?
                                                                    <div className='border_all'>
                                                                        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", padding: "8px" }}>
                                                                            <div style={{ display: "flex", alignItems: "center" }}><img src={poll_icon} alt={'poll'} width="25px" height="25px" className="celebrate" />
                                                                                <span style={{ fontSize: "14px", color: "blue", fontWeight: "500" }}>{pollForm.pollTitle}</span>
                                                                            </div>
                                                                            <h4 style={{ fontSize: "14px", fontWeight: "500" }}>{expiryFun(pollForm.pollExpiry)}</h4>
                                                                        </div>
                                                                        <div>
                                                                            <h2 style={{ fontSize: "21px", fontWeight: "500", padding: "10px", textTransform: "capitalize" }}>{pollForm.pollQuestion}</h2>
                                                                            <ul style={{ listStyleType: "none", padding: "10px" }}>
                                                                                {pollForm.pollOptions.map((element, index) => {
                                                                                    return (
                                                                                        <li key={index} style={{ border: "2px solid #3e7ea5", padding: "10px", margin: "8px", borderRadius: "12px", textAlign: "center", color: "#3e7ea5", fontWeight: "600", fontSize: "100%", textTransform: "capitalize" }}>{element.option}</li>
                                                                                    )
                                                                                })}
                                                                            </ul>
                                                                        </div>
                                                                        {/* <PollPost post={pollForm} border={true} /> */}
                                                                    </div> : ""}
                                                                {
                                                                    state.case ? <QuizModal /> : ""
                                                                }
                                                                {
                                                                    state.event ? <EventView data={eventForm} /> : ""
                                                                }
                                                                {
                                                                    state.subarticle ? <ArticlePage data={data} handleChange={handleChange} /> : ""
                                                                }
                                                                {state.article ? <Article data={data} handleChange={handleChange} subarticlepage={handleSubarticle} /> : ''}

                                                                {
                                                                    state.opinionpoll ?

                                                                        <div >
                                                                            <h5>Take Opinion</h5>
                                                                            <PollForm />
                                                                        </div>

                                                                        : ""
                                                                }
                                                                {
                                                                    state.celebrate ? celVal === 20 ?
                                                                        <Box sx={{ px: 2, mb: 2 }}>
                                                                            <img src={celebration} width="20px" height={'20px'} /> Celebrating
                                                                            <Grid container spacing={2} style={{ marginTop: '10px' }} direction="row">
                                                                                {celeb.map((c, i) => (
                                                                                    <Grid item md={6} xs={12} key={i}>
                                                                                        <Paper
                                                                                            onClick={() => { c.val === 7 || c.val === 8 ? handlePopup('celebrate') : handlePopup('celebrate_form'); setCelVal(c.val) }}
                                                                                            sx={{ p: 2, borderRadius: '10px', fontSize: '14px', border: '0.5px solid lightgrey!important', boxShadow: '0px 3px 6px #00000029!important', cursor: "pointer" }}>
                                                                                            {c.name}
                                                                                        </Paper>
                                                                                    </Grid>
                                                                                ))}
                                                                            </Grid>
                                                                        </Box> : celVal === 7 || celVal === 8 ?

                                                                            <Celebrate handleForm={handlePollForm} val={celVal} formData={pollForm} /> : celVal === 10 && <DisplayAcc badges={celform} />
                                                                        : ''
                                                                }
                                                                {
                                                                    state.acc ? <DisplayAcc badges={props.postdata} /> : ""
                                                                }
                                                            </>
                                                    }
                                                </Grid>

                                            </Grid>

                                        </DialogContent>
                                        {/* <div className="font-caption pl-2">Attach</div> */}

                                        <div className="feed__modal  border__rr border__lr" style={{ borderTop: '1px solid lightgrey', marginTop: '10px' }}>
                                            {edit.open ?
                                                <div className="text-right w-100">
                                                    {alert.post || (state.text && input === '' && hashtag.value === '') ? <Button className="add-option" variant="outlined" disabled>Update Post</Button> :
                                                        <SubmitButton text="Update Post" />}
                                                </div>
                                                : share.state ?
                                                    <div className="text-right w-100">
                                                        {alert.post ? <Button className="add-option" variant="outlined" disabled>Share</Button> :
                                                            <SubmitButton text="Share" />}
                                                    </div>
                                                    :
                                                    <>
                                                        {/*----------------- Desktop INPUT OPTIONS------------------------- */}

                                                        <div className='feed__options__Desktop'>
                                                            <div className="d-flex">
                                                                {/* <InputOption src={media} title="Media" color='red' handleClick={handlePhoto} /> */}
                                                                <input type="file" name="uploadfile" multiple id="post-img2" accept=".png, .jpg, .jpeg, .gif" style={{ display: 'none' }} onChange={(e) => uploadPhoto(e, 'photo')} />
                                                                <label htmlFor="post-img2" className="cursor">
                                                                    <div className="inputOption media_class">
                                                                        <img src={media} alt="media" style={{ fontSize: "16px" }} />
                                                                        <div className="input__title icon__ml">Photo</div>
                                                                    </div>
                                                                </label>
                                                                <input type="file" name="uploadVideo" multiple id="uploadVideo" accept=".mp4" style={{ display: 'none' }} onChange={(e) => uploadPhoto(e, 'video')} />

                                                                <div>
                                                                    {/* <label
                                                                        // htmlFor="uploadVideo" 
                                                                        onClick={handleClickVideo}
                                                                        aria-describedby={ids}
                                                                        className="cursor">
                                                                        <div className="inputOption media_class">
                                                                            <img src={video} alt="video" width="20px" height='24px' />
                                                                            <div className="input__title icon__ml">Videos</div>
                                                                        </div>
                                                                    </label> */}



                                                                    <Popover
                                                                        // id={ids}
                                                                        // open={opens}
                                                                        // anchorEl={videoDeviceOrUrl}
                                                                        // onClose={handleClickVideo}
                                                                        anchorOrigin={{
                                                                            vertical: 'top',
                                                                            horizontal: 'left',
                                                                        }}
                                                                    >
                                                                        <div style={{ padding: '19px' }}>
                                                                            {/* <Typography variant="body1" gutterBottom>
                                                                                Upload from:
                                                                            </Typography> */}
                                                                            {/* <Grid className='flx justify-around'>
                                                                            <LaptopMacIcon onClick={() => {
                                                                                document.getElementById("uploadVideo").click()
                                                                                handleClickVideo()
                                                                            }}
                                                                                style={{ cursor: 'pointer' }} />
                                                                            <LinkIcon style={{ cursor: 'pointer' }} onClick={(e) => handleUploadFromYouTube(e)} />
                                                                        </Grid> */}

                                                                        </div>
                                                                    </Popover>
                                                                </div>

                                                                <div>

                                                                    <label
                                                                        // htmlFor="uploadVideo" 
                                                                        // onClick={handleClickVideo}
                                                                        onClick={handleClickk}
                                                                        aria-describedby={idd}
                                                                        className="cursor">
                                                                        <div className="inputOption media_class">
                                                                            <img src={video} alt="video" width="20px" height='24px' />
                                                                            <div className="input__title icon__ml">Videos</div>
                                                                        </div>
                                                                    </label>

                                                                    <Popover
                                                                        id={idd}
                                                                        open={openn}
                                                                        anchorEl={anchorEll}
                                                                        onClose={handleClosee}
                                                                        anchorOrigin={{
                                                                            vertical: 'top',
                                                                            horizontal: 'left',
                                                                        }}
                                                                    // transformOrigin={{
                                                                    //     vertical: 'top',
                                                                    //     horizontal: 'left',
                                                                    // }}
                                                                    // anchorPosition={{ top: 470, left: 500 }}
                                                                    // anchorReference="anchorPosition"
                                                                    >
                                                                        <div style={{ padding: '8px' }}>
                                                                            <Typography variant="body1" gutterBottom>
                                                                                Upload From
                                                                            </Typography>
                                                                            <Grid container spacing={2} className="inputOption ">
                                                                                <Grid item xs={6}>
                                                                                    <LaptopMacIcon onClick={() => {
                                                                                        document.getElementById("uploadVideo").click()
                                                                                        handleClosee()
                                                                                    }}

                                                                                        style={{ cursor: 'pointer' }} />
                                                                                    <div className="input__title icon__ml" >device</div>
                                                                                </Grid>
                                                                                <Grid item xs={6}>
                                                                                    <LinkIcon style={{ cursor: 'pointer' }} onClick={(e) => { handleUploadFromYouTube(e) }} />
                                                                                    <div className="input__title icon__ml" >YouTube</div>
                                                                                </Grid>
                                                                            </Grid>
                                                                        </div>
                                                                    </Popover>
                                                                </div>







                                                                <input type="file" name="upload_file" id="upload_file" accept=".pdf" style={{ display: 'none' }} onChange={(e) => uploadFile(e)} />
                                                                <label htmlFor="upload_file" className="cursor">
                                                                    <div className="inputOption media_class" style={{ padding: '5px 10px' }}>
                                                                        <img src={note_add} alt="file" style={{ fontSize: "16px" }} />
                                                                        <div className="input__title icon__ml">File</div>
                                                                    </div>
                                                                </label>
                                                                {state.case ? state.opinionpoll ? "" :
                                                                    <>
                                                                        <Button href="#" color="primary" style={{ textTransform: 'capitalize' }} onClick={handleOpinionpoll}>opinion poll</Button>
                                                                        <Button href="#" color="primary" style={{ textTransform: 'capitalize' }}>Knowledge Quiz</Button>
                                                                    </>
                                                                    :
                                                                    <>

                                                                    </>}
                                                            </div>
                                                        </div>

                                                        {/*-----------------Mobile INPUT OPTIONS---------------------*/}

                                                        <div className='feed__options__mobile'>
                                                            <div className="feed__inputOption">
                                                                {/* <div className='photo_border'> */}
                                                                <input type="file" name="uploadfile" multiple id="post-img2" accept=".png, .jpg, .jpeg, .gif" style={{ display: 'none' }} onChange={(e) => uploadPhoto(e, 'photo')} />
                                                                <label htmlFor="post-img2" className="cursor">
                                                                    <div className="inputOption media_class">
                                                                        <img src={media} alt="media" style={{ fontSize: "16px" }} />
                                                                        <div className="input__title icon__ml">Photo</div>
                                                                    </div>
                                                                </label>
                                                                <input type="file" name="uploadVideo" multiple id="uploadVideo" accept=".mp4" style={{ display: 'none' }} onChange={(e) => uploadPhoto(e, 'video')} />
                                                                <label htmlFor="uploadVideo" className="cursor">
                                                                    <div className="inputOption media_class">
                                                                        <img src={video} alt="video" width="20px" height='24px' />
                                                                        <div className="input__title icon__ml">Video</div>
                                                                    </div>
                                                                </label>
                                                                <input type="file" name="upload_file" id="upload_file" accept=".pdf" style={{ display: 'none' }} onChange={(e) => uploadFile(e)} />
                                                                <label htmlFor="upload_file" className="cursor">
                                                                    <div className="inputOption media_class" style={{ padding: '5px 10px' }}>
                                                                        <img src={note_add} alt="file" style={{ fontSize: "16px" }} />
                                                                        <div className="input__title icon__ml">File</div>
                                                                    </div>
                                                                </label>

                                                                {state.case ? state.opinionpoll ? "" :
                                                                    <>
                                                                        <Button href="#" color="primary" style={{ textTransform: 'capitalize' }} onClick={handleOpinionpoll}>opinion poll</Button>
                                                                        <Button href="#" color="primary" style={{ textTransform: 'capitalize' }}>Knowledge Quiz</Button>
                                                                    </>
                                                                    :
                                                                    <></>}
                                                            </div>
                                                        </div>
                                                        <div className="feed__btn" >
                                                            {alert.post || state.article || (state.text && input === '' && hashtag.value === '') ? <Button className="add-option" variant="outlined" disabled>Post</Button> :
                                                                <SubmitButton text="Post" />}
                                                        </div>
                                                    </>
                                            }
                                        </div>
                                    </ValidatorForm></>}
                    </Dialog ></ThemeProvider > : ''
            }
            <Snackbar
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                autoHideDuration={alert.post ? null : 2000}
                open={alert.open}
                onClose={() => !alert.post && setAlert({ open: false, msg: '', post: false })}
                message={alert.msg}
            />
        </div >
    )
}