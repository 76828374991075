import React, { useEffect, useRef, useState } from 'react';
import ShowMoreText from "react-show-more-text";
import axios from 'axios';
import URL from '../../apiurl';
import { socket } from '../../../socket';
import unlike from '../../../icons/liked.svg'
import like from '../../../icons/beforeliked.svg'
import Comment from '../../../icons/Discussions.svg'
import Share from '../../../icons/share.svg'
import Divider from '@mui/material/Divider'
import UserService from '../../../services/UserService';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { useRouteMatch, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Avatar, Typography, Box, Button, Menu, MenuItem } from "@mui/material";
import { CommentTextField } from '../../Fields/PollFields';
import InputOption from './inputOption';
import DialogBox from '../../DialogBox/dialog'
// import { styled } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

import { getMetaData } from '../../../redux/Actions/actions';
import moment from 'moment';
import IconButton from '@mui/material/IconButton';
import Delete from '@mui/icons-material/Delete';
import CreatePost from '../Feed/createpost';
import '../Feed/Feed.css';
// import Link from '../../../icons/link.svg'
import { comp, redirect_uri } from '../../Layout/LinkAccount/linkaccount'
import { headers } from '../../../redux/Actions/connectActions';
// import TextTruncate from 'react-text-truncate';
// import './Post.css'
import MedxpertImg from '../../../images/Group 11339.svg'
import YouTube from 'react-youtube';

export const postAnalytics = (post, key) => {
    let data = {
        posted_by_user: post.userId ? post.userId : null,
        posted_by_page: post.pageId ? post.pageId : null,
        post_id: post.id,
        viewed_by: `${UserService.getUserId()}`
    }

    axios.post(URL[key], data).then(res => console.log(res))
}

export const MetaView = ({ metatags, meta, preview }) => {

    const [expand, setExpand] = useState(false);
    const [play, setPlay] = useState(false)
    const players = useRef({});
    const videoRefs = useRef({});
    const opts = {
        playerVars: {
            autoplay: 0,
        },
    };
    const onClick = () => {
        setExpand(!expand);
    };
    useEffect(() => {
        const handleScroll = () => {
            Object.keys(videoRefs.current).forEach((videoId) => {
                const videoRef = videoRefs.current[videoId];
                if (isInViewport(videoRef)) {
                    players.current[videoId].target.playVideo();
                } else {
                    players.current[videoId].target.pauseVideo();
                }
            });
        };

        const isInViewport = (element) => {
            const rect = element.getBoundingClientRect();
            return (
                rect.top >= 0 &&
                rect.left >= 0 &&
                rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
                rect.right <= (window.innerWidth || document.documentElement.clientWidth)
            );
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <div style={{ paddingTop: "20px", maxWidth: "100%" }} >
            {console.log(metatags, "metatagsss")}
            <div>
                <div className='meta_img_text'

                >
                    {(metatags.image || meta.image) ?
                        <div ref={(el) => (videoRefs.current[metatags?.url?.split('=')[1]] = el)} style={{ width: '100%', height: '500px' }}>
                            {/* <iframe src={`https://www.youtube.com/embed/${metatags?.url.split('=')[1]}`}
                                width="100%" height="100%"
                                title='YouTube video' allowFullScreen
                                // allow={play ? 'autoplay' : ""}
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                sandbox="allow-scripts allow-same-origin"
                                frameborder="0"
                                name="embedded-frame"
                            ></iframe> */}
                            <YouTube
                                videoId={`${metatags?.url?.split('=')[1]}`}
                                opts={opts}
                                onReady={(event) => (players.current[metatags?.url?.split('=')[1]] = event)}
                            />

                        </div>
                        // <img className='meta_display_img' alt="meta" src={(metatags.image) ? metatags.image : (meta.image) ? (meta.image) : ""}></img>
                        : ""}
                </div>
                <div className='cursor' onClick={() => window.open(`${(metatags.url) ? metatags.url : (meta.url) ? meta.url : ""}`, "_blank")}>
                    {/* <div style={{ display: "flex", paddingTop: "8px" }}> */}
                    {/* <h3 className='meta_url_text'>{(metatags.url) ? metatags.url : (meta.url) ? meta.url : ""}</h3> */}
                    {/* </div> */}
                    <div style={{ display: "flex", padding: "8px 0px 0px 10px" }}>
                        <h3 className='meta_title_text'>{(metatags.title) ? metatags.title : (meta.title) ? meta.title : ""}</h3>
                    </div>
                    <div style={{ display: "flex", padding: "8px 0px 0px 10px" }}>
                        <ShowMoreText
                            lines={1}
                            more={<span className='add-cover meta_show_text'>showmore</span>}
                            // less={<span style={{ float: 'right' }} className='add-cover meta_show_text'>showless</span>}
                            className='meta_description_text'
                            onClick={onClick}
                            expanded={expand}
                        >
                            {(metatags.description) ? metatags.description : (meta.description) ? meta.description : ""}
                        </ShowMoreText>
                    </div>
                </div>
            </div>
        </div>
    )
}


// export const BootstrapTooltip = makeStyles(({ className, ...props }) => (
//     <Tooltip {...props} arrow classes={{ popper: className }} />
// ))(({ theme }) => ({
//     [`& .${tooltipClasses.arrow}`]: {
//         color: theme.palette.common.black,
//     },
//     [`& .${tooltipClasses.tooltip}`]: {
//         backgroundColor: theme.palette.common.black,
//     },
// }));


const useStyles = makeStyles((theme) => ({
    arrow: {
        color: theme.palette.common.black,
    },
    tooltip: {
        backgroundColor: theme.palette.common.black,
    },
}));

export const BootstrapTooltip = ({ children, ...props }) => {
    const classes = useStyles();

    return (
        <Tooltip {...props} arrow classes={{ popper: classes.arrow, tooltip: classes.tooltip }}>
            {children}
        </Tooltip>
    );
}





export function timeSince(date) {

    var ms = (new Date()).getTime() - new Date(date).getTime();
    var seconds = Math.floor(ms / 1000);
    var minutes = Math.floor(seconds / 60);
    var hours = Math.floor(minutes / 60);
    var days = Math.floor(hours / 24);
    var months = Math.floor(days / 30);
    var years = Math.floor(months / 12);

    if (seconds < 1) {
        return 'Just now';
    } if (seconds < 60) {
        return seconds + 's';
    } if (minutes < 60) {
        return minutes + 'min';
    } if (hours < 24) {
        return hours + 'h';
    } if (days < 30) {
        return days + 'd';
    } if (months < 12) {
        return months + 'mon';
    } else {
        return years + 'y';
    }
}

export const sendPushNotification = async (type, userId, fullName, postId) => {

    let data = {
        "user_name": fullName,
        "user_id": userId,
        "type": type,
        "post_id": postId ? postId : 0
    }


    await axios.post(`${URL.sendPushNotification}`, data, headers())
        .then(res => {
            // console.log(res.data, 'sendnotification response')
        }).catch(err => {
            console.log("err")
        })
}


export const Actions = ({ post, getPostById, openComment, openDialog, showLikes, userID, username, pid, receiverimage, signup }) => {
    // console.log(post, 'kkkkkkkkkkkkkkkkkkkkkkk')
    const [comments, setComments] = React.useState(openComment)
    const [likes, setLikes] = React.useState(showLikes)
    const [commentVal, setCommentVal] = React.useState('')
    const [viewC, setViewC] = React.useState({ count: 5, text: 'Load More Comments' })
    const [modal, setModal] = React.useState({ open: false, id: '' })
    const [share, setShare] = useState(false)
    const [likedby, setLikedby] = useState(false)

    const state = useSelector(state => state?.userProfile?.userProfile)
    const photo = useSelector(state => state.userProfile?.userProfile?.practioner ? state.userProfile.userProfile?.practioner?.photo?.[0]?.url : '')
    const accounts = useSelector(state => state?.connect?.linkedAccounts)

    const ref = React.useRef();
    const { url } = useRouteMatch()
    const history = useHistory()
    // console.log(state)

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    React.useEffect(() => {
        setComments(openComment)
    }, [openComment])

    const handleNotification = (notificationFrom, postId) => {
        socket?.emit("sendNotification", {
            name: username,
            senderID: userID,
            receiverID: pid,
            notificationFrom: notificationFrom,
            profilePic: receiverimage,
            postId: postId
        });
    };

    const handlepostbyid = (id) => {
        // console.log("data", id)
        getPostById(id)
    }

    const addLike = async () => {
        console.log(post?.likeId, "postlikeID ========")
        setLikedby(true)
        let data =
        {
            "userData": {
                "fullName": state?.practioner?.name[0].text,
                "profilePic": state?.practioner?.photo[0]?.url,
                "caption": state.captionAndTaglines
            },
            "date": new Date().toISOString(),
            "likedBy": Number(sessionStorage.getItem('userId')),
            "metadata": await getMetaData(),
            "postId": post.id
        }


        axios.post(`${URL.addLikes}`, data, headers()).then(res => {
            // console.log("hello")
            // console.log(res.data)
            if (Number(UserService.getUserId()) !== Number(post.userId)) handleNotification("LIKED", post.id)
            postAnalytics(post, 'addInteraction')
            getPostById(post.id)
            // LikeCache(post.id)
            // sendPushNotification("like", res.data, post.userId,)

            if (Number(UserService.getUserId()) !== Number(post.userId)) {
                sendPushNotification("like", post.userId, res.data.userData.fullName, res.data.postId)
            }

            LikeCache("add_like", post.id)

        }).catch(err => { })
    }

    const LikeCache = async (type, POSTID) => {
        let likeCount = 0;
        let likedID = 0;
        //getpostbyid
        await axios.get(`${URL.postById}/${POSTID}`, headers()).then(res => {
            likeCount = res.data.likeCount
            likedID = res.data.likeId
        }).catch(err => {
            console.log(err)
        })

        let data = {
            update: type,
            likes_count: likeCount,
            post_id: POSTID,
            like_id: likedID ? likedID : 0
        }
        UpdateChacheFeed(data)
    }

    const UpdateChacheFeed = async (data) => {
        await axios.post(`${URL.updateChacheFeed}`, data, headers())
            .then(res => {
                console.log(res)
            }).catch(err => {
                console.log(err)
            })
    }

    const updateLike = (likeId) => {
        console.log(likeId, "likeId")
        console.log(post?.likeId, "postlikeID ========")

        setLikedby(false)
        axios.delete(`${URL.deleteLikes}/${likeId}`, headers()).then(res => {
            getPostById(post.id)
            LikeCache("delete_like", post.id)

        }).catch(err => { })
    }

    const addComment = async () => {
        // console.log("1234567")
        setCommentVal('')
        let data =
        {
            "comment": commentVal,
            "commentBy": UserService.getUserId(),
            "date": new Date().toISOString(),
            "metadata": await getMetaData(),
            "userData": {
                "fullName": state?.practioner?.name[0].text,
                "profilePic": state?.practioner?.photo[0]?.url,
                "caption": state.captionAndTaglines
            },
            "postId": post.id
        }
        axios.post(`${URL.addComment}`, data, headers()).then(res => {
            if (Number(UserService.getUserId()) !== Number(post.userId)) handleNotification("COMMENTED", post.id)
            postAnalytics(post, 'addInteraction')
            getPostById(post.id)
            // setCommentVal('')
            // sendPushNotification("comment", res.data, post.userId)
            if (Number(UserService.getUserId()) !== Number(post.userId)) {
                sendPushNotification("comment", post.userId, res.data.userData.fullName, res.data.postId)
            }


            CommentCache("add_comment", post.id)

        }).catch(err => { })
    }
    const CommentCache = async (type, POSTID) => {
        let CommentCount = 0;
        //getpostbyid
        await axios.get(`${URL.postById}/${POSTID}`, headers()).then(res => {
            CommentCount = res.data.commentCount
            // console.log(res.data, "lllllllllllllllllll")
        }).catch(err => {
            console.log(err)
        })

        let data = {
            update: type,
            comment_count: CommentCount,
            post_id: POSTID,
        }
        console.log(data, 'ccccc')
        UpdateChacheFeed(data)
    }


    const view = (data) => {
        if (viewC.count < data.length) {
            if (viewC.count + 5 < data.length) {
                setViewC(prev => ({ count: prev.count + 5, text: 'Load More Comments' }))
            }
            else {

                setViewC(prev => ({ count: data.length, text: 'See Less' }))
            }
        }
        else {
            setViewC({ count: 5, text: 'Load More Comments' })
            ref.current.scrollIntoView()

        }
    }

    const deleteComment = (id) => {
        // console.log(id)
        axios.delete(`${URL.deleteComment}/${id}`, headers()).then(res => { getPostById(); handleModal(); CommentCache("delete_comment", post.id) }).catch(err => { })
    }

    const handleModal = () => {
        setModal({ open: false, id: '' })
    }


    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleMedxpertShare = () => {
        const postdata = { docsteth_postId: post.id, ...post }
        // console.log(postdata, 'jjjjj')
        axios.post(`${process.env.REACT_APP_MEDXPERT_SHAREPOST}`, postdata, {
            headers: {
                'Docsteth': `Bearer ${sessionStorage.getItem('kctoken')}`
            }
        }).then(res => {
            // console.log(res, 'ressssssssssssssss')

        })
    }

    return (
        <>
            <Box sx={{ mt: 2 }} className="newcontact-fieldheader ">
                {modal.open ? <DialogBox width="xs" open={modal.open} handleDialog={() => handleModal()} Content={<p>Do you want to delete comment?</p>}
                    Actions={<div>
                        <Button variant="contained" tabIndex={1} className="add-option" sx={{ mr: 2 }} onClick={() => deleteComment(modal.id)}>Yes</Button>
                        <Button variant="contained" tabIndex={2} className="add-option" onClick={() => handleModal()}>No</Button>
                    </div>} /> : ''}

                {share ? <CreatePost postdata={post} handleOpen={'share'} handleClose={() => { getPostById(post.id); setShare(false) }} /> : ''}

                <BootstrapTooltip title='Click to view likes'>
                    <span style={{ color: '#00000099', fontSize: '12px', paddingLeft: "10px" }} className="cursor" onClick={showLikes ? () => { setLikes(true); setComments(false); handlepostbyid(post.id) } : openDialog}><span>{post.likeCount > 0 ? post.likeCount : ''}</span> {post.likeCount > 1 ? 'people liked' : post.likeCount === 1 ? "person liked" : ''}</span>
                </BootstrapTooltip>
                <BootstrapTooltip title='Click to view discussions'>
                    <span style={{ color: '#00000099', fontSize: '12px', paddingRight: "10px" }} className="cursor" onClick={() => { setComments(true); setLikes(false); handlepostbyid(post.id) }}>{post.commentCount > 0 ? post.commentCount : ''} {post.commentCount > 1 ? 'Discussions' : post.commentCount === 1 ? "Discussion" : ''}</span>
                </BootstrapTooltip>
            </Box>
            {/* {post.likeCount > 0 || post.commentCount > 0 ? <Divider style={{ margin: '10px 0' }} /> : ''} */}
            <Divider style={{ margin: '5px 10px' }} />
            {/* {(signup) ? */}
            {/* <div className="newcontact-fieldheader" ref={ref}>
                    {post.liked ? <InputOption Src={<img alt="unlike" src={unlike} />} likeData={post.likedData} title="Like" color="gray" handleClick={() => UserService.doLogin()} /> : <InputOption Src={<img alt="like" src={like} />} title="Like" color="gray" count={post.likeCount} likeData={post.likedData} handleClick={() => { history.push('/') }} />}
                    <InputOption Src={<img alt="discussion" src={Comment} />} title="Discussions" color="gray" handleClick={() => UserService.doLogin()} />
                    <InputOption Src={<img alt="share" src={Share} />} title="Share" color="gray" handleClick={() => UserService.doLogin()} />
                </div>
                : */}
            <div className="newcontact-fieldheader" ref={ref}>
                {(likedby || post.liked) ?
                    <InputOption Src={<img alt="unlike" src={unlike} />} likeData={post.likedData} title="Like" color="gray" handleClick={() => updateLike(post.likeId)} /> :
                    <InputOption Src={<img alt="like" src={like} />} title="Like" color="gray" count={post.likeCount} likeData={post.likedData} handleClick={() => addLike(post.id)} />}
                <InputOption Src={<img alt="discussion" src={Comment} />} title="Discussions" color="gray" handleClick={() => { setComments(true); setLikes(false) }} />
                {/* <InputOption Src={<img alt="share" src={Share} />} title="Share" color="gray" handleClick={() => setShare(true)} /> */}
                <div className='input-Option'
                    aria-label="more"
                    id="long-button"
                    aria-controls="long-menu"
                    aria-expanded={open ? 'true' : undefined}
                    aria-haspopup="true"
                    onClick={handleClick}
                >
                    <span className="cursor" style={{ display: 'flex', alignItems: 'center' }}>
                        <p className='flex font-caption'><img alt="share" src={Share} /></p>&nbsp;
                        <p className="like_display font-caption">Share</p>&nbsp;
                    </span>
                </div>
                <Menu
                    id="long-menu"
                    MenuListProps={{
                        'aria-labelledby': 'long-button',
                    }}
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    PaperProps={{
                        style: {
                            width: '15ch',
                        },
                    }} sessionStorage
                >
                    <MenuItem onClick={() => { setShare(true); handleClose() }}>
                        <img alt="docsteth" src={'https://docstethprodimages.s3.ap-south-1.amazonaws.com/Logo2.svg'} width="25px" height="25px" style={{ paddingRight: "7px" }} /> Docsteth
                    </MenuItem>
                    {accounts.map((acc, i) => {
                        // if (acc.providerName == "medxperts" && acc.userId == post.userId) {
                        //     return (
                        // <MenuItem className='d-flex e-align capitalize' onClick={handleMedxpertShare}>
                        //     <img src={MedxpertImg} alt="medxperts" width={25} height={25} style={{ paddingRight: '7px' }} />
                        //     <Typography>{acc.providerName}</Typography>
                        // </MenuItem>
                        //     )
                        // }
                        // return (
                        // <MenuItem key={i} onClick={handleClose}>
                        //     <a className="d-flex e-align capitalize" href={redirect_uri('post', post.id)[acc.providerName]}>{comp[acc.providerName]} {acc.providerName}</a>
                        // </MenuItem>
                        // )

                        return (
                            <>
                                {acc.providerName == "medxperts" && acc.userId == post.userId ?
                                    <MenuItem className='d-flex e-align capitalize' onClick={handleMedxpertShare}>
                                        <img src={MedxpertImg} alt="medxperts" width={25} height={25} style={{ paddingRight: '7px' }} />
                                        <Typography>{acc.providerName}</Typography>
                                    </MenuItem> :
                                    acc.providerName !== "medxperts" && <MenuItem key={i} onClick={handleClose}>
                                        <a className="d-flex e-align capitalize" href={redirect_uri('post', post.id)[acc.providerName]}>{comp[acc.providerName]} {acc.providerName}</a>
                                    </MenuItem>}
                            </>
                        )


                    })}
                </Menu>
            </div>
            {/* } */}
            {likes && post.likeCount > 0 ?
                <>
                    {post?.likedData?.map(user => (
                        <>
                            <p style={{ color: '#8b8484', fontSize: '13px', marginBottom: '10px' }}>Liked By</p>
                            <div className="post__header" key={user.id}>
                                {/* {console.log(user)} */}
                                <Avatar src={user.userData.profilePic} />
                                <div className="post__info e-align">
                                    <div>
                                        <h2 className="cursor capitalize" onClick={() => history.push(`/${(user.userData.fullName.replace(/ /g, '')).toLowerCase()}-${user.likedBy}`)}>{user.userData.fullName}</h2>
                                        <p className="capitalize">{user.userData.caption}</p>
                                    </div>
                                </div>
                            </div>
                        </>
                    ))}</>
                :
                comments ?
                    <div>
                        {/* {console.log(photo)} */}
                        <Typography component="div" sx={{ display: 'flex', alignItems: 'flex-start', pt: '16px!important', mt: '10px!important', borderTop: '1px solid lightgrey' }}>
                            <Avatar src={photo} sx={{ mr: 2, borderRadius: "7px!important", marginLeft: "10px !important" }} />
                            <div className="post__comment fabdetail">
                                <CommentTextField
                                    multiline
                                    maxRows="10"
                                    className='input_width'
                                    value={commentVal}
                                    onChange={(e) => {
                                        if (e.target.value.length <= 255) {
                                            setCommentVal(e.target.value)
                                        }
                                    }}
                                    placeholder="Add comment"
                                />
                                {commentVal && <p style={{ color: 'rgba(0, 0, 0, 0.23)' }} className="ml-1">{commentVal.length}/255</p>}
                                {commentVal === '' ?
                                    <Button className="add-option" variant="outlined" disabled sx={{ marginLeft: '16px', mt: '0px!important' }}>Post</Button> :
                                    <button variant="outlined" className="btn__primary cursor" style={{ marginLeft: '16px' }} type="submit" onClick={() => addComment()}>Post</button>
                                }
                            </div>
                        </Typography>
                        {post?.commentData?.slice(0, viewC.count).map(comment => {
                            return (
                                <Typography component="div" className="post__header" key={comment.id} sx={{ mt: '16px!important' }}>
                                    <Avatar src={comment.userData.profilePic} sx={{ borderRadius: '7px!important', marginLeft: "10px !important" }} />
                                    <Typography component={'div'} className="post__info d-flex-start overflow_hidden" sx={{ backgroundColor: '#f3f3f3', p: 1, ml: '8px!important' }}>
                                        <div className='overflow_hidden'>
                                            <h2 className="capitalize text-ellipsis">{comment.userData.fullName}</h2>
                                            <p className="fontItalic text-ellipsis">{comment.userData.caption}</p>
                                            <Typography color="black" className="fabdetail e-align">
                                                <span>{`${timeSince(moment.utc(comment.date).local())}`}</span>&nbsp;
                                            </Typography>
                                            <p style={{ color: '#414141', fontSize: '15px', marginTop: '6px', wordBreak: 'break-word' }}>{comment.comment}</p>
                                        </div>
                                        {url.includes('/myprofile') ? <BootstrapTooltip title='Delete Comment'>
                                            <IconButton aria-label="visible" sx={{ p: '0px!important' }} onClick={() => setModal({ open: true, id: comment.id })}>
                                                <Delete sx={{ fontSize: '18px!important' }} />
                                            </IconButton>
                                        </BootstrapTooltip> : Number(UserService.getUserId()) === comment.commentBy ? <BootstrapTooltip title='Delete Comment'>
                                            <IconButton aria-label="visible" sx={{ p: '0px!important' }} onClick={() => setModal({ open: true, id: comment.id })}>
                                                <Delete sx={{ fontSize: '18px!important' }} />
                                            </IconButton>
                                        </BootstrapTooltip> : ''}
                                    </Typography>
                                </Typography>
                            )
                        })}
                        {post.commentData && post.commentData.length > 0 && <p className="text-right">{viewC.count < post?.commentData.length ? " ...." : ''}{post?.commentData.length > 5 ? <span className="see-more add-cover cursor" onClick={() => view(post?.commentData)}>{viewC.text}</span> : ''}</p>}
                    </div> : ''
            }
        </>
    )
}


const link = (message) => {

    let a
    if (message.includes('dev.docsteth.com') || message.includes('www.docsteth.com')) {
        a = message.replace(/(https?:\/\/[^\s]+)/g, "<a class='add-cover b-color' href='$1' >Link</a>")

    } else {
        a = message.replace(/(https?:\/\/[^\s]+)/g, "<a class='add-cover b-color' href='$1' target='_blank'>Link</a>")

    }

    return (
        <div>
            {/* <img src={Link} width="15px" height="15px" style={{ marginLeft: "5px" }}></img> */}
            <div dangerouslySetInnerHTML={{ __html: a }}></div>
        </div >
    )
}

export const Description = ({ description }) => {

    const [viewMore, setViewMore] = React.useState({ count: 150, text: 'See More' })
    let text = "<a class='add-cover b-color"

    const view = (data) => {
        if (viewMore.count === 150) {
            setViewMore({ count: data.length, text: 'See Less' })
        }
        else {
            setViewMore({ count: 150, text: 'See More' })
        }
    }
    // console.log(description, "description")
    // console.log(link, "link")
    return (
        description && <>
            <Typography component="div" style={{ color: '#00000099', whiteSpace: 'pre-wrap', margin: "0px 10px" }}>{link(description.slice(0, description.includes(text) ? viewMore.count * 2 + 100 : viewMore.count))}</Typography>
            <p className="text-right">{(description.includes(text) ? (viewMore.count * 2) + 100 : viewMore.count) < description.length ? " ...." : ''}
                {description.includes(text) ?
                    description.length > 400 ?
                        <span style={{ marginRight: "10px" }} className="see-more add-cover cursor" onClick={() => view(description)}>{viewMore.text}</span>
                        : '' :
                    description.length > 150 ?
                        <span style={{ marginRight: "10px" }} className="see-more add-cover cursor" onClick={() => view(description)}>{viewMore.text}</span> : ''}
            </p>
        </>
    )

}

export const PostUnavailable = () => {
    return (
        <div className="padding_post" id='comment_margin_end'>
            <div className="post_deleted">Post Unavailable</div>
        </div>
    )
}

// export const FileData = (images,file_size,file_type,file_extension) => {
//     return ({
//         file_type: file_type,
//         files: images.map((value, i) => {
//             return (
//                 {
//                     "file_size": file_size,
//                     "file_extension": file_extension,
//                     "data": value.toString()
//                 }
//             )
//         })
//     })
// }