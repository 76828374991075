import * as React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useDispatch, useSelector } from 'react-redux';
import Doc from '../../../images/Logo2.svg'
import { fetchProfile } from '../../../redux/Actions/actions';
import { useParams } from 'react-router';
import { makeStyles } from '@mui/styles';
import { Avatar } from '@mui/material';

const useStyles = makeStyles((theme) => ({

  secondary: {
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden'
  }
}));

export default function SimpleListMenu(props) {

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selectedIndex, setSelectedIndex] = React.useState(0);
  const dispatch = useDispatch()
  const open = Boolean(anchorEl);

  const classes = useStyles();
  const { id } = useParams()
  const user = useSelector(state => state.userProfile.userProfile)
  const page = useSelector(state => state.userProfile.pages)
  const [username, setUsername] = React.useState({})

  React.useEffect(() => {
    if (Object?.keys(user)?.length === 0) {
      dispatch(fetchProfile())
      setSelectedIndex(0)
    }
  }, [])

  let data = []

  page !== undefined ? page?.map(p => data?.push({ pageId: p?.id, userId: 0, Img: p?.logo, option: p?.name })) : data = []

  const options = Object?.keys(user)?.length > 0 ? [{ userId: user?.id, Img: user ? user?.practioner ? user?.practioner?.photo?.[0]?.url : '' : '', option: user?.fullName, pageId: 0 }, ...data] : [...data]

  React.useEffect(() => {
    let pid = props?.value ? sessionStorage.getItem('userId') : window.location?.href?.includes('/admin') ? id : sessionStorage.getItem('userId')
    let val = options.find(option => option?.userId !== 0 ? option?.userId == pid : option?.pageId !== 0 ? option?.pageId == pid : '')
    val !== undefined ? setUsername(val) : setUsername({})
    setSelectedIndex(options?.indexOf(val))
  }, [user])


  const handleClickListItem = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuItemClick = (event, index) => {
    setSelectedIndex(index);
    setUsername(options?.[index])
    props?.postUser(options?.[index].userId, options?.[index]?.pageId)
    // props.pagename(options[index].option)
    // props.pageimg(options[index].Img)
    setAnchorEl(null);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  // console.log(username)


  return (
    <div className='posting_drop_down'>
      <List
        component="nav"
        aria-label="Device settings"
        className='posting__as'
      >
        <ListItem
          button
          id="lock-button"
          aria-haspopup="listbox"
          aria-controls="lock-menu"
          aria-label="when device is locked"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClickListItem}
          className='device__locked'
        >
          <ListItemIcon sx={{ minWidth: '40px', color: '#0070cd', pr: 1 }}>
            <Avatar style={{ height: "32px", width: "32px", borderRadius: "7px" }} src={username?.Img ? username?.Img : Doc} />
          </ListItemIcon>
          <ListItemText className="side-overflow posted__by" classes={{ secondary: classes.secondary }}
            primary={<p style={{ color: '#2E3B43', fontSize: '12px' }}>Posting as</p>}
            secondary={<span style={{ color: 'black' }} className="capitalize" >{username?.option || 'N/A'}</span>}
          />
          <ListItemIcon sx={{ minWidth: 'auto', color: '#0070cd', pl: 1 }}>
            <KeyboardArrowDownIcon />
          </ListItemIcon>
        </ListItem>
      </List>
      {/* {console.log(options)} */}
      {!props?.edit && options?.length > 0 ? <Menu
        id="lock-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'lock-button',
          role: 'listbox',
        }}
        sx={{ maxHeight: '250px' }}
      >
        {options?.map(({ option, Img }, index) => (
          <MenuItem
            key={index}
            // disabled={index === 0}
            selected={index === selectedIndex}
            onClick={(event) => handleMenuItemClick(event, index)}
            className='open_menu'
          >
            <ListItemIcon sx={{ minWidth: 'auto', color: '#0070cd' }}>
              <Avatar style={{ height: "32px", width: "32px", borderRadius: "7px" }} src={Img ? Img : Doc} />
            </ListItemIcon>
            <span className="capitalize">&nbsp;{option}</span>
          </MenuItem>
        ))}
      </Menu> : []}
    </div>
  );
}

