import { Box, Card, CardContent, CardHeader, Dialog, Tab, Tabs, Typography } from '@mui/material'
import React, { useEffect } from 'react'
import MedicalServicesIcon from '@mui/icons-material/MedicalServices';
import { TabContext, TabPanel } from '@mui/lab';
import LocationAmenities from './LocationAmenities';
import InsatanceAminities from './InsatanceAminities';
import Amenties from './Amenties';
import TimeAnimities from './TimeAnimities';
import UserService from '../../../../../services/UserService';
import { D_API } from '../../../../../services/APIService';
import { useDispatch, useSelector } from 'react-redux';
import { updateLocationAmenities } from '../../../../common/functions/orgfun';
import { useState } from 'react';


const AddAmenities = ({ close, editAmenities, toBeEditId }) => {
    const [updatedLocationState, setUpdatedLocationState] = useState(null)
    const [updateInstanceIds, setUpdateInstanceIds] = useState(null)
    const [updateInstanceSubLocationIds, setUpdateInstanceSubLocationIds] = useState([])
    const [contact, setContact] = useState([]);
    const [value, setValue] = React.useState('1');
    // for LocationAmenities
    const [locationState, setLocationState] = useState({
        name: null,
        locId: "",
        mode: "kind",
        physical: null,
        partOf: null,
    })
    // for instanceAmenities
    const [details, setDetails] = useState([
        {
            name: "",
            subLocationName: "",
            physicalTypeRoom: "",
            units: 0,
            physicalType: null,
            physicalTypeRoom: null,
            hasSubLocation: "yes",
        },
    ]);
    const dispatch = useDispatch()
    const { l2id } = useSelector((state) => state.registration);
    const location = useSelector((state) => state.registration.location);

    useEffect(() => {
        dispatch({ type: "Level_TWO_ID", payload: toBeEditId })
    }, [])

    useEffect(() => {
        console.log(toBeEditId)
        if (toBeEditId) {
            // D_API.get(`https://dev.apis.clinivault.org/auth/v1/Location/${toBeEditId}`, {
            D_API.get(`Location/${toBeEditId}`, {
                headers: { Authorization: `bearer ${UserService.getToken()}` },
            }).then(res => {
                // console.log(res.data, "done")
                setUpdatedLocationState(res.data)
                setLocationState({
                    name: res.data?.type?.[0]?.coding?.[0],
                    // locId:"",
                    mode: res.data?.mode,
                    physical: res.data?.physicalType?.coding?.[0],
                    partOf: location?.name
                })
                setContact(res.data?.telecom ? res.data?.telecom : [])
            })

            // for getting instance
            D_API.get(`Location/partof/${toBeEditId}?count=50&page=1`, {
                headers: { Authorization: `bearer ${UserService.getToken()}` },
            })
                .then(res => {
                    // console.log(res.data.entry, "check please 1")

                    setUpdateInstanceIds(res.data.entry)
                    let allinstance = res.data.entry


                    let newData = []
                    allinstance?.map((item, index) => {
                        console.log(item, 'first')

                        newData?.push({
                            name: item?.resource?.name,
                            subLocationName: "",
                            physicalTypeRoom: "",
                            units: 0,
                            // physicalType: null,
                            physicalType: item?.resource?.physicalType?.coding?.[0],
                            physicalTypeRoom: null,
                            hasSubLocation: "yes",
                        })

                        if (item?.resource?.id) {
                            instance(item?.resource?.id, index)
                            console.log(item?.resource?.id, 'hello')

                        }
                    })

                    // console.log(newData, "newwwww")

                    setDetails(newData)

                }).catch(err => { })

            const instance = (id, index) => {
                // console.log(id, "oneeeeeeeeeeeeeeeeeee")
                if (id) {
                    D_API.get(`Location/partof/${id}?count=50&page=1`, {
                        headers: { Authorization: `bearer ${UserService.getToken()}` },
                    })
                        .then(res => {
                            console.log(res?.data?.entry?.[0]?.resource?.id, "check please gotcha")
                            console.log(res?.data, "check please gotcha 1111")


                            setDetails((prev) => {
                                let newInstances = [...prev];
                                // newInstances[index] = { ...newInstances[index], subLocationName: res.data?.entry?.[0]?.resource?.name };
                                newInstances[index] = {
                                    ...newInstances[index],
                                    subLocationName: res.data?.entry?.[0]?.resource?.name ? res.data?.entry?.[0]?.resource?.name : "",
                                    physicalTypeRoom: res.data?.entry?.[0]?.resource?.physicalType?.coding?.[0] ? res.data?.entry?.[0]?.resource?.physicalType?.coding?.[0] : null,
                                    units: res.data?.entry?.[0]?.resource?.physicalType?.text ? Number(res.data?.entry?.[0]?.resource?.physicalType?.text) : 0
                                };
                                return newInstances;
                            })


                            setUpdateInstanceSubLocationIds((prev) => {
                                return [...prev, res?.data?.entry?.[0]?.resource?.id]
                            })

                        }).catch(err => { })
                }
            }

        }
    }, [editAmenities])

    const addContact = (system) => (use) => {
        setContact([...contact, { system: system, value: "", use: use }]);
    };

    const deleteContact = (index) => () => {
        setContact([...contact.filter((con, i) => index !== i)]);
    };

    const handleContact = (i) => (name, value) => {
        setContact([
            ...contact?.map((con, ind) =>
                i === ind ? { ...con, [name]: value } : { ...con }
            ),
        ]);
    };

    const handleLocationState = (name, value) => {
        setLocationState((prev) => {
            return { ...prev, [name]: value }
        })
    }

    const addPhysicalAnimities = () => {
        // console.log('instances', '160')
        setDetails([
            ...details,
            {
                name: "",
                subLocationName: "",
                physicalTypeRoom: "",
                units: 0,
                physicalType: null,
                physicalTypeRoom: null,
                hasSubLocation: "yes",
            },
        ]);
    };

    const handleDetailsMultiple = (i) => (name, value) => {
        setDetails([
            ...details?.map((item, ind) =>
                i == ind ? { ...item, [name]: value } : { ...item }
            ),
        ]);
    };

    const UpdatelocationL2Data = () => {
        D_API.get(`Location/partof/${location.id}?count=50&page=1`).then(
            (res) => {
                // setAllServices(res.data.entry)
                dispatch({
                    type: "Location_L2_Data",
                    payload: res.data.entry,
                });
            }
        );
    };

    // for tab
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const changeTabTiming = () => {
        //here 3 means go to tab timing
        setValue('2')
    }
    const changeTabInstance = () => {
        //here 3 means go to tab instanceaminities
        setValue('3')
    }

    const handleEditSubmitAmenities = async (data) => {
        if (value == "1") {
            const updatedData = { ...updatedLocationState, ...data }
            D_API.put(`Location/${toBeEditId}`, updatedData, {
                headers: { Authorization: `bearer ${UserService.getToken()}` },
            }).then((res) => {
                dispatch({ type: "Level_TWO_ID", payload: toBeEditId })
                UpdatelocationL2Data()
                changeTabTiming()
            }).catch(err => { })
        } else if (value == "2") {
            let dataUpdated = await updateLocationAmenities(data)
            // console.log(dataUpdated,"hii")
            if (dataUpdated) {
                // props.changeTab()
                UpdatelocationL2Data()
                changeTabInstance()
            }
        } else if (value == "3") {
            const handleSublocationSubmit = async (data, id) => {
                //submit sub location
                // console.log(data, "sublocation data")

                // console.log(data,id,"heyheyeheyeyeyeyey")

                // D_API.put("Location", data, {
                await D_API.put(`Location/${id}`, data, {
                    headers: { Authorization: `bearer ${UserService.getToken()}` },
                }).then((res) => {
                    // console.log(res.data, "final");
                }).catch(err => { })

            }
            data?.map(async (item, index) => {
                let l3id;

                let data1 = {
                    resourceType: "Location",
                    partOf: {
                        reference: `Location/${l2id}`,
                    },
                    name: item?.name,
                    physicalType: { coding: [item?.physicalType] },
                    // id: updateInstanceIds?.[index]?.resource?.id
                    id: item?.resource?.id
                }

                // console.log(data1, "main physical instance", updateInstanceIds)



                // if (updateInstanceIds?.[index]?.resource?.id) {
                if (item?.resource?.id) {
                    await D_API.put(`Location/${item?.resource?.id}`, data1, {

                        headers: { Authorization: `bearer ${UserService.getToken()}` },
                    }).then((res) => {
                        // console.log(res.data, "for l3");
                        l3id = res.data
                    }).catch(err => { })


                    await D_API.get(`Location/partof/${item?.resource?.id}`, {
                        headers: { Authorization: `bearer ${UserService.getToken()}` },
                    }).then(async (res) => {
                        // console.log(res, "he;;;;;")
                        let id = res.data?.entry?.[0]?.resource?.id

                        if (id) {
                            await D_API.get(`Location/${id}`, {
                                headers: { Authorization: `bearer ${UserService.getToken()}` }
                            }).then(res => {
                                // console.log(res.data, "qwertyui")
                                if (res?.data?.id) {
                                    let updatedData = { ...res.data }
                                    updatedData.name = item.subLocationName
                                    updatedData.physicalType = { coding: [item.physicalTypeRoom], text: `${item.units}` }


                                    // handleSublocationSubmit(updatedData, updateInstanceSubLocationIds[index])
                                    handleSublocationSubmit(updatedData, res.data?.id)

                                }
                            })
                        }

                        if (res.data?.total == 0) {
                            // console.log("first")
                            let datanew = {
                                resourceType: "Location",
                                partOf: {
                                    // reference: `Location/${l3id}`,
                                    // reference: `Location/${updateInstanceIds[index].resource.id}`,
                                    // reference: `Location/${updateInstanceIds?.[index]?.resource?.id}`,
                                    reference: `Location/${l3id}`,
                                },
                                name: item?.subLocationName,
                                physicalType: { coding: [item?.physicalTypeRoom], text: `${item?.units}` },
                            }
                            // console.log(datanew, "jjjjjjjjjjjjjjjjjjjjjjjjj")
                            await D_API.post("Location", datanew, {
                                headers: { Authorization: `bearer ${UserService.getToken()}` },
                            }).then((res) => {
                                // console.log(res.data, "final");
                            }).catch(err => { console.log(err) })
                        }



                    })
                    close()
                }
                //  else {
                //     const handleSublocationSubmit = (data) => {
                //         //submit sub location
                //         console.log(data, "sublocation data")

                //         D_API.post("Location", data, {
                //             headers: { Authorization: `bearer ${UserService.getToken()}` },
                //         }).then((res) => {
                //             console.log(res.data, "final");
                //         }).catch(err => { })

                //     }

                //     data.map(async (item) => {

                //         let l3id;

                //         let data1 = {
                //             resourceType: "Location",
                //             partOf: {
                //                 reference: `Location/${l2id}`,
                //             },
                //             name: item.name,
                //             physicalType: { coding: [item.physicalType] },
                //         }

                //         console.log(data1, "main physical instance")
                //         await D_API.post("Location", data1, {
                //             headers: { Authorization: `bearer ${UserService.getToken()}` },
                //         }).then((res) => {
                //             console.log(res.data, "for l3");
                //             l3id = res.data
                //         }).catch(err => { })


                //         if (item.hasSubLocation === "yes") {
                //             let data = {
                //                 resourceType: "Location",
                //                 partOf: {
                //                     reference: `Location/${l3id}`,
                //                 },
                //                 name: item.subLocationName,
                //                 physicalType: { coding: [item.physicalTypeRoom], text: `${item.units}` },
                //             }
                //             handleSublocationSubmit(data)
                //         } else {
                //             //delete sub location api
                //         }
                //         console.log(data, "ddd")
                //     })
                //     close()

                // }

                // if (item.hasSubLocation === "yes") {
                //     let data = {
                //         resourceType: "Location",
                //         partOf: {
                //             reference: `Location/${l3id}`,
                //         },
                //         name: item.subLocationName,
                //         physicalType: { coding: [item.physicalTypeRoom], text: `${item.units}` },
                //         id:updateInstanceSubLocationIds[index]   
                //     }
                //     console.log(data, "main physical instance sub")

                //     handleSublocationSubmit(data,updateInstanceSubLocationIds[index])
                // } else {
                //     //delete sub location api
                // }
                // console.log(data, "ddd")
            })
        } else {

        }
    }


    const handleSubmitAmenities = async (data) => {
        console.log(value)
        if (value == "1") {
            D_API.post("Location", data, {
                headers: { Authorization: `bearer ${UserService.getToken()}` },
            }).then((res) => {
                // console.log(res.data,"mmm");
                dispatch({ type: "Level_TWO_ID", payload: res.data })
                UpdatelocationL2Data()
                // changeTab()
                changeTabTiming()
            }).catch(err => {
            })
        } else if (value == "2") {
            let dataUpdated = await updateLocationAmenities(data)
            if (dataUpdated) {
                // props.changeTab()
                UpdatelocationL2Data()
                changeTabInstance()
            }
        } else if (value == "3") {
            const handleSublocationSubmit = (data) => {
                //submit sub location
                D_API.post("Location", data, {
                    headers: { Authorization: `bearer ${UserService.getToken()}` },
                }).then((res) => {
                    // console.log(res.data, "final");
                }).catch(err => { })
            }

            data?.map(async (item) => {
                let l3id;
                let data1 = {
                    resourceType: "Location",
                    partOf: {
                        reference: `Location/${l2id}`,
                    },
                    name: item?.name,
                    physicalType: { coding: [item?.physicalType] },
                }

                // console.log(data1, "main physical instance")
                await D_API.post("Location", data1, {
                    headers: { Authorization: `bearer ${UserService.getToken()}` },
                }).then((res) => {
                    // console.log(res.data, "for l3");
                    l3id = res.data
                }).catch(err => { })

                if (item?.hasSubLocation === "yes") {
                    let data = {
                        resourceType: "Location",
                        partOf: {
                            reference: `Location/${l3id}`,
                        },
                        name: item.subLocationName,
                        physicalType: { coding: [item.physicalTypeRoom], text: `${item.units}` },
                    }
                    handleSublocationSubmit(data)
                } else {
                    //delete sub location api
                }
                // console.log(data, "ddd")
            })
            close()
        } else {

        }
    }

    const handlePhysicalInstDel = async (data) => {
        // console.log(data, '469')
        await setDetails(data)
    }


    return (
        <Dialog open={true} onClose={close} maxWidth={"sm"} sx={{ height: "680px", position: 'absolute', top: "40px", }} fullWidth>
            <Card sx={{ paddingBottom: '5px' }}>
                <CardHeader sx={{ color: '#750083', boxShadow: 2 }}
                    avatar={
                        <MedicalServicesIcon />
                    } title={<Typography variant='h6' sx={{ color: '#750083', }}>Service Area</Typography>}
                />

                <CardContent sx={{ paddingBottom: '16px !important' }} >

                    <TabContext value={value}>
                        <Tabs
                            value={value}
                            onChange={handleChange}
                            variant="scrollable"
                            sx={{
                                '& .MuiTabs-indicator': {
                                    backgroundColor: 'gray',
                                },
                                '& .Mui-selected': {
                                    color: 'black !important'
                                },
                                '& 	.MuiTabs-flexContainer': {
                                    display: 'flex',
                                    justifyContent: 'space-around'
                                }
                            }}
                        >
                            <Tab sx={{ textTransform: 'capitalize', fontSize: '1rem' }} label="location Details" value="1"></Tab>
                            <Tab sx={{ textTransform: 'capitalize', fontSize: '1rem' }} label="Timings" value="2"></Tab>
                            <Tab sx={{ textTransform: 'capitalize', fontSize: '1rem' }} label="Instances" value="3"></Tab>
                            <Tab sx={{ textTransform: 'capitalize', fontSize: '1rem' }} label="Amenities" value="4"></Tab>
                        </Tabs>
                        <TabPanel value='1' sx={{ padding: '15px 0 0 0' }}><LocationAmenities locationState={locationState} handleLocationState={handleLocationState} contact={contact} addContact={addContact} deleteContact={deleteContact} handleContact={handleContact} changeTab={changeTabTiming} close={close} handleSubmitAmenities={editAmenities ? handleEditSubmitAmenities : handleSubmitAmenities} /></TabPanel>
                        <TabPanel value='2' sx={{ padding: '15px 0 0 0' }}><TimeAnimities changeTab={changeTabInstance} close={close} handleSubmitAmenities={editAmenities ? handleEditSubmitAmenities : handleSubmitAmenities} /></TabPanel>
                        <TabPanel value='3' sx={{ padding: '15px 0 0 0' }}><InsatanceAminities details={details} addPhysicalAnimities={addPhysicalAnimities} handleDetailsMultiple={handleDetailsMultiple} close={close} handlePhysicalInstDel={handlePhysicalInstDel} handleSubmitAmenities={editAmenities ? handleEditSubmitAmenities : handleSubmitAmenities} /></TabPanel>
                        <TabPanel value='4' sx={{ padding: '15px 0 0 0' }}><Amenties close={close} /></TabPanel>
                        {/* <TabPanel value='1' sx={{ padding: '15px 0 0 0' }}><LocationAmenities locationState={locationState} handleLocationState={handleLocationState} contact={contact} addContact={addContact} deleteContact={deleteContact} handleContact={handleContact} changeTab={changeTabTiming} close={close} handleSubmitAmenities={handleSubmitAmenities} /></TabPanel> */}
                        {/* <TabPanel value='2' sx={{ padding: '15px 0 0 0' }}><TimeAnimities changeTab={changeTabInstance} close={close} handleSubmitAmenities={handleSubmitAmenities} /></TabPanel>
                        <TabPanel value='3' sx={{ padding: '15px 0 0 0' }}><InsatanceAminities details={details} addPhysicalAnimities={addPhysicalAnimities} handleDetailsMultiple={handleDetailsMultiple} close={close} handleSubmitAmenities={handleSubmitAmenities} /></TabPanel>
                        <TabPanel value='4' sx={{ padding: '15px 0 0 0' }}><Amenties close={close} /></TabPanel> */}
                    </TabContext>

                </CardContent>
            </Card>
        </Dialog>
    )
}

export default AddAmenities