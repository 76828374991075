const initialState = {
    userData: {},
    regData: {},
    orgData: {},
    facilityData: {},
    step: {},
    connectData: {},
    facilities: [],
    userOrg: {},
    location: {},
    orgResource: {},
    userOrgData: {},
    l2id: "",
    locationL2Data: null,
    location_withouttoken: {},
    healthDeptDetails: {},
    healthDeptLocationDetails: {},
    healthDeptListByPageId: [],
    healthServiceOrgInfo: {},
    healthServiceLocInfo: {},
    subhealthServiceOrgInfo: {},
    subhealthServiceLocInfo: {},
    fhirUserData: {},
    listOfSubOrganizations: [],
    listOfSubOrganizationInPublicOrganization: []
}

const Registration = (state = initialState, action) => {
    switch (action.type) {
        case 'USER_DATA': return { ...state, userData: action.payload }
        case 'REG_DATA': return { ...state, regData: action.payload }
        case 'ORG_DATA': return { ...state, orgData: action.payload }
        case 'FACILITY_DATA': return { ...state, facilityData: action.payload }
        case 'CONNECT_DATA': return { ...state, connectData: action.payload }
        case 'ACTIVE_STEP': return { ...state, step: action.payload }
        case 'FACILITY_LIST': return { ...state, facilities: action.payload, orgResource: action.orgResource }
        case 'USER_ORG': return { ...state, userOrg: action.payload }
        case 'USER_ORG_DATA': return { ...state, userOrgData: action.payload }
        case 'location': return { ...state, location: action.payload }
        case 'Level_TWO_ID': return { ...state, l2id: action.payload }
        case 'Location_L2_Data': return { ...state, locationL2Data: action.payload }
        case 'location_withouttoken': return { ...state, location_withouttoken: action.payload }
        case 'HEALTH_DEPARTMENT': return { ...state, healthDeptDetails: action.payload }
        case 'HEALTH_DEPARTMENT_LOCATION': return { ...state, healthDeptLocationDetails: action.payload }
        case 'HEALTH_DEPT_BY_PAGEID_LIST': return { ...state, healthDeptListByPageId: action.payload }
        case 'HEALTH_SERVICE_LOCATION': return { ...state, healthServiceLocInfo: action.payload }
        case 'HEALTH_SERVICE_INFO': return { ...state, healthServiceOrgInfo: action.payload }
        case 'SUB_HEALTH_SERVICE_LOCATION': return { ...state, subhealthServiceLocInfo: action.payload }
        case 'SUB_HEALTH_SERVICE_INFO': return { ...state, subhealthServiceOrgInfo: action.payload }
        case 'FETCH_USER_DATA': return { ...state, fhirUserData: action.payload }
        case 'FETCH_SUB_ORGANIZATIONS': return { ...state, listOfSubOrganizations: action.payload }
        case 'FETCH_SUB_ORGANIZATIONS_IN_PUBLIC_ORGANIZATION': return { ...state, listOfSubOrganizationInPublicOrganization: action.payload }

        default: return state
    }
}

export default Registration