import React, { useState, useEffect } from "react";
import { Card, Grid, CircularProgress, Avatar } from "@mui/material";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { useDispatch, useSelector } from "react-redux";
import ShowHealthServicesTabs from "../subHealthServicesComp/showHealthserviceTabs";
import { D_API } from "../../../../../../../services/APIService";
import { URI } from "../../../../../../utility/uri";
import { handleSubHealthServiceOrgInfo, handleSubHealthServiceLoc } from "../../../../../../../redux/Actions/regActions";
import DefaultHealthServiceIcon from "../../../../../../../icons/Healthcare_Service.svg";

const SubHealthServices = () => {

    const [subHealthServicesList, setSubHealthServicesList] = useState([]);
    const [opnHealthDialog, setOpnHealthDialog] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);
    const healthServiceOrg = useSelector(state => state.registration.subhealthServiceOrgInfo);
    const deptLocationObj = useSelector((state) => state.registration.healthDeptLocationDetails);

    console.log(deptLocationObj, "6767")
    console.log(healthServiceOrg, "5")

    const dispatch = useDispatch();

    const handleOpnDialog = () => {
        setOpnHealthDialog(true);
        dispatch(handleSubHealthServiceOrgInfo({}));
        dispatch(handleSubHealthServiceLoc({}));

    }

    const handleClsDialog = () => {
        setOpnHealthDialog(false);
        getListOfSubHealthServices(deptLocationObj?.id)

    }

    useEffect(() => {
        setIsLoading(true);
        if (deptLocationObj?.id) {
            getListOfSubHealthServices(deptLocationObj?.id);
        } else {
            setIsLoading(false);
        }
    }, [])

    const getListOfSubHealthServices = (Id) => {
        D_API.get(`${URI.getHealthCareService}/coveragearea/${Id}`).then((res) => {
            console.log(res.data, "subservices");
            setSubHealthServicesList(res.data?.entry || []);
            setIsLoading(false);
        }).catch((err) => console.log(err));


    }


    return (
        <div>

            <div className="mv1">
                <Card sx={{ padding: "10px", minHeight: "150px" }}>
                    <Grid container alignItems="center" justifyContent="space-between">
                        <Grid item>
                            <div className="font-head fntSemiBold">
                                HealthServices
                            </div>
                        </Grid>
                        <Grid item>
                            <div style={{ paddingTop: "2px" }}>
                                <AddCircleIcon fontSize="small" sx={{ color: "#008114", }} className="cursor" onClick={handleOpnDialog} />
                            </div>
                        </Grid>
                    </Grid>




                    {
                        isLoading && <div className="flx-center jc__center " style={{ height: "150px", }}> <CircularProgress />
                        </div>
                    }


                    <div>
                        {
                            <Grid container spacing={3} sm={12} md={12} lg={12} style={{ paddingRight: "5px" }}>
                                {
                                    subHealthServicesList?.length > 0 && subHealthServicesList?.map((val) => {


                                        return (
                                            <Grid item xs={12} sm={6} md={4} lg={4}>
                                                <Card sx={{
                                                    padding: "5px", borderRadius: "",
                                                    width: "100%",
                                                    //  width: {
                                                    //     sm: "100%", md: "100%", lg: "100%"
                                                    // },
                                                    height: { sm: "45px", md: "45px", lg: "45px" }
                                                }} className='mv1 cursor'

                                                    // onClick={() => {
                                                    //     history.push({
                                                    //         pathname: `/admin/organization/${val?.resource?.id}/healthService`,
                                                    //         state: val
                                                    //     });
                                                    //     dispatch(getUserOrg(userOrg))
                                                    // } }

                                                    onClick={() => {
                                                        setOpnHealthDialog(true);
                                                        setSelectedItem(val);
                                                    }}
                                                >
                                                    <Grid container alignItems="" spacing={2}>
                                                        <Grid item>
                                                            <Avatar variant="square" sx={{
                                                                width: '40px !important', height: '40px !important',
                                                                // borderRadius: '5px !important', width: '50px !important', height: '50px !important', backgroundColor: "", boxShadow:
                                                                //   "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px", border: "0.5px solid #fff",
                                                            }} src={val?.resource?.photo?.url || DefaultHealthServiceIcon} />

                                                        </Grid>
                                                        <Grid item>
                                                            <div style={{ height: "45px" }}>
                                                                <div className='fntMd'>{val?.resource?.name?.length > 30 ? <span>{val?.resource?.name?.slice(0, 29)}...</span> : <span>{val?.resource?.name}</span>}</div>
                                                                <div className='fntXSm'>{val?.resource?.comment?.length > 40 ? `${val?.resource?.comment?.slice(0, 39)}...` : val?.resource?.comment}</div>
                                                            </div>
                                                        </Grid>
                                                    </Grid>
                                                </Card>
                                            </Grid>
                                        )
                                    }
                                    )
                                }

                            </Grid>
                        }
                    </div>

                    {
                        (!isLoading && subHealthServicesList?.length <= 0) &&
                        <div className="flx-center justify-center fntMd  fntSemiBold " style={{ height: "150px", }} >
                            <div >
                                No Data is Available
                            </div>

                        </div>
                    }

                </Card>
            </div >

            <ShowHealthServicesTabs opn={opnHealthDialog} cls={handleClsDialog} healthid={selectedItem?.resource?.id} />
        </div >
    )
}
export default SubHealthServices;