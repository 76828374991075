import React, { useState, useEffect } from "react";
import { Card, Grid, Button, InputLabel, Select, MenuItem, Paper, Avatar, Typography, Box, CardMedia, CircularProgress } from "@mui/material";
import Stethoscope from "../../../../../../../images/stethoscope1.svg";
import Location from "../../../../../../../images/location.svg";
import DoctorImg from "../../../../../../../images/docsteth.png";
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import LaunchIcon from '@mui/icons-material/Launch';
import Referal from "../../../../../../../images/referral-3.svg";
import Communicate from "../../../../../../../../src/images/Communication-language.svg"
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { EventAvailable, GTranslateOutlined } from "@mui/icons-material";
import MaleDoctorImg from "../../../../../../../images/MaleDoctor-no-photo.svg";
import FemaleDoctorImg from "../../../../../../../images/FemaleDoctor-No Photo.svg";
import DoctorsInfoCard from "../../../../../../common/components/doctorsInfoCard";
import AddPractitioners from "../addContent/addPractitioners";
import { useSelector } from "react-redux";


import TeleIcon from "../../../../../../../../src/images/Telemedicine.svg";
import { D_API } from "../../../../../../../services/APIService";

const PractitionerGroup = ({ info }) => {

    const healthDeptLoc = useSelector((state) => state.registration.healthDeptLocationDetails);
    const healthDeptOrg = useSelector((state) => state.registration.healthDeptDetails);

    console.log(healthDeptLoc, "70");


    const [partitionerList, setPartitionerList] = useState([]);
    const [isLoading, setIsLoading] = useState(false);


    useEffect(() => {
        setIsLoading(true)
        D_API.get(`/PractitionerRole/location/${healthDeptLoc?.id}`).then((res) => {
            console.log(res.data, "60")
            setPartitionerList(res.data)
            setIsLoading(false);
        }).catch((err) => {
            setIsLoading(false);
            console.log(err)
        });

    }, [])


    return (
        <div>
            <Card sx={{ padding: "10px", minHeight: "150px", }}>
                <Grid container alignItems="center" justifyContent="space-between">
                    <Grid item>
                        <div className="font-head  fntSemiBold">
                            Practitioners Groups
                        </div>
                    </Grid>
                    <Grid item>
                        <div>
                            <AddCircleIcon fontSize="small" sx={{ color: "#008114", }} className="cursor" />
                        </div>
                    </Grid>
                </Grid>
                {
                    isLoading && <div className="flx-center jc__center " style={{ height: "150px", }}> <CircularProgress />
                    </div>
                }
                {
                    partitionerList?.length > 0 &&
                    <Grid container spacing={2}>
                        {
                            partitionerList?.map((e, i) => {
                                return (
                                    <Grid item key={i} xs={12} sm={6} md={4}>
                                        <DoctorsInfoCard practitioner={e?.practitioner} practitionerRole={e?.practitionerRole} type="non-people" />
                                    </Grid>

                                )
                            })
                        }
                    </Grid>

                }
                {
                    (!isLoading && partitionerList?.length <= 0) &&
                    <div className="flx-center justify-center fntMd  fntSemiBold " style={{ height: "150px", }} >
                        <div >
                            No Data is Available
                        </div>

                    </div>
                }

            </Card>


        </div>
    )
}
export default PractitionerGroup;