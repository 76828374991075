import React, { useState, useEffect } from 'react';
import EduCrud from '../Layout/MyProfile/organisation/eduCrud';
import { Grid, Button, IconButton } from '@mui/material'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Link } from 'react-router-dom';
import graduation_cap from '../Atom/modifiedicons/graduation_cap.svg';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { useDispatch, useSelector } from 'react-redux';
import { updateProfile } from '../../redux/Actions/actions.js';
import Organisation from '../Atom/modifiedicons/Organisations.svg'
import DeleteIcon from '@mui/icons-material/Delete';
import moment from 'moment';


function Addeducation() {

  const [check, setCheck] = useState(false)
  const [addmore, setAddmore] = useState(true)
  const [addmore1, setAddmore1] = useState(false)
  const [state, setState] = useState({
    id: null,
    degree: "",
    course: "",
    from: null,
    to: null,
    identifier: "",
    issuer: "",
  });
  const [errorMessage, setErrorMessage] = useState("");
  // const data = useSelector(state => state.userProfile.userProfile);
  // console.log(data)
  // const profession = data.profession
  const [edu, setEdu] = useState([])
  const [user, setUser] = useState({})
  // const [qua, setQua] = useState([])
  const education = useSelector(state => state.userProfile.user)
  console.log(education, "education")
  console.log(user, "user");
  const dispatch = useDispatch()
  // console.log(education)
  // useEffect(() => {
  //     setQua(education)
  // }, [])

  // const handleChange = (name, value) => {
  //     if (name === "degree") {
  //         setState({ ...state, [name]: { display: value } })
  //     } else {
  //         setState({ ...state, [name]: value })
  //     }
  // }



  const handleChange = (name, value) => {
    setState({ ...state, [name]: value })
  }

  // console.log(state, "state")
  // const sub = () => {
  //     if (state.organisation === undefined) {
  //         alert('Please add organisation')
  //     }
  //     else {
  //         handleSubmit()
  //     }
  // }

  const handleCheck = (e) => {
    setCheck(e.target.checked)
  }

  const setQualification = (state) => {
    return {
      // identifier: [
      //     {
      //         system: "docsteth",
      //         value: state.identifier,
      //     }
      // ],
      identifier: {
        system: "docsteth",
        value: state?.identifier,
      },
      code: {
        coding: [{
          system: "http://terminology.hl7.org/CodeSystem/v2-0360/2.7",
          code: state?.degree?.code,
          display: state?.degree?.display
        }],
        text: state?.course
      },
      period: {
        start: state?.from,
        end: state?.to
      },
      issuer: state?.issuer,
      organisation: state?.organisation
    }
  }

  useEffect(() => {
    let eduDet = education
    if (eduDet !== undefined && Object?.keys(eduDet)?.length > 0) {
      let data = eduDet?.user
      let name = data?.practioner?.name?.[0]

      setUser({
        family: name?.family,
        firstName: name?.given?.[0],
        middleName: name?.given?.[1],
        prefix: name?.prefix ? name?.prefix?.[0] : '',
        suffix: name?.suffix,
        text: name?.text,
        gender: data?.practioner?.gender,
        birthDate: data?.practioner?.birthDate,
        caption: data?.captionAndTaglines,
        photo: data?.practioner?.photo?.[0]?.url,
        show: data?.practioner?.photo?.[0]?.show,
        about: data?.about,
        id: data?.id,
        keycloakId: data?.keycloakId,
        coverPic: data?.coverPic,
        address: data?.practioner?.address,
        contact: data?.practioner?.telecom,
        qualification: data?.practioner?.qualification
      })
      setEdu(data?.practioner?.qualification)
    }
  }, [education])

  console.log(edu, "edu")

  // const handleCancel = () => {
  //     // setCheck(false)
  //     // setOpen(false)
  //     // setEdit(false)

  // }

  const handleOrg = (collegeList) => {
    console.log(collegeList, "collegeName", "gggggggggggg")
    setState({ ...state, organisation: collegeList?.collegeName ? collegeList.collegeName : collegeList, issuer: collegeList?.univName ? collegeList.univName : collegeList })
  }
  // console.log(state, "nexttttttt")
  // const handleOrg = (name, value, newOrg, logo) => {
  //     // console.log(logo,name,value,newOrg)
  //     if (newOrg) {
  //         setState({ ...state, organisationName: "", organisation: 0, unofficialOrganisation: value, unofficialOrganisationName: name })
  //     } else {
  //         setState({ ...state, organisationName: name, organisation: value, unofficialOrganisation: 0, unofficialOrganisationName: "", logo: logo })
  //     }
  // }
  // useEffect(() => {
  //     dispatch(fetchProfile())
  // }, [])
  const handleremoveinput = () => {
    setState({ degree: '', course: '', from: null, to: null, identifier: '', issuer: '', organisation: '' })
    setAddmore(true)
    setAddmore1(false)
  }
  const handleSubmit = () => {
    // console.log(user, user.contact, user.address, qualification, "11111111111")
    var qualification = []
    if (edu !== null) {
      qualification = [...edu, setQualification(state)]
    }
    else {
      qualification = [setQualification(state)]
      //  setErrorMessage("Your data is null");
    }

    dispatch(updateProfile(user, user.contact, user.address, qualification))
    // setQua(...qualification)
    setAddmore(false)
    setAddmore1(true)
  }
  // console.log(edu)

  const deleteOrganisation = (idx) => {
    let qualificationList = [...edu]
    let qualification = qualificationList?.filter((each, index) => idx !== index)
    console.log(qualification, "list")
    dispatch(updateProfile(user, user?.contact, user?.address, qualification))
  }




  return (
    <>
      {addmore ? (
        <Grid
          container
          spacing={2}
          className="mobile_padding"
          style={{
            //   backgroundColor: "white",
            //   boxShadow: "0px 3px 6px #00000029",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Grid item xs={12} md={12}>
            <div className="complete_prof">
              <Link to={`/completeprofile`}>
                <ArrowBackIosIcon />
              </Link>
              <h1 className="comp_profile">Complete Your Profile</h1>
            </div>
            <div style={{ paddingTop: "20px" }}>
              <h3 className="step_mobile">Step 2 of 3</h3>
            </div>
            <div className="quali_text">
              <span
                className="e-align d-flex"
                style={{
                  color: "#01CDE5",
                  fontSize: "21px",
                  fontWeight: "550",
                }}
              >
                <img
                  style={{ paddingRight: "10px" }}
                  src={graduation_cap}
                  alt="Scholar"
                />
                &nbsp;Qualification
              </span>
            </div>
          </Grid>
          {/* FORM */}
          <Grid item xs={12} md={8}>
            <div>
              <EduCrud
                state={state}
                handleChange={handleChange}
                handleSubmit={handleSubmit}
                handleOrg={handleOrg}
                check={check}
                nextbtn={true}
                handleCheck={handleCheck}
                education={edu}
              />
            </div>
          </Grid>
        </Grid>
      ) : addmore1 ? (
        <Grid
          container
          sx={{ height: { md: "100vh", xs: "100vh" } }}
          style={{
            //   backgroundColor: "white",
            marginTop: "0px",
            //   boxShadow: "0px 3px 6px #00000029",
          }}
        >
          <Grid item xs={12} md={12}>
            <div className="complete_prof">
              <Button
                onClick={() => {
                  setAddmore(true);
                  setAddmore1(false);
                }}
              >
                <ArrowBackIosIcon style={{ color: "#000000" }} />
              </Button>
              <h1 className="comp_profile">Complete Your Profile</h1>
            </div>
            <div style={{ paddingTop: "20px" }}>
              <h3 className="step_mobile">Step 2 of 3</h3>
            </div>
            <div className="quali_text">
              <span
                className="e-align d-flex"
                style={{
                  color: "#01CDE5",
                  fontSize: "21px",
                  fontWeight: "550",
                }}
              >
                <img src={graduation_cap} alt="Scholar" />
                &nbsp;Qualification
              </span>
            </div>
            <div>{/* <Education /> */}</div>
            {edu !== null && edu?.length > 0 ? (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <div
                  className="list"
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  {edu?.map((e, i) => (
                    <>
                      {console.log(e)}
                      {e?.code ? (
                        <div className="sidebar__substat1 ta__center" key={i}>
                          <div
                            className="my-img1 domain-icon"
                            style={{
                              height: "55px",
                              width: "55px",
                              borderRadius: "7px",
                            }}
                          >
                            <img
                              src={Organisation}
                              alt="organisation"
                              width="25px"
                              height="20px"
                            />
                          </div>
                          <div
                            className="sidebar__ml capitalize"
                            style={{
                              fontSize: "13px",
                              marginLeft: "20px",
                              width: "50%",
                            }}
                          >
                            <p
                              style={{
                                fontWeight: "bold",
                                paddingBottom: "3px",
                              }}
                            >
                              {e?.code?.coding?.[0]?.display
                                ? e?.code?.coding?.[0]?.display
                                : ""}
                            </p>
                            <p style={{ paddingBottom: "3px" }}>
                              {e?.code?.text ? e?.code?.text : ""}
                            </p>
                            <p style={{ paddingBottom: "3px" }}>
                              {e?.organisation ? e?.organisation : ""}
                            </p>
                            <p style={{ paddingBottom: "3px" }}>
                              {e?.issuer ? e?.issuer : ""}
                            </p>
                            <p>
                              {moment(e?.period?.start).format("MMM yyyy")} -{" "}
                              {e?.period?.end
                                ? moment(e?.period?.end).format("MMM yyyy")
                                : "present"}
                            </p>
                          </div>
                          <div>
                            <IconButton onClick={() => deleteOrganisation(i)}>
                              <DeleteIcon color="error" />
                            </IconButton>
                          </div>
                        </div>
                      ) : (
                        <div>gg</div>
                      )}
                      {/* <h1>dfghj</h1> */}
                    </>
                  ))}
                </div>
              </div>
            ) : (
              ""
              // <div>hii</div>
            )}
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <Grid
              container
              style={{ display: "flex", justifyContent: "center" }}
            >
              <div>
                {/* <Link to={`/addeducation`}> */}
                <Button
                  style={{ textTransform: "capitalize" }}
                  onClick={handleremoveinput}
                >
                  <AddCircleOutlineIcon
                    style={{
                      color: "#47AEE1",
                      height: "30px",
                      width: "30px",
                      paddingRight: "15px",
                    }}
                  />
                  <span style={{ color: "#47AEE1", fontSize: "20px" }}>
                    Add Another
                  </span>
                </Button>
                {/* </Link> */}
              </div>
              <Grid item xs={12} md={12}>
                <div className="skip_btn" style={{ marginTop: "30px" }}>
                  <Link to={`/addexperience`}>
                    <Button
                      className="next__btn"
                      variant="contained"
                      type="submit"
                    >
                      <ArrowForwardIcon
                        style={{
                          color: "white",
                          fontSize: "18px",
                          paddingRight: "10px",
                        }}
                      />
                      next
                    </Button>
                  </Link>
                </div>
              </Grid>
              <Grid item xs={12} md={12}>
                <div className="skip_btn" style={{ marginTop: "5px" }}>
                  <Link to={`/addexperience`}>
                    <Button
                      type="submit"
                      style={{
                        color: "#47AEE1",
                        textTransform: "capitalize",
                        margin: "10px 0px",
                      }}
                    >
                      skip
                    </Button>
                  </Link>
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      ) : (
        ""
      )}

      {/* <div className="skip_btn" style={{ marginBottom: "73px" }}>
          <Link to={`/addeducation`}>
            <Button
              style={{
                color: "#47AEE1",
                textTransform: "capitalize",
                margin: "20px 0px",
              }}
              type="submit"
            >
              Skip
            </Button>
          </Link>
        </div> */}
    </>
  );
}

export default Addeducation