import { Box, Button, Card, CardContent, CardHeader, Checkbox, Dialog, DialogContent, FormControlLabel, FormGroup, Tab, Tabs, TextField, Typography, IconButton, RadioGroup, Radio } from '@mui/material'
import React, { useEffect } from 'react'
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { useState } from 'react';
import './addwebsite.css'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowForwardTwoToneIcon from '@mui/icons-material/ArrowForwardTwoTone';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import UserService from '../../../../../services/UserService';
import { useParams } from 'react-router-dom';
import { getUserOrg } from '../../../../../redux/Actions/regActions';
import { D_API } from '../../../../../services/APIService';
import { URI } from '../../../../utility/uri';
import CustomRB from "../../../../common/inputs/radioButton"
import { debounce } from 'lodash';

// export const Custom = (props) => {


//     return (
//         <Radio
//             value=
//             onChange={props.onChange}
//             name="radio-buttons"
//             inputProps={{ 'aria-label': 'A' }}
//         />
//     )

// }


const AddWebsite = ({ close }) => {

    const [tab, setTab] = useState(1)
    const [checked, setChecked] = React.useState(false);
    const [value, setValue] = React.useState('one');
    const [url, setUrl] = useState("")
    const [urlalreadyexist, setUrlalreadyExist] = useState(false);
    const [initdisablebtn, setInitdisablebtn] = useState(true);
    // const [disableCreateNext, setDisableCreateNext] = useState(true)
    const [showPreview, setShowPreview] = useState(false)
    const [createNewWebsite, setCreateNewWebsite] = useState(false)

    const userOrg = useSelector(state => state.registration.userOrg)
    console.log(userOrg, "userorg")

    const { id } = useParams()
    // console.log(id, "userorg")

    const dispatch = useDispatch()

    // bcvhbvhcb

    useEffect(() => {
        updateDebounceText(url);
        return () => {
            updateDebounceText.cancel();
        }
    }, [url])



    const updateOrgWebisteUrl = () => {
        let data = { ...userOrg }
        data.endpoint = [{ display: `${url}.docsteth.com` }]
        // console.log(data, "userorg")

        D_API.put(URI.updateOrg + data.id, data).then(() => {
            dispatch(getUserOrg(data))
        })

    }



    const handleTabs = (val) => {
        setTab(val)
    }

    const handleCheck = (event) => {
        setChecked(event.target.checked);
    };

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const addWebsiteText = async () => {
        let data = {
            "website_url": `${url}.docsteth.com`,
            "page_id": id,
            "page_name": userOrg.name,
            "user_id": UserService.getUserId()
        }
        await axios.post("https://devwebmasterbe.docsteth.com/webmaster/addwebsitedetails", data)
            .then(res => {
                console.log(res)
                updateOrgWebisteUrl()
            }).catch(err => {
                console.log(err)
            })

    }

    const handleCreateDomain = () => {
        // setDisableCreateNext(true)
        setShowPreview(true)

        addWebsiteText()

        let data = {
            "subdomain_name": url
        }
        axios.post("https://devwebmasterbe.docsteth.com/webmaster/addnewsubdomain", data)
            .then(res => {
                console.log(res)
            }).catch(err => {
                console.log(err)
            })

        setTimeout(() => {
            close();
        }, 3000)
    }


    const handleUrl = (e) => {
        const val = e.target.value
        setUrl(val.toLowerCase())
        // updateDebounceText(val.toLowerCase())
        // CheckSubDomain(val)
    }

    // const updateDebounceText = debounce(val => {
    //     CheckSubDomain(val)
    // }, 3000)

    const updateDebounceText = debounce((val) => {
        if (val?.length > 0) {
            // console.log(url, "uuuu")
            let data = {
                "subdomain_name": val.toLowerCase()
            }
            setInitdisablebtn(true)
            axios.post("https://devwebmasterbe.docsteth.com/webmaster/checksubdomain", data)
                .then(res => {
                    if (res.data.msg == "Subdomain exists") {
                        setInitdisablebtn(true)
                        setUrlalreadyExist(true)
                    } else {
                        setUrlalreadyExist(false)
                        // setDisableCreateNext(false)
                        setInitdisablebtn(false)
                    }
                }).catch(err => {
                    console.log(err)
                })
        } else {
            setUrlalreadyExist(false)
            setInitdisablebtn(true)
        }

    }, 1000)

    // function debounce(cb, delay) {
    //     let timeout
    //     return (...args) => {
    //         clearTimeout(timeout)
    //         timeout = setTimeout(() => {
    //             cb(...args)
    //         }, delay)
    //     }
    // }

    // console.log(initdisablebtn, "initdisablebtn")


    // useEffect(() => {
    //     const handler = setTimeout(() => {
    //         CheckSubDomain(url)
    //     }, 300)

    //     return () => {
    //         clearTimeout(handler)
    //     }
    // }, [url])


    const CheckSubDomain = (url) => {
        if (url?.length > 0) {
            // console.log(url, "uuuu")
            let data = {
                "subdomain_name": url.toLowerCase()
            }

            setInitdisablebtn(true)
            axios.post("https://devwebmasterbe.docsteth.com/webmaster/checksubdomain", data)
                .then(res => {
                    if (res.data.msg == "Subdomain exists") {
                        setInitdisablebtn(true)
                        setUrlalreadyExist(true)
                    } else {
                        setUrlalreadyExist(false)
                        // setDisableCreateNext(false)
                        setInitdisablebtn(false)

                    }
                }).catch(err => {
                    console.log(err)
                })
        }
    }

    const handleOnCustomRB = (e) => {
        setCreateNewWebsite(e.target.checked)

    }


    return (
        <Dialog open={true} onClose={close} PaperProps={{
            style: {
                position: 'absolute',
                top: '64px',
                left: 0,
                right: 0,
                margin: 'auto'
            }
        }} fullWidth>

            {tab == 1 &&
                <Card sx={{ paddingBottom: '5px' }}>
                    <CardHeader sx={{ color: '#750083', boxShadow: 2 }}
                        title={<Typography variant='h6' sx={{ color: '#750083', }}>Add Website</Typography>}
                    />
                    {/* <CheckCircleIcon sx={{ color: "white" }} /> */}

                    <CardContent>
                        <Typography sx={{ backgroundColor: "#D9EAF5", padding: '10px', display: 'flex', justifyContent: 'space-between' }}>
                            Have a Working Website
                            <Checkbox onChange={handleOnCustomRB} />
                        </Typography>
                        <TextField size='small' placeholder='www.yourwebsite.com' fullWidth sx={{ marginY: "20px" }} className='input_shadow input_padding' disabled={createNewWebsite == true ? false : true} />

                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <Button variant="contained" sx={{ borderRadius: '10px', backgroundColor: "#A467EA", textTransform: 'capitalize' }} onClick={() => handleTabs(2)} disabled={createNewWebsite == false ? false : true}>Create New Website</Button>
                            <Button variant="outlined" sx={{ borderRadius: '10px', borderColor: '#A467EA', color: "#A467EA", textTransform: 'capitalize' }}>Submit</Button>
                        </div>
                    </CardContent>
                </Card>

            }
            {tab == 2 &&
                <Card sx={{ paddingBottom: '5px' }}>
                    <CardHeader sx={{ color: '#750083', boxShadow: 2 }}
                        title={<Typography variant='h6' sx={{ color: '#750083', }}>Select Template</Typography>}
                    />

                    <CardContent>
                        <Box sx={{ width: '100%' }}>
                            <Tabs
                                value={value}
                                onChange={handleChange}
                                aria-label="wrapped label tabs example"
                                textColor="white"
                                TabIndicatorProps={{
                                    style: {
                                        backgroundColor: '#750083',
                                    },
                                }}
                            >
                                <Tab
                                    value="one"
                                    label="Free Template"
                                    sx={{ textTransform: 'capitalize', fontSize: "1.2rem" }}
                                />
                                <Tab value="two" label="Premium"
                                    sx={{ textTransform: 'capitalize', fontSize: "1.2rem" }}
                                />
                            </Tabs>
                        </Box>

                        {value == "one" &&
                            <div className='content'>
                                <div className='templates'>
                                    <div className='items'>
                                        <Typography>Sample Hospital Template</Typography>
                                        <Box sx={{ boxShadow: 2 }} className='imagewrapper'></Box>
                                        <Typography>Free - 0 $</Typography>
                                        <div className='actions'>
                                            <Button sx={{ backgroundColor: "white", borderRadius: "30px", height: "30px", color: "black", textTransform: 'capitalize' }} variant='outlined' size='small' disabled>Preview</Button>
                                            <FormGroup>
                                                <FormControlLabel control={<Checkbox value={checked} onChange={handleCheck} name="two" />} label="Select" />
                                            </FormGroup>
                                        </div>
                                    </div>
                                    {/* <div className='items'>
                                        <Typography>Sample Hospital Template</Typography>
                                        <Box sx={{ boxShadow: 2 }} className='imagewrapper'></Box>
                                        <Typography>Free - 0 $</Typography>
                                        <div className='actions'>
                                            <Button sx={{ backgroundColor: "white", borderRadius: "30px", height: "30px", color: "black", textTransform: 'capitalize' }} variant='outlined' size='small'>Preview</Button>
                                            <FormGroup>
                                                <FormControlLabel control={<Checkbox value={checked} onChange={handleCheck} name="two" />} label="Select" />
                                            </FormGroup>
                                        </div>
                                    </div> */}
                                </div>
                                <div style={{ display: 'flex', justifyContent: 'end', marginTop: 10 }} >
                                    <Button variant='contained' sx={{ backgroundColor: "#001E9D", borderRadius: "30px", height: "30px", width: "100px", color: "white", textTransform: 'capitalize', paddingTop: 10 }} disabled={checked == true ? false : true} onClick={() => handleTabs(3)}>Next &nbsp;<ArrowForwardIosIcon size="small" sx={{ width: '18px !important' }} /></Button>
                                </div>
                            </div>
                        }
                        {value == "two" &&
                            <div className='content'>
                                <Typography>Coming Soon.</Typography>
                            </div>
                        }

                    </CardContent>
                </Card>
            }
            {
                tab == 3 &&
                <Card sx={{ paddingBottom: '5px' }}>
                    <CardHeader sx={{ color: '#750083', boxShadow: 2 }}
                        title={<Typography variant='h6' sx={{ color: '#750083', }}>Set Temporary Url</Typography>}
                    />

                    <CardContent >
                        <div style={{ display: "flex", alignItems: "center", gap: "20px" }}>
                            <Typography >Preview Url</Typography>
                            <div style={{ display: "flex", alignItems: "center" }}>
                                <TextField size='small' placeholder='www.yourwebsite.com' sx={{ marginY: "20px", marginRight: "7px" }} className='input_shadow input_padding' onMouseDown={() => setInitdisablebtn(false)} onMouseUp={() => setInitdisablebtn(true)} val={url} onChange={handleUrl} />
                                <Typography component="span"> .docsteth.com</Typography>
                            </div>
                        </div>
                        {urlalreadyexist && <Typography>Url Already Exist, Try another name</Typography>}
                        {showPreview &&
                            <>
                                <a href={`https://${url}.docsteth.com`} target='_blank' >Preview : &nbsp;<span style={{ color: "blue" }}>{`${url}.docsteth.com`}</span></a>
                                {/* <Typography>Check after 2 to 5 minutes as it will take some time for deployment. </Typography> */}
                                <Typography>Please Check above Url  after 5 to 10 minutes as we are preparing your preview Url and it will take some time for deployment. </Typography>
                            </>
                        }
                    </CardContent>
                    <div style={{ display: 'flex', justifyContent: 'end', padding: "1rem" }}>
                        <Button variant='contained' disabled={initdisablebtn || url?.length <= 0} sx={{ backgroundColor: "#001E9D", borderRadius: "30px", height: "30px", width: "100px", color: "white", textTransform: 'capitalize' }} onClick={handleCreateDomain}>Next &nbsp;<ArrowForwardTwoToneIcon sx={{ width: '18px !important', height: "18px !important" }} /></Button>
                    </div>
                </Card>
            }


        </Dialog >
    )
}

export default AddWebsite