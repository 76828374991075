import { Button, Typography } from '@mui/material'
import axios from 'axios'
import React from 'react'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import UserService from '../../../services/UserService'

const BackToPage = () => {

    let getLocation = sessionStorage.getItem('backLinkPath')
    let fbToken = sessionStorage.getItem("FBtoken")
    console.log(fbToken, "kckckckc", getLocation, "paramsssss222222222", UserService.getToken())
    const valuesss = sessionStorage.getItem("valueOfAouth")
    const history = useHistory()
    const handleBack = () => {
        console.log("first")
        if (UserService.getToken()) {
            axios.post(process.env.REACT_APP_API_URL_PYI + `/upload${valuesss}Code${fbToken}`,
                {},
                {
                    headers: {
                        "Authorization": `bearer ${UserService.getToken()}`
                    }
                }
            )
                .then(res => {
                    console.log(res, "9090sai")
                    history.push(getLocation)
                    Window.location.reload()
                }).catch(err => console.log(err))
        }

    }
    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '100vh' }}>
            <div style={{ textAlign: 'center', padding: '20px', backgroundColor: '#f0f0f0', borderRadius: '8px', boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)' }}>
                <Typography variant="h5" gutterBottom>Back To Docsteth Page </Typography>
                <Button size='small' sx={{ textTransform: 'capitalize', mt: 2 }} variant="contained" onClick={handleBack}>Back</Button>
            </div>
        </div>
    )
}

export default BackToPage