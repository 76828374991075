import React, { useCallback, useEffect, useState } from "react";
import { Autocomplete, Box, Button, FormHelperText, Grid, TextField } from "@mui/material"
import { ValidatorForm } from "react-material-ui-form-validator"
import MapOutlinedIcon from '@mui/icons-material/MapOutlined';
import { useSelector } from "react-redux";
import GoogleMapReact from 'google-map-react';
import LocationOnSharpIcon from '@mui/icons-material/LocationOnSharp';
import { FiEdit3 } from "react-icons/fi";
import { deleteKeys, displayAddress, getDistricts, getStates, getSubDistricts, getVillages, getSearchLocInfo, getSearchLocByPin, getLongitudeandLatitude, getLocationByLongandLatitVals } from "../../../../common/functions/functions";
import { Actions, OrgLayout, FormLayout, InfoLayout, GridLayout } from "../../../../common/components/layout";
import { FormDivider } from '../../../../utility/utils';
import { debounce } from "lodash";
import { D_API } from "../../../../../services/APIService";
import { URI } from "../../../../utility/uri";


const defaultValues = { searchedVal: '', line1: '', line2: '', text: '', country: "India", state: '', city: '', district: '', subDistrict: '', postalCode: '', type: '', use: '', location: '' }

const LocationIcon = () => <LocationOnSharpIcon sx={{ color: 'maroon', fontSize: '40px' }} />

const Location = (props) => {

    const [address, setAddress] = useState(defaultValues)
    // const [type, setType] = useState({ use: '', type: '' })


    const [states, setStates] = useState([]);
    const [locationInfo, setLocationInfo] = useState(null);
    const [locationList, setLocationList] = useState([]);
    const [pincodeList, setPincodeList] = useState([]);
    const [districts, setDistricts] = useState([])
    const [subDistricts, setSubDistricts] = useState([]);
    const [villages, setVillages] = useState([])
    const [loc, setLoc] = useState({ lat: '', lng: '' })
    const [openMap, setOpenMap] = useState(false)
    const [adjust, setAdjust] = useState(false)

    // const orgAddress = useSelector(state => state.registration.userOrg)
    const orgAddress = useSelector(state => state.registration.userOrgData);
    const orgLocation = useSelector(state => state.registration.location);

    const orgData = useSelector(state => state.registration.userOrg);

    console.log(orgLocation, "orgLocation")


    console.log(orgAddress, "orgAddress")



    useEffect(() => {
        if (orgAddress?.id) {
            D_API.get(`${URI.getLocation}${orgAddress?.id}`).then((res) => {
                let LocationObj = res?.data?.entry?.[0]?.resource;
                setLocationInfo(res?.data?.entry?.[0])
                setAddress({
                    searchedVal: "",
                    line1: LocationObj?.address?.line?.[0] || "",
                    line2: LocationObj?.address?.line?.[1] ? LocationObj?.address?.line?.[1] : "",
                    text: LocationObj?.address?.text || "",
                    country: 'India',
                    state: LocationObj?.address?.state || "",
                    district: LocationObj?.address?.district || "",
                    subDistrict: LocationObj?.address?.city || LocationObj?.address?.line?.[2] || "",
                    postalCode: LocationObj?.address?.postalCode || "",
                    location: "",
                })
                setLoc({
                    lat: parseFloat(LocationObj?.position?.latitude) || "",
                    lng: parseFloat(LocationObj?.position?.longitude) || ""
                })
            }).catch((err) => {
                console.log(err)
            })
        }
    }, [orgAddress.id])



    // useEffect(() => {
    //     const add = orgAddress?.address?.[0] || {}
    //     // setAddress({
    //     //     searchedVal: add?.searchedVal || "",
    //     //     line1: add?.line?.[0] || '',
    //     //     line2: add?.line?.[1] || '',
    //     //     text: add?.text || '',
    //     //     country: 'India',
    //     //     state: add?.state || '',
    //     //     city: add?.city || '',
    //     //     district: add?.district || '',
    //     //     subDistrict: add?.line?.[2] || '',
    //     //     postalCode: add?.postalCode || '',
    //     //     location: add?.line?.[3] || '',
    //     // })
    //     // setLoc({ lat: parseFloat(add?.line?.[3]?.split(',')[0]), lng: parseFloat(add?.line?.[3]?.split(',')[1]) })
    // }, [orgAddress])

    useEffect(() => {
        async function fetchLocation() {
            if (address.searchedVal?.length > 0) {
                let LocList = await getSearchLocInfo(address.searchedVal?.toLowerCase())
                setLocationList(LocList);
            }
            else if (address.searchedVal == null || "") {
                setAddress(defaultValues)
                setLoc({ lat: '', lng: '' });
                setSubDistricts([]);
                setLocationList([]);
            }
        }
        fetchLocation();
    }, [address?.searchedVal])

    // console.log(locationList, "locationList")


    useEffect(() => {
        async function fetchStates() {
            let statesList = await getStates()
            setStates(statesList)
        }
        fetchStates()
    }, [address?.country])

    useEffect(() => {
        async function fetchDistricts() {
            if (address.state) {
                let districtsList = await getDistricts(address.state)
                setDistricts(districtsList)
            }
        }
        fetchDistricts()
    }, [address?.state])

    useEffect(() => {

        async function fetchSubDistricts() {
            if (address.district) {
                let subDistrictsList = await getSubDistricts(address.district)
                setSubDistricts(subDistrictsList)
            }
        }
        fetchSubDistricts();
    }, [address?.district])

    useEffect(() => {
        async function fetchVillages() {
            if (address.subDistrict) {
                let villagesList = await getVillages(address.subDistrict)
                setVillages(villagesList)
            }
        }
        fetchVillages()
    }, [address?.subDistrict])


    useEffect(() => {
        async function fetchLatitAndLongtit() {
            let res;
            if (address?.searchedVal?.name) {
                res = await getLongitudeandLatitude(address?.searchedVal?.name);
                if (res?.data) {
                    if (res.data?.lat && res.data?.lng) {
                        setLoc((prev) => {
                            return { ...prev, lat: res.data?.lat, lng: res.data?.lng }
                        })
                        let LocObj = await getLocationByLongandLatitVals(res.data?.lat, res.data?.lng);
                        if (LocObj?.data) {
                            setAddress((prev) => {
                                return {
                                    ...prev,
                                    // line1: address.searchedVal.name.split(",")?.[1] + " " + address.searchedVal.name.split(",")?.[2] + " " + address.searchedVal.name.split(",")?.[3],
                                    line1: LocObj?.data?.addressLine,
                                    country: LocObj.data?.country, district: LocObj.data?.district, state: LocObj.data?.state, postalCode: LocObj.data?.postalcode,
                                    line2: '',
                                    subDistrict: ""
                                }
                            })
                            setSubDistricts(LocObj.data?.subDistrictList ? LocObj.data?.subDistrictList : [])
                        }

                    }
                }

            }
        }
        fetchLatitAndLongtit()
    }, [address?.searchedVal])

    // these below function search functions for Location
    const handleSearchInputOfLoc = useCallback(debounce((e, value) => {
        async function fetchLocation(val) {
            let LocList = await getSearchLocInfo(val?.toLowerCase())
            setLocationList(LocList?.data);
        }
        fetchLocation(value);
    }, 500), [])

    const handleSearchByPincode = useCallback(debounce((e, value) => {
        // // Remove non-numeric characters
        // const numericValue = value?.replace(/\D/g, "");
        // async function fetchLocByPincode(numericValue) {
        //     let pinList = await getSearchLocByPin(numericValue)
        //     setPincodeList(pinList);
        // }
        // fetchLocByPincode(numericValue);

    }, 500), [])

    const fetchLocByPincode = useCallback(debounce(async (numericValue) => {
        if (numericValue?.length >= 6) {
            try {
                const res = await getSearchLocByPin(numericValue?.toLowerCase());
                console.log(res, "success")
                if (res?.status == 200) {
                    setAddress((prev) => {
                        return {
                            ...prev, district: res.data?.district, state: res.data?.state,
                        }
                    });
                    setSubDistricts(res.data?.subDistrict);
                }
            } catch (err) {
                alert("Please Enter valid pincode");
            }
        } else {
            setAddress((prev) => {
                return {
                    ...prev, district: "", state: "", subDistrict: ""
                }
            });
            setSubDistricts([]);
        }
    }, 500), []);


    // const handleType = (name) => (value) => {
    //     const data = { ...type, [name]: value }
    //     setType(data)
    //     if (data.type !== '' && data.use !== '') {
    //         setAddress([...address, { ...defaultValues, ...data }])
    //         setType({ type: '', use: '' })
    //     }
    // }

    // const deleteAddress = (i) => () => {
    //     setAddress(defaultValues)
    // }


    // const isValidPostalCode = (value) => {
    //     const regex = /^[0-9]+$/;
    //     return regex.test(value);
    // };



    const handleChange = async (name, value) => {
        if (name === "postalCode") {
            // Remove non-numeric characters
            const numericValue = value?.replace(/\D/g, "")?.slice(0, 6);
            setAddress({ ...address, [name]: numericValue });
            // fetchLocByPincode(numericValue)

        } else {
            setAddress({ ...address, [name]: value });
        }
    }


    // console.log(address, "vinay")


    const handleSubmit = () => {
        // console.log(address, "address")
        let LocInfo = {
            "resourceType": "Location",
            status: "active",
            name: address.line1,
            // line: [`${address.line1 && address.line1}`, `${address.line2 && address.line2}`, `${address.district && address.district}`, `${loc.lat && loc.lat}`, `${loc.lng && loc.lng}`],
            address: {
                ...(address.line1 && { line: address.line1 }),
                ...(address.line2 && { line: address.line2 }),
                ...(address.state && { state: address.state }),
                ...(address.district && { district: address.district }),
                ...(address.subDistrict && { city: address.subDistrict }),
                ...(address.postalCode && { postalCode: address.postalCode }),
                // ...(address.subDistrict && { subDistrict: address.subDistrict }),
                ...(address.country && { country: address.country }),
                line: [address.line1, address.line2, address.subDistrict, address.location]?.filter(Boolean),
                use: 'work',
                type: 'physical',
            },
            ... ((loc.lng && loc.lat) && { position: { "longitude": loc.lng, "latitude": loc.lat } }),
            ...(orgLocation?.description && { description: orgLocation?.description }),
            managingOrganization: {
                "reference": `${"Organization" + "/" + orgAddress?.id}`
            },
            ...(orgLocation?.hoursOfOperation && { hoursOfOperation: orgLocation?.hoursOfOperation })
        }

        // console.log(deleteKeys({ id: locationInfo?.resource?.id, ...LocInfo }), "LocInfo");
        props.submitData(locationInfo?.resource?.id ? deleteKeys({ ...LocInfo, id: locationInfo?.resource?.id }) : {
            ...deleteKeys(LocInfo)
        })
        // props.submitData({ ...deleteKeys(LocInfo) })

        // const { state, city, country, district, postalCode } = address
        // let data = {
        //     line: [address.line1, address.line2, address.subDistrict, address.location].filter(Boolean),
        //     country,
        //     state,
        //     city,
        //     district,
        //     postalCode,
        //     use: 'work',
        //     type: 'physical',
        //     text: address.text || displayAddress(address)
        // }
        // // console.log({ ...orgAddress, address: [deleteKeys(data)] })


        // props.submitData({ ...orgAddress, address: [deleteKeys(data)] })
    }

    const locateMap = () => {
        if (loc.lat && loc.lng) {
            console.log(typeof (loc.lat), loc.lng, "location")
            setOpenMap(true)
        }
        else {
            const Location = window.navigator && window.navigator.geolocation
            if (Location) {
                Location.getCurrentPosition((position) => {
                    handleChange('location', `${position.coords.latitude} ,${position.coords.longitude}`)
                    setLoc({ lat: position.coords.latitude, lng: position.coords.longitude })
                    setOpenMap(true)
                }, (error) => {
                    alert('Please enable location')
                })
            }
        }
    }

    const handleCoords = (t) => {
        if (adjust)
            setLoc({ lat: t.lat, lng: t.lng })
        handleChange("location", `${t.lat},${t.lng}`)
    }

    const handleMap = () => {
        setOpenMap(prev => !prev)
        setAdjust(false)
    }

    // console.log(address.searchedVal, "searchVal")
    console.log(states, "state")
    // console.log(subDistricts, "subDistricts")
    return (
        <ValidatorForm onSubmit={handleSubmit}>
            {/* <Box sx={{ maxHeight: '480px', overflow: 'auto' }}> */}
            <Grid container spacing={2} className="ph1 mb1" sx={{ height: "410px", overflow: "auto" }}>
                {/* <Grid item xs={12} className="capitalize">
                <RemoveCircleIcon className="cursPoint" sx={{ color: 'red' }} onClick={deleteAddress} /> {address.use} Address - {address.type === 'both' ? "Physical & Postal" : address.type}
                </Grid> */}

                {/* add search field  */}
                {/* <Grid container spacing={2} sx={{ paddingLeft: "15px", paddingTop: "10px" }}>
                    <Grid item md={3} sm={3} xs={12} >
                    Search for Location Name
                    </Grid>
                    <Grid item md={9} sm={9} xs={12}>
                        <Autocomplete
                            options={locationList}
                            getOptionLabel={(option) => option.name || ""}
                            fullWidth
                            value={null}
                            onChange={(event, value) => {

                                console.log("Selected Option:", value);
                                if (value) {

                                    setAddress((prev) => ({ ...prev, searchedVal: value.name }));
                                }

                            }}

                            className="input_shadow select_padding"
                            inputValue={address.searchedVal}
                            onInputChange={(event, newInputValue) => {
                                setAddress((prev) => {
                                    return { ...prev, searchedVal: newInputValue }
                                })

                            }}
                            renderInput={(params) => (
                                <TextField className='input_shadow input_padding' placeholder="search for Location" fullWidth {...params} label="" variant="outlined" />
                            )}
                        />
                    </Grid>

                </Grid>  */}

                <OrgLayout
                    label="Search By Organisation Name *"
                    name="searchedVal"
                    input="select"
                    options={locationList || []}
                    value={address?.searchedVal}
                    getOptionLabel={(option) => option.name || ""}
                    onInputChange={handleSearchInputOfLoc}
                    handleChange={handleChange} // Corrected prop name
                    placeholder="Search for Location"
                // validators={locationInfo?.resource?.id ? [] : ["required"]}
                // errorMessages={["required field"]}
                />


                {/* 
                <OrgLayout label="Search By Organization Name " placeholder="Flat no. / House no. /Building Name" name="searchedVal" value={address.searchedVal} handleChange={handleChange} required errorMessages={['Enter Flat no. / House no.']} /> */}
                {/* <OrgLayout label="Search for Location pincode" placeholder="Postal Code" name="postalCode" value={address?.postalCode} handleChange={handleChange} validators={["required"]} errorMessages={['Enter Flat no. / House no.']} /> */}
                <OrgLayout label="Address Line 1" placeholder="Flat no. / House no. /Building Name" name="line1" value={address.line1} handleChange={handleChange} required errorMessages={['Enter Flat no. / House no.']} />
                <OrgLayout label="Address Line 2" placeholder="Street/ Road/ Area / Locality" name="line2" value={address.line2} handleChange={handleChange} errorMessages={['Enter Street / Locality']} />
                <Grid item sm={3}>
                    Location
                </Grid>
                <Grid item sm={9}>
                    <Grid container spacing={2}>
                        <Grid item md={6}>
                            India
                        </Grid>

                        <FormLayout type="select" placeholder='Select State' name="state" options={states} value={address.state} handleChange={handleChange} required errorMessages={['Select State']} />
                        <FormLayout type="select" placeholder='Select District' name="district" options={districts} value={address.district} handleChange={handleChange} required errorMessages={['Enter district']} />
                        <FormLayout placeholder='Postal Code' name="postalCode" value={address.postalCode} handleChange={handleChange} />
                        <FormLayout type="select" placeholder='Select Sub-district' name="subDistrict" options={subDistricts} value={address.subDistrict} handleChange={handleChange} getOptionLabel={(option) => option || ""} errorMessages={['Enter sub-district']}
                            onInputChange={(event, newInputValue) => {
                                handleChange("subDistrict", newInputValue)
                            }}
                        />
                        {/* {console.log(subDistricts, "123")} */}
                        {/* <FormLayout placeholder='Select City' name="city" value={address.city} handleChange={handleChange} /> */}
                    </Grid>
                </Grid>
                {/* <FormLayout type="select" placeholder='Select City' name="city" options={villages} value={address.city} handleChange={handleChange} /> */}


                {/* <OrgLayout label="Geo-Location" required errorMessages={['Locate Map']} value={address.location} comp={<Button onClick={locateMap} sx={{ p: '2px!important', fontSize: '13px', width: '100%' }} className="save-btn" size="small" variant="contained"><MapOutlinedIcon />&nbsp;Locate</Button>} placeholder="Latitude,Longitude" handleChange={handleChange} /> */}

                <Grid item md={12}>
                    {openMap || (loc.lat && loc.lng) ?
                        <>
                            <p className="flx capitalize" style={{ marginTop: '10px' }}><span><FiEdit3 className="cursPoint" onClick={handleMap} style={{ fontSize: '22px', color: 'blue' }} />&nbsp;&nbsp;&nbsp;</span>{`${address.line1}, ${address.line2}, ${address.city ? `${address.city}, ` : ''}${address.subDistrict}, ${address.district}, ${address.state}, India${address.postalCode ? ` - ${address.postalCode}` : ''}`}</p>
                            <div style={{ height: '350px', width: '100%', position: 'relative' }}>
                                <Button className="capitalize" sx={{ position: 'absolute', top: 10, right: 60, zIndex: '99999', backgroundColor: 'white!important', color: 'black', px: 4 }} size="small" variant="contained" onClick={() => setAdjust(true)}>Adjust</Button>
                                <GoogleMapReact
                                    bootstrapURLKeys={{ key: "AIzaSyBoKERaSenAxv2oAp-Ma3qNVDlJIwh0UY8" }}
                                    defaultCenter={loc}
                                    defaultZoom={11}
                                    zoom={adjust ? 20 : 11}
                                    onClick={handleCoords}
                                >
                                    <LocationIcon
                                        lat={loc.lat}
                                        lng={loc.lng}

                                    />
                                </GoogleMapReact>
                            </div>
                        </>
                        : ""}
                </Grid>
            </Grid >
            <FormDivider />

            <Actions close={props.close} name={locationInfo?.resource?.id ? "Update" : "Create"} />
        </ValidatorForm >
    )
}

export default Location